<div class="mb-4">
  <div class="d-flex justify-content-end mb-4 mmb-4">
    <button class="btn btn-primary" (click)="addFloor(floorFormModal)">Add</button>
  </div>

  <div class="card mb-4 mmb-4">
    <div class="card-body">
      <div class="card-title mb-4" style="font-size: 16px"><strong>Floor Listing</strong></div>

      <div class="table-responsive" *ngIf="property?.floors?.length && property.floors.length > 0; else noFloors">

        <table class="table  responsive-flex-table">
          <thead>
          <th>Floor</th>
          <th>From Year</th>
          <th>Upto Year</th>
          <th>Property Category</th>
          <th>Property type</th>
          <th>Property Sub Type</th>
          <th>Carpet Area</th>
          <th width="80px">Actions</th>
          </thead>
          <tbody >
          <tr *ngFor="let floor of property.floors; let i = index">
            <td>{{FLOOR_NAMES[floor.floor_number] ?? ''}}</td>
            <td>{{floor.from_year ? floor.from_year + '-' + (toInt(floor.from_year) + 1) : ''}}</td>
            <td>{{floor.upto_year ? floor.upto_year + '-' + (toInt(floor.upto_year) + 1) : ''}}</td>
            <td>{{PROPERTY_CATEGORIES[floor.property_category]}}</td>
            <td>{{floor.propertyType?.name ?? ''}}</td>
            <td>{{floor.propertySubType?.name ?? ''}}</td>
            <td>{{floor.carpet_area ?? ''}}</td>
            <td width="80px">
              <div class="d-flex justify-content-between mob_align_right" style="font-size: 18px">
                <i class="bi bi-pencil-fill mr-2 text-primary" style="cursor: pointer"
                   (click)="editFloor(floorFormModal, floor)"
                ></i>
                <i class="bi bi-x-lg text-danger" style="cursor: pointer" (click)="deleteFloor(i)"></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">Previous</button>

  <button class="btn btn-primary" (click)="nextForm()">Next</button>
</div>

<ng-template #floorFormModal let-modal>
  <div class="modal-header">
    <div class="title">
      <h5 class="modal-title mb-2">Floor Details</h5>
      <h6 class="modal-sub-title">Fill required information</h6>
      <i class="fa fa-close fa-times" (click)=" modal.dismiss();" ></i>

    </div>
  </div>
  <div class="modal-body">
    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="floor-number">Floor Number<span class="text-danger">*</span></label>
        <ng-select id="floor-number"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="Select Floor Number"
                   [items]="FORMATTED_FLOOR_NUMBERS"
                   [(ngModel)]="formFloor.floor_number"
                   appInputError
                   [(error)]="floorFormErrors.floor_number"
                   [disabled]="disableFloorNumber"
        ></ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label>From Year<span class="text-danger">*</span></label>
        <ng-select placeholder="Select Year"
                   [(ngModel)]="formFloor.from_year"
                   [compareWith]="compareFinancialYear"
                   appInputError
                   [(error)]="floorFormErrors.from_year"
        >
          <ng-option *ngFor="let year of financialYears" [value]="year">
            {{year + '-' + (year + 1)}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label>Upto Year<span class="text-danger">*</span></label>
        <ng-select placeholder="Select Year"
                   [(ngModel)]="formFloor.upto_year"
                   [compareWith]="compareFinancialYear"
                   appInputError
                   [(error)]="floorFormErrors.upto_year"
        >
          <ng-option *ngFor="let year of financialYears" [value]="year">
            {{year + '-' + (year + 1)}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="property-category">Property Category<span class="text-danger">*</span></label>
        <ng-select id="property-category"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="Select Property Category"
                   [items]="FORMATTED_PROPERTY_CATEGORIES_WITHOUT_MIX"
                   [ngModel]="formFloor.property_category"
                   (ngModelChange)="setPropertyCategory($event)"
                   appInputError
                   [(error)]="floorFormErrors.property_category"
        ></ng-select>
      </div>
    </div>

    
    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="property-type">Property Type<span class="text-danger">*</span></label>
        <ng-select id="property-type"
                   placeholder="Select Property Type"
                   [(ngModel)]="formFloor.propertyType"
                   appInputError
                   [(error)]="floorFormErrors.property_type"
                   (change) = "getPropertySubtype($event)"
        >
      <ng-option *ngFor="let data of propertyTypeData" [value] = "data">{{data.name}}</ng-option>
    </ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="property-sub-type">Property Sub Type<span class="text-danger">*</span></label>
        <ng-select id="property-sub-type"
                placeholder="Select Sub Property Type"
                [(ngModel)]="formFloor.propertySubType"
                appInputError
                [(error)]="floorFormErrors.property_sub_type"
        >
        <ng-option *ngFor="let data of propertySubTypeData" [value] = "data">{{data.name}}</ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label>Carpet Area (In sq ft)<span class="text-danger">*</span></label>
        <input class="form-control" type="text"
               placeholder="Enter Carpet Area"
               [(ngModel)]="formFloor.carpet_area"
               appInputError
               [(error)]="floorFormErrors.carpet_area"
               [disabled]="disableCarpetArea"
        >
      </div>
    </div>






  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="floorSubmit(modal)">Submit</button>
  </div>
</ng-template>

<!-- No Floors -->
<ng-template #noFloors>
  <p class="no_data">No floors</p>
</ng-template>

import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";
import {AuthGuard} from "../../guards/auth.guard";
import {ListComponent} from "./list/list.component";

const routes: Routes = [
  {
    path: 'my-objections',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'list',
        component: ListComponent
      },
      {
        path: ':id/view',
        component: IndexComponent
      },
    ]
  }
];

export const myObjectionsRouting = RouterModule.forChild(routes);

import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {gql} from '@apollo/client/core';
import {Street, Ward} from '../models';
import {BaseService} from './base-service.service';
import {IServicePaginate} from '../@types/service-paginate';
import {IServiceGetOne} from '../@types/service-get-one';

@Injectable({
  providedIn: 'root'
})
export class WardService extends BaseService implements IServicePaginate<Street>, IServiceGetOne<Street> {
  readonly maxRecords: number = 100000;

  constructor(private apollo: Apollo,) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public getAll(id?: number | undefined, coordsRequired = false): Observable<Ward[] | []> {
      return this.apollo.watchQuery({
        query: this.getAllQuery(coordsRequired)
      }).valueChanges.pipe(
        map((result: any) => result.data && result.data.Wards),
        map((result: any) => result ?? [])
      );
  }

  public paginate(page: number = 1, perPage: number = 10, term: string = ''): Observable<Ward[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, term)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.WardPaginate && result.data.WardPaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public getOne(column: string, value: any): Observable<Ward | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.WardPaginate && result.data.WardPaginate.data && result.data.WardPaginate.data[0]),
    );
  }

  /**
   * GraphQL Queries
   * */
  private getAllField(coordsRequired = false) {
    return `
      id,
      name,
      description,
      city {
        id,
        name,
      },
      ${coordsRequired ? 'gps_compressed_coordinates': ''}
    `
  }

  private getAllQuery(coordsRequired:boolean) {
    return gql`{Wards{${this.getAllField(coordsRequired)}}}`
  }

  private paginateQuery(page: number = 1, perPage: number = 10, term: string = '') {
    const where = term.trim().startsWith('where') ? term : `where: {
          column: NAME, operator: LIKE, value: "${term}%"
        }`;

    return gql`{
      WardPaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {${this.getAllField()}}
      }
    }`
  }

  public getWardsByCity(city_id: number): Observable<Ward[]> {
    return this.apollo
      .watchQuery({
        query: this.getWardsByCityQuery(city_id),
      })
      .valueChanges.pipe(
        map((result: any) => result.data && result.data.WardPaginate && result.data.WardPaginate.data)
      );
  }

  private getWardsByCityQuery(city_id: number) {
    return gql`
      {
        WardPaginate(page: 1,
          first: ${this.maxRecords},where: {AND: [{column: NAME, operator: LIKE, value: "%"}, {column: CITY_ID, value: ${city_id}}]}) {
          data {
            id
            name
            description
            city_id
            gps_compressed_coordinates
            city {
              id
              name
              __typename
            }
            __typename
          }
          __typename
        }
      
    }`;
  }

  private getOneQuery(column: string, value: any) {
    const normalizedValue = typeof value === 'string' ? `"${value}"` : value;

    return gql`{
      WardPaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: ${normalizedValue} }
      ) {
        data {${this.getAllField()}}
      }
    }`;
  }
}

import { Directive, ElementRef, Host, HostListener, Input } from '@angular/core';


@Directive({
    selector: '[inputValidation]'
})

export class InputValidaitonDirective {
    validator: any = {
        number: /[0-9.]$/,
        latLong: /[0-9.,]$/,
        onlyNumber: /[0-9]$/
    }

    @Input() inputValidation!:string;
    constructor(private el: ElementRef) {

    }

    @HostListener('keypress', ['$event']) onInputChange(event:KeyboardEvent) {
        return event.key && this.inputValidation && this.validator[this.inputValidation].test(event.key);
    }

    @HostListener('paste', ['$event']) onPaste(event:any) {
        const copiedText = event.clipboardData.getData('text/plain');
        return copiedText && this.inputValidation && this.validator[this.inputValidation].test(copiedText);
    }
}
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Citizen, DocumentProofType, Property} from '../../../models';
import Swal from 'sweetalert2';
import {OwnershipTypes} from '../../../enums/ownership-types';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';
import {getFormattedErrorsByValidator, Validated, ValidationErrors} from '../../../@types/validation';
import {ReplaySubject} from 'rxjs';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentProofTypeService} from '../../../services/document-proof-type.service';
import {takeUntil} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {OwnerEditValidationRules} from '../../property-correction/data/validation-rules';

// @ts-ignore
import Validator from 'Validator/src/validator.js';

@Component({
  selector: 'app-owners',
  templateUrl: './owners.component.html',
  styleUrls: ['./owners.component.scss']
})
export class OwnersComponent implements OnInit {

  @Input() property!: Property;

  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('previousForm') previousFormEmitter = new EventEmitter();

  readonly OWNER_FORM_ADD_MODE = 1;
  readonly OWNER_FORM_EDIT_MODE = 2;

  readonly OWNERSHIP_TYPES: { id: OwnershipTypes, name: string }[] = [
    {id: OwnershipTypes.Individual, name: 'Individual'},
    {id: OwnershipTypes.Partnership, name: 'Partnership'},
    {id:OwnershipTypes.Company, name: 'Company'},
    {id: OwnershipTypes.Government_Building, name:  'Government Building'},
    {id: OwnershipTypes.Muncipality, name: 'Muncipality'},
    {id: OwnershipTypes.Religious_Trust,name: 'Religious Trust'},
    {id: OwnershipTypes.Charitable_Organisations,name:  'Charitable Organisations'},
    {id: OwnershipTypes.Other,name: 'Other'},
    
  ];

  readonly GENDER_TYPES = getFormattedGenderTypes();
  readonly RELATION_TYPES = Citizen.formattedRelations();
  readonly CITIZEN_RELATION = Citizen.RELATION;

  formOwner = new Citizen();
  documentProofTypes: DocumentProofType[] = [];
  ownerFormErrors: ValidationErrors<{ [key: string]: string }> = {};

  private ownerFormMode = this.OWNER_FORM_ADD_MODE;
  private destroy$ = new ReplaySubject();

  constructor(
    private modalService: NgbModal,
    private documentProofTypeService: DocumentProofTypeService,
  ) { }

  ngOnInit(): void {
    this.documentProofTypeService.getAll().pipe(takeUntil(this.destroy$)).subscribe((i) => {
      this.documentProofTypes = i;
    });
  }

  nextForm() {
    if (!this.property.ownershipType?.id) {
      Swal.fire('Error', 'Select Ownership Type', 'error');
      return;
    }else if(this.property.ownershipType?.id === OwnershipTypes.Individual && this.property?.owners?.length > 1) {
      Swal.fire('Error', 'More than one owners can not be added for Individual Ownership Type', 'error');
      return;
    }

    const ownersCount = this.property?.owners?.length ? this.property.owners.length : 0;
    const minOwnersCount = this.property.ownershipType.id === OwnershipTypes.Partnership ? 2 : 1;

    if (ownersCount < minOwnersCount) {
      Swal.fire('Error', `The number of owners must be at least ${minOwnersCount}!`, 'error');
      return;
    }

    this.nextFormEmitter.emit();
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  ownershipTypeCompareFn(a: any, b: any) {
    return a.id === b.id;
  }

  disableAddButton() {
    return (this.property.ownershipType?.id  === OwnershipTypes.Individual && this.property?.owners?.length);
  }

  addOwner(modal: any) {
    this.ownerFormMode = this.OWNER_FORM_ADD_MODE;
    this.formOwner = new Citizen();
    this.openOwnerFormModal(modal);
  }

  editOwner(modal: any, owner: Citizen) {
    this.ownerFormMode = this.OWNER_FORM_EDIT_MODE;
    this.formOwner = Object.assign(new Citizen(), cloneDeep(owner));
    this.openOwnerFormModal(modal);
  }

  deleteOwner(index: number) {
    this.property.owners = this.property.owners.filter((v, i) => i !== index);
  }

  ownerSubmit(modal: any) {
    const validated = this.validateOwner(this.formOwner);

    this.ownerFormErrors = validated.errors;

    if (!validated.isSuccess) return;

    modal.dismiss();

    if (this.ownerFormMode === this.OWNER_FORM_ADD_MODE) {
      this.formOwner['id'] = this.property.owners.length + 1;
      this.property.owners.push(this.formOwner);
      return;
    }

    const editingOwnerIndex = this.property.owners.findIndex((el) => el.id === this.formOwner.id);

    if (editingOwnerIndex === -1) return;

    this.property.owners[editingOwnerIndex] = this.formOwner;
  }

  private openOwnerFormModal(modal: any) {
    this.ownerFormErrors = {};
    this.modalService.open(modal, {scrollable: true, centered: true});
  }

  private validateOwner(owner: Citizen): Validated<Citizen> {
    let rules = cloneDeep(OwnerEditValidationRules);
    if(this.formOwner.relation_name?.trim()){
      rules['relation'] = 'required';
    }else {
      this.formOwner.relation = null as any;
    }
    const errors: { [key: string]: string } = {};
    const validated = Validator.make(owner, rules);

    return {
      isSuccess: !validated.fails(),
      errors: getFormattedErrorsByValidator(validated.getErrors() ?? {}),
    };
  }
}



<div class="container">
    <div class="row">
       <div id="content" class="col-ms-12">
          <div class="plugin-news news-view split-view">
             <div class="content mb-5">
                <div class="content-view">
                   <h1 class="mt-5">Term of Service</h1>
                   <div class="content-article">
                     <p>Terms and Conditions of Use</p>
                        <ol>
                        <li>Terms</li>
                        </ol>
                        <p>By accessing this Website, accessible from <span *ngIf="cs.city === 'bharatpur'">https://udtbharatpurmc.co.in/</span><span *ngIf="cs.city === 'jodhpur'">https://nnjsouth.in/</span><span *ngIf="cs.city === 'kotputli'">https://udtaxkotputli.org/</span><span *ngIf="cs.city === 'alwar'">https://mcaudtax.in/</span>, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trademark law.</p>
                        <ol start="2">
                        <li>Use License</li>
                        </ol>
                        <p>Permission is granted to temporarily download one copy of the materials on <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span> <span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
                        <ul>
                        <li>modify or copy the materials.</li>
                        <li>use the materials for any commercial purpose or for any public display.</li>
                        <li>attempt to reverse engineer any software contained on <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> Website.</li>
                        <li>remove any copyright or other proprietary notations from the materials; or</li>
                        <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                        </ul>
                        <p>This will let <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated, and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</p>
                        <ol start="3">
                        <li>Disclaimer</li>
                        </ol>
                        <p>All the materials on <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span><span *ngIf="cs.city === 'jodhpur'"></span>Website are provided "as is". Municipal Corporation of Bharatpur makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Municipal Corporation of Bharatpur does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>
                        <ol start="4">
                        <li>Limitations</li>
                        </ol>
                        <p><span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Municipal Corporation of Bharatpur Website, even if Municipal Corporation of Bharatpur or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>
                        <ol start="5">
                        <li>Revisions and Errata</li>
                        </ol>
                        <p>The materials appearing on <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> Website may include technical, typographical, or photographic errors. Municipal Corporation of Bharatpur will not promise that any of the materials in this Website are accurate, complete, or current. Municipal Corporation of Bharatpur may change the materials contained on its Website at any time without notice. Municipal Corporation of Bharatpur does not make any commitment to update the materials.</p>
                        <ol start="6">
                        <li>Links</li>
                        </ol>
                        <p><span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Municipal Corporation of Bharatpur of the site. The use of any linked website is at the user own risk.</p>
                        <ol start="7">
                        <li>Site Terms of Use Modifications</li>
                        </ol>
                        <p><span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span> may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>
                        <ol start="8">
                        <li>Your Privacy</li>
                        </ol>
                        <p>Please read our Privacy Policy.</p>
                        <ol start="9">
                        <li>Governing Law</li>
                        </ol>
                        <p>Any claim related to <span *ngIf="cs.city === 'kotputli'">Kotputli Municipal Corporation</span><span *ngIf="cs.city === 'bharatpur'">Municipal Corporation of Bharatpur</span><span *ngIf="cs.city === 'jodhpur'">Municipal Corporation of Jodhpur</span>Website shall be governed by the laws of in without regards to its conflict of law provisions</p>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </div>
 </div>
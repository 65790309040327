import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-image-pdf-viewer',
  templateUrl: './image-pdf-viewer.component.html',
  styleUrls: ['./image-pdf-viewer.component.scss']
})
export class ImagePdfViewerComponent implements OnInit {
  currentPhotoUrl!:string;
  pdfFileUrl!:SafeResourceUrl;
  openImageViewer = true;
  imageId!:string;
  @Input() documents!:string[];
  @Input() startDocumentIndex!:number;
  @Output() close = new EventEmitter();
  documentIndex = 0;
  constructor(private commonService: CommonService,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.documentIndex = this.startDocumentIndex || 0;
    this.changeDocument();
  }

  showPrevious() {
    if(this.documentIndex > 0) {
      this.documentIndex--;
      this.changeDocument();
    }
  }

  showNext() {
    if(this.documentIndex < this.documents.length - 1) {
      this.documentIndex++;
      this.changeDocument();
    }
  }

  private changeDocument() {
    const file = this.documents[this.documentIndex];
    if(this.commonService.isPdfFile(file)) {
      this.openImageViewer = false;
      this.pdfFileUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.documents[this.documentIndex]  + '#toolbar=0');
    }else {
      this.openImageViewer = true;
      this.currentPhotoUrl = this.commonService.getAbsoluteUrl(this.documents[this.documentIndex]);
    }
  }
}

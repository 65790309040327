import { Directive, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';


@Directive({
    selector: '[dragDrop]'
})

export class DragDropDirective {
    @Output() onFileDrop: EventEmitter<any> = new EventEmitter();

    @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
        evt.preventDefault();
        evt.stopPropagation();
        this.onFileDrop.emit(evt?.dataTransfer?.files);
    }
}
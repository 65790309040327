import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObjectionStatuses } from 'src/app/enums/objection-statuses';
import { PattaVerificationService } from 'src/app/pages/patta-verification/patta-verification.service';
import { LanguageService } from 'src/app/services/language.service';
import { ObjectionService } from 'src/app/services/objection.service';

@Component({
  selector: 'app-correction-request-status',
  templateUrl: './correction-request-status.component.html',
  styleUrls: ['./correction-request-status.component.scss']
})
export class CorrectionRequestStatusComponent implements OnInit {
  objectionNumber: any;
  correctionRequestDetails!: any;
  ObjectionStatuses = ObjectionStatuses;
  isCorrectionRequestExist! : boolean;
  showLoader: boolean = false;
  headings!: any;

  objectionHeadingMessage:any = {
    enter_objection_number : this.languageService.translate('widget.correction_request_status.correctionRequest_enter_objection_number'),
    correction_request_msg  : this.languageService.translate('widget.correction_request_status.correctionRequest_enter_valid_correction_request_number'),
    submit     :   this.languageService.translate('widget.correction_request_status.submit'),
    summary  : this.languageService.translate('widget.correction_request_status.summary')
  };

  readonly correctionRequestHeadings = [
    { key: 'objection_number', name: 'Objection Number'},
    { key: 'property_uid', name: 'Property UID' },
    { key: 'submitted_by', name: 'Submitted By' },
    { key: 'created_at', name: 'Created at' },
    { key: 'remark', name: 'Remark' }
  ]
  
  readonly objectionHeadings = [
    { key: 'objection_number', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_objection_number_label') },
    { key: 'pattaApplication', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_application_number_label') },
    { key: 'submitted_by', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_submitted_by_label') },
    { key: 'created_at', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_created at_label') },
    { key: 'ObjectionType', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_query_releted_to_label')},
    { key: 'Status', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_current_status_label')},
    { key: 'admin_remark', name: this.languageService.translate('widget.objectionHeadings.objectionHeadings_remark_from_authority_label') },
  ]
  constructor(
    private objectionService: ObjectionService,
    private pattaVerificationService: PattaVerificationService, 
    private languageService: LanguageService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>,) { }

  ngOnInit(): void {
  }

  getCorrectionRequestStatus(objectionNo: any){
    this.showLoader = true;
    if(this.data.name === 'home.track_raised_objection_status_sub_menu'){
      this.headings = this.correctionRequestHeadings;
      this.objectionService.getCorrectionRequestStatusById(objectionNo).subscribe(res =>{
        this.setCorrectionRequestValues(res);
      },
      error =>{
        this.isCorrectionRequestExist = false;
        this.showLoader = false;
      });

    } else {
      this.headings = this.objectionHeadings;
      this.pattaVerificationService.getPattaObjectionStatusById(objectionNo).subscribe(res =>{
        this.setCorrectionRequestValues(res);
      },
      error =>{
        this.isCorrectionRequestExist = false;
        this.showLoader = false;
      });
    }
  
  }

  setCorrectionRequestValues(response: any) {
    if(response){
      this.showLoader = false;
      this.correctionRequestDetails = response;
      this.isCorrectionRequestExist = true;
    } else{
      this.showLoader = false;
      this.isCorrectionRequestExist = false;
    }
  }
  getDetailsValue(key: string) {
    const keysArr = key.split('.');
    let value = this.correctionRequestDetails;
    keysArr.forEach((key: string) => {
      if(key === 'submitted_by'){
        value = value?.first_name ? `${value.first_name} ${value.last_name}` : (value as any)[key].first_name + ' ' + (value as any)[key].last_name

      } else if (key === 'created_at'){
        value = new DatePipe('en-US').transform((value as any)[key], 'dd-MM-yyyy');
      } else if (key=== 'ObjectionType'){
        value = JSON.parse(value[key]).name;
      } else if(key === 'pattaApplication'){
        value = value?.[key] ? value?.[key]?.application_number : '';
      } else{
        value = (value as any)[key];
      }
    })
    return value || 'NA';
  }

  closeModal(){
    this.dialogRef.close();
  }
}

import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {gql} from "@apollo/client/core";
import {map} from 'rxjs/operators';
import TaxPayRequest from '../@types/tax-pay-request';

@Injectable({
  providedIn: 'root'
})
export class PayuService {

  constructor(private apollo: Apollo) {
  }

  public taxPayRequest(info: TaxPayRequest): Observable<string | null> {
    return this.apollo.mutate({
      mutation: gql`mutation{ taxPayRequestForPayU(
        first_name: "${info.firstName}",
        last_name: "${info.lastName}",
        email: "${info.email}",
        phone: "${info.phoneNumber}",
        property_id: ${info.propertyId}
        amount: ${info.amount},
        formToken: "${info.formToken}"
      )
      }`,
    }).pipe(
      map((res: any) => typeof res?.data?.taxPayRequestForPayU === 'string'
        ? res.data.taxPayRequestForPayU
        : undefined
      )
    );
  }
}

<app-review-pdf [property]="property"
                [taxCalculation]="taxCalculation"
                [ledgersDetails]="ledgersDetails"
                [selfAssessmentFormPreSave]="selfAssessmentFormPreSave"
                [disabled]="isLedgersLoading || isLedgersLoading || isSelfAssessmentFormPreSave"
></app-review-pdf>

<!-- General information start -->
<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <div class="card-body responsive_table_view">
    <h4 class="card-title brd_btm">General Information</h4>
    <div class="row mb-4 mmb-4 ">
      <div class="col-6  "><strong>District</strong></div>
      <div class="col-6">{{ property.district?.name ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4 ">
      <div class="col-6  "><strong>Municipal Corporation</strong></div>
      <div class="col-6">{{ property.city?.name ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4 ">
      <div class="col-6  "><strong>Ward</strong></div>
      <div class="col-6">{{ property.ward.name ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Colony</strong></div>
      <div class="col-6">{{ property.colony?.name ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4">
      <div class="col-6"><strong>Landmark</strong></div>
      <div class="col-6">{{ property.landmark }}</div>
    </div>
    <div class="row mb-4 mmb-4">
      <div class="col-6"><strong>Pincode</strong></div>
      <div class="col-6">{{ property.pincode }}</div>
    </div>
    <div class="row mb-4 mmb-4">
      <div class="col-6"><strong>Address House Number</strong></div>
      <div class="col-6">{{ property.address_house_number}}</div>
    </div>
    <div class="row mb-4 mmb-4">
      <div class="col-6"><strong>Multi Storey / Apartment / Complex</strong></div>
      <div class="col-6">
        {{
        property.multi !== undefined && property.multi !== null ? (property.multi ? 'Yes' : 'No') : ''
        }}
      </div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Road width (In Ft.)</strong></div>
      <div class="col-6">{{ property.road_width }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Construction Year</strong></div>
      <div class="col-6">{{ commonService.getYearValueAsFY(property.building_construction_year) }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Plot Allotment Year</strong></div>
      <div class="col-6">{{ commonService.getYearValueAsFY(property.plot_allotment_year) }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Plot Area (In sq.yd)</strong></div>
      <div class="col-6">{{ property.plot_area }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Vacant Area (In sq.yd)</strong></div>
      <div class="col-6">{{ property.vacant_area }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Ownership type</strong></div>
      <div class="col-6">{{ property.ownershipType?.name ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Latitude</strong></div>
      <div class="col-6">{{ property.latitude ?? '' }}</div>
    </div>
    <div class="row mb-4 mmb-4  ">
      <div class="col-6  "><strong>Longitude</strong></div>
      <div class="col-6">{{ property.longitude ?? '' }}</div>
    </div>
  </div>
</div>
<!-- General information end -->

<!-- Owners Details Start-->
<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <div class="card-body">
    <h4 class="card-title">Owner Details</h4>
    <div class="table-responsive">
      <table class="table responsive-flex-table">
        <thead>
        <th>#</th>
        <th>Owner Name</th>
        <th>Gender</th>
        <th>Phone Number</th>
        <th>Relation</th>
        <th>Relative Name</th>
        </thead>
        <tbody>
        <tr *ngFor="let owner of property.owners; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ (owner.first_name ?? '') }} {{(owner.last_name ?? '')}}</td>
          <td>{{ GENDER_TYPES[owner.gender]?.name }}</td>
          <td>{{owner.phone_number}}</td>
          <td>{{CITIZEN_RELATION[owner.relation] ?? ''}}</td>
          <td>{{owner.relation_name ?? ''}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Owners Details End -->

<!-- Floors details start -->
<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <div class="card-body">
    <h4 class="card-title">Floors Details</h4>
    <div class="table-responsive" *ngIf="property?.floors?.length && property.floors.length > 0;">
      <table class="table responsive-flex-table">
        <thead>
        <th>Floor</th>
        <th>From Year</th>
        <th>Upto Year</th>
        <th>Property Category</th>
        <th>Property type</th>
        <th>Property Sub Type</th>
        <th>Carpet Area (In Sq.Ft.)</th>
        </thead>
        <tbody>
        <tr *ngFor="let floor of property.floors; let i = index">
          <td>{{FLOOR_NAMES[floor.floor_number] ?? ''}}</td>
          <td>{{floor.from_year ? floor.from_year + '-' + (toInt(floor.from_year) + 1) : ''}}</td>
          <td>{{floor.upto_year ? floor.upto_year + '-' + (toInt(floor.upto_year) + 1) : ''}}</td>
          <td>{{PROPERTY_CATEGORIES[floor.property_category]}}</td>
          <td>{{floor.propertyType?.name ?? ''}}</td>
          <td>{{floor.propertySubType?.name ?? ''}}</td>
          <td>{{floor.carpet_area ?? ''}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- Floors details end -->

<!-- Photos -->
<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)"
     *ngIf="property?.photos?.length">
  <div class="card-body">
    <h4 class="card-title">Property Photos</h4>

    <app-photos-list [photos]="property.photos"></app-photos-list>
  </div>
</div>
<!-- Photos -->

<!-- Tax Calculation Start -->
<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <div class="card-body">
    <h4 class="card-title">Estimated UD Tax Calculation</h4>
    <div class="table-responsive" *ngIf="taxCalculation; else estimatedUDTaxAlternative">
      <table class="table responsive-flex-table">
        <thead>
        <th>Sl No</th>
        <th>Usage Category</th>
        <th>Area Type</th>
        <th>Area(sq yd)</th>
        <th>DLC Rate</th>
        <th>Rate/2000</th>
        <th>Tax (In Rs.)</th>
        </thead>
        <tbody>
        <tr *ngFor="let details of taxCalculation?.taxCalculation; let i = index">
          <td>{{i + 1}}</td>
          <td>{{PROPERTY_CATEGORIES[details.propertyCategory] ?? ''}}</td>
          <td>{{details.areaType}}</td>
          <td>{{details.area}}</td>
          <td>{{details.rate ?? ''}}</td>
          <td>{{details.rate ? (details.rate / 2000).toFixed(2) : ''}}</td>
          <td>{{details.tax}}</td>
        </tr>
        <tr>
          <td colspan="5"></td>
          <td><strong>Total Tax</strong></td>
          <td>{{taxCalculation.totalTax ?? '0'}}</td>
        </tr>
      </table>
    </div>
  </div>
</div>
<!-- Tax Calculation End -->

<div class="card mb-4 mmb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <div class="card-body">
    <h4 class="card-title">Ledgers</h4>
    <div class="table-responsive" *ngIf="!isLedgersLoading && ledgersDetails; else ledgersLoadingAlternative">
      <table class="table table-bordered th-bg ledger_property responsive-flex-table">
        <thead class="tax-main-head">

        <th>Financial Year</th>
        <th>Date</th>
        <th>Particulars</th>
        <th>Debit</th>
        <th>Credit</th>
        <th>Balance</th>
        </thead>
        <tbody>
        <tr class="ledger-item" *ngFor="let item of ledgersDetails?.ledgers; let i = index">
          <td data-th="Financial Year:">
            {{ item.financial_year }}-{{item.financial_year && item.financial_year + 1}}
          </td>
          <td data-th="Date:">{{ item?.transaction_datetime | date: "dd-MM-yyyy hh:mm:ss" }}</td>
          <td data-th="Particulars:">{{ formatParticulars(item) }}</td>
          <td data-th="Debit:">{{ item?.debit_amount }}</td>
          <td data-th="Credit:">{{ item?.credit_amount }}</td>
          <td data-th="Balance:" #balance>{{ item | rebateBalanceCalculator : i }}</td>
        </tr>

        <ng-container *ngIf="ledgersDetails?.penalties">
          <tr class="ledger-item" *ngFor="let penalty of ledgersDetails.penalties">
            <td colspan="2">Penalty</td>
            <td data-th="Particulars:"></td>
            <td data-th="Debit:">{{ penalty?.debit_amount | number:'1.2'}}</td>
            <td data-th="Credit:">{{ penalty?.credit_amount | number:'1.2'}}</td>
            <td data-th="Balance:" #balance>{{ penalty | rebateBalanceCalculator | number:'1.2'}}</td>
          </tr>
        </ng-container>

        <ng-container *ngIf="ledgersDetails?.rebates">
          <tr *ngFor="let rebate of ledgersDetails.rebates; let i = index" class="ledger-item">
            <td colspan="2">{{formatParticulars(rebate)}}</td>
            <td data-th="Particulars:"></td>
            <td data-th="Debit:">{{ rebate?.debit_amount }}</td>
            <td data-th="Credit:">{{ rebate?.credit_amount }}</td>
            <td data-th="Balance:" #balance>{{ rebate | rebateBalanceCalculator | number:'1.2'}}</td>
          </tr>
        </ng-container>

        <tr>
          <td colspan="2"><strong>Closing Balance</strong></td>
          <td colspan="3"></td>
          <td><strong>{{ balanceCells?.last?.nativeElement.textContent }}</strong></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary" style="margin-right: 20px;" (click)="previousForm()">Previous</button>

  <button class="btn btn-success" (click)="save()" [disabled]="isSaving">Save</button>
</div>

<ng-template #ledgersLoadingAlternative>
  <tbody *ngIf="isLedgersLoading">
  <tr>
    <td colspan="100">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>

  <tbody *ngIf="!ledgersDetails && !isLedgersLoading">
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">Error occupied</td>
  </tr>
  </tbody>
</ng-template>

<ng-template #estimatedUDTaxAlternative>
  <tbody *ngIf="isTaxCalculationLoading">
  <tr>
    <td colspan="100">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>

  <tbody *ngIf="!taxCalculation && !isTaxCalculationLoading">
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">Tax can not be calculated</td>
  </tr>
  </tbody>
</ng-template>

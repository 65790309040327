import {PropertyCategories} from '../enums/property-categories';

const propertyMapIcons = {
  [PropertyCategories.Residential]: 'assets/images/map/property-icons/residential_icon.png',
  [PropertyCategories.Commercial]: 'assets/images/map/property-icons/commercial_icon.png',
  [PropertyCategories.Industry]: 'assets/images/map/property-icons/industrial_icon.png',
  [PropertyCategories.Institutional]: 'assets/images/map/property-icons/institutional_icon.png',
  [PropertyCategories.Mix]: 'assets/images/map/property-icons/mixuse_icon.png',
  [PropertyCategories.Vacant]: 'assets/images/map/property-icons/residential_icon.png',
  [PropertyCategories.AnyOther]: 'assets/images/map/property-icons/active_map.png',
}

export function getPropertyMapIcon(propertyCategory: PropertyCategories): string {
  return (propertyMapIcons as any)[propertyCategory] ?? propertyMapIcons[PropertyCategories.AnyOther];
}

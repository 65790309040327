<div class="container" style="display: none;">
  <h2>Faqs</h2>
  <p class="lead">Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>

  <!-- <ul class="faqs-list">
    <li *ngFor="let question of questionsList" [class]="{'active':question.isActive,'faqs-item':true}"
        (click)="faqClick(question)">
      <div class="question">{{question.question}}</div>
      <div class="answer">{{question.answer}}</div>
    </li>

  </ul> -->

<ngb-accordion [closeOthers]="true" activeIds="faq-1">

  <ngb-panel id="faq-{{i+1}}" *ngFor="let question of questionsList; let i = index">
    <ng-template ngbPanelTitle>
      <h5>{{question.question}}</h5>
    </ng-template>
    <ng-template ngbPanelContent>
      <p>{{question.answer}}</p>
    </ng-template>
  </ngb-panel>

</ngb-accordion>

</div>

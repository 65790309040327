import {Component, OnInit} from '@angular/core';
import {Button} from '../../@types';
import {MatDialog} from '@angular/material/dialog';
import {SigninComponent} from '../../dialogs/signin/signin.component';
import {SignupComponent} from '../../dialogs/signup/signup.component';
import {AuthenticationService} from '../../services';
import {CityService} from '../../services/city.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CorrectionRequestStatusComponent } from 'src/app/widgets/correction-request-status/correction-request-status.component';
import { LanguageService } from 'src/app/services/language.service';
import Swal from 'sweetalert2';
import { Cities } from 'src/app/enums/cities';
import { DocumentRequiredPattaComponent } from 'src/app/widgets/document-required-patta/document-required-patta.component';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  accountButtons: Button[] = [];
  footerButtons: Button[] = [];
  navButtons: Button[] = [];
  notifications: { message: string, datetime: Date }[] = [];
  public isCollapsed = true;
  readonly homeUrl:any = "/";
  showFooter:boolean=false;
  langName! : any;

  constructor(
    public dialog: MatDialog,
    public auth: AuthenticationService,
    public cs: CityService,
    private _router: Router,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    sessionStorage.removeItem('redirectionRoute');
    this.languageService.currentLanguage.subscribe(res =>{
      if(res == "en"){
        this.langName = "English";
      }else{
        this.langName = "हिन्दी";
      }
    });
    this.showFooter = this._router.url != this.homeUrl;
    this._router.events.subscribe((event:any) => {
      if(event instanceof NavigationEnd) {
        this.showFooter = this._router.url != this.homeUrl;
      }
    })



    this.initNotifications();

    /* Buttons for Account section */
    this.accountButtons.push(new Button({name: 'home.login_registration_menu', function: this.runLoginModal, dialog: this.dialog}));

    /* Buttons for Nav Bar */
    this.navButtons.push(new Button({name: 'home.home_menu', route: '/'}));
    // this.navButtons.push(new Button({name: 'Property Search', route: '/property-search'}));
    this.navButtons.push(new Button({
      name: 'home.property_services_menu',
      route: '/property-search',
      children: [
        new Button({name: 'home.search_property_&_pay_tax_sub_menu', route: '/property-search'}),
        new Button({name: 'home.gis_map_search_sub_menu ', route: '/properties/map'}),
        new Button({name: 'home.recently_viewed_properties_sub_menu', route: '/property-search/recentlyViewed'}),
        new Button({name: 'home.no_dues_certificate_sub_menu', fragmentRoute: '/', fragment: 'anchor1'}),
        new Button({name: 'home.raise_objection_sub_menu', fragmentRoute: '/', fragment: 'anchor2'}),
        new Button({name: 'home.track_raised_objection_status_sub_menu', function: this.runCorrectionStatusModal, dialog: this.dialog}),

      ],
    }));
    this.navButtons.push(new Button({
    name: 'home.tax_self_assessment_sub_menu', 
    route: '/self-assessment-form'
  
  }));
    this.navButtons.push(new Button({name: 'home.dlc_rates_menu', route: '/dlc-rates'}));

    /* Add patta verification menu only for Bharatpur (Temp) */
    if(this.cs.id === Cities.Bharatpur) {
      this.navButtons.push(new Button({
        name: 'home.patta_services_menu',
        route: '/patta-verification',
        children: [
          new Button({name: 'home.patta_verification', route: '/patta-verification'}),
          new Button({name: 'home.gis_map_search_sub_menu ', route: '/patta-verification/map/search'}),
          new Button({name: 'home.track_patta_raised_objection_status', function: this.runCorrectionStatusModal, dialog: this.dialog}),
          new Button({name: 'home.apply_for_patta', children: [
            new Button({name: 'home.apply_patta_online', redirectionRoute: '/patta-application/online', redirectionFn: this.redirectIfLoggedIn.bind(this)}),
            new Button({name: 'home.apply_patta_offline', redirectionRoute: '/patta-application/offline', redirectionFn: this.redirectIfLoggedIn.bind(this)}),
            new Button({name: 'home.my_patta_application', redirectionRoute: '/patta-application/my-patta-applications', redirectionFn: this.redirectIfLoggedIn.bind(this)})
          ]}),
          new Button({name: 'home.document_required_for_patta', function: this.rundocumentRequiredPattaModal, dialog: this.dialog}),
        ],
      }));
    }
    

    /* Buttons for Footer */
    this.footerButtons.push(new Button({name: 'home.term_of_service_label', route: '/term-of-service'}));
    this.footerButtons.push(new Button({name: 'home.privacy_policy_label', route: '/private-policy'}));
    this.footerButtons.push(new Button({name: 'home.refund_and_cancellation_policy_label', route: '/refund-and-cancellation-policy'}));
  }

  runRegistrationModal(e: Event) {
    this.dialog.open(SignupComponent);
  }

  runLoginModal(e?: Event) {
    this.dialog.open(SigninComponent);
  }
  runCorrectionStatusModal(e: Event) {
    this.dialog.open(CorrectionRequestStatusComponent, { data: this});
  }

  rundocumentRequiredPattaModal(e: Event) {
    this.dialog.open(DocumentRequiredPattaComponent, { data: this});
  }
  initNotifications() {
    this.auth.userSubject.subscribe((user) => {
      this.notifications = (user?.portal_notifications.map((n) => ({message: n.message, datetime: n.created_at})) ?? [])
        .sort((a, b) => a.datetime < b.datetime ? 1 : -1);
    });
  }

  signOut(e: Event) {
    this.auth.signOut();
    this._router.navigate(['./']);
  }

  isRouteActive(url:string | Button[]) {
    const queryParamsIndex = this._router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this._router.url : 
    this._router.url.slice(0, queryParamsIndex);
    if(Array.isArray(url)) {
      const urls = url.filter(el => el.route).map(el => el.route);
      return urls.includes(baseUrl);
    }
    return baseUrl === url;
  }

  goToAnchor1() {
    this._router.navigate(['/my-test'], { fragment: 'anchor1' });
  }

  scrollTo(elId:string | undefined, fragementRoute:string) {
    if(!elId) return;
    this._router.navigate([`${fragementRoute}`]);
    setTimeout(() => {
      const el = document.getElementById(elId);
      el?.scrollIntoView({behavior: 'smooth'});
    },100)
  }
  onLangChange(langCode: string) {
    this.languageService.changeLang(langCode);
    if(langCode == "en"){
      this.langName = "English";
    }else{
      this.langName = "हिन्दी";
    }
  }

  redirectIfLoggedIn(route:string):boolean | void {
    if (!this.auth.user) {
      Swal.fire({
          title: this.languageService.translate('home.login_to_proceed'),
          showCancelButton: true,
          cancelButtonText : this.languageService.translate('home.cancel'),
          confirmButtonText: this.languageService.translate('home.ok'),
          icon: 'warning'
        }).then(res => {
          if(res?.isConfirmed) {
            sessionStorage.setItem('redirectionRoute', route);
            this.runLoginModal();
          }
        })
    }else {
      this._router.navigate([route]);
    }
  }
}

import {SortDirection} from "../widgets/sortable.directive";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import {Apollo} from "apollo-angular";
import {gql} from "@apollo/client/core";
import {map, tap} from "rxjs/operators";

export type PaginationInfo = {
  perPage: number,
  total: number,
  lastPage: number,
};

type TableSettings = {
  apollo: Apollo,
  queryName: string,
  needleColumns: string,
  whereCondition: (searchTerm: string) => string | undefined;
}

interface State {
  page: number;
  perPage: number;
  searchTerm: string;
  sortColumn: string;
  sortDirection: SortDirection;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

type TablePaginator<T> = {
  data: T[],
  paginatorInfo: PaginationInfo,
}

export class TableService<T> {

  private searchSub!: Subscription;

  private _loading$ = new BehaviorSubject<boolean>(true);
  private _items$ = new Subject<T[]>();
  private _total$ = new BehaviorSubject<number>(0);

  private state: State = {
    page: 1,
    perPage: 10,
    searchTerm: '',
    sortColumn: '',
    sortDirection: '',
    startIndex: 0,
    endIndex: 0,
    totalRecords: 0
  };

  private _currentLoading = false;

  constructor(private tableSettings: TableSettings) {
  }

  paginate(): void {
    this.searchSub?.unsubscribe();

    const {perPage, page} = this.state;

    this.changeLoading(true);

    this.searchSub = this.apollo.query({
      query: this.getQuery(page, perPage),
      fetchPolicy: 'no-cache',
    }).pipe(
      map((res: any): TablePaginator<T> => this.searchMap(res)),
      tap(() => this.changeLoading(false)),
    ).subscribe(this.searchResponse);
  }

  patchState(patch: Partial<State>, muted = false) {
    Object.assign(this.state, patch);

    if (muted) return;

    this.paginate();
  }

  softSubscribe() {
    return this._items$.asObservable();
  }

  private searchResponse = (result: TablePaginator<T>) => {
    if ((this.page === 1 || this.page === 0) && result.paginatorInfo.lastPage === 0) {
      this._total$.next(0);
      this.state.totalRecords = 0;
      this._items$.next([]);
      return;
    }

    if (this.page > result.paginatorInfo.lastPage) {
      this.page = result.paginatorInfo.lastPage
      return;
    }

    this.state.totalRecords = result.paginatorInfo.total;
    this.state.startIndex = (this.page - 1) * result.paginatorInfo.perPage + 1;
    this.state.endIndex = (this.page - 1) * result.paginatorInfo.perPage + result.paginatorInfo.perPage;
    this._items$.next(result.data);
    this._total$.next(result.paginatorInfo.total);
  }

  private getQuery(page: number, perPage: number) {
    return gql`{
      ${this.queryName} (
        page:${page},
        first:${perPage},
        ${this.tableSettings.whereCondition(this.searchTerm)}
        ${this.buildOrderBy()}
        ) {
          paginatorInfo { perPage, total, lastPage },
          data ${this.tableSettings.needleColumns.trim() === '' ? '' : `{ ${this.tableSettings.needleColumns} }`}
      }
    }`
  }

  private buildOrderBy() {
    return `orderBy: {
      column: "${this.sortColumn || 'id'}",
      order: ${this.sortDirection === 'desc' ? 'DESC' : 'ASC'},
    }`
  }

  private searchMap(res: any) {
    if (!res.data || !res.data[this.queryName]) {
      return this.getDefaultTablePaginator();
    }

    const result = res.data[this.queryName];

    if (!result.paginatorInfo || !result.paginatorInfo.total || !result.paginatorInfo.perPage || !result.data) {
      return this.getDefaultTablePaginator();
    }

    return result as unknown as TablePaginator<T>;
  }

  private getDefaultTablePaginator(): TablePaginator<T> {
    return {
      data: [],
      paginatorInfo: {
        total: 0,
        perPage: this.state.perPage,
        lastPage: 0,
      }
    }
  }

  /**
   * Table settings
   * */
  get apollo() {
    return this.tableSettings.apollo;
  }

  get queryName() {
    return this.tableSettings.queryName;
  }

  get needleColumns() {
    return this.tableSettings.needleColumns;
  }

  set needleColumns(value) {
    this.tableSettings.needleColumns = value;
  }

  get whereCondition() {
    return this.tableSettings.whereCondition;
  }

  set whereCondition(value) {
    this.tableSettings.whereCondition = value;
  }

  get items$(): Observable<T[]> {
    this.state.page = 1;
    this._total$.next(0);
    this.paginate();
    return this._items$.asObservable();
  }

  get softItems$(): Observable<T[]> {
    this.state.page = 1;
    this._total$.next(0);
    return this._items$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  get page() {
    return this.state.page;
  }

  get perPage() {
    return this.state.perPage;
  }

  get searchTerm() {
    return this.state.searchTerm;
  }

  get sortColumn() {
    return this.state.sortColumn;
  }

  get sortDirection() {
    return this.state.sortDirection;
  }

  get startIndex() {
    return this.state.startIndex;
  }

  get endIndex() {
    return this.state.endIndex;
  }

  get totalRecords() {
    return this.state.totalRecords;
  }

  get currentLoading() {
    return this._currentLoading;
  }

  /**
   * Table settings
   * */
  set queryName(value) {
    this.tableSettings.queryName = value;
  }

  /**
   * set the value
   */
  set page(page: number) {
    this.patchState({page});
  }

  set perPage(perPage: number) {
    this.patchState({perPage});
  }

  set startIndex(startIndex: number) {
    this.patchState({startIndex});
  }

  set endIndex(endIndex: number) {
    this.patchState({endIndex});
  }

  set totalRecords(totalRecords: number) {
    this.patchState({totalRecords});
  }

  set searchTerm(searchTerm: string) {
    this.patchState({searchTerm});
  }

  set sortColumn(sortColumn: string) {
    this.patchState({sortColumn});
  }

  set sortDirection(sortDirection: SortDirection) {
    this.patchState({sortDirection});
  }

  /**
   * Set muted
   * */
  setSortColumnMuted(sortColumn: string) {
    this.patchState({sortColumn}, true);
  }

  setSortDirectionMuted(sortDirection: SortDirection) {
    this.patchState({sortDirection}, true);
  }

  setPerPageMuted(perPage: number) {
    this.patchState({perPage}, true);
  }

  private changeLoading(status: boolean) {
    this._loading$.next(status);
    this._currentLoading = status;
  }
}

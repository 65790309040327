import {ConfigService} from './config.service';
import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {

  constructor(protected configService: ConfigService) {
  }

  init() {
    return new Promise<void>((resolve) => {
      this.appendPaytmScript();
      this.configService.init();
      this.configService.isInit$().subscribe(() => resolve());
    });
  }

  private appendPaytmScript() {
    const script = document.createElement('script');
    const url = environment.paytmUrl + 'merchantpgpui/checkoutjs/merchants/' + environment.paytmMid + '.js';
    script.setAttribute('src', url);
    script.setAttribute('crossorigin', 'anonymous');
    document.body.appendChild(script);
  }
}

export function initializeAppFactory(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

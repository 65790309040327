import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {invoiceRouting} from "./invoice.routing";



@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    invoiceRouting
  ]
})
export class InvoiceModule { }

import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Ledger} from "../../../models";
import {Property} from "../../../models";
import {capitalize, startCase} from "lodash";
import {LedgerService} from "../../../services/ledger.service";
import {RebateService} from "../../../services/rebate.service";
import {PenaltyService} from "../../../services/penalty.service";
import {Apollo, gql} from 'apollo-angular';

@Component({
  selector: 'app-property-tax-ledger',
  templateUrl: './property-tax-ledger.component.html',
  styleUrls: ['./property-tax-ledger.component.scss']
})
export class PropertyTaxLedgerComponent implements OnInit {
  @ViewChildren('balance') balanceCells?: QueryList<ElementRef>;

  Property = Property;
  isCollapsed: boolean = false;

  items: Ledger[] = [];
  rebates: Ledger[] = [];
  penalty?: Ledger;

  openedSubRows: Array<number> = [];

  @Input('property') property!: Property;

  @Output()ledgerData = new EventEmitter();

  constructor(
    private apollo: Apollo,
    private ledgerService: LedgerService,
    private rebateService: RebateService,
    private penaltyService: PenaltyService,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.apollo.query({
      query: gql`{
        ledgers: ${LedgerService.ledgersByPropertyQuery(this.property.id)},
        penalties: ${PenaltyService.query(this.property.id)},
        rebates: ${RebateService.query(this.property.id)},
      }`,
      fetchPolicy: 'no-cache'
    }).subscribe((res: any) => {
      if (!res?.data) return;

      this.items = res.data.ledgers ?? [];
      this.cdr.detectChanges();
      this.penalty = res.data?.penalties?.[0];
      this.cdr.detectChanges();
      this.rebates = res.data?.rebates ?? [];
      this.cdr.detectChanges();
      // Emit ledger data to match current year tax value ( Current year tax mismatch issue )
      this.ledgerData.emit(this.items);
    });
  }

  capitalize(s: string) {
    return capitalize(s)
  }

  isRowOpened(itemIndex: number) {
    return this.openedSubRows.includes(itemIndex);
  }

  formatParticulars(item: Ledger) {
    if (item.transaction_type === Ledger.REBATE_TYPE || item.transaction_type === Ledger.PENALTY_TYPE) {
      return startCase(`${Ledger.LEDGER_TYPES[item.transaction_type]}`) + (item.remarks ? `(${item.remarks})` : '');
    }

    return startCase(`${Ledger.LEDGER_TYPES[item.transaction_type]}`);
  }
}

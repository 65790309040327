// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultCity: 'alwar',
  // apiBaseUrl:'http://localhost:8888/',
  // apiBaseUrl:'http://ptms-api.local/',
  // apiBaseUrl: 'http://127.0.0.1:8000/',
  // apiBaseUrl:'https://bptjns.ycspl.in/',
  apiBaseUrl: 'https://alwar-dev.ycspl.in/backend/',
  // apiBaseUrl: 'https://kotputli-dev.ycspl.in/backend/public/',
  // apiBaseUrl: 'https://testptms.ycspl.in/backend/b4/public/',
  mapTileUrl: 'https://map.ycspl.in/fetchmaptile.php?x={x}&y={y}&z={z}',
  baseMapTileUrl: 'https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  payUUrl: 'https://test.payu.in/',
  paytmUrl: 'https://securegw-stage.paytm.in/',
  paytmMid: 'NagarN62644046217859',
  razorpayKey: 'rzp_test_i0vfygAMx1xhkm'
}


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {merge, Observable, of, OperatorFunction, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, switchMap, tap} from 'rxjs/operators';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';

type Item = { [key: string]: any };

@Component({
  selector: 'app-typeahead-input',
  templateUrl: './typeahead-input.component.html',
  styleUrls: ['./typeahead-input.component.scss']
})
export class TypeaheadInputComponent<T extends object> implements OnInit, OnDestroy {

  clearSubscription!: Subscription;

  @ViewChild('instance', {static: true}) instance!: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  items: Item[] = [];

  @Input() searchInputSettings = {
    auditTime: 500,
  };

  @Input() printField: string = 'value';
  @Input() currentItem: Item | string | number | undefined = undefined;
  @Input() loadItems: (search: string) => Observable<Item[]> = () => of<Item[]>([]);
  @Input() isLoading = false;
  @Input() placeholder = 'Enter';
  @Input() clear$!: Observable<any>;

  @Output() currentItemChange = new EventEmitter<Item | undefined>();

  constructor() {
  }

  ngOnInit(): void {
    this.clearSubscription = this.clear$?.subscribe(() => this.currentItemChange.emit(undefined));
  }

  ngOnDestroy(): void {
    this.clearSubscription?.unsubscribe();
  }

  itemsFormatter = (item: any) => item[this.printField];
  inputFormatter = (item: any) => item?.[this.printField] ?? this.currentItem;

  search$: OperatorFunction<any, readonly Item[]> = (text$: Observable<string>) => {
    const auditedText$ = text$.pipe(
      distinctUntilChanged()
    );

    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(auditedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap(this.loadItems),
      tap((items) => this.items = items)
    );
  }

  inputModelChangeEvent(input: any) {
    const inputType = typeof input;
    const currentItemType = typeof this.currentItem;

    this.currentItem = input;

    /**
     * This select case
     * */
    if (inputType === 'object') return;

    if (inputType === 'string' && currentItemType === 'object') {
      const local = this.currentItem;
      setTimeout(() => this.currentItem = local);
      this.currentItemChange.emit(undefined);
      return;
    }

    this.currentItem = input;
  }

  blurInput(event: any) {
    if (typeof this.currentItem === 'object') return;

    const value = event.target.value.trim();
    /* use 2 equal for match '2' and 2 this should be true */
    const item = this.items.find((item) => item[this.printField] == value);

    if (item === undefined) return;

    this.currentItemChange.emit(item)
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Apollo} from 'apollo-angular';
import {Ledger, Property} from '../models';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Search} from '../@types';
import {SearchMapProperty} from '../@types/search-map-property';
import {RadiusCondition} from '../@types/radius-condition';

interface State {
  page: number;
  pageSize: number;
  searchTerm: string;
  sortColumn: string;
  startIndex: number;
  endIndex: number;
  totalRecords: number;
}

@Injectable({
  providedIn: 'root',
})
export class PropertyService {

  // tslint:disable-next-line: variable-name
  private _loading$ = new BehaviorSubject<boolean>(true);
  // tslint:disable-next-line: variable-name
  private _tables$ = new BehaviorSubject<any[]>([]);
  // tslint:disable-next-line: variable-name
  private _total$ = new BehaviorSubject<number>(0);


  private _state: State = {
    page: 1,
    pageSize: 6,
    searchTerm: '',
    sortColumn: '',
    startIndex: 0,
    endIndex: 9,
    totalRecords: 0,
  };

  constructor(private apollo: Apollo) {
  }

  private _set(patch: Partial<State>) {
    Object.assign(this._state, patch);
  }

  /**
   * Returns the value
   */
  get tables$() {
    return this._tables$.asObservable();
  }

  get total$() {
    return this._total$.asObservable();
  }

  get loading$() {
    return this._loading$.asObservable();
  }

  get page() {
    return this._state.page;
  }

  get pageSize() {
    return this._state.pageSize;
  }

  get searchTerm() {
    return this._state.searchTerm;
  }

  get startIndex() {
    return this._state.startIndex;
  }

  get endIndex() {
    return this._state.endIndex;
  }

  get totalRecords() {
    return this._state.totalRecords;
  }

  /**
   * set the value
   */
  set page(page: number) {
    this._set({page});
  }

  set pageSize(pageSize: number) {
    this._set({pageSize});
  }

  set startIndex(startIndex: number) {
    this._set({startIndex});
  }

  set endIndex(endIndex: number) {
    this._set({endIndex});
  }

  set totalRecords(totalRecords: number) {
    this._set({totalRecords});
  }

  set searchTerm(searchTerm: string) {
    this._set({searchTerm});
  }

  set sortColumn(sortColumn: string) {
    this._set({sortColumn});
  }

  /**
   * Search Method
   */
  getOneById(id: number): Observable<Property | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneByIdQuery(id),
    }).valueChanges.pipe(map((result: any) => result.data && result.data.Property));
  }

  getOneByUId(uid: number): Observable<Property | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneByUIdQuery(uid),
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.PropertyByUId),
    );
  }

  paginate(page: number, perPage: number = 6, search: Search = new Search()): Observable<any> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, search),
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(map((result: any) => result.data && result.data.PropertyPaginate));
  }

  paginatePropertyByPhoneNumberOwners(page: number, perPage: number = 10, phone_number: string): Observable<Property[] | []> {
    return this.apollo.watchQuery({
      query: this.paginatePropertyByOwnersNumberQuery(page, perPage, phone_number),
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(map((result: any) => result.data && result.data.PropertyPaginate.data));
  }

  paginateAddresses(page: number = 1, perPage: number = 10, term: string = ''): Observable<string[]> {
    return this.apollo.watchQuery({
      query: this.paginateAddressesQuery(page, perPage, term),
    }).valueChanges.pipe(
      map((property: any) => {
        if (!property.data && property.data.PropertyPaginate && property.data.PropertyPaginate.data) return [];

        if (!Array.isArray(property.data.PropertyPaginate.data)) return [];

        return property.data.PropertyPaginate.data.map((el: Property) => el.address_house_number);
      }),
    );
  }

  paginatePN(page: number = 1, perPage: number = 10, term: string = ''): Observable<string[]> {
    return this.apollo.watchQuery({
      query: this.paginatePNQuery(page, perPage, term),
    }).valueChanges.pipe(
      map((property: any) => {
        if (!property.data && property.data.PropertyPaginate && property.data.PropertyPaginate.data) return [];

        if (!Array.isArray(property.data.PropertyPaginate.data)) return [];

        return property.data.PropertyPaginate.data.map((el: Property) => el.property_name);
      }),
    );
  }

  paginatePropertyNames(page: number = 1, perPage: number = 10, term: string = ''): Observable<string[] | []> {
    return this.apollo.watchQuery({
      query: this.paginatePropertyNamesQuery(page, perPage, term),
    }).valueChanges.pipe(
      map((result: any) => {
        if (!result.data && result.data.PropertyPaginate && result.data.PropertyPaginate.data) return [];

        if (!Array.isArray(result.data.PropertyPaginate.data)) return [];

        return result.data.PropertyPaginate.data.map((el: { property_name: string }) => el.property_name);
      }),
    );
  }

  getPropertiesForMap(southwest: L.LatLng | undefined, northeast: L.LatLng | undefined, searchMapProperty: SearchMapProperty, isSearchMode: boolean): Observable<Property[]> {
    const where = searchMapProperty?.getGraphQLCondition();
    let whereShapeQuery = '';
    let landmarkCondition = '';
    if (!isSearchMode) {
      whereShapeQuery = `edgesCondition: {
        beginLat: ${northeast!.lat},
        beginLng: ${northeast!.lng}
        endedLat: ${southwest!.lat}
        endedLng: ${southwest!.lng}
    }`;
    }
    if(searchMapProperty.selectedLandmark) {
      landmarkCondition =`landmarkCondition:${searchMapProperty.selectedLandmark}`;
    }
    const parametersQuery = `(
       ${landmarkCondition}
       ${where ?? ''}
       ${whereShapeQuery}
    )`;
    return this.apollo.query({
      query: gql`{ properties: PropertiesForMap${parametersQuery} }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.properties ?? [],
    ));
  }

  getPropertiesForMapByCircle(searchMapProperty?: SearchMapProperty, radiusCondition?: RadiusCondition): Observable<Property[]> {
    const where = searchMapProperty?.getGraphQLCondition();

    const radiusQuery = radiusCondition ? `radiusCondition: {
        distance: ${radiusCondition.distance}
        lat: ${radiusCondition.lat}
        lng: ${radiusCondition.lng}
    }` : undefined;

    const parametersQuery = !where && !radiusQuery ? '' : `(
       ${where ?? ''}
       ${radiusQuery ?? ''}
    )`;

    return this.apollo.query({
      query: gql`{ properties: PropertiesForMapByCircle${parametersQuery} }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.properties ?? [],
    ));
  }

  selfAssessmentFormPreSave(property: Property) {
    const floorsDetails = property.floors?.reduce((acc, floor) => acc + ` {
        floor_number: ${floor.floor_number ?? 0}
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? 0}
        property_sub_type_id: ${floor.propertySubType?.id ?? 0}
        property_category: ${floor.property_category ?? 0}
        upto_year: ${floor.upto_year ?? 0}
        from_year: ${floor.from_year ?? 0}
   }`, '');

    const ownersDetails = property.owners?.reduce((acc, owner) => acc + ` {
      first_name: "${owner.first_name}"
      last_name: "${owner.last_name}"
      gender: ${owner.gender}
      email: "${owner.email}"
    }`, '');

    return this.apollo.query({
      query: gql`{
        SelfAssessmentFormPreSave(property: {
          property_category: ${property.property_category ?? null}
          property_type_id: ${property.property_type_id ?? null}
          property_sub_type_id: ${property.property_sub_type_id ?? null}
          source: "${property.source ?? 'new_survey'}"
          road_width: ${property.road_width ?? null}
          plot_area: ${property.plot_area ?? null}
          city_id: ${property.city?.id ?? null}
          vacant_area: ${property.vacant_area ?? null}
          road_location: "${property.road_location ?? null}"
          colony_id: ${property.colony_id ?? property.colony?.id ?? null}
          floors: [ ${floorsDetails} ]
          owners: [ ${ownersDetails} ]
        })
      }`,
    }).pipe(map((res: any) => res.data.SelfAssessmentFormPreSave));
  }

  getTemporaryUid() {
    return this.apollo.mutate({
      mutation: gql`mutation { GenerateTemporaryPropertyId }`,
    }).pipe(map((res: any) => res.data.GenerateTemporaryPropertyId));
  }

  taxCalculation(property: Property) {
    const floorsDetails = property.floors?.reduce((acc, floor) => acc + ` {
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? 0}
        property_sub_type_id: ${floor.propertySubType?.id ?? 0}
        property_category: ${floor.property_category ?? 0}
        upto_year: ${floor.upto_year ?? 0}
        from_year: ${floor.from_year ?? 0}
        floor_number :  ${floor.floor_number}
   }`, '');

    return this.apollo.query({
      query: gql`{ TaxCalculationForProperty(
        property: {
          property_type_id: ${property.property_type_id ?? null}
          property_sub_type_id: ${property.property_sub_type_id ?? null}
          property_category: ${property.property_category ?? null}
          source: "${property.source ?? 'new_survey'}"
          road_width: ${property.road_width ?? null}
          plot_area: ${property.plot_area ?? null}
          city_id: ${property.city?.id ?? null}
          vacant_area: ${property.vacant_area ?? null}
          road_location: "${property.road_location ?? null}"
          colony_id: ${property.colony_id ?? property.colony?.id ?? null}
          floors: [ ${floorsDetails} ]
          multi: ${property.multi ?? null}
          sub_divided_plots: ${property.sub_divided_plots ?? null}
        }
      ) {
          taxCalculation {
            propertyCategory
            areaType
            area
            rate
            tax
          }
          totalTax
      } }`,
    }).pipe(map((res: any) => res?.data?.TaxCalculationForProperty));
  }

  createSelfAssessment(property: Property): Observable<boolean> {
    const propertyDetails = `{
      property_uid: "${property.property_uid}"
      state_id: ${property.state_id ?? null}
      district_id: ${property.district?.id ?? null}
      city_id: ${property.city?.id ?? null}
      ward_id: ${property.ward?.id ?? null}
      colony_id: "${property.colony?.id ?? null}"
      address_house_number: "${property.address_house_number}"
      landmark: ${property.landmark ? `"${property.landmark}"` : null}
      multi: ${property.multi ?? null}
      road_width: ${property.road_width ?? null}
      building_construction_year: ${property.building_construction_year ?? null}
      plot_allotment_year: ${property.plot_allotment_year ?? null}
      plot_area: ${property.plot_area ?? null}
      vacant_area: ${property.vacant_area ?? null}
      ownership_type_id: ${property.ownershipType?.id ?? null}
      latitude: ${property.latitude ?? null}
      longitude: ${property.longitude ?? null}
      pincode: ${property.pincode ?? null}
      property_category: ${property.property_category ?? null}
      property_type_id: ${property.property_type_id ?? null}
      property_sub_type_id: ${property.property_sub_type_id ?? null}
      creation_source: "Self Assessment"
    }`;

    const floorsDetails = property.floors?.reduce((acc, floor) => acc + ` {
        floor_number: ${floor.floor_number}
        carpet_area: ${floor.carpet_area ?? null}
        property_type_id: ${floor.propertyType?.id ?? null}
        property_sub_type_id: ${floor.propertySubType?.id ?? null}
        property_category: ${floor.property_category ?? null}
        upto_year: ${floor.upto_year ?? null}
        from_year: ${floor.from_year ?? null}
    }`, '');

    const ownersDetails = property.owners?.reduce((acc, owner) => acc + ` {
      first_name: "${owner.first_name}"
      last_name: "${owner.last_name}"
      gender: ${owner.gender}
      email: ${owner.email ? `"${owner.email}"` : null}
      relation_name: ${owner.relation_name ? `"${owner.relation_name}"` : null}
      relation: ${owner.relation ? owner.relation : null}
      phone_number: ${owner.phone_number ? `"${owner.phone_number}"` : null}
    }`, '');

    const photosDetails = property.photos?.reduce((acc, owner) => acc + ` {
      url: "${owner.url}"
      name: "${owner.name}"
    }`, '');

    return this.apollo.mutate({
      mutation: gql`mutation { SuccessMessageResponse: CreateSelfAssessment(
         property: ${propertyDetails}
         floors: [${floorsDetails}]
         owners: [${ownersDetails}]
         photos: [${photosDetails}]
      ),{
        success
        message
      }
     }`,
    }).pipe(
      map((res: any) => res?.data?.SuccessMessageResponse?.success),
    );
  }

  isPaymentExistsInYear(propertyId: number, year: number): Observable<boolean> {
    return this.apollo.query({
      query: gql`{
        exists: Property(
          where: {
            AND: [
              { column: ID, value: ${propertyId} }
              { HAS: {relation: "ledgers", condition: { AND: [
                {column: TRANSACTION_TYPE, value: ${Ledger.PAYMENT_TYPE}}
                {column: FINANCIAL_YEAR, value: ${year}}
              ] } } }
            ]
          }
        ) {
          id
        }
      }`,
    }).pipe(map((res: any) => !!res?.data?.exists));
  }

  isPropertyObjectionExists(propertyId: number) {
    return this.apollo.query({
      query: gql`{ property: ${this.isPropertyObjectionExistsQuery(propertyId)} }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (result: any) => result?.data?.property ? Object.assign(new Property(), result?.data?.property) : undefined),
    );
  }

  /**
   * GraphQL Queries
   * */
  getOneByUIdQuery(uid: number) {
    return gql`{ PropertyByUId(uid: ${uid}) { ${this.needleColumns()} } }`;
  }

  private needleColumns() {
    return `
      id,
      property_uid,
      property_name,
      address_house_number,
      vacant_area,
      floor_count,
      plinth_area,
      exemption,
      constructed_area,
      multi,
      latitude
      longitude,
      center_of_polygons,
      polygon_coordinates,
      pincode,
      landmark,
      nearby_houseno,
      permanent_address,
      old_house_tax_no,
      sub_divided_plots,
      subdivision_year,
      parent_id,
      parent_property_uid,
      survey_denied,
      denied_reason,
      building_permission,
      building_permission_number,
      building_permission_year,
      building_permission_num_floor,
      building_construction_year,
      business_type_id,
      use_building_permission_matches_actual,
      water_connection,
      electricity_connection,
      electricity_connection_number,
      sewerage_line,
      sewerage_line_connection,
      septic_tank,
      compost_pit,
      property_usage_id,
      rainwater_harvesting,
      toilet,
      parking,
      street_light,
      fire_fighting_system,
      mobile_tower,
      mobile_tower_placement,
      free_hold_patta,
      one_time_deposited,
      lease_deed_patta,
      plot_area,
      length,
      breadth,
      first_financial_year,
      landuse_change,
      address_line2,
      road_name,
      property_status,
      center_of_polygons,
      polygon_coordinates,
      property_category,
      construction_type_id,
      road_location,
      owners {
         id, first_name, last_name, gender, type, birth_date, relation, relation_name, phone_number_na_reason,
         ownership_share, uid_number, occupation, phone_number, email, profession, document_proof_type_id, bpl_card
         bpl_no
         documentProofType {
            id,
            name
         }
      },
      occupiers {
         id, first_name, last_name, gender, type, birth_date, relation, relation_name, phone_number_na_reason,
         ownership_share, uid_number, occupation, phone_number, email, profession,
         documentProofType {
            id,
            name
         }
      },
      ward { id, name }
      street { id, name }
      state { id, name },
      colony { id, name }
      district { id, name, state{ name }}
      city { id, name }
      sector { id, name }
      propertyType { id, name }
      propertySubType { id, name }
      rebate { id name }
      ownershipType { id, name}
      photos {id, url, photoType{ id name status}},
      society {id, name},
      floors {
        id, floor_number, carpet_area, property_usage_id, property_category, property_construction_type_id,
        floor_construction_year no_of_room no_of_toilet, from_year, upto_year
        propertyType{id, name, description},
        propertySubType{id, name, description},
        citizen{first_name, last_name},
      },
      ledgers {
        id transaction_datetime transaction_type debit_amount credit_amount balance financial_year
      }
      createdBy {
        id
        first_name
        last_name
      },
      trade_license,
      name_of_shop,
      advertisement_on_building,
      solar_plant_or_unit,
      building_permission_use_case,
      water_connection_number
      establishment_year
      items_traded
      occupation_year
      road_type_id
      road_width
      license_number
      license_date
      ward_id
      sector_id
      colony_id
      property_type_id
      property_sub_type_id
      is_closed
      source
      has_objection
    `;
  }

  private getOneByIdQuery(id: number) {
    return gql`{ Property(
      where: {
        AND: [
          {column: ID, value: ${id}}
          {column: IS_CLOSED, value: false}
        ]
      }
    ) { ${this.needleColumns()} } }`;
  }

  private isPropertyObjectionExistsQuery(propertyId: number) {
    return `IsPropertyObjectionExists(propertyId: ${propertyId}) { ${this.needleColumns()} }`;
  }

  private paginateQuery(page: number, perPage: number = 6, search: Search) {
    let whereCondition = ``;
    let landmarkCondition = '';
    if(search.selectedLandmark) {
      landmarkCondition = `landmarkCondition:${Number(search.selectedLandmark)}`;
    }
    whereCondition += search.wardId ? `{ column: WARD_ID, value: "${search.wardId}" },` : '';
    whereCondition += search.address ? `{ column: ADDRESS_HOUSE_NUMBER, value: "${search.address}" }` : '';
    whereCondition += search.serviceNo ? `{ column: PROPERTY_UID, value: "${search.serviceNo}" }` : '';
    whereCondition += search.colonyId ? `{ column: COLONY_ID, value: ${search.colonyId} }` : '';
    whereCondition += search.landmark ? `{ column: LANDMARK, value: "${search.landmark}" }` : '';
    whereCondition += search.oldPropertyId ? `{ column: OLD_HOUSE_TAX_NO, value: "${search.oldPropertyId}" }` : '';

    whereCondition += search.propertyName ? `
    { OR: [
          { column: PROPERTY_NAME, operator: LIKE, value: "%${search.propertyName}%" }
          { column: PROPERTY_NAME, operator: LIKE, value: "${search.propertyName}%" }
          { column: PROPERTY_NAME, operator: LIKE, value: "%${search.propertyName}" }
        ]}
    ` : '';

    whereCondition += search.streetId ? `{ column: STREET_ID, value: "${search.streetId}" }` : '';
    whereCondition += search.apartmentName ? `{ column: PROPERTY_NAME, value: "${search.apartmentName}" }` : '';
    whereCondition += search.propertyCategory ? `{ column: PROPERTY_CATEGORY, value: "${search.propertyCategory}" }` : '';
    whereCondition += search.property_type_id ? `{ column: PROPERTY_TYPE_ID, value: "${search.property_type_id}" }` : '';
    whereCondition += search.property_sub_type_id ? `{ column: PROPERTY_SUB_TYPE_ID, value: "${search.property_sub_type_id}" }` : '';

    whereCondition += search.mobile ? `{ HAS: {
      relation: "owners",
      condition: {column: PHONE_NUMBER, value: ${search.mobile} }
      } }` : '';

    whereCondition += search.fatherName ? `{AND:[
        {HAS: {relation: "owners", condition: {column: RELATION, value: "${2}" }}}
        {HAS: {relation: "owners", condition: {column: RELATION_NAME, operator: LIKE, value: "%${search.fatherName}%" }}}
    ]}` : '';

    whereCondition += search.accountHolderName ? `{OR:[
        {HAS: {relation: "owners", condition: { OR: [
          {column: FIRST_NAME, operator: LIKE, value: "${search.accountHolderName}%" }
          {column: FIRST_NAME, operator: LIKE, value: "% ${search.accountHolderName} %" }
          {column: FIRST_NAME, operator: LIKE, value: "% ${search.accountHolderName}" }
        ]}}}

        {HAS: {relation: "owners", condition: { OR: [
          {column: LAST_NAME, operator: LIKE, value: "${search.accountHolderName}%" }
          {column: LAST_NAME, operator: LIKE, value: "% ${search.accountHolderName} %" }
          {column: LAST_NAME, operator: LIKE, value: "% ${search.accountHolderName}" }
        ]}}}
    ]}` : '';

    return gql`{
      PropertyPaginate(
        page:${page},
        first:${perPage},
        ${landmarkCondition},
        where: {
          column: IS_CLOSED, value: 0
          AND: [${whereCondition}]
        }
      ) {
        paginatorInfo {
          perPage,
          total,
        },
        data{ ${this.propertyListNeedleColumns()} }
      }
    }`;
  }

  private propertyListNeedleColumns() {
    return `
      id
      property_uid
      property_name
      address_house_number
      plot_area
      address_line2
      ward { id, name }
      street { id, name }
      colony { id, name }
      state { id, name },
      district { id, name, state{ name }}
      city { id, name }
      property_category,
      owners {id, first_name, last_name}
      photos {id, url, photoType{ id name status}}
      pincode
      `
  }

  private paginateAddressesQuery(page: number = 1, perPage: number = 10, term: string = '') {
    return gql`{
      PropertyPaginate(
        page: ${page},
        first: ${perPage},
        where: {
          column: IS_CLOSED, value: false
          column: ADDRESS_HOUSE_NUMBER, operator: LIKE, value: "%${term}%"
        }
      ) {
        data{
          address_house_number
        }
      }
    }`;
  }

  private paginatePNQuery(page: number = 1, perPage: number = 10, term: string = '') {
    return gql`{
      PropertyPaginate(
        page: ${page},
        first: ${perPage},
        where: {
          column: PROPERTY_NAME, operator: LIKE, value: "%${term}%"
        }
      ) {
        data{
          property_name
        }
      }
    }`;
  }

  private paginateWardIdQuery(page: number, perPage: number, term: string) {
    return gql`{
      PropertyPaginate(
        page: ${page},
        first: ${perPage},
        where:{
          column: IS_CLOSED, value: false
          column: WARD_ID, operator: LIKE, value: "${term}%"
        }
      ) {
        data{
          ward_id
        }
      }
    }`;
  }

  private paginatePropertyNamesQuery(page: number, perPage: number, term: string) {
    return gql`{
      PropertyPaginate(
        page: ${page},
        first: ${perPage},
        where:{
       
          column: PROPERTY_NAME, operator: LIKE, value: "${term}%"
        },
      ) {
        data {
          property_name
        }
      }
    }`;
  }

  private paginatePropertyByOwnersNumberQuery(page: number, perPage: number, phone_number: string) {
    return gql`{
      PropertyPaginate(
        page: ${page},
        first: ${perPage},
        where:{
          column: IS_CLOSED, value: false
          AND:[
            {
              HAS: {
                relation: "owners",
                condition: {
                  column: PHONE_NUMBER,
                  operator: LIKE,
                  value: "${phone_number}"
                }
              }
            }
          ]
        },
      ) {
        paginatorInfo {
          perPage,
          total,
        },
        data{ ${this.needleColumns()} }
      }
    }`;
  }

  getAllLandmarks(cityId: number) {
    return this.apollo.query({
      query: gql`{ LandmarksData(city_id: ${cityId}) {
        success
        data {
          name
          id
          latitude
          longitude
          colony_id
          name_hindi
        }
      } }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.LandmarksData ?? [],
    ));
  }
}

import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class InformPaymentCreditService {

  constructor(private apollo: Apollo) { }

  public createInformPaymentCredit(formData: any): any {
    return this.apollo.mutate({
      mutation: this.createInformPaymentCreditQuery(formData)
    })
  }

  private createInformPaymentCreditQuery(formData: any) {
    formData.property_id = parseInt(formData.property_id);
    formData.transactions.forEach((e: any) => {
      e.transaction_date_time = e.transaction_date_time + ' 00:00:00'
    })
    const transactionDetails = formData.transactions?.reduce((acc: any, transaction: any) => acc + ` {
      financial_year: ${transaction.financial_year}
      transaction_date_time: "${transaction.transaction_date_time}"
      amount: ${transaction.amount}
    }`, '');
    const attachmentDetails = formData.attachments?.reduce((acc: any, attachment: any) => acc + ` 
      "${attachment}"
`, '');

    return gql`mutation {
      CreateCreditRequest(
        data: {
          property_id: ${formData.property_id}
          user_remark: "${formData.user_remark}"
          transactions: [${transactionDetails}]
          attachments: [${attachmentDetails}]
        }
      ) {
        success
        message
      }
    }`
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { FileSaverService } from 'ngx-filesaver';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private startingYear = 2007
  private IMG_FILE_EXT = ['png', 'jpg', 'jpeg'];
  private PDF_FILE_ICON = 'assets/images/pdf_icon.svg';
  private DOCUMENT_ICON = 'assets/images/document_icon.svg';


  constructor(private http: HttpClient,
    private fileSaverService: FileSaverService,public languageService : LanguageService) { }


  /**
     * 
     * @param year 
     * @param startingYear 
     * @returns in financial year format or before starting year 
     */
  getYearValueAsFY(year: number, showBeforeYear?: boolean): string {
    if (showBeforeYear && year === 0) {
      return `Before ${this.startingYear}`;
    }
    if (year) {
      return `${year}-${+year + 1}`;
    }
    return '';
  }

  /**
   * 
   * @param documentUrl 
   * @returns true if file url is for pdf file
   */
  isPdfFile(documentUrl: string) {
    const urlArr = documentUrl.split('.');
    const fileFormat = urlArr[urlArr.length - 1];
    return fileFormat?.toLowerCase() === 'pdf';
  }

  /**
   * 
   * @param path 
   * @returns absolute path of the file
   */

  getAbsoluteUrl(path: string) {
    if (path && (path.startsWith('http://') || path.startsWith('https://'))) {
      return path;
    }

    return environment.apiBaseUrl + path;
  }

  /**
   * 
   * @param owners 
   * @returns owner fullname
   */
  getOwnerName(owners: { first_name: string, last_name: string; }[]) {
    if (!owners?.[0]) return 'Unknown';

    return (owners[0].first_name ?? '') + ' ' + (owners[0].last_name ?? '');
  }

  /** 
   * @param obj 
   * @returns serialized graphQL query to send to server
   */
  objToString(obj: any) {
    if (typeof obj === 'number') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const props: any = obj.map(value => `${this.objToString(value)}`).join(',');
      return `[${props}]`;
    }

    if (typeof obj === 'object') {
      const props: any = Object.keys(obj)
        .map(key => `${key}:${this.objToString(obj[key])}`)
        .join(',');
      return `{${props}}`;
    }

    return JSON.stringify(obj);
  }

  /**
    * 
    * @param obj 
    * @param keys can be key or array of keys
    * @returns obj after modifying date values
    */

  modifyDateValue(parentObj: any, keys: string | string[], dateObj: any = null) {
    if (dateObj) {
      return `${dateObj?.year}-${dateObj?.month}-${dateObj?.day}`;
    }

    if (parentObj) {
      if (typeof keys === 'string') {
        keys = [keys];
      }

      keys.forEach(key => {
        const yearObj = parentObj[key];
        parentObj[key] = yearObj ? `${yearObj?.year}-${yearObj?.month}-${yearObj?.day}` : null;
      })
      return parentObj;
    }

    return null;

  }


  /**
      * Helper method to convert object to string
      * Used for creating graphQl payload
      */

  convertToGraphqlPayLoad(obj: any, keysForIntType: string[]) {
    if (typeof obj === 'number') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const props: any = obj.map(value => `${this.convertToGraphqlPayLoad(value, keysForIntType)}`).join(',');
      return `[${props}]`;
    }
    if (obj !== null && typeof obj === 'object') {
      const props: any = Object.keys(obj)
        .map(key => {
          const value = keysForIntType.includes(key) ? (obj[key] ? +obj[key] : null) : obj[key];
          return `${key}:${this.convertToGraphqlPayLoad(value, keysForIntType)}`;
        })
        .join(',');
      return `{${props}}`;
    }
    return JSON.stringify(obj);
  }


  /**
      * checks if provided file is an image file
      */
  isImageFile(url: string): boolean {
    let urlArr = url.split(".");
    const fileExtension = urlArr[urlArr.length - 1];
    return fileExtension?.toLowerCase() !== 'pdf';
  }

  /**
  * Returns comma separated property uid as graphql payload.
  */

  getPropertyUidAsArray(uidStr: string): string {
    let uidPayLoad = '['
    const uidArr = uidStr.split(',');
    uidArr.forEach((el, index) => {
      if (index === 0) {
        uidPayLoad += `"${el?.trim()}"`;
      } else {
        uidPayLoad += `"${el?.trim()}",`;
      }
    })
    return uidPayLoad + ']';
  }


  /**
  * 
  * @param initial 
  * @param firstName 
  * @param lastName 
  * @returns full name
  */
  getFullName(initial: string, firstName: string, lastName: string) {
    let name = '';
    name += initial ? initial + ' ' : '';
    name += firstName ? firstName + ' ' : '';
    name += lastName ? lastName + ' ' : '';
    return name;
  }

  /**
  * 
  * @param dateObj 
  * @returns date in dd-mm-yyyy format
  */
  getNgbDateAsString(dateObj: { year: number, month: number, day: number }) {
    if (dateObj) {
      return `${dateObj.day}-${dateObj.month}-${dateObj.year}`;
    }
    return '';
  }

  /**
  * details obj and key
  * returns date or number value
  */

  getDocDateAndNumber(documentObj: any, key: string) {
    if (documentObj && documentObj[0]) {
      return documentObj[0][key] || (documentObj[1] ? documentObj[1][key] : '');
    }
    return '';
  }

  /**
  * 
  * @param date 
  * @returns date in ngb date format
  */
  getNgbDate(date: any) {
    if (!date) return null;
    let dateArray = date.split('-')
    return new NgbDate(+dateArray[0], +dateArray[1], +dateArray[2]);
  }

  /**
      * 
      * @param fileName 
      * @returns path of the file icon
      */
  getFileIcon(fileName: string): string | null {
    const fileArr = fileName.split('.');
    const fileExtension = fileArr[fileArr.length - 1]?.toLowerCase();
    if (fileExtension === 'pdf') {
      return this.PDF_FILE_ICON;
    } else if (this.IMG_FILE_EXT.includes(fileExtension)) {
      return this.getAbsoluteImageUrl(fileName);
    } else if (fileExtension) {
      return this.DOCUMENT_ICON;
    }
    return null;
  }

  /**
  * Open file path in new window.
  * @param path 
  * @param name 
  */
  saveFile(path: string, name = '') {
    const url = environment.apiBaseUrl + path;
    window.open(url, '_blank');
  }

  getFile(url: string) {
    return this.http.get(url, {
      observe: 'response',
      responseType: 'blob',
    })
  }

  /**
  * Split name into firstname and last name
  * @param ownerName 
  * @returns 
  */
  formatOwnerName(ownerName: string) {
    if (!ownerName) return { firstName: '', lastName: '' };

    let ownerNameArr = ownerName.split(' ');
    let firstName = "";
    let lastName = "";
    if (ownerNameArr.length > 1) {
      firstName = ownerNameArr[0];
      lastName = ownerNameArr[ownerNameArr.length - 1];
    }
    return { firstName: firstName, lastName: lastName };
  }

  limitLength(event: any, length: number) {
    if (event.target.value.length >= length) {
      event.preventDefault();
    }
  }

  /* 
   * @param path 
   * @returns absolute path of image/document
   */

  getAbsoluteImageUrl(path: string): string | null {
    if (!path) {
      return null;
    }

    if (path && (path.startsWith('http://') || path.startsWith('https://'))) {
      return path;
    }

    return environment.apiBaseUrl + path;
  }

  showSuccessMessage(successMsg:string){
    Swal.fire({
      title:  this.languageService.translate('patta_application.validation_msg.success_text'),
      text : this.languageService.translate(successMsg),
      icon :'success',
      confirmButtonText: this.languageService.translate('home.ok'),
    });

  }

  showErrorMessage(errorMsg:string){
    Swal.fire({
      title:  this.languageService.translate('home.error'),
      text : this.languageService.translate(errorMsg),
      icon :'error',
      confirmButtonText: this.languageService.translate('home.ok'),
  });
  }
}

<div class="container" *ngIf="!loading && !processing; else loadingBlock">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">{{'property_search.home' | translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page"><a [routerLink]="'/'">{{'property_search.property_listing' | translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'property_search.payment_page' | translate}}</li>
    </ol>
  </nav>

  <div class="title-head">
    <h2 class="m-0">{{'property_search.payment_page' | translate}}</h2>
    <div class="print-icon">
      <a href="#">
        <i class="bi bi-printer"></i>
      </a>
    </div>
  </div>

  <div class="payment-container mb-4">
    <div class="row">
      <div class="col-lg-8 col-md-12 col-sm-12 col-12">

        <!-- Start Login User Details --> 
        <div class="payment-sections" *ngIf="!!auth.user">
          <h4>{{'property_search.user_details' | translate}}</h4>
          <div class="payment-main-section">
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <p>{{'property_search.name' | translate}}</p>
                <h6>{{auth?.user?.first_name ?? ''}} {{auth?.user?.last_name ?? ''}}</h6>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <p>{{'property_search.mobile_number' | translate}}</p>
                <h6>{{auth?.user?.phone_number ?? 'Unknown'}}</h6>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6 col-12">
                <p>{{'property_search.email_address' | translate}}</p>
                <h6>{{auth?.user?.email ?? 'Unknown'}}</h6>
              </div>
            </div>
          </div>
        </div>
        <!-- End Login User Details -->


        <!--  Start Not Login User Details  -->
        <div class="payment-sections" *ngIf="!auth.user">
          <h4>{{'property_search.user_details' | translate}}</h4>

          <form [formGroup]="userForm">
            <div class="personal-form">
              <div class="row">
                <div class="col-lg-56 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="FirstName">{{'property_search.first_name' | translate}}: <span class="required">*</span></label>
                    <input type="text" class="form-control" id="FirstName" placeholder="{{'property_search.type_first_name' | translate}}"
                           formControlName="firstName">
                    <p class="error-message" *ngIf="isControlInvalid('firstName')">{{'property_search.length_must_be_in_2_and_60_range' | translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="LastName">{{'property_search.last_name' | translate}}: <span class="required">*</span></label>
                    <input type="text" class="form-control" id="LastName" placeholder="{{'property_search.type_last_name' | translate}}"
                           formControlName="lastName">
                    <p class="error-message" *ngIf="isControlInvalid('lastName')">{{'property_search.length_must_be_in_2_and_20_range' | translate}}</p>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="MobileNumber">{{'property_search.mobile_number' | translate}}: <span class="required">*</span></label>
                    <ngx-mat-intl-tel-input
                            [onlyCountries]="['in']"
                            [enablePlaceholder]="true"
                            [enableSearch]="false"
                            [inputPlaceholder]="'Type Mobile Number'"
                            class="mobile-number-input"
                            name="phone"
                            id="MobileNumber"
                            formControlName="phoneNumber"
                    ></ngx-mat-intl-tel-input>
                    <p class="error-message" *ngIf="isControlInvalid('phoneNumber')">{{'property_search.incorrect_value' | translate}}</p>
                  </div>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                  <div class="form-group">
                    <label for="EmailAddress">{{'property_search.email_address' | translate}}: <span class="required">*</span></label>
                    <input type="email" class="form-control" id="EmailAddress" placeholder="{{'property_search.type_email_address' | translate}}"
                           formControlName="email">
                    <p class="error-message" *ngIf="isControlInvalid('email')">{{'property_search.incorrect_email' | translate}}</p>
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
        <!-- End Not Login User Details  -->

        <!-- Start Property Info -->
        <div class="payment-sections tax-calculation">
          <h4>{{'property_search.property_details' | translate}}</h4>

          <div class="tax-table table-responsive">
            <table class="table table-borderless responsive-flex-table">
              <thead>
              
                <th>{{'property_search.service_number' | translate}}</th>
                <th>{{'property_search.property_category' | translate}}</th>
                <th>{{'property_search.property_type' | translate}}</th>
                <th>{{'property_search.property_usage' | translate}}</th>
                <th>{{'property_search.plot_area' | translate}}</th>
             
              </thead>
              <tbody>

              <tr>
                <th>{{property?.property_uid}}</th>
                <th>{{property?.property_category ? Property.PROPERTY_CATEGORIES[property.property_category] : ''}}</th>
                <th>{{property?.propertyType?.name}}</th>
                <th>{{property?.property_usage_id ? Property.PROPERTY_USAGES[property.property_usage_id] : ''}}</th>
                <th>{{property?.plot_area}}</th>
              </tr>
              </tbody>
              <tfoot>
              </tfoot>

            </table>

          </div>

        </div>
        <!-- End Property Info -->

        <!--  Start Owner Details  -->
        <div class="payment-sections mb-4">
          <h4>{{'property_search.owner_details' | translate}} </h4>

          <div class="owner-details-content">
            <table class="table table-borderless responsive-flex-table">
              <thead>
                <th>{{'property_search.name' | translate}}  </th>
                <th>{{'property_search.mobile_number' | translate}}  </th>
                <th>{{'property_search.email_address' | translate}}  </th>
              </thead>
              <tbody>
                <tr>
                  <td>
                {{owner?.fullName?.trim() !== '' ? owner?.fullName : 'Unknown'}}
                    
                  </td>
                  <td>
                    {{owner?.phone_number ?? 'Unknown'}}                    
                  </td>
                  <td>
                    {{owner?.email ?? 'Unknown'}}                
                  </td>
                </tr>
              </tbody>
            </table>




            <!-- <div class="payment-sections__col">
              <span class="payment-sections__name">Name</span>
              <span class="payment-sections__value">
                {{owner?.fullName?.trim() !== '' ? owner?.fullName : 'Unknown'}}
              </span>
            </div>

            <div class="payment-sections__col">
              <span class="payment-sections__name">Mobile Number</span>
              <span class="payment-sections__value">{{owner?.phone_number ?? 'Unknown'}}</span>
            </div>

            <div class="payment-sections__col">
              <span class="payment-sections__name">Email Address</span>
              <span class="payment-sections__value">{{owner?.email ?? 'Unknown'}}</span>
            </div> -->
          </div>
        </div>
        <!--  End Owner Details  -->

        <!-- Start Tax Calculation -->
        <div class="payment-sections tax-calculation" *ngIf="taxCalculation">
          <h4>{{'property_search.tax_calculation_current_year' | translate}}</h4>

          <div class="tax-table table-responsive">
            <table class="table table-bordered th-bg responsive-flex-table">
              <thead>
             
                <th>{{'property_search.usage_category' | translate}}</th>
                <th>{{'property_search.area_type' | translate}}</th>
                <th>{{'property_search.area_sq_yd' | translate}}</th>
                <th>{{'property_search.rate_per_sq_mt' | translate}}</th>
                <th>{{'property_search.rate_2000' | translate}}</th>
                <th>{{'property_search.tax' | translate}}</th>
             
              </thead>
              <tbody *ngIf="taxCalculation;">
              <tr *ngFor="let currentTaxCalculation of taxCalculation.taxCalculation">
                <td>{{Property.PROPERTY_CATEGORIES[currentTaxCalculation.propertyCategory] ?? ''}}</td>
                <td>{{currentTaxCalculation.areaType}}</td>
                <td>{{currentTaxCalculation.area}}</td>
                <td>{{currentTaxCalculation.rate ?? ''}}</td>
                <td>{{currentTaxCalculation.rate ? (currentTaxCalculation.rate / 2000).toFixed(2) : ''}}</td>
                <td>{{currentTaxCalculation.tax}}</td>
              </tr>
              <tr *ngIf="property.property_category === PropertyCategories.Mix">
                <td><strong>{{'property_search.total_tax' | translate}}</strong></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{taxCalculation.totalTax}}</td>
              </tr>
              </tbody>
            </table>
          </div>

        </div>
        <!-- End Tax Calculation -->

      </div>


      <!-- Start Right Sidebar -->
      <div class="col-lg-4 col-md-12 col-sm-12 col-12">

        <div class="tax-due-summary">

          <!-- Start Tax Calculation -->
          <h4>{{'property_search.tax_due_summary' | translate}}</h4>
          <div class="tax-due-summary__tax">
            <div class="tax-due-summary__block" *ngIf="taxDetails?.propertyDebtInPastYear">
              <h6 class="tax-due-summary__block-title">{{'property_search.total_tax_due_before_current_year' | translate}}</h6>

              <div class="tax-due-summary__block-row">
                <div>{{'property_search.arrears' | translate}}</div>
                <div><strong>{{taxDetails?.propertyDebtInPastYear | toIndianValue}}</strong></div>
              </div>
            </div>

            <div class="tax-due-summary__block" *ngIf="taxDetails.taxInCurrentYear">
              <h6 class="tax-due-summary__block-title">{{'property_search.tax_amount_current_year' | translate}}</h6>

              <div class="tax-due-summary__block-row">
                <div>{{'property_search.tax_amount' | translate}} {{taxDetails.financialYear + ' - ' + (taxDetails.financialYear + 1)}}</div>
                <div><strong>{{taxDetails.taxInCurrentYear | toIndianValue}}</strong></div>
              </div>
            </div>

            <div class="tax-due-summary__block"
                 *ngIf="taxDetails && (taxDetails.availableRebateAmount() > 0 || taxDetails.totalPenaltyAmount > 0 || taxDetails.paidAmount() > 0)"
            >
              <h6 class="tax-due-summary__block-title">{{'property_search.rebates_&_penalty' | translate}}</h6>

              <div class="tax-due-summary__block-row">
                <ul class="rebates-and-penalty-list">
                  <li class="rebates-and-penalty-list-item" *ngIf="taxDetails && taxDetails.totalPenaltyAmount > 0">
                    <div>{{'property_search.penalty' | translate}}</div>
                    <div class="debit-type">
                      <strong>{{taxDetails?.totalPenaltyAmount | toIndianValue}}</strong>
                    </div>
                  </li>

                  <li class="rebates-and-penalty-list-item" *ngFor="let rebate of taxDetails?.availableRebates">
                    <div>{{'property_search.rebate' | translate}}({{rebate.remarks}})</div>
                    <div class="credit-type">
                      <strong>{{rebate.credit_amount | toIndianValue}}</strong>
                    </div>
                  </li>

                  <hr>

                  <li class="rebates-and-penalty-list-item">
                    <div>{{'property_search.total_penalty' | translate}}</div>
                    <div class="text-black">
                      <strong>{{taxDetails?.totalPenaltyAmount | toIndianValue}}</strong>
                    </div>
                  </li>

                  <li class="rebates-and-penalty-list-item">
                    <div>{{'property_search.total_rebate_amount' | translate}}</div>
                    <div class="text-black">
                      <strong>{{taxDetails?.availableRebateAmount() | toIndianValue}}</strong>
                    </div>
                  </li>

                  <li class="rebates-and-penalty-list-item" *ngIf="taxDetails.paidAmount() > 0">
                    <div>{{'property_search.paid_amount' | translate}}</div>
                    <div class="text-success">
                      <strong>{{taxDetails?.paidAmount()}}</strong>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="tax-due-summary__block">
              <h6 class="tax-due-summary__block-title">{{'property_search.total_amount_due' | translate}}</h6>

              <div class="tax-due-summary__block-row">
                <div>{{'property_search.amount_due' | translate}}</div>
                <div class="total-amount-due__color">
                  <strong>{{taxDetails?.debtWithAllRebates() | toIndianValue}}</strong></div>
              </div>
            </div>
          </div>
          <!-- End Tax Calculation -->

          <!-- Start Payment Amount -->
          <h4>{{'property_search.payment_amount' | translate}}</h4>
          <div class="payment-amount">

            <div class="form-check mb-3">
              <input class="form-check-input" type="radio" name="payment" id="fullpayment" value="option1"
                     (input)="fullPaymentCheckBox = true"
                     [checked]="fullPaymentCheckBox"
              >
              <label class="form-check-label" for="fullpayment">
                {{'property_search.full_payment' | translate}} 
              </label>
              <p class="form-text text-muted">
                {{'property_search.pay_full_tax_due_amount' | translate}}
              </p>

            </div>
            <div class="form-check mb-3">
              <input class="form-check-input" type="radio" name="payment" id="enteramount"
                     value="option1"
                     (input)="fullPaymentCheckBox = false"
                     [checked]="fullPaymentCheckBox === false"
              >
              <label class="form-check-label" for="enteramount">
                {{'property_search.enter_amount_for_payment' | translate}}
              </label>
              <p class="form-text text-muted">
                {{'property_search.enter_the_payment_amount_below' | translate}}
              </p>
            </div>

            <div class="amount-input" *ngIf="fullPaymentCheckBox === false">
              <input type="text" class="form-control" id="paymentamount" placeholder="Enter Amount"
                     [(ngModel)]="paymentAmount">
              <p class="incorrect-amount" *ngIf="showIncorrectAmount">{{'property_search.amount_can_not_be_more_than_current_amount_due_or_less_than_1' | translate}}</p>
            </div>
          </div>
        </div>
        <!-- End Payment Amount -->

        <div class="payment-sections proceed-payment-block">
          <div class="proceed-payment">
            <button class="btn proceed-payment-btn btn-primary btn-lg w-100"
                    (click)="proceedPayment()"
                    [disabled]="processing">{{'property_search.proceed_payment' | translate}}
            </button>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<ng-template #loadingBlock>
  <div class="loading-container">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'property_search.loading' | translate}}</span>
    </div>
  </div>
</ng-template>

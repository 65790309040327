import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {gql} from "@apollo/client/core";
import {Ledger} from "../models";

@Injectable({
  providedIn: 'root'
})
export class PenaltyService {

  constructor(private apollo: Apollo) {
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public paginate(propertyId: number): Observable<Ledger[]> {
    return this.apollo.watchQuery({
      query: gql`{ Penalties: ${PenaltyService.query(propertyId)} }`,
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.Penalties),
      map((result: any) => result ?? [])
    );
  }

  /**
   * GraphQL Queries
   * */
  static getAllFields() {
    return `
      id
      transaction_datetime
      property_id
      property_uid
      financial_year
      transaction_type
      debit_amount
      credit_amount
      balance
      remarks
      created_at
      updated_at
    `
  }

  static query(propertyId: number) {
    return `
      Penalties(property_id:${propertyId})
      {${PenaltyService.getAllFields()}}
    `
  }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Colony, Photo, Property, PropertySubType, PropertyType, Ward} from '../../../models';
import {SearchMapProperty} from '../../../@types/search-map-property';
import {StreetService} from '../../../services/street.service';
import {ColonyService} from '../../../services/colony.service';
import {WardService} from '../../../services/ward.service';
import {ReplaySubject, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil, tap, filter } from 'rxjs/operators';
import { PropertyTypeService } from 'src/app/services/property-type.service';
import { PropertySubTypeService } from 'src/app/services/property-sub-type.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {

  PROPERTY_CATEGORIES = Property.formattedPropertyCategories();
  private destroy$ = new Subject();
  wards: Ward[] = [];
  colonies: Colony[] = [];
  coloniesData: Colony[] = [];
  loadingColonies: boolean = false;
  colonyInput$ = new Subject<string>();
  latLongInput = {
    isLatLongInput: true,
    latitude: undefined,
    longitude: undefined
  }
  loadingPropertyTypes!: boolean;
  propertyTypesAll: PropertyType[] = [];
  propertyTypeData: PropertyType[] = [];
  loadingPropertySubTypes!: boolean;
  propertySubTypesAll: PropertySubType[] = [];
  propertySubTypeData: PropertySubType[] = [];
  @Input() set searchMapProperty(val) {
    if (val === this._searchMapProperty) return;

    this._searchMapProperty = val;
    if(this._searchMapProperty.wardId) {
      this.getColonyDetails(+this._searchMapProperty.wardId, false);
    }
    this.afterInitSubject.next();
  };

  @Input() requiredZoom: number = 20;
  @Input() currentZoom!: number;
  @Input() nearbyProperties: Property[] = [];
  @Input() landmarks: any = [];
  @Output('search') searchEmitter = new EventEmitter<SearchMapProperty>();
  @Output('flyTo') flyToMap = new EventEmitter();
  landmarkFiltered: any = [];
  landmarksForwardId: { colony_id: number; }[] = [];
  latLongValue!:string;
  
  get afterInit$() {
    return this.afterInitSubject.asObservable();
  }

  get searchMapProperty() {
    return this._searchMapProperty;
  }

  private _searchMapProperty!: SearchMapProperty;
  private afterInitSubject = new ReplaySubject();

  constructor(
    public wardService: WardService,
    public colonyService: ColonyService,
    public streetService: StreetService,
    private router: Router,
    private propertyTypeService: PropertyTypeService,
    private propertySubTypeService: PropertySubTypeService,
    private currentRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getWards();
    this.searchColonies();
    this.initInputs();
    this.landmarkFiltered = this.landmarks;
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes?.landmarks) {
     this.landmarkFiltered = changes.landmarks.currentValue;
    }
  }
  private initInputs() {
    this.currentRoute.queryParams.pipe(takeUntil(this.destroy$)).pipe(takeUntil(this.destroy$)).subscribe((queryParams) => {
      if(queryParams.property_type_id) {
        this.getPropertyType();
      }
      if(queryParams.property_sub_type_id) {
        this.getPropertySubType();
      }
    })
  }

  search() {
    this.searchEmitter.emit(this.searchMapProperty);
  }

  setId(column: keyof SearchMapProperty, $event: string) {
    const id = Number.parseInt($event);

    (this.searchMapProperty[column] as any) = isNaN(id) ? undefined : id;
  }

  selectCompareWithFn = (a: any, b: any) => {
    return a?.id?.toString() === (typeof b === 'object' ? b.id?.toString() : b?.toString());
  };

  toList() {
    this.router.navigate(['/property-search'], {
      queryParams: this.getListQueryParams(),
    });
  }

  private getListQueryParams() {
    const filteredParams: Record<string, any> = {};

    if (this.searchMapProperty.propertyUID && this.searchMapProperty.propertyUID !== '') {
      filteredParams['serviceNo'] = this.searchMapProperty.propertyUID;
    }

    if (this.searchMapProperty.houseNumber && this.searchMapProperty.houseNumber !== '') {
      filteredParams['address'] = this.searchMapProperty.houseNumber;
    }

    if (this.searchMapProperty.streetId) filteredParams['streetId'] = this.searchMapProperty.streetId;
    if (this.searchMapProperty.colonyId) filteredParams['colonyId'] = this.searchMapProperty.colonyId;
    if (this.searchMapProperty.wardId) filteredParams['wardId'] = this.searchMapProperty.wardId;
    if (this.searchMapProperty.propertyCategoryId) filteredParams['propertyCategory'] = this.searchMapProperty.propertyCategoryId;


    if (this.searchMapProperty.propertyName && this.searchMapProperty.propertyName !== '') {
      filteredParams['propertyCategory'] = this.searchMapProperty.propertyName;
    }if (this.searchMapProperty.oldPropertyUID && this.searchMapProperty.oldPropertyUID !== '') {
      filteredParams['propertyCategory'] = this.searchMapProperty.oldPropertyUID;
    }if (this.searchMapProperty.fathersName && this.searchMapProperty.fathersName !== '') {
      filteredParams['propertyCategory'] = this.searchMapProperty.fathersName;
    }if(this.searchMapProperty.property_type_id) {
      filteredParams['property_type_id'] = this.searchMapProperty.property_type_id;
    }if(this.searchMapProperty.property_sub_type_id) {
      filteredParams['property_sub_type_id'] = this.searchMapProperty.property_sub_type_id;
    }
    return filteredParams;
  }

  static advancedBuffer: boolean;
  public advancedBufferLocal: boolean = SidebarComponent.advancedBuffer;
  OnClick(){
    if(!SidebarComponent.advancedBuffer){
      SidebarComponent.advancedBuffer = true;
      this.advancedBufferLocal = SidebarComponent.advancedBuffer;
    }else {
      SidebarComponent.advancedBuffer = false;
      this.advancedBufferLocal = SidebarComponent.advancedBuffer;
    }
  }

  private getWards() {
    this.wardService.getAll().pipe(
      takeUntil(this.destroy$)
    ).subscribe((wards: Ward[]) => {
      this.wards = wards
    })
  }
flyToLandmark(landmarkObj: any) {
  if(landmarkObj) {
    landmarkObj['isLandmark'] = true;
  this.flyToMap.emit(landmarkObj);
 }
}
flyToLocation() {
  this.flyToMap.emit(this.latLongInput);
}
  getColonyDetails(wardId:number | undefined, resetValue = true) {
    this.flyToMap.emit({wardId: wardId});
    this.colonies = [];
    if(resetValue) this.searchMapProperty.colonyId = undefined as any;
    if(wardId === undefined) { 
      this.searchMapProperty.selectedLandmark = undefined;
      this.landmarksForwardId = [];
      return;
    };
    this.loadingColonies = true;
    this.colonyService.getAll(wardId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((colonies: Colony[]) => {
      this.coloniesData = colonies;
      this.colonies = colonies;
      this.landmarkFiltered = wardId ? this.updateLandmarksForWard(colonies) : this.landmarks;
      this.loadingColonies = false;
    })
  }

  searchColonies() {
    this.colonyInput$.pipe(
      filter((term:string) => {
        if(this.searchMapProperty.wardId != undefined) {
          if(!term.trim()) {
            this.colonies = this.coloniesData.slice();
            return false;
          }
          this.colonies = this.coloniesData.filter((el:Colony) => el.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
          return false;
        }
        return true;
      }),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() =>  this.loadingColonies = true),
      switchMap((term:string) => {
        const where = this.colonyService.defaultSearchWhere(term);
        return this.colonyService.paginate(1, 100000, where)
      })
    ).subscribe((colonies: Colony[]) => {
      this.loadingColonies = false;
      this.colonies = colonies;
    })
  }

  getPropertyType() {
    if(this.propertyTypesAll.length) return;
    const propertyCategory = this.searchMapProperty.propertyCategoryId;
    this.loadingPropertyTypes = true;
    this.propertyTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertyType[]) => {
      this.loadingPropertyTypes = false;
      this.propertyTypesAll = data;
      if(propertyCategory) {
        this.propertyTypeData = this.propertyTypesAll.filter(el => el.property_category == propertyCategory);
      }else {
        this.propertyTypeData = this.propertyTypesAll;
      }
      
    })
  }

  onPropertyCategoryChange(propertyCategory: {id:number, name:string}) {
    this.searchMapProperty.property_type_id = null as any;
    this.searchMapProperty.property_sub_type_id = null as any;
    if(!propertyCategory) {
      this.propertyTypeData = this.propertyTypesAll;
      return;
    }
    const properyCategoryId = propertyCategory?.id;
    this.propertyTypeData = this.propertyTypesAll.filter(el => el.property_category == properyCategoryId);
  }

  getPropertySubType() {
    if(this.propertySubTypesAll.length) return;
    const propertyType = this.searchMapProperty.property_type_id;
    this.loadingPropertySubTypes = true;
    this.propertySubTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertySubType[]) => {
      this.loadingPropertySubTypes = false;
      this.propertySubTypesAll = data;
      if(propertyType) {
        this.propertySubTypeData = this.propertySubTypesAll.filter(el => el.propertyType.id == propertyType);
      }else {
        this.propertySubTypeData = this.propertySubTypesAll;
      }
      
    })
  }

  onPropertyTypeChange(propertyTypeId: number) {
    this.searchMapProperty.property_sub_type_id = null as any;
    if(!propertyTypeId) {
      this.propertySubTypeData = this.propertySubTypesAll;
      return;
    }
    this.propertySubTypeData = this.propertySubTypesAll.filter(el => el.propertyType.id == propertyTypeId);
  }

  // Update Landmarks based on the ward selection.
  updateLandmarksForWard(colonies: Colony[]) {
    this.landmarks.forEach((landmark: { colony_id: number; }) => {
      colonies.map((colonies: { id: number; }) => {
        if(colonies.id === landmark.colony_id) {
          this.landmarksForwardId.push(landmark);
        }
      });
    });
    return this.landmarksForwardId;
  }
  
  // Update Landmarks based on the colony selection.
  updateLandmarks(colonyId: number | undefined) {
    this.flyToMap.emit({colonyId: colonyId});
    this.searchMapProperty.selectedLandmark = undefined;
    if(colonyId) {
    this.landmarkFiltered = this.landmarks.filter((landmark: { colony_id: number; }) => landmark.colony_id === colonyId);
   } else {
    if(this.searchMapProperty.wardId &&  this.landmarksForwardId.length > 0) {
      this.landmarkFiltered = this.landmarksForwardId;
    } else {
      this.landmarkFiltered = this.landmarks;
    }
   }
  }

  /**
   *  Assign values if correct format is entered (lat,long)
   */

  onLatLongValueChange() {
    if(!this.latLongValue){
      this.latLongInput.latitude = undefined;
      this.latLongInput.longitude = undefined;
      return;
    }
    const latLongArr = this.latLongValue.split(',');
    if(Number(latLongArr[0]?.trim()) && Number(latLongArr[1]?.trim())){
      this.latLongInput.latitude = Number(latLongArr[0].trim()) as any;
      this.latLongInput.longitude = Number(latLongArr[1].trim()) as any;
    }
    
  }

  /**
   * On Latitute Longitude field blur check if lat,long format is correct
   */
  afterLatLongInput() {
    if(!this.latLongValue) return;
    const latLongArr = this.latLongValue.split(',');
    if(Number(latLongArr[0]?.trim()) && Number(latLongArr[1]?.trim())) return;
    Swal.fire('Error', 'Incorrect Latitude,Longitude format', 'error');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

<div class="card">
    <div class="card-body">
      <div class="tab-content" id="v-pills-tabContent">
        <div id="v-pills-shipping"
             role="tabpanel"
             class="tab-pane fade show active"
             aria-labelledby="v-pills-shipping-tab"
        >
          <div>
  
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex flex-column justify-content-center">
                <h4 class="patta-card-title">{{ cardTitle }}</h4>
                <p class="card-title-desc m-0">{{ cardTitleDesc }}</p>
              </div>
  
              <div class="" *ngIf="needToggle">
                <button class="toggle-btn" (click)="toggleCard()">
                  <i class='bx bx-chevron-up toggle-btn__icon' *ngIf="toggleStatus"></i>
                  <i class='bx bx-chevron-down toggle-btn__icon' *ngIf="!toggleStatus"></i>
                </button>
              </div>

              <ng-container *ngIf = "showButton">
                <ng-content select = "[button]"></ng-content>
              </ng-container>
            </div>
  
            <div class="card__content" *ngIf="(toggleStatus && needToggle === true) || needToggle === false">
              <ng-content></ng-content>
            </div>
  
          </div>
        </div>
  
      </div>
    </div>
  </div>
  


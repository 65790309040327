<div class="apps-section position-relative">
  <div class="container ">
    <div class="row app-section-row">
      <div class="col-sm-6 col-md-6 col-12">
        <h2 class="page-heading">{{'home.10_contact_us_icon_label' | translate}}</h2>
        <p class="contact-content">{{'home.contact_us_desc' | translate}}</p>
        <div class="apps-link d-flex justify-content-start">
          <button class="see_all right" routerLink="/contact-us">{{'home.contact_us_button' | translate}}</button>
        </div>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-6 col-12 position-inherit">
      </div>
    </div>
    <!-- <div class="main-apps">
      <div class="inner-apps">
    <img src="assets/images/app-screen.png" class="apps-screens" alt="Enter Service No">
    <h3>Coming soon</h3>
    <h2>Use Our Services From Anywhere</h2>
    <div class="apps-link d-flex justify-content-start">
      <a class="mr-4" href="https://play.google.com/" target="_blank">
        <img src="assets/images/google-play-button.png" class="img-fluid" alt="Google Play">
      </a>
      <a href="https://www.apple.com/app-store/" target="_blank">
        <img src="assets/images/app-store-button.png" class="img-fluid" alt="App Store">
      </a>
    </div>
  </div>
  </div> -->
  </div>
</div>

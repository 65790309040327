import { Component, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyService } from '../../../services/property.service';
 
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  private destroy$ = new ReplaySubject();
  landmarks: any = [];
  loadingLandmarks!:boolean;
  constructor(private propertyService: PropertyService,) { }

  ngOnInit(): void {
    this.loadingLandmarks = true;
    const cityId = localStorage.getItem('cityId');
    this.propertyService.getAllLandmarks(Number(cityId))
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((landmarks: any) => {
      this.landmarks = landmarks.data;
      this.loadingLandmarks = false;
    });
  }
  
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

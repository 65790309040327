<div class="container">

  <!-- Start breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">My Profile</li>
    </ol>
  </nav>


  <!-- Start Title and Filter Options -->
  <div class="profile-form" >
    <div class="listing-head mb-4 d-flex align-items-center justify-content-between">
      <h2 class="m-0">My Profile</h2>
    </div>
  <div class="profile-form">
    <div class="row">
      <div class="col-md-6">
        <div class="mb-3 position-relative">
          <label>First Name</label>
          <input type="text" class="form-control"
                 [(ngModel)]="userModel.first_name"
                 [ngModelOptions]="{standalone: true}"
          >
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3 position-relative">
          <label>Last Name</label>
          <input type="text" class="form-control"
                 [(ngModel)]="userModel.last_name"
                 [ngModelOptions]="{standalone: true}"
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="mb-3 position-relative">
          <label>Email address</label>
          <input type="text" class="form-control" email="true" #email="ngModel"
                 [(ngModel)]="userModel.email"
                 [ngModelOptions]="{standalone: true}"
                 [ngClass]="{'is-invalid': email.invalid}"
          >
          <div *ngIf="email.invalid" class="invalid-tooltip" >
            <span *ngIf="email.errors?.email">
              This field should be valid email
            </span>
            <span *ngIf="email.errors?.unique">
              The email has already been taken.
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3 position-relative">
          <label>Phone Number</label>
          <input type="text" class="form-control"
                 [(ngModel)]="userModel.phone_number"
                 [ngModelOptions]="{standalone: true}"
                 #phone_number="ngModel"
                 minlength="10"
                 [ngClass]="{'is-invalid': phone_number.invalid}"
          >
          <div *ngIf="phone_number.invalid" class="invalid-tooltip">
            <span *ngIf="phone_number.errors?.minlength">
              Mobile number must be at least 10 characters long.
            </span>
          </div>
        </div>
      </div>
    </div>
    <div>
    <button class="btn btn-primary float-right" type="submit" (click)="updateUser()">Update</button>
    </div>
  </div>
</div>

import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../services";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthenticationService
  ) {
    auth.userSubject.subscribe((user) => {
      if (user === null) {
        router.navigate(['/']);
      }
    });
  }

  ngOnInit(): void {
  }

}

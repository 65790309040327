import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {sarasRouting} from "./saras.routing";



@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    sarasRouting
  ]
})
export class SarasModule { }

import {City} from "./city";
import {Ward} from './ward';

export class Colony {
  id!: number;
  name!: string;
  name_hindi!: string;
  city = new City();
  wards: Ward[] = []
  gps_coordinates!: { latitude: number, longitude: number }[]
  gps_compressed_coordinates!: string
  description!: string;
  sro_zone!: string;
  establishment_year!: number;
  scheme_name!: string;
  total_plot_approved!: number;

  colony_code!: string;
  created_at: Date = new Date();
  updated_at: Date = new Date();
}

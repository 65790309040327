import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {PropertyCorrectionRequest} from '../@types/property-correction';
import {gql} from '@apollo/client/core';
import {toGraphQLRequest} from '../helpers/to-graphqL-request';
import {map} from 'rxjs/operators';
import {Objection} from '../models/objection';
import {Observable} from 'rxjs';
import {TableService} from './table.service';
import {BaseService} from './base-service.service';
import {NotificationChannels} from '../enums/notification-channels';

@Injectable({
  providedIn: 'root',
})
export class ObjectionService extends BaseService {
  table: TableService<Objection>;

  constructor(
    private apollo: Apollo,
  ) {
    super();

    this.table = new TableService<Objection>({
      apollo,
      queryName: 'ObjectionPaginate',
      needleColumns: this.needleColumns(),
      whereCondition: () => '',
    });
  }


  getById(id: number): Observable<Objection | undefined> {
    return this.apollo.query({
      query: gql`{ objection: ${ObjectionService.getByIdQuery(id)} }`,
    }).pipe(map(
      (res: any) => res?.data?.objection ? Object.assign(new Objection(), res?.data?.objection) : undefined,
    ));
  }
  getCorrectionRequestStatusById(id: number): Observable<Objection | undefined> {
    return this.apollo.query({
      query: gql`{${ObjectionService.getCorrectionRequestStatusByIdQuery(id)} }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.CorrectionRequestStatus,
    ));
  }

  createObjection(propertyId: number, correctionRequest: PropertyCorrectionRequest): Observable<Objection | undefined> {
    return this.apollo.mutate({
      mutation: gql`mutation { objection: ${ObjectionService.createObjectionQuery(propertyId, correctionRequest)} }`,
    }).pipe(map(
      (res: any) => res?.data?.objection ? Object.assign(new Objection(), res.data.objection) : undefined,
    ));
  }

  updateObjectionStatus(
    objectionData: { objectionId: number, status: number, remark?: string },
    notification: { needNotification: boolean, channels?: NotificationChannels[], subject?: string, message?: string },
  ): Observable<boolean> {
    return this.apollo.mutate({
      mutation: gql`mutation {
      updateStatus: ${ObjectionService.updateObjectionStatusQuery(objectionData, notification)}
      }`,
    }).pipe(map((res: any) => !!res?.data?.updateStatus));
  }

  static getByIdQuery(id: number) {
    const userColumns = 'id first_name last_name email phone_number';
    const modificationColumns = 'field old_value new_value';
    const propertyColumns ='id';

    return `ObjectionById(id: ${id}) {
      id objection_no status created_at citizen_remark
      user {${userColumns}} reviewer {${userColumns}}
      property {${propertyColumns}}
      documents {proof_type_id documentProofType {id, name} path name}
      objectionPropertyDetails {id status ${modificationColumns} show_new_value show_old_value}
      objectionOwnerModifications {
        id status citizen {id first_name last_name relation relation_name gender email} ${modificationColumns}
      }
      objectionOwnerNews {
        id status first_name last_name relation relation_name ownership_share document_proof_type_id uid_number gender email
        phone_number bpl_card bpl_number
      }
      objectionOwnerDeletes {id status citizen {id first_name last_name}}
      objectionFloorModifications {
        id status floor {
          id floor_number property_category propertyType {id name} propertySubType {id name} property_usage_id
          carpet_area from_year upto_year
        } ${modificationColumns}
      }
      objectionNewFloors {
        id status floor_number property_usage_id property_category propertyType {id name} propertySubType {id name}
        property_construction_type_id floor_construction_type_id carpet_area from_year upto_year
      }
      objectionFloorDeletes {id status floor {
        id floor_number property_category propertyType {id name} propertySubType {id name} property_usage_id
        carpet_area from_year upto_year
      } }
      objectionStatusHistories {id status remark created_at}
    }`;
  }
  static getCorrectionRequestStatusByIdQuery(id: number) {
    return `CorrectionRequestStatus(objection_number: ${id}) {
      objection_number
      property_uid
      status
      remark
      submitted_by {
        id
        first_name
        last_name
      }
      created_at
    }`;
  }
  static createObjectionQuery(propertyId: number, correctionRequest: PropertyCorrectionRequest) {
    return `CreateObjection(
      propertyId: ${propertyId}
      citizenRemark: "${correctionRequest.citizenRemark}"
      proofs: ${toGraphQLRequest(correctionRequest.proofs)}
      propertyChanges: ${toGraphQLRequest(correctionRequest.propertyChanges)}
      floorsChanges: ${toGraphQLRequest(correctionRequest.floorsChanges)}
      ownersChanges: ${toGraphQLRequest(correctionRequest.ownersChanges)}
    ) {
      id
      objection_no
    }`;
  }

  static updateObjectionStatusQuery(
    objectionData: { objectionId: number, status: number, remark?: string },
    notification: { needNotification: boolean, channels?: NotificationChannels[], subject?: string, message?: string },
  ) {
    return `UpdateObjectionStatus(
        objectionId: ${objectionData.objectionId}
        status: ${objectionData.status}
        needNotification: ${notification.needNotification}
        ${notification.needNotification ? `notificationInfo: {
          message: "${notification.message}"
          channels: [${notification.channels?.reduce((acc, v) => acc + ', ' + v, '')}]
          ${notification.subject ? `subject: "${notification.subject}"` : ''}
        }` : ''}
        ${objectionData.remark && objectionData.remark.trim() !== '' ? `remark: "${objectionData.remark}"` : ''}
    )`;
  }

  needleColumns() {
    return `
      id
      objection_no
      status
      created_at
      citizen_remark
      property{
        id
        property_uid
        ward{ id name }
        colony{ id name }
      }
      user{ id first_name last_name phone_number }
    `;
  }
}

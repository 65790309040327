import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import * as L from 'leaflet';
import { LatLng, LatLngExpression, LeafletEvent } from 'leaflet';
import { SearchMapProperty } from '../../../@types/search-map-property';
import { Colony, Property, Ward } from '../../../models';
import { PropertyService } from '../../../services/property.service';
import { ReplaySubject, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { PropertyInfoCardComponent } from '../property-info-card/property-info-card.component';
import 'leaflet.markercluster';
import 'leaflet.fullscreen';
import 'leaflet.locatecontrol';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { getPropertyMapIcon } from '../../../global/property-map-icons';
import GeometryUtil from 'leaflet-geometryutil';
import { CityService } from '../../../services/city.service';
import { MapServiceService } from '../../../map-service.service';
import { PattaVerificationService } from '../../patta-verification/patta-verification.service';

type PropertyCircle = { circle: L.Circle, property: Property };

interface bounds {
  northeast?: L.LatLng,
  southwest?: L.LatLng,
}

@Component({
  selector: "app-map",
  templateUrl: "./map.component.html",
  styleUrls: ["./map.component.scss"],
})
export class MapComponent implements OnInit, OnDestroy, AfterContentChecked {
  readonly minZoom = 16;
  readonly maxZoom = 20;
  readonly requiredZoom = 17;
  readonly zoomForColoniesLabels = 17;
  readonly zoomForLandmarkLabels = 18;
  readonly zoomForPropertiesLabels = 19;
  readonly zoomForWardsLabels = 13;
  initialCenter: [number, number] = [26.2389, 73.0243];
  readonly mainTile = environment.mapTileUrl;
  // kilometers
  readonly radiusForProperty = 1;
  // meters
  readonly minDistanceDiff = 200;
  readonly nearbyPropertyDistance = 50;

  readonly cssMapLabel = "map-label";
  readonly landmarkMapLabel = "map-landmark-label";

  currentZoom = 13;
  nearbyProperties: Property[] = [];
  loadingProperties = false;
  searchMapProperty = new SearchMapProperty();

  @ViewChild("map") mapElement!: ElementRef;

  protected map!: L.Map;
  protected currentMarkers: L.Marker[] = [];
  protected coloniesPolygon: { polygon: L.Polygon<any>; colony: Colony }[] = [];
  protected propertiesPolygon: { polygon: L.Polygon<any>; property: Property }[] = [];
  protected wardsPolygon: { polygon: L.Polygon<any>; ward: Ward }[] = [];
  protected currentCluster: L.MarkerClusterGroup | undefined;
  protected currentPropertyCircle: PropertyCircle | { [key: string]: any } = {};
  protected landmarkLabels: L.Marker[] = [];
  protected coloniesLabels: L.Marker[] = [];
  protected wardsLabels: L.Marker[] = [];

  protected colonies: Colony[] = [];
  protected wards: Ward[] = [];
  protected properties: Property[] = [];
  public landmarks: any = [];
  protected previousLoadingLatLng!: LatLng;

  protected destroy$ = new ReplaySubject();
  protected centerSubject = new Subject<LatLng>();
  protected initColoniesBoundarySubject = new ReplaySubject();
  protected initWardsBoundarySubject = new ReplaySubject();
  protected initPropertiesBoundarySubject = new ReplaySubject();
  protected initCityBoundarySubject = new ReplaySubject<[[number, number]]>();

  protected loadPropertySub!: Subscription;

  protected lastBounds: bounds = {};

  currentLocationMarker!: L.Marker;
  currentSearchedLocationMarker!: L.Marker;
  constructor(
    protected cs: CityService,
    protected router: Router,
    protected injector: Injector,
    protected currentRoute: ActivatedRoute,
    protected propertyService: PropertyService,
    protected componentFactory: ComponentFactoryResolver,
    protected ref: ChangeDetectorRef,
    protected mapService: MapServiceService,
    protected pattaVerificationService: PattaVerificationService
  ) {
    this.initialCenter = this.cs.mapCenterCoordinates;
    this.searchMapProperty = Object.assign(new SearchMapProperty(), this.currentRoute.snapshot.queryParams);
  }

  ngOnInit(): void {
    this.getLandmarks();
    this.initBoundaries();
    this.initCenterWatcher();
    this.initMap("map");

    if (this.searchMapProperty?.valid()) this.loadProperties();
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.loadPropertySub?.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  search(searchMapProperty: SearchMapProperty) {
    this.searchMapProperty = searchMapProperty;
    this.router.navigate([], {
      relativeTo: this.currentRoute,
      queryParams: this.searchMapProperty,
    });

    if (this.currentZoom !== this.maxZoom) this.clearMap();

    this.loadProperties();
  }

  flyToMap(flyToObj: any) {
    let coords: any;
    let zoomLevel: number = 16;
    if (!flyToObj?.isLandmark) {
      if (flyToObj?.isLatLongInput) {
        coords = [flyToObj?.latitude, flyToObj?.longitude];
        this.setSearchedLocationMarker(flyToObj);
        zoomLevel = 16;
      } else {
        coords = flyToObj.wardId
          ? this.wardsPolygon.find((w) => w.ward?.id === Number(flyToObj.wardId))?.polygon?.getCenter()
          : this.coloniesPolygon.find((c) => c.colony?.id === Number(flyToObj.colonyId))?.polygon?.getCenter();
          if(flyToObj.colonyId!==undefined){
            zoomLevel = 18;
          }
      }
    } else {
      coords = [flyToObj?.latitude, flyToObj?.longitude];
      zoomLevel = 18;
    }
    if (coords)
      this.map.flyTo(coords, zoomLevel, {
        animate: true,
        duration: 2,
      });
  }
  protected getLandmarks() {
    const cityId = localStorage.getItem('cityId');
    this.propertyService.getAllLandmarks(Number(cityId))
      .pipe(
        takeUntil(this.destroy$)
      ).subscribe((landmarks: any) => {
        this.landmarks = landmarks.data;
      });
    this.resolveLandmarkLabels();
  }

  protected loadProperties() {

    this.stopLoading();

    if (!this.searchMapProperty?.valid() && this.currentZoom < this.requiredZoom) return;

    this.loadingProperties = true;

    this.previousLoadingLatLng = this.map.getCenter();
    this.lastBounds.northeast = this.map.getBounds().getNorthEast();
    this.lastBounds.southwest = this.map.getBounds().getSouthWest();

    this.loadPropertySub = this.propertyService
      .getPropertiesForMap(this.lastBounds.northeast, this.lastBounds.southwest, this.searchMapProperty, this.isSearchMode())
      .subscribe((properties) => {
        this.loadingProperties = false;
        this.properties = properties;
        this.unCluster();
        this.removeAllMarkers();
        this.resolvePropertyCircleAfterLoaded(properties);
        this.isSearchMode() ? this.resolveCluster() : this.appendMarkersForProperties(properties);
        this.initPropertiesBoundarySubject.next();
        this.flyToPropertyCenter();
      });
  }

  protected flyToPropertyCenter()
  {
    if ((this.searchMapProperty?.propertyUID || this.searchMapProperty?.mobile) && this.properties.length>0 && this.properties[0].latitude && this.properties[0].longitude) {
      this.map.flyTo(new LatLng(this.properties[0].latitude, this.properties[0].longitude), 17, {
        animate: true,
        duration: 2,
      });
    }
  }
  

  protected stopLoading() {
    this.loadPropertySub?.unsubscribe();
    this.loadingProperties = false;
  }

  protected isSearchMode(): boolean {
    return this.searchMapProperty?.valid() ?? false;
  }

  protected setNearbyProperties(property: Property) {
    this.nearbyProperties = [];

    if (!property.latitude || !property.latitude) return;

    const search = new SearchMapProperty();

    if (property.ward_id) search.wardId = property.ward_id;

    this.loadPropertySub = this.propertyService
      .getPropertiesForMapByCircle(search, {
        distance: this.nearbyPropertyDistance / 1000,
        lat: property.latitude,
        lng: property.longitude,
      })
      .subscribe((properties) => {
        this.nearbyProperties = properties.filter((p) => p.id !== property.id);
      });
  }

  protected removeNearbyProperties() {
    this.nearbyProperties = [];
  }

  protected resolveNotSearchModeZoom() {
    if (this.currentZoom >= this.requiredZoom && this.properties.length === 0) {
      this.loadProperties();
    }

    if (this.currentZoom < this.requiredZoom) {
      this.stopLoading();
      this.clearMap();
      this.properties = [];
    }
  }

  protected resolvePropertyIcon(property: Property) {
    return new L.Icon({
      iconUrl: getPropertyMapIcon(property.property_category),
      iconSize: [23, 40],
      iconAnchor: [12, 38],
      popupAnchor: [-2, -37],
    });
  }

  protected resolveLandmarkLabels() {
    if (this.currentZoom < this.zoomForLandmarkLabels) {
      this.landmarkLabels.forEach((l) => l.remove());
      this.landmarkLabels = [];
      return;
    }

    if (this.currentZoom >= this.zoomForLandmarkLabels && this.landmarkLabels.length !== 0) return;

    this.landmarks.forEach((l: { latitude: number; longitude: number; name: string; }) => {
      this.landmarkLabels.push(this.mapService.createLabel([l?.latitude, l?.longitude], l?.name, this.landmarkMapLabel).addTo(this.map));
    });
  }

  protected resolveColoniesLabels() {
    if (this.currentZoom < this.zoomForColoniesLabels) {
      this.coloniesLabels.forEach((l) => l.remove());
      this.coloniesLabels = [];
      return;
    }

    if (this.currentZoom >= this.zoomForColoniesLabels && this.coloniesLabels.length !== 0) return;
    this.coloniesPolygon.forEach((c) => {
      this.coloniesLabels.push(this.mapService.createLabel(c.polygon.getBounds().getCenter(), c.colony.name, this.cssMapLabel).addTo(this.map));
    });

  }

  protected resolveWardsLabels() {
    if (this.currentZoom < this.zoomForWardsLabels) {
      this.wardsLabels.forEach((l) => l.remove());
      this.wardsLabels = [];
      return;
    }

    if (this.currentZoom >= this.zoomForWardsLabels && this.wardsLabels.length !== 0) return;

    this.wardsPolygon.forEach((w) => {
      this.wardsLabels.push(this.mapService.createLabel(w.polygon.getBounds().getCenter(), w.ward.name, this.cssMapLabel).addTo(this.map));
    });
  }

  protected clearMap() {
    this.removeCurrentPropertyCircle();
    this.removeAllMarkers();
    this.removeNearbyProperties();
    this.unCluster();
  }

  /**
   * Handlers
   * */
  protected zoomHandler = (e: LeafletEvent) => {
    this.currentZoom = e.target.getZoom();
    this.resolveWardsLabels();
    this.resolveColoniesLabels();
    this.resolveLandmarkLabels();
    if (this.isSearchMode()) {
      this.resolveCluster();
    } else {
      this.resolveNotSearchModeZoom();
    }
  };

  protected markerClickHandler = (e: LeafletEvent, marker: L.Marker, property: Property) => {
    this.removeCurrentPropertyCircle();

    if (!this.currentCluster) {
      this.currentPropertyCircle = this.createPropertyCircle(property, marker.getLatLng());
    }

    this.setNearbyProperties(property);

    if (!marker.getPopup()) {
      marker.bindPopup(this.createPropertyPopup(property), {
        className: "property-information-popup-wrapper",
      });
      marker.openPopup();
    }
  };

  /**
   * Create/remove/append Map Element
   * 
   */
  protected createPropertyMarker(property: Property): L.Marker {
    const marker = new L.Marker([property.latitude, property.longitude], {
      icon: this.resolvePropertyIcon(property),
    })
    .bindTooltip(property.address_house_number,
        {
          permanent: false,
          offset: [0, -40],
          direction: 'top',
          className: "property-label"
        });
    marker.on("click", (e) => this.markerClickHandler(e, marker, property));
    return marker;
  }

  protected createPropertyPopup(property: Property) {
    const component = this.componentFactory.resolveComponentFactory(PropertyInfoCardComponent).create(this.injector);
    component.instance.property = property;
    component.changeDetectorRef.detectChanges();
    return component.location.nativeElement;
  }

  protected createPropertyCircle(property: Property, latLng: LatLng): PropertyCircle {
    const circle = L.circle(latLng, {
      color: "#5E2EBA",
      fillColor: "rgba(94, 46, 186, .40)",
      fillOpacity: 0.5,
      radius: this.nearbyPropertyDistance,
    }).addTo(this.map);
    return { circle, property };
  }

  protected removeAllMarkers() {
    this.currentMarkers.forEach((m) => {
      m.remove();
      this.map.removeLayer(m);
    });

    this.propertiesPolygon.forEach((p) => {
      p.polygon.remove();
      this.map.removeLayer(p.polygon);
    });

    this.propertiesPolygon = [];
    this.currentMarkers = [];
  }

  protected removeCurrentPropertyCircle() {
    this.currentPropertyCircle?.circle?.remove();
    this.currentPropertyCircle = {};
  }

  protected appendMarker(marker: L.Marker) {
    this.currentMarkers.push(marker);
    marker.addTo(this.map);
  }

  protected appendMarkersForProperties(properties: Property[]) {
    if (this.searchMapProperty.propertyCategoryId) {
      properties = properties.filter(
        (p) => p.property_category.toString() === this.searchMapProperty.propertyCategoryId?.toString()
      );
    }
    properties.forEach((p) => {
      if (p.latitude !== null) {
        this.appendMarker(this.createPropertyMarker(p));
      }
    });
  }

  protected initPropertiesBoundary() {
    this.initPropertiesBoundarySubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.properties.forEach((property) => {
        if(property.polygon_coordinates !== null && Array.isArray(property.polygon_coordinates)){
          this.propertiesPolygon.push({
            polygon: this.mapService.createPolygon(property.polygon_coordinates, "#39b3db", 1.5).addTo(this.map),
            property: property,
          });
        }
      });
    });
  }

  /**
   * Cluster
   * */
  protected resolveCluster() {
    
    if (this.needCluster()) {
      this.cluster();
      this.removeAllMarkers();
      this.removeCurrentPropertyCircle();
    } else if (this.needUnCluster()) {
      this.unCluster();
      this.appendMarkersForProperties(this.properties);
      this.initPropertiesBoundary();
    } else if (this.currentMarkers.length === 0 && !this.currentCluster) {
      this.appendMarkersForProperties(this.properties);
      this.initPropertiesBoundary();
    }
  }

  protected needCluster() {
    return this.searchMapProperty?.valid() && !this.currentCluster && this.currentZoom < this.requiredZoom && this.properties.length > 50;
  }

  protected needUnCluster() {
    return this.currentCluster && this.properties.length < 50;
  }

  protected cluster() {
    this.currentCluster = L.markerClusterGroup({
      showCoverageOnHover: false,
      removeOutsideVisibleBounds: true,
      disableClusteringAtZoom: 19,
      chunkedLoading: true,
      chunkInterval: 300,
    });
    this.properties?.forEach(el => {
      if (el.latitude && el.longitude) {
       this.currentCluster?.addLayer(this.createPropertyMarker(el))
      }
    })
    this.map.addLayer(this.currentCluster);
  }

  protected unCluster() {
    if (!this.currentCluster) return;

    this.currentCluster?.remove();
    this.currentCluster?.getLayers().forEach((l) => l?.remove());
    this.map.removeLayer(this.currentCluster);
    this.currentCluster = undefined;
  }

  /**
   * For Request
   * */
  protected resolvePropertyCircleAfterLoaded(properties: Property[]) {
    if (!this.currentPropertyCircle.property) return;

    const propertyExists = properties.findIndex((p) => p.id === this.currentPropertyCircle.property?.id) !== -1;

    if (propertyExists) {
      this.setNearbyProperties(this.currentPropertyCircle.property);
      return;
    }

    this.removeCurrentPropertyCircle();
    this.removeNearbyProperties();
  }

  /**
   * Initializers
   * */
  protected initMap(domId: string) {
    this.map = L.map(domId, {
      center: this.initialCenter,
      zoom: this.currentZoom,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: "topright",
      },
    });

    this.map.addControl(
      L.control.locate({
        position: "topright",
        strings: {
          title: "Current location",
        },
        icon: "location-control-marker",
        drawMarker: false,
      })
    );
    /* Legend specific */
    var legend = new L.Control({ position: "topright" });
    legend.onAdd = function () {
      var div = L.DomUtil.create("div", "border-info-legend");
      div.innerHTML +=
        '<div class="color-block" style="background-color: #d34900"><span style="color: #000">City</span></div>';
      div.innerHTML +=
        '<div class="color-block" style="background-color: #0063ff"><span style="color: #000">Wards</span></div>';
      div.innerHTML +=
        '<div class="color-block" style="background-color: #DBD71F"><span style="color: #000">Colonies</span></div>';
      div.innerHTML +=
        '<div class="color-block" style="background-color: #87CEEB"><span style="color: #000">Properties</span></div>';
      return div;
    };
    legend.addTo(this.map);
    this.initMapEvents();
    this.initLayer();
    this.initColoniesBoundary();
    this.initWardsBoundary();
    this.initCityBoundary();
    this.initPropertiesBoundary();
  }

  protected initBoundaries() {
    this.loadingProperties = true;
      this.mapService.getMapBoundaries()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        if (!res?.data?.colonies) return;
        this.loadingProperties = false;
        this.colonies = res?.data?.colonies?.filter((c: Colony) => c.gps_compressed_coordinates !== "[]");
        this.wards = res?.data?.wards?.filter((c: Ward) => c.gps_compressed_coordinates !== "[]");

        this.initColoniesBoundarySubject.next();
        this.initWardsBoundarySubject.next();
        this.initCityBoundarySubject.next(
          res.data.city[0]?.gps_compressed_coordinates ? JSON.parse(res.data.city[0]?.gps_compressed_coordinates) : []
        );
      });
  }

  protected initColoniesBoundary() {
    this.initColoniesBoundarySubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.colonies.forEach((colony) => {
        this.coloniesPolygon.push({
          polygon: this.mapService.createPolygon(JSON.parse(colony.gps_compressed_coordinates), "#DBD71F", 2).addTo(this.map),
          colony: colony,
        });
      });

      this.resolveColoniesLabels();
    });
  }

  protected initWardsBoundary() {
    this.initWardsBoundarySubject.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.wards.forEach((ward) => {
        this.wardsPolygon.push({
          polygon: this.mapService.createPolygon(JSON.parse(ward.gps_compressed_coordinates), "#0063ff", 2.5).addTo(this.map),
          ward: ward,
        });
      });

      this.resolveWardsLabels();
    });
  }

  protected initCityBoundary() {
    this.initCityBoundarySubject.pipe(takeUntil(this.destroy$)).subscribe((coordinates) => {
      var polygon=this.mapService.createPolygon(coordinates, "#d34900", 3);
      console.log(polygon.getBounds());
      polygon.addTo(this.map);
    });
  }

  protected initLayer() {
    // Add base map layer
    L.tileLayer(environment.baseMapTileUrl, { maxZoom: this.maxZoom, subdomains:['mt0','mt1','mt2','mt3']}).addTo(this.map);

    // Add drone image layer
    const tiles = this.mapService.getTileLayer(this.mainTile, this.maxZoom, this.minZoom);
    tiles.addTo(this.map);
  }

  protected initMapEvents() {
    this.map.on("zoom", this.zoomHandler);
    let lastZoom: number;
    this.map.on('zoomend', () => {
      let zoom = this.map.getZoom();
      if (zoom < this.zoomForPropertiesLabels && (!lastZoom || lastZoom >= this.zoomForPropertiesLabels)) {
        this.map.eachLayer(function (l) {
          if (l.getTooltip()) {
            let tooltip: any = l.getTooltip();
            if (tooltip.options.className === "property-label") {
              l.unbindTooltip().bindTooltip(tooltip, {
                permanent: false
              })
            }
          }
        })
      } else if (zoom >= this.zoomForPropertiesLabels && (!lastZoom || lastZoom < this.zoomForPropertiesLabels)) {
        this.map.eachLayer(function (l) {
          if (l.getTooltip()) {
            let tooltip: any = l.getTooltip();
            if (tooltip.options.className === "property-label") {
              l.unbindTooltip().bindTooltip(tooltip, {
                permanent: true
              })
            }
          }
        });
      }
      lastZoom = zoom;
    })
    this.map.on("moveend", (e) => this.centerSubject.next(e.target.getCenter()));

    this.map.on("locationfound", (e) => this.setLocationMarker(e));
  }

  protected initCenterWatcher() {
    this.centerSubject
      .pipe(
        debounceTime(600),
        distinctUntilChanged(),
        filter((c) => {
          return this.previousLoadingLatLng
            ? GeometryUtil.length([this.previousLoadingLatLng, c]) > this.minDistanceDiff
            : true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((t) => {
        if (!this.searchMapProperty?.valid()) this.loadProperties();
      });
  }

  setLocationMarker(e: any) {
    if (this.currentLocationMarker) {
      this.map.removeLayer(this.currentLocationMarker);
    }

    this.currentLocationMarker = new L.Marker(e.latlng, {
      icon: new L.Icon({
        iconUrl: "../../../../assets/images/map/location-icons/marker-location.png",
        iconSize: [23, 40],
        iconAnchor: [12, 38],
        popupAnchor: [-2, -37],
      }),
    });
    this.appendMarker(this.currentLocationMarker);
  }

  setSearchedLocationMarker(location: any) {
    if (this.currentSearchedLocationMarker) {
      this.map.removeLayer(this.currentSearchedLocationMarker);
    }
    this.currentSearchedLocationMarker = new L.Marker([location.latitude, location.longitude], {
      icon: new L.Icon({
        iconUrl: "../../../../assets/images/map/location-icons/location_user.png",
        iconSize: [23, 40],
        iconAnchor: [12, 38],
        popupAnchor: [-2, -37],
      }),
    });
    this.currentSearchedLocationMarker.addTo(this.map);
  }
}

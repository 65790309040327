<div class="d-flex justify-content-end mb-4 mmb-4">
  <button class="btn btn-primary" (click)="print()" [disabled]="disabled">Tax Notice (Self Assessment) Print</button>
</div>

<div style="display: none;">
  <div class="main-page" id="print-self-assessment-form" style="position: absolute; z-index: 10;">
    <div class="header" style="vertical-align:middle; margin-bottom: 5px;">
      <div style="padding-top: 5px;width: 15%;">
        <img src="assets/images/{{ cs.city }}/logo-dark.png" width="100">
      </div>
      <div class="header-title" style="text-align: center; width: 70%; height: 110px">
        <div>
          <h1 *ngIf="cs.city === 'jodhpur'" style="font-size: 16px; color: #2e4764;">Jodhpur Nagar Nigam</h1>
          <h1 *ngIf="cs.city === 'bharatpur'" style="font-size: 16px; color: #2e4764;">Bharatpur Nagar Nigam</h1>
          <h1 style="margin-bottom: 0; padding: 0; font-size:16px;">
            <b style="margin-bottom: 0; padding: 0">Urban Development Tax Self Assessment Information 2022-2023</b>
          </h1>
          <h1 class="underline" style="margin: 0; padding: 0; font-size:15px;">
            (Under Section 128-129 of Rajasthan Municipal Act, 2009)
          </h1>
        </div>
      </div>
      <div style="text-align: right; padding-top: 24px; width:15%;">
        <img src="{{'assets/images/logo_1.png'}}" width="100">
      </div>
    </div>
    <div class="service">
      <table>
        <tr>
          <td class="font-size-600">Temporary UID</td>
          <td id="new-property-id">{{ property.property_uid ?? 'N/A'}}</td>
          <td class="font-size-600">Ward No.</td>
          <td id='ward-no'>{{property.ward?.name}}</td>
        </tr>
        <tr>
          <td class="font-size-600">DLC Colony</td>
          <td id='colony-name'>{{property.colony?.name}}</td>
          <td class="font-size-600">Colony Code</td>
          <td id='colony-code'>{{property.colony?.colony_code}}</td>
        </tr>
        <tr>
          <td class="font-size-600">Owner/Occupier Name</td>
          <td id='owner-name-new'>
            {{getOwnerNames()}}
          </td>
          <td class="font-size-600">SRO Zone</td>
          <td id='sro-zone'>{{property.colony?.sro_zone}}</td>
        </tr>
        <tr>
          <td class="font-size-600">Owner Gender</td>
          <td id='owner-gender'>
           {{getOwnerGender()}} 
          </td>
          <td class="font-size-600"> Ownership Type</td>
          <td id='ownership-type'>
            {{property.ownershipType?.id ? $any(OWNERSHIP_TYPES_NAMES)[property.ownershipType.id] : ''}}
          </td>
        </tr>
        <tr>
          <td class="font-size-600">Mobile No</td>
          <td id='mobile-number'>{{property.owners?.[0]?.phone_number}}</td>
          <td class="font-size-600"></td>
          <td>
          </td>
        </tr>
        <tr>
          <td class="font-size-600">Property Address</td>
          <td colspan="3">
            <div id='permanent-address-line1'>
              {{property.address_house_number}},{{property.colony?.name}},{{property.landmark}},{{property.ward?.name}}
            </div>
            <br>
            <div id='permanent-address-line2'>
              {{property.district?.name}},{{property.city?.name}}, Rajasthan <br>{{property.pincode}}}
            </div>
          </td>
        </tr>
      </table>
    </div>
      <table>
        <tbody>
        <tr>
          <th>Plot Area<br>(in Sq.Yd.)</th>
          <th>Vacant Area<br>(in Sq. Yd.)</th>
          <th>Total Built-up Area<br>(In sq.ft)</th>
          <th>Property Category</th>
          <th>Road Location</th>
          <th>Road Width<br>(In Ft.)</th>
          <th>Multi Storey / Complex</th>
          <th>Construction Year</th>
          <th>Rebate</th>
          <th>Exemption</th>
        </tr>
        <tr>
          <td id="plot-area">{{property.plot_area}}</td>
          <td id="vacant-area">{{property.vacant_area}}</td>
          <td id="carpet-area">{{property?.totalCarpetAreaCY() ?? ''}}</td>
          <td id="property-category">
            {{PROPERTY_CATEGORIES_NAMES[$any(property.propertyCategoryByFloors())]}}
          </td>
          <td id="road-location">{{selfAssessmentFormPreSave?.road_location}}</td>
          <td id="road-width">{{property.road_width}}</td>
          <td id="multi-storey">{{property.multi !== undefined ? (property.multi ? 'Yes' : 'No') : ''}}</td>
          <td id="construction-year">{{ commonService.getYearValueAsFY(property.building_construction_year) }}</td>
          <td id="rebate">{{property.rebate?.name ?? 'N/A'}}</td>
          <td id="exemption">{{isExempted() ? 'Yes' : 'No'}}</td>
        </tr>
        </tbody>
      </table>
      <table>
        <thead>
        <tr id="floorheading">
          <th style="width:8%">Sl No</th>
          <th>Floor</th>
          <th>From Year</th>
          <th>Upto Year</th>
          <th>Property Category</th>
          <th>Property Type</th>
          <th>Sub Type</th>
          <th>Built-up Area<br>(in Sq.Ft)</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let floor of property.floors; let i = index">
          <td>{{i + 1}}</td>
          <td>{{FLOOR_NUMBER[floor.floor_number]}}</td>
          <td>{{floor.from_year}}</td>
          <td>{{floor.upto_year}}</td>
          <td>{{PROPERTY_CATEGORIES_NAMES[floor.property_category]}}</td>
          <td>{{floor?.propertyType?.name}}</td>
          <td>{{floor?.propertySubType?.name}}</td>
          <td>{{floor.carpet_area}}</td>
        </tr>
        </tbody>
      </table>
      <table>
        <thead>
        <tr id="calculationheading">
          <th style="width:8%">Sl No</th>
          <th>Property Category</th>
          <th>Area (in Sq. Yd.)<br>(Current Year)</th>
          <th>DLC Rate <br>(per Sq Mt)</th>
          <th>DLC Rate / 2000</th>
          <th>Current Year Tax Demand (In ₹)</th>
        </tr>
        </thead>
        <tbody *ngIf="taxCalculation; else notCalculatedTax">
        <tr *ngFor="let calculationInfo of taxCalculation.taxCalculation; let i = index">
          <td>{{i + 1}}</td>
          <td>{{PROPERTY_CATEGORIES_NAMES[calculationInfo.propertyCategory]}}</td>
          <td>{{calculationInfo.area}}</td>
          <td>{{calculationInfo.rate}}</td>
          <td>{{(calculationInfo.rate / 2000) | number:'1.2' }}</td>
          <td>{{calculationInfo.tax}}</td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td colspan="3">Total Tax Demand Current Year</td>
          <td id="total-tax-demand">{{taxCalculation?.totalTax | currencyINR}}</td>
        </tr>
        </tbody>
      </table>
      <table>
        <thead>
        <tr id="ledgerHeading">
          <th>Year</th>
          <th>Date</th>
          <th>Particulars</th>
          <th>Debit Amount</th>
          <th>Credit Amount</th>
          <th>Balance</th>
        </tr>
        </thead>
        <tbody *ngIf="ledgersDetails; else ledgerNotExists">
        <tr *ngFor="let ledger of ledgersDetails.ledgers; let i = index">
          <td>{{ledger.financial_year + '-' + (ledger.financial_year + 1)}}</td>
          <td>{{ledger.transaction_datetime | date:  'dd-MM-yyyy HH:mm:ss'}}</td>
          <td>{{LEDGER_TYPES[ledger.transaction_type] | titlecase}}</td>
          <td>{{ledger.debit_amount | currencyINR}}</td>
          <td>{{ledger.credit_amount | currencyINR}}</td>
          <td #balance>{{ledger | rebateBalanceCalculator : i}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="6">Tax Due Summary:</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Total Tax before current year (In ₹)</td>
          <td id="arrears">{{lastYearDebt() | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Tax Amount (Current Year) (In ₹)</td>
          <td id="current-year-tax">{{taxCalculation?.totalTax | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Total Penalty (In &#8377;)</td>
          <td id="penalty">{{ledgersDetails.penalties[0]?.debit_amount ?? 0 | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Rebate (100% Rebate on Penalty In case of Full Payment (In ₹)</td>
          <td id="rebate-penalty">{{getRebateByType(REBATE_TYPES.Penalty)?.credit_amount ?? 0 | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Rebate (50% Rebate On Tax Demand from FY 2007-08 to 2010-11)</td>
          <td id="rebate-50percentile">{{getRebateByType(REBATE_TYPES.Arrears)?.credit_amount ?? 0 | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Female Ownership Rebate (@10% of Current Year Tax Demand) (In ₹)</td>
          <td id="rebate-gender">{{getRebateByType(REBATE_TYPES.Gender)?.credit_amount | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Early Payment Rebate (Till 30th Jun 22) (@10% of Current Year Tax Demand) (In ₹)</td>
          <td id="rebate-early-pay-june">{{getRebateByType(REBATE_TYPES.Date)?.credit_amount ?? 0 | currencyINR}}</td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Total Amount Due with Early Payment Rebate (Till 30th Jun 22) (In ₹)</td>
          <td id="total-amount-june-rebate">
            {{(getTotalAmountDue() + (getRebateByType(REBATE_TYPES.Date)?.credit_amount ?? 0) | currencyINR)}}
          </td>
        </tr>
        <tr class="text-left font-size-600">
          <td colspan="5">Total Amount Due (In ₹)</td>
          <td id="total-amount-due">{{getTotalAmountDue() | currencyINR}}</td>
        </tr>
        </tbody>
      </table>
    
  </div>
</div>

<ng-template #notCalculatedTax>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">Tax can not be calculated</td>
  </tr>
  </tbody>
</ng-template>

<ng-template #ledgerNotExists>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">Unable to calculate ledgers</td>
  </tr>
  </tbody>
</ng-template>

import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'any'
})
export class NgbDateFormatter extends NgbDateParserFormatter {
  parse(value: string = '') {
    const dateParts = value?.trim()?.split(" ");

    if (dateParts.length === 0) return null;

    const month = getMonth(dateParts[1]);

    return {
      day: isNumber(dateParts[0]) ? toInteger(dateParts[0]) : 0,
      month: isNaN(month) ? 0 : month,
      year: isNumber(dateParts[2]) ? toInteger(dateParts[2]) : 0,
    };
  }

  format(date: NgbDateStruct): string {
    return date ? `${date.day} ${this.getFormattedMonth(date.month)} ${date.year}` : "";
  }

  private getFormattedMonth(month: number) {
    let date = new Date(2020, month - 1, 21);

    return date.toLocaleString('en-in', {month: 'short'}); /* example Jun */
  }
}

function getMonth(month: string) {
  return new Date(`1 ${month} 2000`).getMonth() + 1;
}

function toInteger(value: any): number {
  return parseInt(`${value}`, 10);
}

function isNumber(value: any): value is number {
  return !isNaN(toInteger(value));
}

export function ngbDate(ngb: NgbDateStruct) {
  return `${ngb.year}-${ngb.month}-${ngb.day}`;
}

export function ngbDateToDateTime(ngb: NgbDateStruct) {
  return `${ngb.year}-${ngb.month}-${ngb.day} 00:00:00`;
}

<div class="model-main">
    <button class="dialog-close" mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 37 37" fill="none">
    <rect width="37" height="37" rx="18.5" fill="#747474" fill-opacity="0.2"/>
    <path d="M12 25L25 12" stroke="#232930" stroke-width="2"/>
    <path d="M25 25L12 12" stroke="#232930" stroke-width="2"/>
    </svg></button>
    <div class="row m-0">
        <div class="col-md-5   p-0 hide-on-mobile">
            <div class="sidebar-left" style="background: url(/assets/images/{{cs.city}}/login-sidebar.png); background-size: cover;">
                <p >{{'home.property_tax_managment_made_easy' | translate}}</p>
            </div>
        </div>

        <div class="col-md-7 p-0">
            <div class="signupform" *ngIf ="!isLoading; else loadingBlock">
                <h2 class="mb-1">{{'home.create_an_account' | translate}}</h2>
                <p class="sign-up-data">{{'home.enter_your_details_to_get_started' | translate }}</p>
                <form [formGroup] = "signUpForm">
                  <div class="form-row form-group ">
                      <div class="col-md-12 mb-4">
                        <label>{{'home.email_id' | translate }}<span class="required">*</span></label>
                        <input formControlName ="email"  email="true"
                              class="form-control" style="margin-bottom: 20px"
                              [ngClass]="{'is-invalid': showErrorMessages && getControlError('email')}">
        
                        <div *ngIf="showErrorMessages && getControlError('email')" class="invalid-tooltip d-block">
                          <span *ngIf="getControlError('email')?.required">
                            {{'home.email_is_required' | translate}}
                          </span>
                          <span *ngIf="getControlError('email')?.pattern">
                            {{'home.this_field_should_be_valid_email' | translate}}
                          </span>
                          <span *ngIf="getControlError('email')?.unique">
                            {{'home.the_email_has_already_been_taken' | translate}}
                          </span>
                        </div>
                      </div>

                      <div class="col-md-12 mb-4">
                        <label>{{'home.mobile_no' | translate }}<span class="required">*</span></label>
                        <input formControlName ="phoneNumber" minlength="10" maxlength="10"
                              class="form-control" style="margin-bottom: 20px"
                              [ngClass]="{'is-invalid': showErrorMessages && getControlError('phoneNumber')}"
                              mask = "0000000000"
                        >
                        <div *ngIf="showErrorMessages && getControlError('phoneNumber')" class="invalid-tooltip d-block">
                          <span *ngIf="getControlError('phoneNumber')?.required">
                            {{'home.mobile_number_is_required' | translate}}
                          </span>
                          <span *ngIf="getControlError('phoneNumber')?.minlength || getControlError('phoneNumber')?.maxlength">
                            {{'home.mobile_number_must_be_at_least_10_characters_long' | translate }}
                          </span>
                          <span *ngIf="getControlError('phoneNumber')?.unique">
                            {{'home.mobile_number_already_been_taken' | translate}}
                          </span>
                        </div>
                      </div>


                      <div class="col-md-6 first-name">
                        <label>{{'home.first_name' | translate }} <span class="required">*</span></label>
                        <input formControlName ="firstName" 
                              style="margin-bottom: 20px"
                              requered
                              class="form-control"
                              [ngClass]="{'is-invalid': showErrorMessages && getControlError('firstName')}">
                              <div *ngIf="showErrorMessages && getControlError('firstName')" class="invalid-tooltip d-block">
                                <span *ngIf="getControlError('firstName')?.required">
                                  {{'home.first_name_is_required' | translate }}
                                </span>
                              </div>
                      </div>

                      <div class="col-md-6 ">
                        <label>{{'home.last_name' | translate }}<span class="required">*</span></label>
                        <input formControlName ="lastName" 
                              style="margin-bottom: 20px"
                              class="form-control"
                              [ngClass]="{'is-invalid': showErrorMessages && getControlError('lastName')}">
                              <div *ngIf="showErrorMessages && getControlError('lastName')" class="invalid-tooltip d-block">
                                <span *ngIf="getControlError('lastName')?.required">
                                  {{'home.last_name_is_required' | translate}}
                                </span>
                              </div>
                      </div>

                      <div class="col-md-12 ">
                          <button (click)="signUp()" class="btn btn-primary w-100 btn-lg align-center">{{'home.create_an_account' | translate}}</button>
                      </div>
                      <div class="error-message" >
                        <p class="text-danger h5 pb-2" style="font-size: 15px!important;">{{error}}</p>
                      </div>
          
                      <div class="check-login" >
                        <p>
                          {{'home.already_an_user' | translate }}
                            <span (click)="openSignInModal()" class="form-anchor" style="margin-left: 10px">
                              {{'home.login' |translate }}
                            </span>
                        </p>
                      </div>
                  </div>
              </form>
            </div>
        </div>
        <ng-template #loadingBlock>
          <div class="loader__general text-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">{{'home.loading' | translate }}</span>
            </div>
          </div>
        </ng-template>
import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";


const routes: Routes = [
    {
        path: 'saras',
        component: MainComponent,
        children: [
          {
            path: '',
            component: IndexComponent
          },
        ]
    }
];

export const sarasRouting = RouterModule.forChild(routes);

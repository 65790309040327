<div class="container faq-container">
  <div class="how-work-header">
    <h2>{{'home.quick_access_section_heading' | translate}}</h2>
  </div>
  <section class="pt-5 pb-5 leaders ">
    <div class="container">
      <div class="row mb-120">
        <div class="col-12">
          <owl-carousel-o [options]="customOptions1">
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/property-search">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M17.498 15.8759C17.498 15.8759 21.6698 15.8759 23.3016 15.8759M25.623 32.1259L23.3016 28.8759L20.6561 25.1616C20.6561 25.1616 22.373 25.1616 23.3016 25.1616C24.2302 25.1616 27.248 25.1616 27.248 20.5187C27.248 15.8759 24.2302 15.8759 23.3016 15.8759M30.498 15.8759C30.498 15.8759 26.0213 15.8759 23.3016 15.8759M17.498 20.5187H30.498"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M19.4902 41.0204H39.3635L34.3952 34.396" stroke="#FFA726"
                    stroke-width="3" />
                  <path
                    d="M41.2301 31.5559C42.2829 29.1909 42.868 26.5717 42.868 23.8158C42.868 13.2933 34.3378 4.76318 23.8153 4.76318C13.2928 4.76318 4.7627 13.2933 4.7627 23.8158C4.7627 31.349 9.13471 37.8611 15.4798 40.9531"
                    stroke="#4D5660" stroke-width="3" />
                </svg>
                <p>{{'home.01_search_property_pay_tax_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/properties/map">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.6196 10.2771H6.3125V36.5464H17.9186V40.7552L26.2167 36.5464H37.262V25.7518"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M36.4889 12.5982L24.8828 24.2042V28.8467H29.5252L41.1313 17.2406M36.4889 12.5982L40.3576 8.72949L45 13.3719L41.1313 17.2406M36.4889 12.5982L41.1313 17.2406"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M11.5 28.5H21" stroke="#FFA726" stroke-width="3" />
                </svg>
                <p>{{'home.02_gis_map_search_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/property-search/recentlyViewed">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.6196 10.2771H6.3125V36.5464H17.9186V40.7552L26.2167 36.5464H37.262V25.7518"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M36.4889 12.5982L24.8828 24.2042V28.8467H29.5252L41.1313 17.2406M36.4889 12.5982L40.3576 8.72949L45 13.3719L41.1313 17.2406M36.4889 12.5982L41.1313 17.2406"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M11.5 28.5H21" stroke="#FFA726" stroke-width="3" />
                </svg>
                <p>{{'home.03_recently_viewed_properties_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" (click)="qAccess('anchor1')">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.6196 10.2771H6.3125V36.5464H17.9186V40.7552L26.2167 36.5464H37.262V25.7518"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M36.4889 12.5982L24.8828 24.2042V28.8467H29.5252L41.1313 17.2406M36.4889 12.5982L40.3576 8.72949L45 13.3719L41.1313 17.2406M36.4889 12.5982L41.1313 17.2406"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M11.5 28.5H21" stroke="#FFA726" stroke-width="3" />
                </svg>
                <p>{{'home.04_no_dues_certificate_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" (click)="qAccess('anchor2')">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.7155 29.2876H29.2998V33.1623H19.7939V29.2876H21.3783M27.7155 29.2876V26.1879M27.7155 29.2876H21.3783M27.7155 26.1879H41.5585V21.0882H27.7155M27.7155 26.1879V21.0882M27.7155 21.0882V17.9885M27.7155 17.9885H29.2998V13.3389H19.7939V17.9885H21.3783M27.7155 17.9885H21.3783M21.3783 17.9885V21.0882V26.1879V29.2876"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M7.17969 17.1038C9.4194 10.2607 15.253 5.60477 21.8874 4.5587"
                    stroke="#FFA726" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M37.9252 36.6842C33.1666 41.6102 25.8604 43.6862 18.9363 41.42C12.7524 39.396 8.37049 34.3985 6.8452 28.5189"
                    stroke="#4D5660" stroke-width="3" />
                </svg>
                <p>{{'home.05_raise_objection_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" (click) = "runCorrectionStatusModal()">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M27.7155 29.2876H29.2998V33.1623H19.7939V29.2876H21.3783M27.7155 29.2876V26.1879M27.7155 29.2876H21.3783M27.7155 26.1879H41.5585V21.0882H27.7155M27.7155 26.1879V21.0882M27.7155 21.0882V17.9885M27.7155 17.9885H29.2998V13.3389H19.7939V17.9885H21.3783M27.7155 17.9885H21.3783M21.3783 17.9885V21.0882V26.1879V29.2876"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M7.17969 17.1038C9.4194 10.2607 15.253 5.60477 21.8874 4.5587"
                    stroke="#FFA726" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M37.9252 36.6842C33.1666 41.6102 25.8604 43.6862 18.9363 41.42C12.7524 39.396 8.37049 34.3985 6.8452 28.5189"
                    stroke="#4D5660" stroke-width="3" />
                </svg>
                <p>{{'home.06_track_raised_objection_status_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/self-assessment-form">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M32.6196 10.2771H6.3125V36.5464H17.9186V40.7552L26.2167 36.5464H37.262V25.7518"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight"
                    d="M36.4889 12.5982L24.8828 24.2042V28.8467H29.5252L41.1313 17.2406M36.4889 12.5982L40.3576 8.72949L45 13.3719L41.1313 17.2406M36.4889 12.5982L41.1313 17.2406"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M11.5 28.5H21" stroke="#FFA726" stroke-width="3" />
                </svg>
                <p>{{'home.07_tax_self-assessment_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/dlc-rates">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M29.0012 6.59998H10.0254V41.2174H38.9885V33.5631M29.0012 6.59998L38.9885 17.327M29.0012 6.59998V17.327H38.9885M38.9885 17.327V28.1995"
                    stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M17 25.1437H26.5" stroke="#FFA726" stroke-width="3" />
                  <path class="svg-path-highlight" d="M17 32.9999H26.5" stroke="#4D5660" stroke-width="3" />
                </svg>

                <p>{{'home.08_dlc_rate_icon_label' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/faqs">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.7732 40.5886H9.42676V7.45642H37.6472V15.8338" stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M16 15.0001H25.5" stroke="#4D5660" stroke-width="3" />
                  <ellipse cx="32.5" cy="30.0001" rx="9.5" ry="9" stroke="#4D5660" stroke-width="3" />
                  <path class="svg-path-highlight" d="M32 25.0001V29.9092L35 34.0001" stroke="#FFA726"
                    stroke-width="3" />
                </svg>

                <p>{{'home.09_faqs' | translate}}</p>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="box-access" routerLink="/contact-us">
                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                  <path d="M9.91211 40.4533L24.0225 33.6047L38.1328 40.4533V7.59241H9.91211V40.4533Z" stroke="#4D5660"
                    stroke-width="3" />
                  <path class="svg-path-highlight" d="M18 20.3895L31 20.3895" stroke="#FFA726" stroke-width="3" />
                  <path class="svg-path-highlight" d="M24.5 13.6855L24.5 27.0936" stroke="#FFA726" stroke-width="3" />
                </svg>
                <p>{{'home.10_contact_us_icon_label' | translate}}</p>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="row mb-120 ">
        <div class="col-md-7">
          <h2 class="page-hd" *ngIf="cs.city === 'jodhpur'">{{'home.municipal_corporation_of_jodhpur_south_text' | translate}} </h2>
          <h2 class="page-hd" *ngIf="cs.city === 'bharatpur'">{{'home.municipal_corporation_of_bharatpur_text' | translate}}</h2>
          <h2 class="page-hd" *ngIf="cs.city === 'jodhpurNorth'">{{'home.municipal_corporation_of_jodhpur_north_text' | translate}}</h2>
          <h2 class="page-hd" *ngIf="cs.city === 'alwar'">{{'home.municipal_corporation_of_alwar_text' | translate}}</h2>
          <p>{{'home.brief_introduction_sub_text' | translate}}</p>
          <img src="assets/images/{{cs.city}}/map.png" class="img-fluid">
        </div>
        <div class="col-md-5">
          <p *ngIf="cs.city === 'jodhpur'" [innerHTML]="'home.intro_content_jodhpur' | translate"></p>

          <p *ngIf="cs.city === 'bharatpur'" [innerHTML]="'home.intro_content_bharatpur' | translate"></p>

          <p *ngIf="cs.city === 'kotputli'" [innerHTML]="'home.intro_content_kotputli' | translate"></p>

          <p *ngIf="cs.city === 'jodhpurNorth'" [innerHTML]="'home.intro_content_jodhpurNorth' | translate"></p>

          <p *ngIf="cs.city === 'alwar'" [innerHTML]="'home.intro_content_alwar' | translate"></p>
          <div class="row mt-4">
            <div class="col-md-6 col-6 my-2">
              <div class="box-green  box-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M7 4V26H25V4H7ZM6 2H26C26.2652 2 26.5196 2.10536 26.7071 2.29289C26.8946 2.48043 27 2.73478 27 3V27C27 27.2652 26.8946 27.5196 26.7071 27.7071C26.5196 27.8946 26.2652 28 26 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2Z"
                    fill="white" />
                  <path d="M2 26H30V28H2V26ZM10 6H14V9H10V6Z" fill="white" />
                  <path
                    d="M12 26H20V24C20 22.9391 19.5786 21.9217 18.8284 21.1716C18.0783 20.4214 17.0609 20 16 20C14.9391 20 13.9217 20.4214 13.1716 21.1716C12.4214 21.9217 12 22.9391 12 24V26ZM16 18C17.5913 18 19.1174 18.6321 20.2426 19.7574C21.3679 20.8826 22 22.4087 22 24V28H10V24C10 22.4087 10.6321 20.8826 11.7574 19.7574C12.8826 18.6321 14.4087 18 16 18ZM10 12H14V15H10V12ZM18 6H22V9H18V6ZM18 12H22V15H18V12Z"
                    fill="white" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">338<span>{{'home.institutional' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">701<span>{{'home.institutional' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">173<span>{{'home.institutional' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">107<span>{{'home.institutional' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">107<span>{{'home.institutional' | translate}}</span></p>
              </div>
            </div>
            <div class="col-md-6 col-6 my-2 pd-0">
              <div class="box-green shade-green box-title">
                <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M4.41406 14.0203V25.4619C4.41406 26.1363 4.41443 28.0045 4.41443 28.0045C5.36356 28.0045 6.27454 28.0045 6.94607 28.0045H24.6701C25.3416 28.0045 26.2526 28.0045 27.2021 28.0045C27.2021 27.0056 27.2021 26.1363 27.2021 25.4619V14.0203"
                    stroke="white" stroke-width="1.99775" />
                  <path
                    d="M28.2369 12.5073L26.0923 4.61084C26.0469 4.44389 25.951 4.29704 25.8192 4.19251C25.6874 4.08798 25.5269 4.03146 25.3618 4.03149H20.2383L20.8396 11.6283C20.8487 11.7504 20.8854 11.8685 20.9466 11.9728C21.0078 12.077 21.0918 12.1644 21.1916 12.2276C21.6853 12.5379 22.65 13.1106 23.4033 13.3543C24.6895 13.7712 26.5683 13.6207 27.6393 13.4822C27.7435 13.468 27.8434 13.4301 27.9322 13.3712C28.0209 13.3122 28.0964 13.2335 28.1533 13.1407C28.2102 13.0479 28.2471 12.9431 28.2616 12.8337C28.276 12.7243 28.2676 12.6129 28.2369 12.5073Z"
                    stroke="white" stroke-width="1.99775" />
                  <path
                    d="M18.3404 13.3543C19.0595 13.1213 19.971 12.5899 20.48 12.2729C20.5986 12.1986 20.6949 12.0905 20.7576 11.9611C20.8203 11.8318 20.847 11.6863 20.8345 11.5417L20.2394 4.03149H11.3774L10.7824 11.5417C10.7697 11.6866 10.7962 11.8323 10.8589 11.9619C10.9217 12.0915 11.0181 12.1998 11.1369 12.2742C11.6458 12.5899 12.5573 13.1213 13.2764 13.3543C15.1666 13.967 16.4503 13.967 18.3404 13.3543V13.3543Z"
                    stroke="white" stroke-width="1.99775" />
                  <path
                    d="M5.52307 4.61084L3.37847 12.5086C3.3482 12.6141 3.3401 12.7252 3.35475 12.8343C3.3694 12.9433 3.40644 13.0478 3.46329 13.1403C3.52015 13.2328 3.59546 13.3111 3.684 13.37C3.77254 13.4288 3.87219 13.4666 3.97602 13.4809C5.04579 13.6207 6.9258 13.7699 8.21206 13.3543C8.96533 13.1106 9.93129 12.5379 10.4238 12.2289C10.5237 12.1656 10.6078 12.078 10.669 11.9735C10.7302 11.869 10.7668 11.7507 10.7757 11.6283L11.3771 4.03149H6.25356C6.0885 4.03146 5.92792 4.08798 5.79612 4.19251C5.66431 4.29704 5.56847 4.44389 5.52307 4.61084V4.61084Z"
                    stroke="white" stroke-width="1.99775" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">1597<span>{{'home.commercial' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">1765<span>{{'home.commercial' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">859<span>{{'home.commercial' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">3274<span>{{'home.commercial' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">3274<span>{{'home.commercial' | translate}}</span></p>
              </div>
            </div>
            <div class="col-md-6 col-6 my-2 ">
              <div class="box-green blue box-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M7 4V26H25V4H7ZM6 2H26C26.2652 2 26.5196 2.10536 26.7071 2.29289C26.8946 2.48043 27 2.73478 27 3V27C27 27.2652 26.8946 27.5196 26.7071 27.7071C26.5196 27.8946 26.2652 28 26 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2Z"
                    fill="white" />
                  <path d="M2 26H30V28H2V26ZM10 6H14V9H10V6Z" fill="white" />
                  <path
                    d="M12 26H20V24C20 22.9391 19.5786 21.9217 18.8284 21.1716C18.0783 20.4214 17.0609 20 16 20C14.9391 20 13.9217 20.4214 13.1716 21.1716C12.4214 21.9217 12 22.9391 12 24V26ZM16 18C17.5913 18 19.1174 18.6321 20.2426 19.7574C21.3679 20.8826 22 22.4087 22 24V28H10V24C10 22.4087 10.6321 20.8826 11.7574 19.7574C12.8826 18.6321 14.4087 18 16 18ZM10 12H14V15H10V12ZM18 6H22V9H18V6ZM18 12H22V15H18V12Z"
                    fill="white" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">92<span>{{'home.industrial' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">271<span>{{'home.industrial' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">37<span>{{'home.industrial' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">71<span>{{'home.industrial' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">71<span>{{'home.industrial' | translate}}</span></p>
              </div>
            </div>
            <div class="col-md-6 col-6 my-2 pd-0">
              <div class="box-green pink box-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M7 4V26H25V4H7ZM6 2H26C26.2652 2 26.5196 2.10536 26.7071 2.29289C26.8946 2.48043 27 2.73478 27 3V27C27 27.2652 26.8946 27.5196 26.7071 27.7071C26.5196 27.8946 26.2652 28 26 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2Z"
                    fill="white" />
                  <path d="M2 26H30V28H2V26ZM10 6H14V9H10V6Z" fill="white" />
                  <path
                    d="M12 26H20V24C20 22.9391 19.5786 21.9217 18.8284 21.1716C18.0783 20.4214 17.0609 20 16 20C14.9391 20 13.9217 20.4214 13.1716 21.1716C12.4214 21.9217 12 22.9391 12 24V26ZM16 18C17.5913 18 19.1174 18.6321 20.2426 19.7574C21.3679 20.8826 22 22.4087 22 24V28H10V24C10 22.4087 10.6321 20.8826 11.7574 19.7574C12.8826 18.6321 14.4087 18 16 18ZM10 12H14V15H10V12ZM18 6H22V9H18V6ZM18 12H22V15H18V12Z"
                    fill="white" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">13640<span>{{'home.residential' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">4661<span>{{'home.residential' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">2549<span>{{'home.residential' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">3998<span>{{'home.residential' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">3998<span>{{'home.residential' | translate}}</span></p>
              </div>
            </div>
            <div class="col-md-6 col-6 my-2">
              <div class="box-green red box-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M7 4V26H25V4H7ZM6 2H26C26.2652 2 26.5196 2.10536 26.7071 2.29289C26.8946 2.48043 27 2.73478 27 3V27C27 27.2652 26.8946 27.5196 26.7071 27.7071C26.5196 27.8946 26.2652 28 26 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2Z"
                    fill="white" />
                  <path d="M2 26H30V28H2V26ZM10 6H14V9H10V6Z" fill="white" />
                  <path
                    d="M12 26H20V24C20 22.9391 19.5786 21.9217 18.8284 21.1716C18.0783 20.4214 17.0609 20 16 20C14.9391 20 13.9217 20.4214 13.1716 21.1716C12.4214 21.9217 12 22.9391 12 24V26ZM16 18C17.5913 18 19.1174 18.6321 20.2426 19.7574C21.3679 20.8826 22 22.4087 22 24V28H10V24C10 22.4087 10.6321 20.8826 11.7574 19.7574C12.8826 18.6321 14.4087 18 16 18ZM10 12H14V15H10V12ZM18 6H22V9H18V6ZM18 12H22V15H18V12Z"
                    fill="white" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">1245<span>{{'home.vacant_plot_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">1390<span>{{'home.vacant_plot_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">370<span>{{'home.mix_use_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">875<span>{{'home.mix_use_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">875<span>{{'home.mix_use_icon_label' | translate}}</span></p>
              </div>
            </div>
            <div class="col-md-6 col-6 my-2 pd-0">
              <div class="box-green orange box-title">
                <svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32" fill="none">
                  <path
                    d="M7 4V26H25V4H7ZM6 2H26C26.2652 2 26.5196 2.10536 26.7071 2.29289C26.8946 2.48043 27 2.73478 27 3V27C27 27.2652 26.8946 27.5196 26.7071 27.7071C26.5196 27.8946 26.2652 28 26 28H6C5.73478 28 5.48043 27.8946 5.29289 27.7071C5.10536 27.5196 5 27.2652 5 27V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2Z"
                    fill="white" />
                  <path d="M2 26H30V28H2V26ZM10 6H14V9H10V6Z" fill="white" />
                  <path
                    d="M12 26H20V24C20 22.9391 19.5786 21.9217 18.8284 21.1716C18.0783 20.4214 17.0609 20 16 20C14.9391 20 13.9217 20.4214 13.1716 21.1716C12.4214 21.9217 12 22.9391 12 24V26ZM16 18C17.5913 18 19.1174 18.6321 20.2426 19.7574C21.3679 20.8826 22 22.4087 22 24V28H10V24C10 22.4087 10.6321 20.8826 11.7574 19.7574C12.8826 18.6321 14.4087 18 16 18ZM10 12H14V15H10V12ZM18 6H22V9H18V6ZM18 12H22V15H18V12Z"
                    fill="white" />
                </svg>
                <p *ngIf="cs.city === 'jodhpur'">109<span>{{'home.religious_property_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'bharatpur'">272<span>{{'home.religious_property_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'kotputli'">16<span>{{'home.other_icon_label' | translate}}</span></p>
                <p *ngIf="cs.city === 'jodhpurNorth'">671<span>{{'home.colonies' | translate}}</span></p>
                <p *ngIf="cs.city === 'alwar'">671<span>{{'home.colonies' | translate}}</span></p>
              </div>
            </div>
          </div>

          <p class="jd-para" *ngIf="cs.city === 'jodhpur'">Jodhpur Corporation has a total of 80 wards.</p>
          <!-- <p class="jd-para" *ngIf="cs.city === 'bharatpur'">Bharatpur Corporation has a total of 65 wards.</p> -->
        </div>
      </div>
      <!-- Leaders -->
      <!-- <div class="row mb-120 ">
        <div class="col-sm-6 col-md-6  col-lg-6 col-6 align-self-center">
        </div>
        <div class="col-md-12 col-sm-12 col-lg-12">
          <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide>
              <div class="cards">
                <img class="img-fluid" alt="100%x280" src="assets/images/cm-ashok-gehlot.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.ashok_gehlot_label' | translate}}</h4>
                  <p class="card-text">{{'home.chief_minister_of_rajasthan_label' | translate}}</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="cards">
                <img class="img-fluid" alt="100%x280" src="assets/images/rajendra.jpg">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.sh_rajendra_singh_yadav_label' | translate}}</h4>
                  <p class="card-text">{{'home.home_&_misiter_self_government_label' | translate}}</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="cards">
                <img class="img-fluid" alt="100%x280" src="assets/images/shanti-kumar.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.sh_shanti_kumar_dhariwal_label' | translate}}</h4>
                  <p class="card-text">{{'home.udh_&_local_self_government_label' | translate}}</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="cards" *ngIf="cs.city === 'jodhpur'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/vanita.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.ms_vanita_seth_label' | translate}}</h4>
                  <p class="card-text">{{'home.mayor_nagar_nigam_jodhpur_label' | translate}}</p>
                </div>
              </div>
              <div class="cards" *ngIf="cs.city === 'bharatpur'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/person-abhijeet-kumar.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.abhijeet_kumar_label' | translate}}</h4>
                  <p class="card-text">{{'home.nagar_nigam_bharatpur_label' | translate}}</p>
                </div>
              </div>
              <div class="cards" *ngIf="cs.city === 'kotputli'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/person_pushpa_saini.jpg">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.pusha_saini_label' | translate}}</h4>
                  <p class="card-text">{{'home.mayor_kotputli_label' | translate}}</p>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="cards" *ngIf="cs.city === 'jodhpur'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/arun-kumar.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.arun_kumar_purohit_label' | translate}}</h4>
                  <p class="card-text">{{'home.commissioner_nagar_nigam_jodhpur_label' | translate}}</p>
                </div>
              </div>
              <div class="cards" *ngIf="cs.city === 'bharatpur'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/person_akhilesh.png">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.mr_akhilesh_kumar_label' | translate}}</h4>
                  <p class="card-text">{{'home.commissioner_nagar_nigam_bharatpur_label' | translate}}</p>
                </div>
              </div>
              <div class="cards" *ngIf="cs.city === 'kotputli'">
                <img class="img-fluid" alt="100%x280" src="assets/images/{{cs.city}}/person_fateh_singh_meena.jpg">
                <div class="card-body1">
                  <h4 class="card-title">{{'home.fateh_singh_label' | translate}}</h4>
                  <p class="card-text">{{'home.commissioner_kotputli_label' | translate}}</p>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div> -->
      <div class="row mb-120 ">
        <div class="col-md-12">
          <h2 class="mb-5 no-space text-center">{{'home.portal_section_heading' | translate}}</h2>
          <div class="d-flex">
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/prop1.png" />
              <div class="title-box ">
                <div class="text-number">
                  01
                </div>
                <div class="text-sub">{{'home.01_search_property_tax_section_heading' | translate}}</div>
                <div class="text-para">{{'home.01_search_property_tax_section_content' | translate}}</div>

              </div>

            </div>
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/prop2.png" />
              <div class="title-box ">
                <div class="text-number">
                  02
                </div>
                <div class="text-sub">{{'home.02_gis_map_sub_heading' | translate}}</div>
                <div class="text-para">{{'home.02_gis_map_sub_content' | translate}}
                </div>
              </div>
            </div>
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/recent-view.png" />
              <div class="title-box ">
                <div class="text-number">
                  03
                </div>
                <div class="text-sub">{{'home.03_recently_viewed_properties_heading' | translate}}</div>
                <div class="text-para">{{'home.03_recently_viewed_properties_content' | translate}}</div>

              </div>

            </div>
          </div>
          <div class="d-flex">
            <div class="ex-box" id="anchor1">
              <img src="assets/images/{{cs.city}}/steps/nds.png" />
              <div class="title-box ">
                <div class="text-number">
                  04
                </div>
                <div class="text-sub">{{'home.04_no_dues_certificate_section_heading' | translate}}</div>
                <div class="text-para">{{'home.04_no_dues_certificate_section_content' | translate}}</div>

              </div>

            </div>
            <div class="ex-box" id="anchor2">
              <img src="assets/images/{{cs.city}}/steps/raise-obj.png" />
              <div class="title-box ">
                <div class="text-number">
                  05
                </div>
                <div class="text-sub">{{'home.05_raise_objection_section_heading' | translate}}</div>
                <div class="text-para">{{'home.05_raise_objection_section_content' | translate}}
                </div>

              </div>

            </div>
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/track-obj.png" />
              <div class="title-box ">
                <div class="text-number">
                  06
                </div>
                <div class="text-sub">{{'home.06_track_objection_status_section_heading' | translate}}</div>
                <div class="text-para">{{'home.06_track_objection_status_section_content' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/self-assesment.png" />
              <div class="title-box ">
                <div class="text-number">
                  07
                </div>
                <div class="text-sub">{{'home.07_self-assessment_section_heading' | translate}}</div>
                <div class="text-para">{{'home.07_self-assessment_section_content' | translate}}</div>

              </div>

            </div>
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/dlc.png" />
              <div class="title-box ">
                <div class="text-number">
                  08
                </div>
                <div class="text-sub">{{'home.08_dlc_rate_section_heading' | translate}}</div>
                <div class="text-para">{{'home.08_dlc_rate_section_content' | translate}}
                </div>

              </div>

            </div>
            <div class="ex-box">
              <img src="assets/images/{{cs.city}}/steps/Faqss.png" />
              <div class="title-box ">
                <div class="text-number">
                  09
                </div>
                <div class="text-sub">{{'home.09_faqs_&_contact_us_section_heading' | translate}}</div>
                <div class="text-para">{{'home.09_faqs_&_contact_us_section_content' | translate}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- End Leaders -->

</div>
<section class="faq ">
  <div class="container">

    <div class="row">
      <div class="col-md-4">
        <h2>{{'home.FAQs_heading' | translate}}</h2>
        <p>{{'home.FAQs_subheading' | translate}}</p>
      </div>
      <div class="col-md-8">
        <div id="accordionExample" class="accordion">

          <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <h4 class="faq-hd" *ngIf="cs.city=='kotputli'">{{'faq_kotputli.portal_faq_1_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_1_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_1_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpurNorth'">{{'faq_jodhpur.portal_faq_1_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='alwar'">{{'faq_jodhpur.portal_faq_1_question' | translate}}</h4>
              </ng-template>
              <ng-template ngbPanelContent >
                <div [innerHTML]="'faq_kotputli.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='kotputli'"></div>
               <div [innerHTML]="'faq_bharatpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='jodhpurNorth'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='alwar'"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <h4 class="faq-hd" *ngIf="cs.city=='kotputli'">{{'faq_kotputli.portal_faq_2_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_2_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_2_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpurNorth'">{{'faq_jodhpur.portal_faq_2_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='alwar'">{{'faq_jodhpur.portal_faq_2_question' | translate}}</h4>
              </ng-template>
              <ng-template ngbPanelContent>
                <div [innerHTML]="'faq_kotputli.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='kotputli'"></div>
               <div [innerHTML]="'faq_bharatpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='jodhpurNorth'"></div>
               <div [innerHTML]="'faq_jodhpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='alwar'"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <h4 class="faq-hd" *ngIf="cs.city=='kotputli'">{{'faq_kotputli.portal_faq_3_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_3_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_3_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpurNorth'">{{'faq_jodhpur.portal_faq_3_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='alwar'">{{'faq_jodhpur.portal_faq_3_question' | translate}}</h4>
              </ng-template>
              <ng-template ngbPanelContent>
                <div [innerHTML]="'faq_kotputli.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='kotputli'"></div>
                <div [innerHTML]="'faq_bharatpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='jodhpurNorth'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='alwar'"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <h4 class="faq-hd" *ngIf="cs.city=='kotputli'">{{'faq_kotputli.portal_faq_4_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_4_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_4_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpurNorth'">{{'faq_jodhpur.portal_faq_4_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='alwar'">{{'faq_jodhpur.portal_faq_4_question' | translate}}</h4>
              </ng-template>
              <ng-template ngbPanelContent>
                <div [innerHTML]="'faq_kotputli.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='kotputli'"></div>
                <div [innerHTML]="'faq_bharatpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='jodhpurNorth'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='alwar'"></div>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <h4 class="faq-hd" *ngIf="cs.city=='kotputli'">{{'faq_bharatpur.portal_faq_5_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_5_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_5_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='jodhpurNorth'">{{'faq_jodhpur.portal_faq_5_question' | translate}}</h4>
                <h4 class="faq-hd" *ngIf="cs.city=='alwar'">{{'faq_jodhpur.portal_faq_5_question' | translate}}</h4>
              </ng-template>
              <ng-template ngbPanelContent>
                <div [innerHTML]="'faq_kotputli.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='kotputli'"></div>
                <div [innerHTML]="'faq_bharatpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='jodhpurNorth'"></div>
                <div [innerHTML]="'faq_jodhpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='alwar'"></div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>
</section>
import { Pipe, PipeTransform } from '@angular/core';
import { Floor } from '../models';

@Pipe({
  name: 'floorPipe'
})
export class FloorPipePipe implements PipeTransform {

  transform(value: number | undefined, ...args: unknown[]): string {
    if(value || value === 0){
      return Floor.FLOOR_NUMBER[value].toString()
    }
    return '---';
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Property} from "../../../models";

@Component({
  selector: 'app-grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.scss'],
})
export class GridItemComponent implements OnInit {
  PROPERTY_CATEGORIES: {[key: number]: string} = Property.PROPERTY_CATEGORIES;
  @Input('property') property: any={};


  constructor() {
  //   setTimeout(() => {
  //   for (let i = 0; i < this.property.photos.length; i++) {
  //     if (this.property.photos[i].photoType?.id !== 50) {
  //       this.property.photos.splice(i, 1);
  //     }
  //   }
  // }, 100);
  }

  ngOnInit(): void {
  }

  getPhotoUrl(): string | null{
    if(this.property.photos){
      for (let i = 0; i < this.property.photos.length; i++) {
        if(this.property.photos[i].photoType){
          if (this.property.photos[i].photoType.id == 50) {
            return this.property.photos[i].url;
          }
        }
      }
    }
    return null;
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HiddenPhoneNumberPipe} from './hidden-phone-number.pipe';
import {FilterRebatePipe} from './filter-rebate.pipe';
import {RebateBalanceCalculatorPipe} from './rebate-balance-calculator.pipe';
import {GetFileUrlPipe} from './get-file-url.pipe';
import {ToIndianValuePipe} from './to-indian-value.pipe';
import {SafeUrlPipe} from './safe-url.pipe';
import {CurrencyInrPipe} from './currency-inr.pipe';
import { TranslatePipe } from './translate.pipe';
import { FloorPipePipe } from './floor-pipe.pipe';


@NgModule({
  declarations: [
    HiddenPhoneNumberPipe,
    FilterRebatePipe,
    RebateBalanceCalculatorPipe,
    GetFileUrlPipe,
    ToIndianValuePipe,
    SafeUrlPipe,
    CurrencyInrPipe,
    TranslatePipe,
    FloorPipePipe
  ],
  exports: [
    HiddenPhoneNumberPipe,
    FilterRebatePipe,
    RebateBalanceCalculatorPipe,
    ToIndianValuePipe,
    GetFileUrlPipe,
    SafeUrlPipe,
    CurrencyInrPipe,
    TranslatePipe,
    FloorPipePipe
  ],
  imports: [
    CommonModule,
  ],
})
export class PipesModule {
}

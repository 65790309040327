<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.building_utility_information' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg property-details">
      <tbody>
      <tr>
        <th width="25%">{{'property_search.water_connection' | translate}}</th>
        <td width="25%">{{ yesNoNothing(property.water_connection) }}</td>
        <th>{{'property_search.electricity_connection' | translate}}</th>
        <td>{{ yesNoNothing(property.electricity_connection) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.compost_pit' | translate}}</th>
        <td>{{ yesNoNothing(property.compost_pit) }}</td>
        <th>{{'property_search.parking' | translate}}</th>
        <td>{{ yesNoNothing(property.parking) }}</td>
      </tr>
      <tr>
        <th>{{'property_search.street_light' | translate}}</th>
        <td>{{ yesNoNothing(property.street_light) }}</td>
        <th>{{'property_search.fire_fighting_system' | translate}}</th>
        <td>{{ yesNoNothing(property.fire_fighting_system) }}</td>
      </tr>
      <tr>
        <th>{{'property_search.advertisement_on_building' | translate}}</th>
        <td>{{ yesNoNothing(property.advertisement_on_building) }}</td>
        <th>{{'property_search.sewerage' | translate}}</th>
        <td>{{ yesNoNothing(property.sewerage_line) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.septic_tank' | translate}}</th>
        <td>{{ yesNoNothing(property.septic_tank) }}</td>
        <th>{{'property_search.toilet' | translate}}</th>
        <td>{{ yesNoNothing(property.toilet) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.solar_plant_unit' | translate}}</th>
        <td>{{ yesNoNothing(property.solar_plant_or_unit) }}</td>
        <th>{{'property_search.rain_water_harvesting' | translate}}</th>
        <td>{{ yesNoNothing(property.rainwater_harvesting) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.advertisement_on_building' | translate}}</th>
        <td>{{ yesNoNothing(property.advertisement_on_building) }}</td>
        <th>{{'property_search.mobile_towers' | translate}}</th>
        <td>{{ yesNoNothing(property.mobile_tower) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.mobile_towers_location' | translate}}</th>
        <td>{{ Property.TOWER_LOCATIONS[property.mobile_tower_placement] }}</td>
        <th></th>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>



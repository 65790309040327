<div class="form-group search-input position-relative">
  <label [for]="inputId">{{ label }}<span class="text-danger" *ngIf="showRequired">*</span></label>
  <div class="position-relative">
    <input class="form-control selectdroupdown"
           autocomplete="off"
           aria-autocomplete="none"
           [id]="inputId"
           [placeholder]="placeholder"
           [style]="inputStyles"
           [class.error-input]="errorExists"
           [ngModel]="_localInputModel"
           (ngModelChange)="inputModelChangeEvent($event)"
           (selectItem)="selectItem($event)"
           (change)="prefetch()"
           [resultFormatter]="resultFormatter"
           [inputFormatter]="inputFormatter"
           (focus)="focus($event)"
           (click)="click$.next($any($event).target.value)"
           (blur)="focusout()"
           [ngbTypeahead]="suitableTerms$"
           [container]="'input'"

           #instance="ngbTypeahead"
    />
    <div class="spinner-border position-absolute" role="status"
         style="width: 1rem; height: 1rem; right: 10px; top: calc(50% - 0.5rem)"
         *ngIf="loadingModel"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>

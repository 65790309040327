import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {homeRouting} from "./home.routing";
import { IndexComponent } from './index/index.component';
import { InstructionComponent } from './instruction/instruction.component';
import { MobileLinksComponent } from './mobile-links/mobile-links.component';
import {WidgetsModule} from "../../widgets/widgets.module";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    IndexComponent,
    InstructionComponent,
    MobileLinksComponent,
  ],
  imports: [
    homeRouting,
    CommonModule,
    WidgetsModule,
    NgbModule,
    CarouselModule,
    PipesModule
  ],
  bootstrap: [IndexComponent]
})
export class HomeModule {
}

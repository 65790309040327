import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import * as L from 'leaflet';
import { gql } from '@apollo/client/core';
import { CityService } from './services/city.service';
import { LatLngExpression } from 'leaflet';

@Injectable({
  providedIn: 'root'
})
export class MapServiceService {

  constructor(private apollo: Apollo, private cs: CityService,) { }
  
  getTileLayer(tile: string ,maxZoom: number, minZoom: number) {
    return L.tileLayer(tile, {
      maxZoom: maxZoom,
      minZoom: minZoom,
    });
  }

  getMapBoundaries() {
    return this.apollo
      .query({
        query: gql`{
        colonies: Colonies(where: {column: GPS_COORDINATES, operator: IS_NOT_NULL}) {
          id,
          name
          gps_compressed_coordinates
        }
        wards: Wards(where: {column: GPS_COORDINATES, operator: IS_NOT_NULL}) {
          id,
          name
          gps_compressed_coordinates
        }
        city: Cites(where: {column: "id", value: "${this.cs.id}"}) {gps_compressed_coordinates}
      }`,
        fetchPolicy: "no-cache",
      })
  }

  createPolygon(latLng: [number, number][] | any, polygonColor: string, weight?: number) {
    const coordinates: [number, number][] = latLng.map((coordinate: any[]) => [coordinate[1], coordinate[0]]);
    return L.polygon(coordinates).setStyle({ color: polygonColor, fillOpacity: 0, weight: weight });
  }

  createLabel(coordinates: LatLngExpression, labelTitle: string, labelClass?: string) {
    const tooltip = new L.Tooltip({
      permanent: true,
      className: labelClass,
      direction: "center",
    }).setContent(`<div class="map-label-content">${labelTitle}</div>`);

    return new L.Marker(coordinates, {
      icon: new L.DivIcon({ className: "bg-none" }),
    })
      .bindTooltip(tooltip);
  }
}

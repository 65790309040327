import {Component, Input, OnInit} from '@angular/core';
import {Citizen} from '../../../models';
import {Property} from '../../../models';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.scss'],
})
export class OwnerDetailsComponent implements OnInit {
  RELATION: { [key: number]: string } = {
    1: 'Self',
    2: 'Father',
    3: 'Husband',
    4: 'W/O',
    5: 'S/O',
    6: 'D/O',
  };

  PROFESSION: { [key: number]: string } = {
    1: 'Service',
    2: 'Profession',
    3: 'Housewife',
    4: 'Other',
  };

  readonly GENDER_TYPES = getFormattedGenderTypes();

  isCollapsed: boolean = false;


  @Input('owners') owners: Citizen[] = [];
  @Input('property') property!: Property;


  constructor() {
  }

  ngOnInit(): void {
  }
}

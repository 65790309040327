import {Injectable} from '@angular/core';
import {IServicePaginate} from "../@types/service-paginate";
import {IServiceGetOne} from "../@types/service-get-one";
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {gql} from "@apollo/client/core";
import {PropertyType} from "../models";
import {BaseService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class PropertyTypeService extends BaseService implements IServicePaginate<PropertyType>, IServiceGetOne<PropertyType> {

  constructor(private apollo: Apollo) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public getAll(): Observable<PropertyType[]> {
    return this.apollo.watchQuery({
      query: PropertyTypeService.getAllQuery()
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.PropertyTypes),
      map((result: any) => result ?? [])
    );
  }


  public paginate(page: number = 1, perPage: number = 10, where: string): Observable<PropertyType[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, where)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.PropertyTypePaginate && result.data.PropertyTypePaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public getOne(column: string, value: any): Observable<PropertyType | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.PropertyTypePaginate && result.data.PropertyTypePaginate.data && result.data.PropertyTypePaginate.data[0])
    );
  }

  /**
   * GraphQL Queries
   * */
  static getAllFields() {
    return `
      id,
      name,
      description,
      property_category,
      form,
      enable
    `
  }

  static getAllQuery() {
    return gql`{ PropertyTypes { ${PropertyTypeService.getAllFields()} } }`;
  }

  static getAllQueryRow() {
    return 'PropertyTypes { ' + PropertyTypeService.getAllFields() + '} ';
  }

  private paginateQuery(page: number = 1, perPage: number = 10, where: string) {
    return gql`{
      PropertyTypePaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {${PropertyTypeService.getAllFields()}}
      }
    }`
  }

  private getOneQuery(column: string, value: any) {
    const normalizedValue = typeof value === 'string' ? `"${value}"` : value;

    return gql`{
      PropertyTypePaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: ${normalizedValue} }
      ) {
        data {${PropertyTypeService.getAllFields()}}
      }
    }`;
  }
}

import {PropertyType} from "./property-type";
import {PropertySubType} from "./property-sub-type";
import {Citizen} from "./citizen";

export class Floor {

  static readonly FLOOR_NUMBER: { [key: number]: string } = {
    '-5': 'B5',
    '-4': 'B4',
    '-3': 'B3',
    '-2': 'B2',
    '-1': 'B1',
    '0': 'Ground Floor',
    '1': '1st',
    '2': '2nd',
    '3': '3rd',
    '4': '4th',
    '5': '5th',
    '6': '6th',
    '7': '7th',
    '8': '8th',
    '9': '9th',
    '10': '10th',
    '11': '11th',
    '12': '12th',
    '13': '13th',
    '14': '14th',
    '15': '15th',
    '16': '16th',
    '17': '17th',
    '18': '18th',
    '19': '19th',
    '20': '20th',
  };
  temp_id!: number;
  id!: number;
  property_id!: number;
  floor_number!: number;
  property_usage_id!: number;
  area!: number;
  floor_construction_year!: number;
  no_of_room!: number;
  no_of_toilet!: number;
  property_category!: number;
  propertyType = new PropertyType();
  propertySubType = new PropertySubType();
  property_construction_type_id!: number;
  citizen: Citizen | null = new Citizen();
  carpet_area!: number;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  from_year!: string;
  upto_year!: string;

  static formattedFloorNumber() {
    return Object.entries(Floor.FLOOR_NUMBER)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}))
      .sort((a:any, b:any) => a.id - b.id);
  }

  static getFloorName(floorNumber: number) {
    let floorName = this.FLOOR_NUMBER[floorNumber]
    return floorNumber === 0 ?  floorName: `${floorName} floor`; 
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DirectivesModule } from '../directives/directives.module';
import { ImageViewerComponent } from './image-viewer/image-viewer.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ImagePdfViewerComponent } from './image-pdf-viewer/image-pdf-viewer.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { PhotoViewerDialogComponent } from './photo-viewer-dialog/photo-viewer-dialog.component';
import { PipesModule } from '../pipes/pipes.module';


@NgModule({
  declarations: [
    FileUploadComponent,
    ImageViewerComponent,
    ImagePdfViewerComponent,
    PdfViewerComponent,
    PhotoViewerDialogComponent
  ],
  imports: [
    CommonModule,
    DirectivesModule,
    DragDropModule,
    PipesModule
  ],
  exports: [
    FileUploadComponent,
    ImageViewerComponent,
    DragDropModule,
    ImagePdfViewerComponent,
    PdfViewerComponent
  ]
})
export class SharedModule { }

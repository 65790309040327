<div class="container">
  <nav aria-label="breadcrumb" class="brdcrum">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">{{'property_search.home' | translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'property_search.properties' | translate}}</li>
    </ol>
  </nav>
  <app-search-widget [landmarksData] = "landmarks" [className]="'listsearch'" [loadingLandmarks] = "loadingLandmarks"></app-search-widget>
  <app-properties-list></app-properties-list>
</div>

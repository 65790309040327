<div class="mb-4">
  <!-- Owner Details Start -->
  <div class="row mb-4">
    <div class="col-md-3 col-6">
      <app-vertical-title title="{{'property_search.owner_details' | translate}}"
                          subTitle="{{'property_search.details_of_current_owners_of_property' | translate}}"
      ></app-vertical-title>
    </div>
    <div class="col-md-4 col-6">
      <label for="ownership-type">{{'property_search.ownership_type' | translate}}<span class="text-danger">*</span></label>
      <ng-select id="ownership-type" placeholder="{{'property_search.enter_ownership_type' | translate}}"
                 [(ngModel)]="property.ownershipType"
                 [compareWith]="ownershipTypeCompareFn"
      >
        <ng-option *ngFor="let ownershipType of OWNERSHIP_TYPES" [value]="ownershipType">
          {{ownershipType.name}}
        </ng-option>
      </ng-select>
    </div>
  </div>
  <!-- Owner Details End -->

  <hr>

  <div class="d-flex justify-content-end mb-4">
    <button class="btn btn-primary" (click)="addOwner(ownerFormModal)" [disabled]="disableAddButton()">{{'property_search.add' | translate}}</button>
  </div>

  <div class="card">
    <div class="card-body">
      <div class="card-title mb-4" style="font-size: 16px"><strong>{{'property_search' | translate}}Owner Listing</strong></div>

      <table class="table responsive-flex-table">
        <thead>
        <th>#</th>
        <th>{{'property_search.owner_name' | translate}}</th>
        <th>{{'property_search.gender' | translate}}</th>
        <th>{{'property_search.phone_number' | translate}}</th>
        <th>{{'property_search.relation_type' | translate}}</th>
        <th>{{'property_search.relative_name' | translate}}</th>
        <th width="80px">{{'property_search.action' | translate}}</th>
        </thead>
        <tbody *ngIf="property?.owners?.length && property.owners.length > 0; else noOwners">
        <tr *ngFor="let owner of property.owners; let i = index">
          <td>{{i + 1}}</td>
          <td>{{(owner.first_name ?? '') + ' ' + (owner.last_name ?? '')}}</td>
          <td>{{ GENDER_TYPES[owner.gender]?.name }}</td>
          <td>{{owner.phone_number}}</td>
          <td>{{CITIZEN_RELATIONS[owner.relation] ?? ''}}</td>
          <td>{{owner.relation_name}}</td>
          <td width="80px">
            <div class="d-flex justify-content-between mob_align_right" style="font-size: 18px">
              <i class="bi bi-pencil-fill mr-2 text-primary" style="cursor: pointer"
                 (click)="editOwner(ownerFormModal, owner)"></i>
              <i class="bi bi-x-lg text-danger" style="cursor: pointer" (click)="deleteOwner(i)"></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">{{'property_search.previous' | translate}}</button>

  <button class="btn btn-primary" (click)="nextForm()">{{'property_search.next' | translate}}</button>
</div>

<!-- Owner Modal -->
<ng-template #ownerFormModal let-modal>
  <div class="modal-header">
    <div class="title">
      <h5 class="modal-title mb-2">{{'property_search.owner_details' | translate}}</h5>
      <h6 class="modal-sub-title">{{'property_search.fill_required_information' | translate}}</h6>
      <i class="fa fa-close fa-times" (click)="modal.dismiss();"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <div class="col-6">
        <label for="first-name">{{'property_search.first_name' | translate}}<span class="text-danger">*</span></label>
        <input id="first-name" class="form-control"
               placeholder="{{'property_search.enter_first_name' | translate}}"
               [(ngModel)]="formOwner.first_name"
               appInputError
               [(error)]="ownerFormErrors.fullName"
        >
      </div>
      <div class="col-6">
        <label for="last-name">{{'property_search.last_name' | translate}}<span class="text-danger">*</span></label>
        <input id="last-name" class="form-control"
               placeholder="{{'property_search.enter_last_name' | translate}}"
               [(ngModel)]="formOwner.last_name"
               appInputError
               [(error)]="ownerFormErrors.fullName"
        >
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label for="gender">{{'property_search.gender' | translate}}<span class="text-danger">*</span></label>
        <ng-select id="gender"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="{{'property_search.select_gender' | translate}}"
                   [items]="GENDER_TYPES"
                   [(ngModel)]="formOwner.gender"
                   appInputError
                   [(error)]="ownerFormErrors.gender"
        ></ng-select>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="relation-type">{{'property_search.relation_type' | translate}}<span class="text-danger" *ngIf = "formOwner.relation_name?.trim()">*</span></label>
        <ng-select id="relation-type"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="{{'property_search.select_relation_type' | translate}}"
                   [items]="FORMATTED_CITIZEN_RELATIONS"
                   [(ngModel)]="formOwner.relation"
                   appInputError
                   [(error)]="ownerFormErrors.relation"
                   
        ></ng-select>
      </div>
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="relation-name">{{'property_search.relative_name' | translate}}</label>
        <input id="relation-name" class="form-control"
               placeholder="{{'property_search.enter_relative_name' | translate}}"
               [(ngModel)]="formOwner.relation_name"
        >
      </div>
      
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <label for="phone-number">{{'property_search.phone_number' | translate}}<span class="text-danger">*</span></label>
        <input id="phone-number" class="form-control" [(ngModel)]="formOwner.phone_number"
               placeholder="{{'property_search.enter_phone_number' | translate}}"
               appInputError
               maxlength="10"
               oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
               [(error)]="ownerFormErrors.phone_number"
               mask="0*">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="ownerSubmit(modal)">{{'property_search.submit' | translate}}</button>
  </div>
</ng-template>

<!-- No owners -->
<ng-template #noOwners>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">{{'property_search' | translate}}No owners</td>
  </tr>
  </tbody>
</ng-template>

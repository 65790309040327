<div class="list-view-listing" *ngIf="properties.length !== 0;">
  <div class="row list-header">
    <div class=" col-1 col"></div>
    <!-- <div class="col-1 col">S.No</div> -->
    <div class="col-1 col">{{'property_search.service_no' | translate}}</div>
    <div class=" col-1 col">{{'property_search.ward' | translate}}</div>
    <div class=" col-2 col">{{'property_search.category' | translate}}</div>
    <div class="col-1 col"> {{'property_search.property_name' | translate}}</div>
    <div class=" col-2 col">{{'property_search.owner_name' | translate}}</div>
    <div class=" col-1 col" style="padding-right: 5px;">{{'property_search.plot_area' | translate}}</div>
    <div class=" col-2 col">{{'property_search.address' | translate}}</div>
    <div class=" col-1 col">{{'property_search.action' | translate}}</div>
  </div>
  <div class="list-view-main">
    <app-list-item class="list-items-responsive"
      *ngFor="let property of properties; let i = index" [property]="property" [index]="(page - 1) * perPage + i + 1">
    </app-list-item>
  </div>
</div>

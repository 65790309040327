import {Pipe, PipeTransform} from '@angular/core';
import {Ledger} from '../models';

@Pipe({
  name: 'filterRebatePipe'
})
export class FilterRebatePipe implements PipeTransform {
  transform(value: Ledger[] | undefined): Ledger[] {
    if(!value){
      return [];
    }
    return value.filter(item => item.transaction_type === Ledger.REBATE_TYPE);
  }

}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-block',
  templateUrl: './card-block.component.html',
  styleUrls: ['./card-block.component.scss']
})
export class CardBlockComponent implements OnInit {
  @Input() toggleStatus = false;

  @Input() needToggle = false;
  @Input() cardTitle = '';
  @Input() cardTitleDesc = '';
  @Input() showButton!:boolean;

  constructor() { }

  ngOnInit(): void {
  }

  toggleCard(){
    this.toggleStatus = !this.toggleStatus;
  }

}

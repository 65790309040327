import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {
  @Input() open: boolean = false;
  @Input() imageURL: string = "";
  @Input() id: string = "";
  @Input() showRotate: boolean = true;

  @Output() close = new EventEmitter();
  scale = 1;
  rotationAmount = 0;
  constructor(private renderer: Renderer2,) { }

  ngOnInit(): void {
  }
  rotateImage() {
    this.rotationAmount += 90;
    const image = document.getElementById(this.id);
    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.rotationAmount}deg)`
    )
  }

  zoomIn() {
    this.scale += 0.3;
  }

  zoomOut() {
    this.scale -= 0.3;
  }

}

import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Apollo} from "apollo-angular";
import {Citizen, Property} from "../models";
import {gql} from "@apollo/client/core";
import {map} from "rxjs/operators";


@Injectable({
  providedIn: 'root'
})
export class CitizenService {

  constructor(private apollo: Apollo) {
  }

  public myProperties(): Observable<Property[] | []> {
    return this.apollo.watchQuery({
      query: this.myPropertiesQuery()
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.MyProperties),
      map((result: any) => result ?? [])
    );
  }

  public paginate(page: number = 1, perPage: number = 10, term: string = ''): Observable<Citizen[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, term)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.CitizenPaginate && result.data.CitizenPaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public getOne(column: string, value: any): Observable<Citizen | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value),
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.CitizenPaginate && result.data.CitizenPaginate.data),
      map((result: any) => result && result[0])
    )
  }

  public getOneByFullName(value: any): Observable<Citizen | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneByFullNameQuery(value),
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.CitizenByFullName),
    )
  }

  private myPropertiesQuery() {
    return gql`{
      MyProperties {
          id,
          property_uid,
          owners {
            first_name, last_name, gender, document_proof_type_id, type, birth_date, relation, relation_name,
            phone_number, profession, email, uid_number
          },
          property_name,
          address_house_number,
          vacant_area,
          exemption,
          property_category,
          ward_id,
          ward {name, description},
          plinth_area,
          constructed_area,
          latitude,
          longitude,
          street {name},
          colony {name, description},
          district {name, description},
          city {name, description},
          sector {name, description},
          address_line2,
          pincode,
          landmark,
          nearby_houseno,
          propertyType {name, description},
          propertySubType {name, description},
          property_status,
          permanent_address,
          old_house_tax_no,
          landuse_change,
          sub_divided_plots,
          survey_denied,
          denied_reason,
          construction_type_id,
          rebate {name},
          ownershipType {name},
          building_permission,
          building_permission_number,
          building_permission_year,
          building_permission_num_floor,
          use_building_permission_id,
          use_building_permission_matches_actual,
          building_construction_year,
          water_connection,
          electricity_connection,
          electricity_connection_number,
          sewerage_line,
          sewerage_line_connection,
          septic_tank,
          compost_pit,
          rainwater_harvesting,
          toilet,
          parking,
          street_light,
          fire_fighting_system,
          mobile_tower,
          mobile_tower_placement,
          free_hold_patta,
          one_time_deposited,
          lease_deed_patta,
          plot_area,
          length,
          breadth,
          first_financial_year,
          photos {url},
          floors {area},
          taxDueAmount,
          multi,
          road_name,
          createdBy {
            id,
            first_name,
            last_name,
          },
      }
    }`;
  }

  private paginateQuery(page: number = 1, perPage: number = 10, term: string = '') {
    return gql`{
      CitizenPaginate(
        page: ${page},
        first: ${perPage},
        where: {
          OR: [
            {column: FIRST_NAME, operator: LIKE, value: "%${term}%"},
            {column: LAST_NAME, operator: LIKE, value: "%${term}%"},
            {column: RELATION_NAME, operator: LIKE, value: "%${term}%"},
          ]
        }
      ) {
        data {
          id,
          first_name,
          last_name,
          relation_name
        }
      }
    }`
  }

  private getOneQuery(column: string, value: any) {
    return gql`{
      CitizenPaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: "${value}" }
      ) {
        data {
          id,
          first_name,
          last_name,
        }
      }
    }`;
  }

  private getOneByFullNameQuery(value: any) {
    return gql`{
      CitizenByFullName(fullName: "%${value}%") {
        id,
        first_name,
        last_name,
        relation_name,
      }
    }`;
  }
}

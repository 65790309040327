import {Component, OnInit, ViewChild} from '@angular/core';
import {PaymentService} from '../../../services/payment.service';
import {ActivatedRoute} from '@angular/router';
import {Payment} from '../../../models';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {

  transactionId: string;
  payment!: Payment;

  @ViewChild('container') container: any;

  constructor(
    private currentRoute: ActivatedRoute,
    private paymentService: PaymentService,
  ) {
    this.transactionId = this.currentRoute.snapshot.params?.transactionId;
  }

  ngOnInit(): void {
    this.paymentService.receiptHtml(this.transactionId).subscribe((html) => {
      this.container.nativeElement.innerHTML = html;
    });
  }

}

import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Colony, Property, PropertyType, Ward} from '../../../models';
import {WardService} from '../../../services/ward.service';
import {SectorService} from '../../../services/sector.service';
import {ColonyService} from '../../../services/colony.service';
import {PropertyTypeService} from '../../../services/property-type.service';
import {PropertySubTypeService} from '../../../services/property-sub-type.service';
import {BusinessTypeService} from '../../../services/business-type.service';
import {of, ReplaySubject} from 'rxjs';
import {NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
// @ts-ignore
import Validator from 'Validator/src/validator.js';
import {PropertyValidationRules} from '../data/validation-rules';
import {cloneDeep} from 'lodash';
import {getFormattedErrorsByValidator, Validated, ValidationErrors} from '../../../@types/validation';
import {PropertyCategories} from '../../../enums/property-categories';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from 'src/app/services/language.service';

const oldPropertyFields = `
      id, floors {
        id, floor_number, carpet_area, property_usage_id, property_category, property_construction_type_id,
        floor_construction_year no_of_room no_of_toilet, from_year, upto_year
        propertyType{id, name, description},
        propertySubType{id, name, description},
        citizen{first_name, last_name},
      }
      ledgers { id transaction_type credit_amount debit_amount financial_year }
`

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss']
})
export class GeneralInformationComponent implements OnInit, OnDestroy {

  @Input() property!: Property;
  @Input() oldProperty!: Property;
  @Input() isOldPropertyFound = false;

  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('newOldProperty') newOldPropertyEmitter = new EventEmitter<Property | undefined>();

  @ViewChild('datepickerInput') datepickerInput!: NgbInputDatepicker;
  @ViewChild('dateInput') dateInput!: ElementRef;

  readonly PROPERTY_CATEGORIES = PropertyCategories;
  readonly FORMATTED_PROPERTY_CATEGORIES = Property.formattedPropertyCategories();
  readonly FORMATTED_PROPERTY_USAGES = Property.formattedPropertyUsages();
  readonly FORMATTED_PROPERTY_CONSTRUCTION_TYPES = Property.formattedConstructionTypes();
  readonly FORMATTER_ROAD_TYPES = Property.formattedRoadTypes();
  readonly YES_NO_OPTIONS = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];

  businessTypes!: { id: number, name: string }[];
  errors: ValidationErrors<Property> = {};
  isOldPropertyUnderFocus = false;
  isLoadingOldProperty = false;
  isColonyLoading = false;
  colonies: Colony[] = [];

  get afterInit$() {
    return this.afterInitSubject.asObservable();
  }

  private afterInitSubject = new ReplaySubject();

  constructor(
    public apollo: Apollo,
    public wardService: WardService,
    public sectorService: SectorService,
    public colonyService: ColonyService,
    public propertyTypeService: PropertyTypeService,
    public propertySubTypeService: PropertySubTypeService,
    public businessTypeService: BusinessTypeService,
    public commonService:CommonService,
    public languageService:LanguageService
  ) {
  }

  ngOnInit(): void {
    this.isColonyLoading = true;
    this.colonyService.getAll(undefined).subscribe((c) => {
      this.colonies = c;
      this.isColonyLoading = false;
    });
    this.businessTypeService.getAll().subscribe((bt) => this.businessTypes = bt)
    this.afterInitSubject.next();

    window.addEventListener('keyup', this.keyboardEvent)
  }

  ngOnDestroy(): void {
    window.removeEventListener('keyup', this.keyboardEvent)
  }

  nextForm() {
    if (this.isLoadingOldProperty) {
      this.commonService.showErrorMessage('property_search.old_service_no_is_checking_wait_a_little_bit');
      return;
    }
    this.errors = {};


    const validated = this.validate();
    this.errors = validated.errors;

    if (validated.isSuccess) return this.nextFormEmitter.emit();

    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  findOldProperty(oldPropertyId?: string) {
    this.isOldPropertyUnderFocus = false;

    if (!oldPropertyId || oldPropertyId.trim() === '' || oldPropertyId.trim() === this.oldProperty.property_uid) {
      this.isLoadingOldProperty = true;
      this.isLoadingOldProperty = false;
      this.newOldPropertyEmitter.emit(undefined);
      return;
    }

    this.isLoadingOldProperty = true;

    this.apollo.query({
      query: gql`{
        Property(where: {AND:[{column: PROPERTY_UID, value: "${oldPropertyId}"}, {column: SOURCE, value: "old_survey"}]}) { ${oldPropertyFields}}
      }`,
      fetchPolicy: 'no-cache'
    }).subscribe((p: any) => {
      this.isLoadingOldProperty = false;

      if (!p?.data?.Property) {
        this.isLoadingOldProperty = false;
        this.isOldPropertyFound = false;
        this.newOldPropertyEmitter.emit(undefined);
        return;
      }

      this.isLoadingOldProperty = false;
      this.isOldPropertyFound = true;
      this.newOldPropertyEmitter.emit(p?.data?.Property);
    });
  }

  loadColonies = () => {
    return of(
      this.property.ward?.id
        ? this.colonies.filter((c) => c.wards.find((w) => w.id === this.property.ward.id))
        : this.colonies
    );
  };

  openDateInput($event: Event, d: NgbInputDatepicker) {
    $event.preventDefault();
    d.open();
    setTimeout(() => this.dateInput.nativeElement.focus())
  }

  closeDateInput = () => this.datepickerInput?.close();

  keyboardEvent = (e: KeyboardEvent) => {
    if (e.key === "Escape") this.closeDateInput();
  }

  /** --------------- Setters --------------- **/
  setWardId(val: Ward | undefined) {
    if (val === this.property.ward) return;

    this.property.ward = val as Ward ?? new Ward();

    this.property.sector = undefined as any;
    this.property.colony = undefined as any;
  }

  setPropertyCategory(val: number | undefined) {
    if (val === this.property.property_category) return;

    this.property.property_category = val as number;
    this.setPropertyType(undefined);
  }

  setPropertyType(val: PropertyType | undefined) {
    if (val === this.property.propertyType) return;

    this.property.propertyType = val as any;
    this.property.propertySubType = undefined as any;
  }

  /** --------------- Where Conditions --------------- **/
  sectorWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {HAS: {relation: "ward", condition: {column: ID, value: ${this.property.ward?.id}}}}
  ] }`)

  colonyWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {HAS: {relation: "wards", condition: {column: ID, value: ${this.property.ward?.id}}}}
  ] }`)

  propertyTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_CATEGORY, value: ${this.property.property_category ?? 0}}
  ] }`);

  propertySubTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_TYPE_ID, value: ${this.property.propertyType?.id ?? 0}}
  ] }`);

  /** --------------- Validation --------------- **/
  private validate(): Validated<Property> {
    let rules = cloneDeep(PropertyValidationRules);

    // if (this.property.building_permission) {
    //   Object.assign(rules, {
    //     building_permission_year: 'required|numeric|min:1900|max:2100',
    //     building_permission_num_floor: 'required|numeric',
    //     building_permission_use_type: 'required',
    //   })
    // }

    // if (this.property.property_category === PropertyCategories.Commercial) {
    //   Object.assign(rules, {
    //     name_of_shop: 'required|min:1|max:40',
    //     items_traded: 'required',
    //     establishment_year: 'required|numeric|min:1900|max:2100',
    //     occupation_year: 'nullable|numeric|min:1900|max:2100',
    //   })
    // }

    const propertyValidated = Validator.make(this.property, rules);
    const relationValidation = this.validateRelation();

    return {
      isSuccess: !propertyValidated.fails() && relationValidation.isSuccess && (this.isOldPropertyFound || !this.property.old_house_tax_no),
      errors: {...getFormattedErrorsByValidator(propertyValidated.getErrors()), ...relationValidation.errors},
    };
  }

  private validateRelation(): Validated<Property> {
    const requiredMessage = this.languageService.translate('home.this_is_required_field');

    const errors: { isSuccess: boolean, errors: Record<string, string> } = {isSuccess: true, errors: {}};

    const setError = (field: string, message: string) => {
      errors.isSuccess = false;
      errors.errors[field] = message;
    };

    // if (!this.property.ward?.id) setError('ward_id', requiredMessage);
    // if (!this.property.sector?.id) setError('sector_id', requiredMessage);
    if (!this.property.colony?.id) setError('colony_id', requiredMessage);
    // if (!this.property.propertyType?.id) setError('propertyType', requiredMessage);
    // if (!this.property.propertySubType?.id) setError('propertySubType', requiredMessage);

    return errors;
  }
}

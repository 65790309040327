import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {propertySearchRouting} from "./property-search.routing";
import {FormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {WidgetsModule} from "../../widgets/widgets.module";
import {PropertyService} from "../../services/property.service";
import { GridViewComponent } from './grid-view/grid-view.component';
import { ListViewComponent } from './list-view/list-view.component';
import { PropertiesListComponent } from './properties-list/properties-list.component';
import { ListItemComponent } from './list-item/list-item.component';
import { GridItemComponent } from './grid-item/grid-item.component';
import { RecentlyViewedPropertyComponent } from './recently-viewed-property/recently-viewed-property.component';
import { PipesModule } from "../../pipes/pipes.module";



@NgModule({
    declarations: [
        IndexComponent,
        GridViewComponent,
        ListViewComponent,
        PropertiesListComponent,
        ListItemComponent,
        GridItemComponent,
        RecentlyViewedPropertyComponent,
    ],
    providers: [
        PropertyService
    ],
    imports: [
        propertySearchRouting,
        CommonModule,
        FormsModule,
        NgbModule,
        WidgetsModule,
        PipesModule
    ]
})
export class PropertySearchModule { }

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: '[noContentRow]',
  templateUrl: './no-content-row.component.html',
  styleUrls: ['./no-content-row.component.scss']
})
export class NoContentRowComponent implements OnInit {
  @Input() noItemsMessage = 'No items';
  @Input() loading!: boolean;
  @Input() loadingHeight: string = '500px';

  constructor() { }

  ngOnInit(): void {
  }

}

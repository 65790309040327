import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {TermOfServiceComponent} from "./term-of-service/term-of-service.component";
import {PrivatePolicyComponent} from "./private-policy/private-policy.component";
import {PageNotFoundComponent} from "./page-not-found/page-not-found.component";
import { RefundCancellationPolicyComponent } from './refund-cancellation-policy/refund-cancellation-policy.component';


const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'term-of-service',
        component: TermOfServiceComponent
      },
      {
        path: 'private-policy',
        component: PrivatePolicyComponent
      },
      {
        path: 'refund-and-cancellation-policy',
        component: RefundCancellationPolicyComponent
      },
    ]
  },
  {path: '**', component: PageNotFoundComponent},
];

export const staticRouting = RouterModule.forChild(routes);

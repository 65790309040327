import {Injectable} from '@angular/core';
import {IServicePaginate} from "../@types/service-paginate";
import {IServiceGetOne} from "../@types/service-get-one";
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {gql} from "@apollo/client/core";
import {BaseService} from "./base-service.service";
import {BusinessType} from '../models/business-type';

@Injectable({
  providedIn: 'root'
})

export class BusinessTypeService extends BaseService implements IServicePaginate<BusinessType>, IServiceGetOne<BusinessType> {

  constructor(private apollo: Apollo) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public getAll(): Observable<BusinessType[]> {
    return this.apollo.watchQuery({
      query: this.getAllQuery()
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.BusinessTypes),
      map((result: any) => result ?? [])
    );
  }

  public paginate(page: number = 1, perPage: number = 10, where: string): Observable<BusinessType[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, where)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.BusinessTypePaginate && result.data.BusinessTypePaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public getOne(column: string, value: any): Observable<BusinessType | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.BusinessTypePaginate && result.data.BusinessTypePaginate.data && result.data.BusinessTypePaginate.data[0])
    );
  }

  /**
   * GraphQL Queries
   * */
  private getAllFields() {
    return `
      id,
      name,
    `
  }

  private getAllQuery() {
    return gql`{ BusinessTypes { ${this.getAllFields()} } }`;
  }

  private paginateQuery(page: number = 1, perPage: number = 10, where: string) {
    return gql`{
      BusinessTypePaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {${this.getAllFields()}}
      }
    }`
  }

  private getOneQuery(column: string, value: any) {
    const normalizedValue = typeof value === 'string' ? `"${value}"` : value;

    return gql`{
      BusinessTypePaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: ${normalizedValue} }
      ) {
        data {${this.getAllFields()}}
      }
    }`;
  }
}

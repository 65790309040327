<div class="table-hold property-details">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.commercial_details' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg">
      <tbody>
      <tr>
        <th width="25%">{{'property_search.business_type' | translate}}</th>
        <td width="25%">{{ BUSINESS_TYPE[property.business_type_id] }}</td>
        <th width="25%">{{'property_search.name_of_shop_business' | translate}}</th>
        <td width="25%">{{ property.name_of_shop }}</td>
      </tr>
      <tr>
        <th>{{'property_search.trade_license' | translate}}</th>
        <td>{{ yesNoNothing(property.trade_license) }}</td>
        <th>{{'property_search.license_date' | translate}}</th>
        <td>---</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>



import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {merge, Observable, OperatorFunction, ReplaySubject, Subject, Subscription} from 'rxjs';
import {NgbTypeahead} from '@ng-bootstrap/ng-bootstrap';
import {auditTime, distinctUntilChanged, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {IServicePaginate} from '../../@types/service-paginate';
import {IServiceGetOne} from '../../@types/service-get-one';
import {BaseService} from '../../services/base-service.service';

interface ItemsSource extends BaseService, IServicePaginate<any>, IServiceGetOne<any> {
}

type SearchInputSettings = {
  auditTime: number,
}

/**
 +------------------------------------------------------------+
 |                    Using only for models                   |
 +------------------------------------------------------------+
 * */
@Component({
  selector: 'app-search-model-by-api',
  templateUrl: './search-model-by-api.component.html',
  styleUrls: ['./search-model-by-api.component.scss'],
})
export class SearchModelByApi<Item extends { [key: string]: any }> implements OnInit, OnDestroy {

  clearSubscription!: Subscription;
  fetchOneSubscription!: Subscription;

  private destroy$ = new ReplaySubject();

  searchInputSettings: SearchInputSettings = {
    auditTime: 500,
  };

  @ViewChild('instance', {static: true}) instance!: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  errorExists = false;

  loadingModel = false;

  @Input() inputModel: Item | string | undefined = undefined;
  @Input() placeholder: string = '';
  @Input() inputStyles: object = {};
  @Input() perPage: number = 25;
  @Input() itemsSource!: ItemsSource;

  @Input() where: (term?: string) => string = (term: string = '') => this.itemsSource.defaultSearchWhere(term);

  /** Using for output */
  @Input() bindLabel: string = '';

  /** Using for emit from component */
  @Input() identifierColumn: string = '';

  /** Formatting output in popup list */
  @Input() resultFormatter: (item: any) => string = (item) => item[this.bindLabel];

  @Input() clear$!: Observable<any>;

  @Input() afterInit$!: Observable<any>;

  @Output() inputModelChange = new EventEmitter<Item>();

  ngOnInit() {
    this.clearSubscription = this.clear$?.subscribe(() => {
      this.clear();
    });

    this.afterInit$?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.inputModel || typeof this.inputModel === 'object') return;

      this.loadingModel = true;

      this.itemsSource.getOne(this.identifierColumn, this.inputModel)
        .pipe(takeUntil(this.destroy$))
        .subscribe((item) => {
          this.loadingModel = false;

          if (!this.isNewValue(item)) return;

          this.inputModel = item;
          this.inputModelChange.emit(item);
        });
    });
  }

  ngOnDestroy() {
    if (this.clearSubscription) this.clearSubscription.unsubscribe();
    if (this.fetchOneSubscription) this.fetchOneSubscription.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Get items from itemsSource */
  suitableTerms$: OperatorFunction<any, readonly any[]> = (text$: Observable<string>) => {
    const auditedText$ = text$.pipe(
      auditTime(this.searchInputSettings.auditTime),
      distinctUntilChanged(),
    );

    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(auditedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) => this.itemsSource
        ? this.loadItems(term)
        : [],
      ),
    );
  };

  inputModelChangeEvent($event: any) {
    this.errorExists = false;

    const emitVal = typeof $event === 'string' ? undefined : $event;

    if (!this.isNewValue($event)) return;

    this.inputModelChange.emit(emitVal);

    setTimeout(() => this.inputModel = $event);
  }

  selectItem($event: any) {
    $event.preventDefault();

    if (!this.isNewValue($event.item)) return;

    const emitVal = typeof $event.item === 'string' ? undefined : $event.item;

    this.inputModelChange.emit(emitVal);

    this.inputModel = $event.item;
  }

  prefetch() {
    if (!this.inputModel || this.inputModel === '' || typeof this.inputModel === 'object') return;

    if (this.fetchOneSubscription) this.fetchOneSubscription.unsubscribe();

    /** Need if user entered text but not selected item or if items has not been loaded */
    this.fetchOneSubscription = this.itemsSource.getOne(this.bindLabel, this.inputModel).subscribe((item) => {
      if (!item) {
        this.errorExists = true;
        return;
      }

      if (!this.isNewValue(item)) return;

      this.inputModel = item;
      this.inputModelChange.emit(item);
    });
  }

  focus($event: FocusEvent) {
    this.errorExists = false;
    this.focus$.next(($event?.target as any)?.value);
  }

  focusout() {
    this.loadingModel = false;
    this.prefetch();
  }

  getInputModel() {
    return typeof this.inputModel === 'object' ? this.inputModel?.[this.bindLabel] : this.inputModel;
  }

  private clear() {
    this.inputModel = undefined;
    this.inputModelChange.emit(undefined);
    this.instance.writeValue('');
    this.errorExists = false;
  }

  private loadItems(term: string = '') {
    this.loadingModel = true;

    return this.itemsSource.paginate(1, this.perPage, this.where(term)).pipe(
      tap(() => this.loadingModel = false),
    );
  }

  private isNewValue(el: any) {
    return !(el === this.inputModel || (typeof el === 'object' && typeof this.inputModel === 'object'
      && el[this.identifierColumn] === this.inputModel[this.identifierColumn]));
  }
}

import {Citizen} from './citizen';
import {PortalNotification} from './portal-notification';

export class User {
  static readonly ROLE_CITIZEN = 1;

  id!: number;
  role_id!: number;
  owner = new Citizen();
  portal_notifications: PortalNotification[] = [];
  first_name!: string;
  last_name!: string;
  gender!: number;
  phone_number!: string;
  email!: string;
}

import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Property} from "../../../models";

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListViewComponent implements OnInit {
  @Input() properties!: Property[];
  @Input() page!: number;
  @Input() perPage!: number;

  constructor() {
  }

  ngOnInit(): void {
  }

}

import {Component, OnInit} from '@angular/core';
import {NdcFileService} from '../../../services/ndc-file.service';
import {ActivatedRoute} from '@angular/router';
import {NdcFile} from '../../../models/ndc-file';
import {Property} from '../../../models';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  ndcFile!: NdcFile;

  loading: boolean = false;

  propertyCategories = Property.PROPERTY_CATEGORIES;

  constructor(
    private route: ActivatedRoute,
    private ndcFileService: NdcFileService
  ) {
  }

  ngOnInit(): void {
    const urlKey = this.route.snapshot.params.urlKey;

    this.loadNdcFile(urlKey);
  }

  loadNdcFile(urlKey: string) {
    this.loading = true;

    this.ndcFileService.getOne(urlKey)
      .subscribe(ndcFile => {
        if (ndcFile) {
          this.ndcFile = ndcFile;
        }
        this.loading = false;
      });
  }
}

<div class="container">
  <!-- Start breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">{{'property_search.home' | translate}}</a></li>
      <li class="breadcrumb-item active" aria-current="page">{{'property_search.payment_history' | translate}}</li>
    </ol>
  </nav>
  <!-- End breadcrumb -->

  <!--Start Table Section-->
  <h2 class="d-flex justify-content-between align-items-center">
    {{'property_search.payment_history' | translate}}
  </h2>



    <div class="row row-cols-lg-auto g-3 align-items-center search-form-history">
        <div class="col-12">
          <div class="input-group">
            <input type="search" class="form-control" id="inlineFormInputGroupUsername" placeholder="{{'property_search.search_by_property_uid_or_transaction_id' | translate}}" [(ngModel)]="mainSearchModel" (ngModelChange)="load()">
          </div>
        </div>
    </div>



  <div class="table-hold table-responsive">
    <table class="table table-bordered th-bg">
      <thead>
      <tr>
        <th>{{'property_search.property_uid_service_no' | translate}}</th>
        <th>{{'property_search.transaction_id' | translate}}</th>
        <th>{{'property_search.date_and_time' | translate}}</th>
        <th>{{'property_search.view_amount' | translate}} <span class="rupes-icon"> &#x20b9; </span></th>
        <th>{{'property_search.payment_status' | translate}}</th>
        <th>{{'property_search.payment_method' | translate}}</th>
        <th>{{'property_search.actions' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let payment of payments">
        <td data-th="Property UID/Service No:">{{payment.property.property_uid}}</td>
        <td data-th="Transaction ID:" class="font-weight-600">{{payment.transaction_id}}</td>
        <td data-th="Date and Time:" class="date-time">{{payment.payment_date}}</td>
        <td data-th="Amount:" class="font-weight-600">{{payment.amount}}</td>
        <td data-th="Payment Status:" class="font-weight-500">
          <div
            class="{{STATUSES_STYLES[payment.status]}}">{{Payment.PAYMENT_STATUSES[payment.status] | titlecase}}</div>
        </td>
        <td data-th="Payment Method:" class="font-weight-500">
          {{Payment.PAYMENT_METHODS[payment.payment_method] | titlecase}}
        </td>
        <td data-th="Actions:">
          <div class="d-flex">
            <a type="button" class="btn btn-sm btn-outline-secondary mr-3"
                    [href]="payment?.payuPayment?.payment_url"
                    *ngIf="payment.status === PaymentStatuses.NotProcessed && payment.payment_source === PaymentSources.Payu
                      && payment?.payuPayment?.payment_url"
            >
              <i class="bi bi-bootstrap-reboot"></i>{{'property_search.resume' | translate}} 
            </a>
            <button type="button" class="btn btn-sm btn-outline-secondary mr-3"
                    [routerLink]="'/payment/' + payment.transaction_id + '/view'"
                    *ngIf="payment.status === PaymentStatuses.Success"
            >
              <i class="bi bi-eye"></i>{{'property_search.view' | translate}} 
            </button>
            <a class="success-payment__menu-button" [href]="getDownLoadLink(payment.transaction_id)" target="_blank">
              <button type="button" class="btn btn-sm btn-outline-secondary"
                      *ngIf="payment.status === PaymentStatuses.Success"
              >
                <i class="bi bi-download"></i>{{'property_search.download' | translate}} 
              </button>
            </a>
        </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <!--End Table Section-->

  <div class="row justify-content-center">
    <ngb-pagination [(page)]="page" [collectionSize]="totalPayments" [maxSize]="5"
                    [pageSize]="perPage"></ngb-pagination>
  </div>
</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {paymentRouting} from './payment.routing';
import {AuthenticationService} from '../../services';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {SuccessPaymentComponent} from './success-payment/success-payment.component';
import {FailurePaymentComponent} from './failure-payment/failure-payment.component';
import {ViewComponent} from './view/view.component';
import {NgxPrintModule} from 'ngx-print';
import {PipesModule} from '../../pipes/pipes.module';


@NgModule({
  declarations: [
    IndexComponent,
    SuccessPaymentComponent,
    FailurePaymentComponent,
    ViewComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    paymentRouting,
    ReactiveFormsModule,
    NgxMatIntlTelInputModule,
    MatFormFieldModule,
    NgxPrintModule,
    PipesModule,
  ],
  providers: [
    AuthenticationService,
  ],
})
export class PaymentModule {
}

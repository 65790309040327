export class Search {
  serviceNo!: String;
  address!: string;
  wardId!: string;
  colonyId!: Number;
  streetId!: Number;
  apartmentName!: string;
  propertyCategory!: number;
  selectedLandmark!: Number | undefined;
  landmark!: String;
  oldPropertyId!: String;
  propertyName!: string;
  mobile!: string;
  fatherName!: string;
  accountHolderName!: string;
  property_type_id!:number;
  property_sub_type_id!:number;
}

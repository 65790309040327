import {State} from "./state";

export class District {
  id!: number;
  state = new State();
  name!: string;
  description!: string;
  created_at:  Date = new Date();
  updated_at:  Date = new Date();
}

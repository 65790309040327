export class Payu {
  txnid: string = '';
  amount!: number;
  productinfo: string = '';
  firstname: string = '';
  email: string = '';
  phone: string = '';
  enforce_paymethod!: string;
  key: string = '';
  hash: string = '';
  surl: string = '';
  curl: string = '';
  furl: string = '';
}

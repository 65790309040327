<div class="form-group search-input position-relative mb-0">
  <input class="form-control selectdroupdown"
         autocomplete="off"
         aria-autocomplete="none"
         type="search"
         id="searchbutton"
         [placeholder]="placeholder"

         [ngModel]="currentItem"
         (ngModelChange)="inputModelChangeEvent($event)"
         (selectItem)="currentItemChange.emit($event.item)"
         (blur)="blurInput($event)"

         [resultFormatter]="itemsFormatter"
         [inputFormatter]="inputFormatter"

         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         [ngbTypeahead]="search$"

         [container]="'input'"
         [popupClass]="'search-input-by-api__popup fasten-to-bottom'"
         #instance="ngbTypeahead"
  >
  <div class="spinner-border position-absolute" role="status"
       style="width: 1rem; height: 1rem; right: 10px; top: calc(50% - 0.5rem)"
       *ngIf="isLoading"
  >
    <span class="sr-only">Loading...</span>
  </div>
</div>

import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import printJS from 'print-js';
import {PaymentService} from '../../../services/payment.service';
import {Payment} from '../../../models';
import {PaymentSources} from '../../../enums/payment-sources';
import {PaymentStatuses} from '../../../enums/payment-statuses';

@Component({
  selector: 'app-success-payment',
  templateUrl: './success-payment.component.html',
  styleUrls: ['./success-payment.component.scss']
})
export class SuccessPaymentComponent implements OnInit, OnDestroy {

  private destroy$ = new ReplaySubject();

  loading = true;

  transactionId: string;
  payment!: Payment;
  @ViewChild('printBlock') printBlock!: ElementRef;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private paymentService: PaymentService,
  ) {
    this.transactionId = this.currentRoute.snapshot.params?.transactionId;
  }

  ngOnInit() {
    const fields = `amount, payment_date, payment_source, status`

    this.paymentService.getOneByTransactionId(
      this.transactionId as unknown as number, fields
    ).pipe(takeUntil(this.destroy$)).subscribe((p) => {
      if (
        !p || p.status !== PaymentStatuses.Success
        || ![PaymentSources.Payu, PaymentSources.Paytm, PaymentSources.CCAvenue, PaymentSources.Razorpay ].includes(p.payment_source)
      ) {
        this.router.navigate(['']);
        return;
      }

      this.payment = p;
      this.loading = false;
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getDownLoadLink() {
    return environment.apiBaseUrl + 'download/pdf/payment/' + this.transactionId;
  }

  sendReceiptEmail() {
    this.paymentService.sendReceiptEmail(this.transactionId).pipe(takeUntil(this.destroy$)).subscribe();
  }

  print() {
    this.paymentService.receiptHtml(this.transactionId).subscribe((res) => {
      this.printBlock.nativeElement.innerHTML = res;
      printJS({
        printable: 'test',
        type: 'html',
        maxWidth: 1300,
        documentTitle: 'Payment Receipt',
        style: '@page { size: Letter portrait; }'
      });
    });
  }

  goToViewLink(url: string){
    window.open(url, "_blank");
  }
}

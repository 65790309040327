import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Property} from "../../../models";

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html', 
  styleUrls: ['./grid-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class GridViewComponent implements OnInit {
  @Input() properties!: Property[];

  constructor() {
  }

  ngOnInit(): void {
  }

}

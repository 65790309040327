import {NgModule} from '@angular/core';
import { DragDropDirective } from './drag-drop.directive';
import { InputErrorDirective } from './input-error/input-error.directive';
import { InputValidaitonDirective } from './input-validation.directive';

@NgModule({
  declarations: [
    InputErrorDirective,
    InputValidaitonDirective,
    DragDropDirective
  ],
  exports: [
    InputErrorDirective,
    InputValidaitonDirective,
    DragDropDirective
  ],
  imports: []
})
export class DirectivesModule {
}

import {Component, EventEmitter, Host, Inject, Input, OnInit, Optional, Output, Self, ViewChild} from '@angular/core';
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {merge, Observable, OperatorFunction, Subject} from "rxjs";
import {auditTime, distinctUntilChanged, filter, map, switchMap, tap} from "rxjs/operators";

interface SearchInputSettings {
  auditTime: number,
}

@Component({
  selector: 'app-search-input-by-id',
  templateUrl: './search-input-by-id.component.html',
  styleUrls: ['./search-input-by-id.component.scss']
})
export class SearchInputByIdComponent {
  searchInputSettings: SearchInputSettings = {
    auditTime: 500,
  };

  @Input() inputId: string = 'searchInputId';
  @Input() label: string = 'Search input';
  @Input() placeholder: string = '';

  @Input() terms$ = new Observable<string[]>();

  @Output() newTerm = new EventEmitter<string>();

  @ViewChild('instance', {static: true}) instance!: NgbTypeahead;

  @Input() inputModel: string = '';
  @Output() inputModelChange = new EventEmitter<string>();
  @Input() searchId!: string;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  suitableTerms: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) => {
    const auditedText$ = text$.pipe(
      auditTime(this.searchInputSettings.auditTime),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(auditedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      tap((term) => this.newTerm.emit(term)),
      switchMap(() => this.terms$)
    );
  }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Search} from "../../@types";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable, ReplaySubject, Subject} from "rxjs";
import {PropertyService} from "../../services/property.service";
import {Citizen, Colony, Property, PropertySubType, PropertyType, Street, Ward} from "../../models";
import {filter, take, takeUntil, switchMap, distinctUntilChanged, debounceTime, tap} from "rxjs/operators";
import {StreetService} from "../../services/street.service";
import {ColonyService} from "../../services/colony.service";
import {CitizenService} from "../../services/citizen.service";
import {WardService} from "../../services/ward.service";
import {SearchMapProperty} from '../../@types/search-map-property';
import { PropertyTypeService } from 'src/app/services/property-type.service';
import { PropertySubTypeService } from 'src/app/services/property-sub-type.service';

@Component({
  selector: 'app-search-widget',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy{
  Property = Property;

  private destroy$ = new ReplaySubject();
  public searchParameters: Search = new Search;
  public colonies: Colony[] = [];
  public realWardId!: number | undefined;
  landmarks:any = [];
  @Input() set landmarksData(data:any) {
    if(!(data && data.length)) {
      return;
    }
    this.landmarks = data;
    if(this.searchParameters.colonyId) {
      this.updateLandmarks(+this.searchParameters.colonyId, false);
    }else if(this.searchParameters.wardId && this.colonies) {
      this.updateLandmarksForWard(this.colonies);
    }else if(!(this.searchParameters.colonyId && this.searchParameters.wardId)) {
      this.landmarkFiltered = this.landmarks;
    }
    this.loadingLandmarks = false;
  }

  /**
   * Input models
   * */
  public streetInputModel: string = '';
  public colonyInputModel: string = '';
  public wardInputModel: string = '';

  /**
   * Terms
   * */
  private _streetsTerms: Street[] = [];
  private _addressTerms: string[] = [];
  private _accountHolderTerms: Citizen[] = [];
  private _accountHolderTermsFather: Citizen[] = [];
  private _wardTerms: Ward[] = [];
  private _apartmentNameTerms: string[] = [];
  private _propertyName: string[] = [];

  /**
   * Terms streams
   * */
  private _streetsTerms$ = new ReplaySubject<string[]>();
  private _addressTerms$ = new ReplaySubject<string[]>();
  private _coloniesTerms$ = new ReplaySubject<string[]>();
  private _wardTerms$ = new ReplaySubject<string[]>();
  private _accountHolderTerms$ = new ReplaySubject<string[]>();
  private _accountHolderTermsFather$ = new ReplaySubject<string[]>();
  private _apartmentNameTerms$ = new ReplaySubject<string[]>();
  private _propertyName$ = new ReplaySubject<string[]>();
  wards: Ward[] = [];
  loadingColonies!: boolean;
  @Input() loadingLandmarks!: boolean;
  coloniesData: Colony[] = [];
  colonyInput$ = new Subject<string>();
  loadingPropertyTypes!: boolean;
  propertyTypesAll: PropertyType[] = [];
  propertyTypeData: PropertyType[] = [];
  loadingPropertySubTypes!: boolean;
  
  landmarkFiltered: any = [];
  landmarksForwardId: { colony_id: number; }[] = [];
  propertySubTypesAll: PropertySubType[] = [];

  propertySubTypeData: PropertySubType[] = [];
  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private ownerService: CitizenService,
    private wardService: WardService,
    private streetService: StreetService,
    private colonyService: ColonyService,
    private propertyTypeService: PropertyTypeService,
    private propertySubTypeService: PropertySubTypeService,
  ) {
  }

  ngOnInit(): void {
    this.getWards();
    this.initInputs();
    this.searchColonies();
    this.landmarkFiltered = this.landmarks;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  getPropertyCategoriesId() {
    return Object.keys(Property.PROPERTY_CATEGORIES);
  }
  selectCompareWithFn = (a: any, b: any) => {
    return a?.id?.toString() === (typeof b === 'object' ? b.id?.toString() : b?.toString());
  };

  async search() {
    this.router.navigate(['/property-search'], {queryParams: this.getFilters()});
  }

  async viewMap() {
    const searchMapProperty = new SearchMapProperty();
    const filters = this.getFilters();

    searchMapProperty.wardId = filters.wardId;
    searchMapProperty.colonyId = filters.colonyId;
    searchMapProperty.streetId = filters.streetId;
    searchMapProperty.propertyUID = filters.serviceNo;
    searchMapProperty.houseNumber = this.searchParameters.address;
    searchMapProperty.propertyCategoryId = this.searchParameters.propertyCategory;
    searchMapProperty.property_type_id = this.searchParameters.property_type_id;
    searchMapProperty.property_sub_type_id = this.searchParameters.property_sub_type_id;

    this.router.navigate(['/properties/map'],  {queryParams: searchMapProperty})
  }

  async getPromise<T>(observable: Observable<T>): Promise<T> {
    return observable.pipe(
      take(1),
    ).toPromise();
  }

  reloadColony(id: string) {
    this.colonies = [];
    this.wardService.getOne('name', id).subscribe((ward) => {
      this.realWardId = ward?.id;
      this.colonyService.getAll(this.realWardId).pipe(takeUntil(this.destroy$)).subscribe((colonies) => {
        this.colonies = colonies ?? [];

        if (id != '') {
          let flagDeleteColonyId = true;
          colonies.forEach((colony) => {
            if (colony?.name == this.colonyInputModel) {
              flagDeleteColonyId = false;
            }
          })
          if (flagDeleteColonyId) {
            this.colonyInputModel = '';
          }
        }
      })
    })
  }

  /**
   * New terms
   * */
  newStreetTerm(term: string = '') {
    if (term.length < 1) {
      this._streetsTerms$.next([]);
      return;
    }

    this.streetService.paginate(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((streets) => {
      if (!streets) return;

      this._streetsTerms = streets;
      this._streetsTerms$.next(streets.map((street) => street.name));
    })
  }

  newColoniesTerm(term: string = '') {
    if (term.length < 1) {
      this._coloniesTerms$.next([]);
      return;
    }

    this._coloniesTerms$.next(
      this.colonies
        .filter((colony: Colony) => colony.name.toLowerCase().includes(term.toLowerCase()))
        .map((colony: Colony) => colony.name)
        .slice(0, 10)
    );
  }


  newAccountHolderTerm(term: string = '') {
    if (term.length < 1) {
      this._accountHolderTerms$.next([]);
      return;
    }

    this.ownerService.paginate(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((owners) => {
      this._accountHolderTerms = owners;
      this._accountHolderTerms$.next(owners.map((owner: Citizen) => (owner.first_name ?? '') + ' ' + (owner.last_name ?? '')));

      this.searchParameters.accountHolderName = term
    })
  }

  newAccountHolderTermFather(term: string = '') {
    if (term.length < 1) {
      this._accountHolderTermsFather$.next([]);
      return;
    }
    this.ownerService.paginate(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((owners) => {
      this._accountHolderTermsFather = owners;
      this._accountHolderTermsFather$.next(owners.map((owner: Citizen) => owner.relation_name ?? ''));

      this.searchParameters.fatherName  = term
    })
  }

  newAddressTerm(term: string = '') {
    if (term.length < 1) {
      this._addressTerms$.next([]);
      return;
    }

    this.propertyService.paginateAddresses(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((addresses) => {
      this._addressTerms = addresses;
      this._addressTerms$.next(addresses);
    })
  }
  newAccountHolderTermPN(term: string = '') {
    if (term.length < 1) {
      this._propertyName$.next([]);
      return;
    }

    this.propertyService.paginatePN(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((addresses) => {
      this._propertyName = addresses;
      this._propertyName$.next(addresses);
    })
  }

  newWardTerm(term: string = '') {
    this.wardService.paginate(1, 2000, term).pipe(takeUntil(this.destroy$)).subscribe((wards) => {
      this._wardTerms = wards;
      this._wardTerms$.next(wards.map((owner: Ward) => owner.name));
    })
  }

  newApartmentNameTerm(term: string = '') {
    this.propertyService.paginatePropertyNames(1, 10, term).pipe(takeUntil(this.destroy$)).subscribe((propertyNames) => {
      this._apartmentNameTerms = propertyNames;
      this._apartmentNameTerms$.next(propertyNames);
    })
  }

  /**
   * Terms observers
   * */
  get streetsTerms(): Observable<string[]> {
    return this._streetsTerms$.asObservable();
  }

  get addressTerms(): Observable<string[]> {
    return this._addressTerms$.asObservable();
  }

  get coloniesTerms(): Observable<string[]> {
    return this._coloniesTerms$.asObservable();
  }

  get wardTerms(): Observable<string[]> {
    return this._wardTerms$.asObservable();
  }



  private initInputs() {
    
    this.currentRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((queryParams) => {
      
      this.unsetSearchParameters();
      this.unsetModelsBySearchParams((queryParams as unknown as Search));

      Object.assign(this.searchParameters, (queryParams as unknown as Search));
      
      if (this.searchParameters.wardId) {
        this.getColonyDetails(+this.searchParameters.wardId, false);
      }
      if(this.searchParameters.property_type_id) {
        this.getPropertyType();
      }
      if(this.searchParameters.property_sub_type_id) {
        this.getPropertySubType();
      }
    })
  }


  private unsetSearchParameters() {
    this.searchParameters = new Search();
  }

  private unsetModelsBySearchParams(searchParameters: Search) {
    if (!searchParameters.wardId) {
      this.wardInputModel = '';
    }

    if (!searchParameters.colonyId) {
      this.colonyInputModel = '';
    }

    if (!searchParameters.fatherName) {
      this.streetInputModel = '';
    }
    if (!searchParameters.accountHolderName) {
      this.streetInputModel = '';
    }
  }

  private getFilters() {
    const filteredParams: any = {};

    for (let k in this.searchParameters) {
      if ((this.searchParameters as any)[k]) {
        filteredParams[k] = (this.searchParameters as any)[k];
      }
    }

    filteredParams['colonyId'] = this.searchParameters.colonyId;
    filteredParams['wardId'] = this.searchParameters.wardId;
    return filteredParams;
  }


  static advancedBuffer: boolean;
  public advancedBufferLocal: boolean = SearchComponent.advancedBuffer;

  OnClick(){
    if(!SearchComponent.advancedBuffer){
      SearchComponent.advancedBuffer = true;
      this.advancedBufferLocal = SearchComponent.advancedBuffer;
    }else {
      SearchComponent.advancedBuffer = false;
      this.advancedBufferLocal = SearchComponent.advancedBuffer;
    }
  }

  private getWards() {
    this.wardService.getAll().pipe(
      takeUntil(this.destroy$)
    ).subscribe((wards: Ward[]) => {
      this.wards = wards
    })
  }

  getColonyDetails(wardId:number, resetValue = true) {
    this.colonies = [];
    if(resetValue) this.searchParameters.colonyId = undefined as any;
    if(wardId === undefined) {
      this.searchParameters.selectedLandmark =  undefined;
      this.landmarkFiltered = this.landmarks;
      return;
    };
    this.loadingColonies = true;
    this.loadingLandmarks = true;
    this.colonyService.getAll(wardId).pipe(
      takeUntil(this.destroy$)
    ).subscribe((colonies: Colony[]) => {
      this.coloniesData = colonies;
      this.colonies = colonies;
      this.searchParameters.colonyId ?  this.updateLandmarks(Number(this.searchParameters.colonyId), false) : this.updateLandmarksForWard(colonies);
      this.loadingColonies = false;
    })
  }

  searchColonies() {
    this.colonyInput$.pipe(
      filter((term:string) => {
        if(this.searchParameters.wardId != undefined) {
          if(!term.trim()) {
            this.colonies = this.coloniesData.slice();
            return false;
          }
          this.colonies = this.coloniesData.filter((el:Colony) => el.name.toLocaleLowerCase().includes(term.toLocaleLowerCase()));
          return false;
        }
        return true;
      }),
      distinctUntilChanged(),
      debounceTime(300),
      tap(() =>  this.loadingColonies = true),
      switchMap((term:string) => {
        const where = this.colonyService.defaultSearchWhere(term);
        return this.colonyService.paginate(1, 100000, where)
      })
    ).subscribe((colonies: Colony[]) => {
      this.loadingColonies = false;
      this.colonies = colonies;
    })
  }

  getPropertyType() {
    if(this.propertyTypesAll.length) return;
    const propertyCategory = this.searchParameters.propertyCategory;
    this.loadingPropertyTypes = true;
    this.propertyTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertyType[]) => {
      this.loadingPropertyTypes = false;
      this.propertyTypesAll = data;
      if(propertyCategory) {
        this.propertyTypeData = this.propertyTypesAll.filter(el => el.property_category == propertyCategory);
      }else {
        this.propertyTypeData = this.propertyTypesAll;
      }
      
    })
  }

  onPropertyCategoryChange(propertyCategory: number) {
    this.searchParameters.property_type_id = null as any;
    this.searchParameters.property_sub_type_id = null as any;
    if(!propertyCategory) {
      this.propertyTypeData = this.propertyTypesAll;
      return;
    }
    this.propertyTypeData = this.propertyTypesAll.filter(el => el.property_category == propertyCategory);
  }

  getPropertySubType() {
    if(this.propertySubTypesAll.length) return;
    const propertyType = this.searchParameters.property_type_id;
    this.loadingPropertySubTypes = true;
    this.propertySubTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertySubType[]) => {
      this.loadingPropertySubTypes = false;
      this.propertySubTypesAll = data;
      if(propertyType) {
        this.propertySubTypeData = this.propertySubTypesAll.filter(el => el.propertyType.id == propertyType);
      }else {
        this.propertySubTypeData = this.propertySubTypesAll;
      }
      
    })
  }
  // Update Landmarks based on the ward selection.
  updateLandmarksForWard(colonies: Colony[]) {
    this.loadingLandmarks = true;
    this.landmarksForwardId = [];
    this.landmarks.forEach((landmark: { colony_id: number; }) => {
      colonies.map((colonies: { id: number; }) => {
        if(colonies.id === landmark.colony_id) {
          this.landmarksForwardId.push(landmark);
        }
      });
    });
    this.landmarkFiltered = this.landmarksForwardId;
    const landmarkFound = this.landmarkFiltered.some((element: { id: Number | undefined; }) => element.id === Number(this.searchParameters.selectedLandmark));
    if(!landmarkFound) this.searchParameters.selectedLandmark = undefined;
    this.loadingLandmarks = false;
  }
  // Update Landmarks based on the colony selection.
  updateLandmarks(colonyId: Number | undefined, resetValue = true) {
    this.loadingLandmarks = true;
    if(resetValue) this.searchParameters.selectedLandmark = undefined;
    if(colonyId) {
    this.landmarkFiltered = this.landmarks.filter((landmark: { colony_id: number; }) => landmark.colony_id === colonyId);
   } else {
    if(this.searchParameters.wardId) {
      this.updateLandmarksForWard(this.colonies);
    } else {
      this.landmarkFiltered = this.landmarks;
    }
   }
   this.loadingLandmarks = false;
  }
  
  onPropertyTypeChange(propertyTypeId: number) {
    this.searchParameters.property_sub_type_id = null as any;
    if(!propertyTypeId) {
      this.propertySubTypeData = this.propertySubTypesAll;
      return;
    }
    this.propertySubTypeData = this.propertySubTypesAll.filter(el => el.propertyType.id == propertyTypeId);
  }
}

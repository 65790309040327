import {Pipe, PipeTransform} from '@angular/core';
import {getFileUrl} from '../helpers/get-file-url';

@Pipe({
  name: 'getFileUrl',
})
export class GetFileUrlPipe implements PipeTransform {

  transform(url: string, ...args: unknown[]): string {
    return url ? getFileUrl(url) : '';
  }

}


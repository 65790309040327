import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'hiddenPhoneNumber'
})
export class HiddenPhoneNumberPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    if (value.length < 5) {
      return value;
    }
    return value.substr(0, 2) + '*'.repeat(value.length - 4) + value.substr(-2, 2);
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Citizen, Ledger, Property} from '../../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {PropertyService} from '../../../services/property.service';
import {AuthenticationService} from '../../../services';
import {FormBuilder, Validators} from '@angular/forms';
import {PaymentService} from '../../../services/payment.service';
import TaxDetails from '../../../@types/tax-details';
import {PayuService} from '../../../services/payu.service';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {LedgerService} from '../../../services/ledger.service';
import Swal from 'sweetalert2';
import {PropertyCategories} from 'src/app/enums/property-categories';
import {ConfigService} from '../../../services/config.service';
import {Cities} from '../../../enums/cities';
import {PaytmService} from '../../../services/paytm.service';
import { cloneDeep } from 'lodash';
import { currentFinancialYear } from 'src/app/helpers/current-financial-year';
import { LanguageService } from 'src/app/services/language.service';
import { CcAvenueService } from 'src/app/services/cc-avenue.service';
import { RazorpayService } from 'src/app/services/razorpay.service';
import { CityService } from 'src/app/services/city.service';


type UserCredentials = {
  firstName: string,
  lastName: string,
  phoneNumber: string,
  email: string,
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, OnDestroy {
  Property = Property;

  private destroy$ = new ReplaySubject();

  private readonly propertyId;
  private showErrors = false;

  processing = false;
  loading = true;

  owner!: Citizen;
  userForm = this.fb.group({
    firstName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(60)]],
    lastName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20)]],
    phoneNumber: ['', [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
    email: ['', [Validators.required, Validators.email, Validators.maxLength(50)]],
  });
  property!: Property;
  taxCalculation: any;
  PropertyCategories = PropertyCategories;

  fullPaymentCheckBox = true;
  showIncorrectAmount = false;

  taxDetails!: TaxDetails;
  paymentAmount!: number;
  private formToken!: string;
  _ledgerData!:any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private payuService: PayuService,
    private ccAvenueService: CcAvenueService,
    private currentRoute: ActivatedRoute,
    private ledgerService: LedgerService,
    private paymentService: PaymentService,
    private propertyService: PropertyService,
    private paytmService: PaytmService,
    private razorpayService: RazorpayService,
    public auth: AuthenticationService,
    public languageService: LanguageService,
    public cs: CityService
  ) {
    const id = this.currentRoute.snapshot.params.id;

    if (!id) {
      this.router.navigate(['property-search']);
      return;
    }

    this.propertyId = id;
  }

  ngOnInit(): void {
    this.initProperty();
    this.initTaxDetails();
    this.initFormToken();

    // Get ledger data to check ledger and tax amount mismatch

    this.getLedgers();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getLedgers() {
    this.ledgerService.ledgersByProperty(this.propertyId)
    .pipe(takeUntil(this.destroy$))
    .subscribe(res => {
      this._ledgerData = res;
      this.changeCurrentYearTaxIfMismatched();
    })
  }

  proceedPayment() {
    if (
      !this.fullPaymentCheckBox && (!this.paymentAmount || this.paymentAmount > this.taxDetails.debtWithAllRebates()
        || this.paymentAmount <= 0)
    ) {
      this.showIncorrectAmount = true;
      return;
    }

    this.showIncorrectAmount = false;
    const credentials = this.getUserCredentials();

    if (!credentials) return;

    const amount = this.fullPaymentCheckBox ? this.taxDetails.debtWithAllRebates() : this.paymentAmount;
    this.startPayment(credentials, amount);
  }

  isControlInvalid(controlName: string): boolean {
    return this.userForm.controls[controlName].invalid && this.showErrors;
  }

  private initProperty() {
    const successfulRes = (property: Property | undefined) => {
      if (!property) {
        this.router.navigate(['/property/' + this.propertyId]);
        return;
      }

      this.property = property;
      this.owner = Object.assign(new Citizen(), property?.owners[0]);
      this.initTaxCalculation();
    };

    const errorRes = () => {
      this.router.navigate(['/property/' + this.propertyId]);
    };

    this.propertyService.getOneById(this.propertyId).pipe(takeUntil(this.destroy$)).subscribe(successfulRes, errorRes);
  }

  private initTaxCalculation() {
    this.propertyService.taxCalculation(this.property).pipe(takeUntil(this.destroy$)).subscribe((taxCalculation) => {
      if (!taxCalculation) return;

      this.taxCalculation = cloneDeep(taxCalculation);
      this.changeCurrentYearTaxIfMismatched();
    });
  }

  private initTaxDetails() {
    const successfulRes = (details: TaxDetails | undefined) => {
      if (!details || details.propertyDebt <= 0) {
        this.router.navigate(['/property/' + this.propertyId]);
        return;
      }

      this.taxDetails = details;
    };

    const errorRes = () => {
      this.router.navigate(['/property/' + this.propertyId]);
    };

    this.ledgerService.taxDetails(this.propertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        successfulRes,
        errorRes,
        () => this.loading = false,
      );
  }

  private initFormToken() {
    this.paymentService.getFormToken(this.propertyId).pipe(takeUntil(this.destroy$)).subscribe((token) => {
      if (!token) {
        this.router.navigate(['/property/' + this.propertyId]);
        return;
      }

      this.formToken = token;
    });
  }

  private startPayment(credentials: UserCredentials, amount: number) {
    if (ConfigService.getValue('city_id') === Cities.Bharatpur) {
      this.requestForPaytm(credentials, amount);
      return;
    } else if (ConfigService.getValue('city_id') === Cities.JodhpurNorth) {
      this.requestForCCAvenue(credentials, amount);
      return;
    } else if (ConfigService.getValue('city_id') === Cities.Jodhpur) {
      this.requestForRazorpay(credentials, amount);
      return;
    }
    
    this.requestForPayU(credentials, amount);
  }

  private requestForPayU(credentials: UserCredentials, amount: number) {
    const resData = Object.assign(credentials, {propertyId: this.propertyId, amount, formToken: this.formToken});
    this.processing = true;

    this.payuService.taxPayRequest(resData).pipe(takeUntil(this.destroy$)).subscribe(
      (r) => r ? location.assign(r) : this.requestError(undefined),
      this.requestError,
    );
  }

  private requestForCCAvenue(credentials: UserCredentials, amount: number) {
    const resData = Object.assign(credentials, {propertyId: this.propertyId, amount, formToken: this.formToken});
    this.processing = true;

    this.ccAvenueService.taxPayRequest(resData).pipe(takeUntil(this.destroy$)).subscribe(
      (r) => r ? location.assign(r) : this.requestError(undefined),
      this.requestError,
    );
  }

  private requestForPaytm(credentials: UserCredentials, amount: number) {
    const resData = Object.assign(credentials, {propertyId: this.propertyId, amount, formToken: this.formToken});
    this.processing = true;

    this.paytmService.initiateTransaction(resData).subscribe(
      (res: any) => {
        if (!res.status) {
          this.requestError();
          return;
        }

        this.paytmService.startPayment(res);
        setTimeout(() => this.processing = false, 2000);
      },
      this.requestError,
    );
  }

  private requestForRazorpay(credentials: UserCredentials, amount: number) {
    const resData = Object.assign(credentials, {propertyId: this.propertyId, amount, formToken: this.formToken});
    this.processing = true;

    this.razorpayService.createRazorpayOrder(resData).subscribe(
      (res: any) => {
        if (!res) {
          this.requestError();
          return;
        }

        this.razorpayService.startPayment({
          ...res,
          ...resData
        });

        setTimeout(() => this.processing = false, 2000);
      },
      this.requestError,
    );
  }

  private getUserCredentials(): UserCredentials | null {
    if (this.auth.user) {
      return {
        firstName: this.auth.user.first_name,
        lastName: this.auth.user.last_name,
        email: this.auth.user.email,
        phoneNumber: this.auth.user.phone_number,
      };
    }

    return this.isUserFormValid() ?
      {
        firstName: this.userForm.controls['firstName'].value,
        lastName: this.userForm.controls['lastName'].value,
        email: this.userForm.controls['email'].value,
        phoneNumber: this.userForm.controls['phoneNumber'].value,
      }
      : null;
  }

  private isUserFormValid(): boolean {
    if (this.userForm.invalid) {
      this.showErrors = true;
      return false;
    }

    this.showErrors = false;
    return true;
  }

  private requestError = (e?: any) => {
    this.processing = false;
    Swal.fire({
      icon: 'error',
      title: this.languageService.translate('home.oops'),
      text: e?.message ?? this.languageService.translate('home.something_went_wrong'),
      confirmButtonText: this.languageService.translate('home.ok')
    });
  };

    /**
   * Change current year tax if current year tax and ledger amout doesn't match 
   */
     private changeCurrentYearTaxIfMismatched() {
      if(this.taxCalculation && this._ledgerData) {
        const currentYearTaxFromCalculation = this.taxCalculation?.taxCalculation[0]?.tax;
        const currentYearTaxFromLedger = this._ledgerData.filter((el:any) => el.financial_year === currentFinancialYear() && el.transaction_type === Ledger.TAX_BILL_TYPE)[0]?.debit_amount;
        if(currentYearTaxFromCalculation && currentYearTaxFromLedger && currentYearTaxFromCalculation !== currentYearTaxFromLedger) { 
          this.taxCalculation.taxCalculation[0].tax = currentYearTaxFromLedger;
        }
      }
    }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {faqsRouting} from "./faqs.routing";
import {FormsModule} from "@angular/forms";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {WidgetsModule} from "../../widgets/widgets.module";
import {PropertyService} from "../../services/property.service";
import { PipesModule } from 'src/app/pipes/pipes.module';



@NgModule({
  declarations: [
    IndexComponent,
  ],
  imports: [
    faqsRouting,
    CommonModule,
    FormsModule,
    NgbModule,
    WidgetsModule,
    PipesModule
  ],
  providers:[
    PropertyService
  ]
})
export class FaqsModule { }

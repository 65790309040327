import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermOfServiceComponent } from './term-of-service/term-of-service.component';
import { PrivatePolicyComponent } from './private-policy/private-policy.component';
import { RefundCancellationPolicyComponent } from './refund-cancellation-policy/refund-cancellation-policy.component';
import {staticRouting} from "./static.routing";
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';



@NgModule({
  declarations: [
    TermOfServiceComponent,
    PrivatePolicyComponent,
    RefundCancellationPolicyComponent,
    PageNotFoundComponent
  ],
  imports: [
    CommonModule,
    staticRouting
  ]
})
export class StaticModule { }

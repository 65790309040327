import {Component, OnInit, ViewChild} from '@angular/core';
import {SigninComponent} from "../signin/signin.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OtpConfirmComponent} from "../otp-confirm/otp-confirm.component";
import {AuthenticationService} from "../../services";
import {CityService} from '../../services/city.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  @ViewChild('phoneNumber') phoneNumber!: HTMLFormElement;
  @ViewChild('email') email!: HTMLFormElement;
  readonly EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  signUpForm!: FormGroup;
  isLoading: boolean = false;
  error = '';
  showErrorMessages!:boolean;

  newUser: any = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  }

  constructor(
    public cs: CityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public authenticationService: AuthenticationService,
    private _fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.signUpForm = this._fb.group({
      email: ['', [Validators.required, Validators.pattern(this.EMAIL_REGEX)]],
      phoneNumber: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]]
    })
  }

  getControlError(controlName:string) {
    return this.signUpForm.get(controlName)?.errors;
  }

  private removeUniqueErrors() {
    const emailErrors = this.signUpForm.get('email')?.errors;
    const phoneErrors = this.signUpForm.get('phoneNumber')?.errors;
    if(emailErrors) {
      delete emailErrors['unique'];
      const newErrors = Object.keys(emailErrors).length ? emailErrors : null;
      this.signUpForm.get('email')?.setErrors(newErrors);
    }
    if(phoneErrors) {
      delete phoneErrors['unique'];
      const newErrors = Object.keys(phoneErrors).length ? phoneErrors : null;
      this.signUpForm.get('phoneNumber')?.setErrors(newErrors);
    }
    this.signUpForm.updateValueAndValidity();
  }

  signUp() {
    this.removeUniqueErrors();
    if(this.signUpForm.invalid) {
      this.showErrorMessages = true;
      return;
    }
    const values = this.signUpForm.value;
    this.error = '';
    this.isLoading = true;
    this.authenticationService.signUpOTP(
      values.firstName,
      values.lastName,
      values.email,
      values.phoneNumber
    ).subscribe(
      (response: any) => {
        this.isLoading = false;
        if (response.data.registrationOTP) {
          this.dialogRef.close();
          this.dialog.open(OtpConfirmComponent, {
            data: {otpToken: response.data.registrationOTP, phoneNumber: this.newUser.phoneNumber},
          });
        } else if (response?.errors?.message) {
          this.error = response.errors.message
        }
      },
      (error: any) => {
        this.isLoading =false;
        this.showErrorMessages = true;
        if (error?.message) this.error = error?.message;

        if (error?.error?.errors?.email) this.signUpForm.get('email')?.setErrors({unique: true});

        if (error?.error?.errors?.phone_number) this.signUpForm.get('phoneNumber')?.setErrors({unique: true});
      })
  }

  openSignInModal() {
    this.dialogRef.close();
    this.dialog.open(SigninComponent);
  }
}

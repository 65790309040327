export class FaqQuestion {
  constructor(question:String, answer:String) {
    this.question = question;
    this.answer = answer;
  }

  question: String = '';
  answer: String = '';
  isActive: Boolean = false;
}

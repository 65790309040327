<div class="container">

    <!-- Start breadcrumb -->
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'property_search.home' | translate}}</a></li>
        <li class="breadcrumb-item active" aria-current="page">{{'property_search.credit_requests' | translate}}</li>
      </ol>
    </nav>
  
    <div class="listing-head mb-4 align-items-center ">
      <h3 class="mb-2">{{'property_search.credit_requests' | translate}}</h3>
    </div>
  
    <!-- Table -->
    <div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
      <div class="table-responsive responsive-flex-table">
        <table class="table border-top table-hover nowrap datatables no-footer dtr-inline responsive-flex-table">
          <thead>
        
            <th class="not-sortable">#</th>
            <th class="not-sortable">{{'property_search.property_uid' | translate}}</th>
            <th class="not-sortable">{{'property_search.status' | translate}}</th>
            <th class="not-sortable">{{'property_search.reviewer_remark' | translate}}</th>
            <th class="not-sortable">{{'property_search.created' | translate}}</th>
        
          </thead>
          <tbody *ngIf="(this.tableService.loading$ | async) === false; else loadingBlock">
          <tr *ngIf="items.length === 0">
            <td colspan="100">
              <h5>{{'property_search.credit_requests_does_not_exist' | translate}}</h5>
            </td>
          </tr>
          <tr *ngFor="let item of items; let i = index">
            <td>
              <ngb-highlight></ngb-highlight>
              {{(tableService.page - 1) * tableService.perPage + i + 1 }}
            </td>
            <td>
              <ngb-highlight style="font-weight: bold;" [result]="item.property?.property_uid ??'N/A'"
                            [term]="search"></ngb-highlight>
            </td>
            <td style="color: white; ">
                <span *ngIf="item.status_id === CreditRequestStatus.Pending" class="badge bg-primary">{{'property_search.pending' | translate}}</span>
                <span *ngIf="item.status_id === CreditRequestStatus.Rejected" class="badge bg-danger ms-1">{{'property_search.rejected' | translate}}</span>
                <span *ngIf="item.status_id === CreditRequestStatus.Approved" class="badge bg-success ms-1">{{'property_search.approved' | translate}}</span>
              </td>
            <td>
                <ngb-highlight style="font-weight: bold;" [result]="item.reviewed_remark??'N/A'"
                              [term]="search"></ngb-highlight>
              </td>
            <td>
              <ngb-highlight [result]="item.created_at | date:'dd-MM-yyyy HH:mm:ss'"
                            [term]="search"></ngb-highlight>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- End table -->
  
      <!-- Pagination for listing-->
      <div class="d-flex justify-content-center" *ngIf="tableService.totalRecords > 0">
        <div class="text-md-right float-md-end pagination-rounded">
          <ngb-pagination [collectionSize]="tableService.totalRecords"
                          [(page)]="tableService.page"
                          [pageSize]="tableService.perPage"
                          [maxSize]="5"
                          [rotate]="true"
                          [disabled]="tableService.currentLoading"
          >
          </ngb-pagination>
        </div>
        <!-- End Pagination -->
      </div>
    </div>  
  </div>
  
  <ng-template #loadingBlock>
    <tbody>
    <tr>
      <th colspan="100">
        <div class="loading d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
            <span class="sr-only">{{'property_search.loading' | translate}}</span>
          </div>
        </div>
      </th>
    </tr>
    </tbody>
  </ng-template>
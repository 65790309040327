import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import TaxPayRequest from '../@types/tax-pay-request';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CcAvenueService {

  constructor(private apollo: Apollo) { }

  public taxPayRequest(info: TaxPayRequest): Observable<string | null> {
    return this.apollo.mutate({
      mutation: gql`mutation{ taxPayRequestForCCAvenue(
        first_name: "${info.firstName}",
        last_name: "${info.lastName}",
        email: "${info.email}",
        phone: "${info.phoneNumber}",
        property_id: ${info.propertyId}
        amount: ${info.amount},
        formToken: "${info.formToken}"
      )
      }`,
    }).pipe(
      map((res: any) => typeof res?.data?.taxPayRequestForCCAvenue === 'string'
        ? res.data.taxPayRequestForCCAvenue
        : undefined
      )
    );
  }
}

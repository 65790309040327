<div class="container">

  <!-- Start breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">My Raised Objection</li>
    </ol>
  </nav>

  <div class="listing-head mb-4 align-items-center ">
    <h3 class="mb-2">My Raised Objection</h3>
  </div>

  <!-- Table -->
  <div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
    <div class="table-responsive responsive-flex-table">
      <table class="table border-top table-hover nowrap datatables no-footer dtr-inline responsive-flex-table">
        <thead>
      
          <th class="not-sortable">#</th>
          <th class="not-sortable">Property UID</th>
          <th class="not-sortable">Tracking no</th>
          <th class="not-sortable">Ward</th>
          <th class="not-sortable">Colony</th>
          <th class="not-sortable">Submitted By</th>
          <th class="not-sortable">Mobile Number</th>
          <th class="not-sortable">Status</th>
          <th class="not-sortable">Created</th>
          <th class="not-sortable">Action</th>
      
        </thead>
        <tbody *ngIf="(this.tableService.loading$ | async) === false; else loadingBlock">
        <tr *ngIf="items.length === 0">
          <td colspan="100">
            <h5>Objections with this filters not exists.</h5>
          </td>
        </tr>
        <tr *ngFor="let item of items; let i = index">
          <td>
            <ngb-highlight></ngb-highlight>
            {{(tableService.page - 1) * tableService.perPage + i + 1 }}
          </td>
          <td>
            <ngb-highlight style="font-weight: bold;" [result]="item.property.property_uid"
                          [term]="search"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="item.objection_no"
                          [term]="search"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="item.property.ward.name" [term]="search"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="item.property.colony.name" [term]="search"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="item.user.first_name + ' ' + item.user.last_name"
                          [term]="search"></ngb-highlight>
          </td>
          <td>
            <ngb-highlight [result]="item.user.phone_number" [term]="search"></ngb-highlight>
          </td>
          <td style="color: white; ">
            <span *ngIf="item.status === ObjectionStatuses.Pending" class="badge bg-primary">Pending</span>
            <span *ngIf="item.status === ObjectionStatuses.UnderReview" class="badge bg-warning ms-1">Review</span>
            <span *ngIf="item.status === ObjectionStatuses.MissingDoc" class="badge bg-info ms-1">Missing Documents</span>
            <span *ngIf="item.status === ObjectionStatuses.Rejected" class="badge bg-danger ms-1">Rejected</span>
            <span *ngIf="item.status === ObjectionStatuses.Accepted" class="badge bg-success ms-1">Accepted</span>
          </td>
          <td>
            <ngb-highlight [result]="item.created_at | date:'dd-MM-yyyy HH:mm:ss'"
                          [term]="search"></ngb-highlight>
          </td>
          <td>
            <a class="btn btn-sm btn-primary text-white"
              [routerLink]="'/my-objections/' + item.id + '/view'">
              View
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <!-- End table -->

    <!-- Pagination for listing-->
    <div class="d-flex justify-content-center" *ngIf="tableService.totalRecords > 0">
      <div class="text-md-right float-md-end pagination-rounded">
        <ngb-pagination [collectionSize]="tableService.totalRecords"
                        [(page)]="tableService.page"
                        [pageSize]="tableService.perPage"
                        [maxSize]="5"
                        [rotate]="true"
                        [disabled]="tableService.currentLoading"
        >
        </ngb-pagination>
      </div>
      <!-- End Pagination -->
    </div>
  </div>  
</div>

<ng-template #loadingBlock>
  <tbody>
  <tr>
    <th colspan="100">
      <div class="loading d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </th>
  </tr>
  </tbody>
</ng-template>


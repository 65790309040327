<div class="mb-4">
  <h5 style="color: #3D3D3D; font-size: 14px">{{'property_search.review_changes_&_estimated_ud_tax' | translate }}</h5>
  <h6 style="color: #8E8E8E; font-size: 13px; font-weight: 400">{{'property_search.details_of_modifications' | translate }}</h6>
</div>

<!-- Updated Details Start -->
<div class="card mb-4" *ngIf="differentPropertyKeys.length > 0">
  <div class="card-body">
    <h4 class="card-title">{{'property_search.updated_details' | translate }}</h4>

    <div class="property-details-card">
      <hr>

      <div class="row">
        <div class="col-4 property-details-card__bold">
          <p>{{'property_search.field_name' | translate }}</p>
        </div>
        <div class="col-4  property-details-card__bold">
          <p>{{'property_search.new_data' | translate }}</p>
        </div>
      </div>

      <hr>

      <ng-container *ngFor="let key of differentPropertyKeys">
        <div class="row">
          <div class="col-4 property-details-card__bold">{{PROPERTY_FIELD_NAME_RESOLVERS[key] ?? key}}</div>
          <div class="col-4">
            {{PROPERTY_FIELD_RESOLVERS[key]?.forDisplay(updatedProperty) ?? updatedProperty[key] ?? 'Unknown'}}
          </div>
        </div>

        <hr>
      </ng-container>

    </div>
  </div>
</div>
<!-- Updated Details End -->

<!-- Owners Details Start-->
<div *ngIf = "showOwnerDetailsTable" class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <h4 class="card-title" style="padding: 18px 0 0 16px">{{'property_search.owner_details' | translate }}</h4>
  <table class="table responsive-flex-table">
    <thead>
    <th>#</th>
    <th>{{'property_search.owner_name' | translate }}</th>
    <th>{{'property_search.gender' | translate }}</th>
    <th>{{'property_search.phone_number' | translate }}</th>
    <th>{{'property_search.relation' | translate }}</th>
    <th>{{'property_search.relation_name' | translate }}</th>
    <th></th>
    </thead>

    <tbody>
    <tr *ngFor="let owner of getNormalizedModelChanges(ownersChanges); let i = index">
      <td>{{ i + 1 }}</td>
      <td>{{ (owner.item.first_name ?? '') }} {{(owner.item.last_name ?? '')}}</td>
      <td>{{ GENDER_TYPES[owner.item.gender]?.name }}</td>
      <td>{{owner.item.phone_number}}</td>
      <td>{{OWNER_RELATIONS[owner.item.relation] ?? ''}}</td>
      <td>{{owner.item.relation_name}}</td>
      <td>
        <span [class]="MODEL_CHANGE_TYPE_CLASSES[owner.changeType]">
        {{MODEL_CHANGE_TYPE_NAMES[owner.changeType]}}
        </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!-- Owners Details End -->

<!-- Floors Details Start -->
<div *ngIf = "showFloorDetailsTable" class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <h4 class="card-title" style="padding: 18px 0 0 16px">{{'property_search.floor_details' | translate }}</h4>
  <table class="table responsive-flex-table">
    <thead>
    <th>{{'property_search.floor' | translate }}</th>
    <th>{{'property_search.from_year' | translate }}</th>
    <th>{{'property_search.upto_year' | translate }}</th>
    <th>{{'property_search.property_category' | translate }}</th>
    <th>{{'property_search.property_type' | translate }}</th>
    <th>{{'property_search.property_sub_type' | translate }}</th>
    <th>{{'property_search.property_usage' | translate }}</th>
    <th>{{'property_search.carpet_area_in_sqft' | translate }}</th>
    <th>{{'property_search.action' | translate }}</th>
    </thead>

    <tbody>
    <tr *ngFor="let floor of getNormalizedModelChanges(floorsChanges); let i = index">
      <td>{{ FLOOR_NUMBER_NAMES[floor.item.floor_number] ?? '' }}</td>
      <td>{{ floor.item.from_year ? floor.item.from_year + '-' + (toInt(floor.item.from_year) + 1) : '' }}</td>
      <td>{{ floor.item.upto_year ? floor.item.upto_year + '-' + (toInt(floor.item.upto_year) + 1) : '' }}</td>
      <td>{{ PROPERTY_CATEGORIES[floor.item.property_category] ?? '' }}</td>
      <td>{{ floor.item.propertyType?.name ?? '' }}</td>
      <td>{{ floor.item.propertySubType?.name ?? '' }}</td>
      <td>{{ PROPERTY_USAGES[floor.item.property_usage_id] ?? '' }}</td>
      <td>{{ floor.item.carpet_area ?? '' }}</td>
      <td>
        <span [class]="MODEL_CHANGE_TYPE_CLASSES[floor.changeType]">
        {{MODEL_CHANGE_TYPE_NAMES[floor.changeType]}}
        </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<!-- Floors Details End -->

<div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <h4 class="card-title" style="padding: 18px 0 0 16px">{{'property_search.current_tax_calculation' | translate }}</h4>
  <table class="table responsive-flex-table">
    <thead>
   
      <th>{{'property_search.sl_no' | translate }}</th>
      <th>{{'property_search.usage_category' | translate }}</th>
      <th>{{'property_search.area_type' | translate }}</th>
      <th>{{'property_search.area_sq_yd' | translate }}</th>
      <th>{{'property_search.dlc_rate' | translate }}</th>
      <th>{{'property_search.rate_2000' | translate }}</th>
      <th>{{'property_search.tax_in_rs' | translate }}</th>
   
    </thead>
    <tbody *ngIf="currentTaxCalculation; else estimatedUDTaxAlternative">
    <tr *ngFor="let details of currentTaxCalculation!.taxCalculation; let i = index">
      <td>{{i + 1}}</td>
      <td>{{PROPERTY_CATEGORIES[details.propertyCategory] ?? ''}}</td>
      <td>{{details.areaType}}</td>
      <td>{{details.area}}</td>
      <td>{{details.rate ?? ''}}</td>
      <td>{{details.rate ? (details.rate / 2000).toFixed(2) : ''}}</td>
      <td>{{details.tax}}</td>
    </tr>
    <tr>
      <td colspan="5"></td>
      <td>{{'property_search.total_tax' | translate }}</td>
      <td>{{currentTaxCalculation.totalTax}}</td>
    </tr>
  </table>
</div>

<div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
  <h4 class="card-title" style="padding: 18px 0 0 16px">{{'property_search.estimated_ud_tax_calculation' | translate }}</h4>
  <table class="table responsive-flex-table">
    <thead>
   
      <th>{{'property_search.sl_no' | translate }}</th>
      <th>{{'property_search.usage_category' | translate }}</th>
      <th>{{'property_search.area_type' | translate }}</th>
      <th>{{'property_search.area_sq_yd' | translate }}</th>
      <th>{{'property_search.dlc_rate_per_sqmt' | translate }} </th>
      <th>{{'property_search.rate_2000' | translate }}</th>
      <th>{{'property_search.tax_in_rs' | translate }}</th>
   
    </thead>
    <tbody *ngIf="updatedTaxCalculation; else estimatedUDTaxAlternative">
    <tr *ngFor="let details of updatedTaxCalculation!.taxCalculation; let i = index">
      <td>{{i + 1}}</td>
      <td>{{PROPERTY_CATEGORIES[details.propertyCategory] ?? ''}}</td>
      <td>{{details.areaType}}</td>
      <td>{{details.area}}</td>
      <td>{{details.rate ?? ''}}</td>
      <td>{{details.rate ? (details.rate / 2000).toFixed(2) : ''}}</td>
      <td>{{details.tax}}</td>
    </tr>
    <tr>
      <td colspan="5"></td>
      <td>{{'property_search.total_tax_in_rs' | translate }}</td>
      <td>{{updatedTaxCalculation.totalTax}}</td>
    </tr>
  </table>
</div>

<app-document-proofs [oldTaxId]="oldTaxIdChanged" (uploadedProofs)="setProofs($event)"></app-document-proofs>

<!-- Citizen Remark Start -->
<div class="mb-4">
  <h5 class="disclaimer">{{'property_search.remark' | translate }}</h5>
  <div>
    <textarea class="form-control" rows="5" placeholder="{{'property_search.enter_any_remark_or_note' | translate }}" [(ngModel)]="citizenRemark"
              (ngModelChange)="validateRemark()"></textarea>
    <span *ngIf="isValidateRemark" style="color: red">{{'property_search.maximum_500_words' | translate }}</span>
  </div>
</div>
<!-- Citizen Remark End -->

<!-- Disclaimer Start -->
<div>
  <h5 class="disclaimer">{{'property_search.disclaimer' | translate }}</h5>

  <div class="form-check d-flex align-items-center disclaimer-checkbox" style="margin-bottom: 8px">
    <input type="checkbox" [(ngModel)]="isAgree">{{'property_search.i_agree' | translate }} 
    <label class="form-check-label"></label>
  </div>

  <p class="disclaimer-remark">{{'property_search.disclairation_sentence' | translate }}
    
  </p>
</div>
<!-- Disclaimer End -->

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">{{'property_search.previous' | translate }}</button>
  <button class="btn btn-primary" [disabled]="!isAgree || isSubmitting" (click)="submit()">{{'property_search.submit' | translate }}</button>
</div>

<ng-template #estimatedUDTaxAlternative>
  <tbody *ngIf="isTaxCalculationLoading">
  <tr>
    <td colspan="100">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">{{'property_search.loading' | translate }}</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>

  <tbody *ngIf="(!updatedTaxCalculation || !currentTaxCalculation) && !isTaxCalculationLoading">
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">{{'property_search.tax_can_not_be_calculated' | translate }}</td>
  </tr>
  </tbody>
</ng-template>

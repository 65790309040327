<div class="property-information-card">


  <div class="property-information-card__info">
    <div class="property-information-card__photo" *ngIf="property.photos?.[0]?.url; else noPhoto"
      style="background-image: url({{property.photos[0].url}}); background-position: center;">
    </div>
  <section class="prop-list">
      <p class="property-information-card__property-uid" routerLink="/property/{{property.id}}">{{'property_search.service_no' | translate}}
        {{property?.property_uid}}</p>

      <p class="property-information-card__address">
        {{property?.property_category ? Property.PROPERTY_CATEGORIES[property.property_category] : 'Unknown'}}</p>


      <div class="property-information-card-item">
        <div class="property-information-card-item__key">{{'property_search.ward' | translate}}</div>
        <div class="property-information-card-item__value">{{property?.ward?.name ?? 'Unknown'}}</div>
      </div>
      <div class="property-information-card-item">
        <div class="property-information-card-item__key">{{'property_search.owner_name' | translate}}</div>
        <div class="property-information-card-item__value">{{commonService.getOwnerName(property.owners)}}</div>
      </div>
      <div class="property-information-card-item">
        <div class="property-information-card-item__key">{{'property_search.plot_area' | translate}}</div>
        <div class="property-information-card-item__value"> {{property?.plot_area ?? 'Unknown'}}</div>
      </div>
      
    </section>


  <div class="d-flex align-center justify-between w100" style="margin-top: 10px;">
    <p class="property-information-card__property-uid">
      <span class="svg-icon">
        <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.90892 12.0012L7.99986 16.666L12.0908 12.0012C13.9718 9.85634 13.8769 6.62283 11.8733 4.592C9.74255 2.43236 6.25718 2.43236 4.12645 4.592C2.12282 6.62283 2.02789 9.85635 3.90892 12.0012Z"
            stroke="#87909E" stroke-width="1.6" />
          <circle cx="7.99969" cy="8.09417" r="1.71429" stroke="#87909E" stroke-width="1.6" />
        </svg>

      </span>
      {{(property?.address_house_number != null)? property?.address_house_number + ', ' : ''}}
      {{(property?.address_line2 != null)? property?.address_line2 + ', ' : ''}}
      {{(property?.street?.name != null)? property?.street?.name + ', ' : ''}}
      {{(property?.colony?.name != null)? property?.colony?.name + ', ' : ''}} ,
      {{(property?.ward?.name != null)? 'Ward ' + property?.ward?.name : ''}}
    </p>
    <a class="btn btn-primary text-white no-wrap" routerLink="/property/{{property.id}}" target="_blank">{{'property_search.view_all' | translate}}</a>
  </div>

</div>

</div>

<ng-template #noPhoto>
  <div class="property-information-card__photo">
    <span>{{'property_search.no_photo' | translate}}</span>
  </div>
</ng-template>

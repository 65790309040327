import {Ledger} from '../models';
import {RebateTypes} from '../enums/rebate-types';

export default class TaxDetails {

  readonly financialYear: number;
  readonly rebatesAndPenalties: { isRebate: boolean, ledger: Ledger }[] = []
  readonly rebateOnPenaltyAmount: number = 0;
  readonly notAddedPenaltyAmount: number = 0;

  private constructor(
    public readonly taxInCurrentYear: number,
    public readonly propertyDebt: number,
    public readonly propertyDebtInPastYear: number,
    public readonly ledgersCurrentYear: Ledger[] = [],
    public readonly propertyDebtForFinancialYears: Ledger[] = [],
    public readonly availableRebates: Ledger[] = [],
    public readonly penalty?: Ledger,
    public readonly totalPenaltyAmount: number = 0,
  ) {
    this.financialYear = ledgersCurrentYear[0]?.financial_year;

    ledgersCurrentYear.forEach((ledger) => {
      const isRebate = ledger.transaction_type === Ledger.REBATE_TYPE;

      if (isRebate || ledger.transaction_type === Ledger.PENALTY_TYPE) {
        this.rebatesAndPenalties.push({isRebate, ledger})
      }
    })

    this.notAddedPenaltyAmount = this.penalty?.debit_amount ?? 0;
    this.rebateOnPenaltyAmount = availableRebates.find((el) => el.rebate_type === RebateTypes.Penalty)?.credit_amount ?? 0;
  }

  needShowPenalty() {
    return this.propertyDebt > 0 && this.totalPenaltyAmount > 0;
  }

  availableRebateAmount() {
    return this.availableRebates.length > 0
      ? this.availableRebates.reduce((acc, r) => acc + r.credit_amount, 0)
      : 0;
  }

  paidAmount() {
    return this.ledgersCurrentYear
      ?.filter((l) => l.transaction_type === Ledger.PAYMENT_TYPE)
      ?.reduce((acc, l) => acc + l.credit_amount, 0) ?? 0;
  }

  debtWithAllRebates() {
    return this?.propertyDebt
      ? this.propertyDebt - (this.availableRebateAmount() ?? 0)
      : 0;
  }

  static create(
    data: {
      taxInCurrentYear: number,
      propertyDebt: number,
      propertyDebtInPastYear: number
      penalty?: Ledger,
      totalPenaltyAmount?: number
      availableRebates?: Ledger[],
      ledgersInCurrentYear?: Ledger[],
      propertyDebtForFinancialYears?: Ledger[],
    }
  ): TaxDetails | undefined {
    if (
      typeof data !== 'object' || typeof data?.taxInCurrentYear !== 'number' || typeof data?.propertyDebt !== 'number'
      || typeof data?.propertyDebtInPastYear !== 'number'
    ) return undefined;

    let ledgers: Ledger[] = [];
    let propertyDebtForFinancialYears: Ledger[] = [];

    if (Array.isArray(data?.ledgersInCurrentYear)) {
      ledgers = data.ledgersInCurrentYear.map((l) => Object.assign(new Ledger(), l));
    }

    if (Array.isArray(data?.propertyDebtForFinancialYears)) {
      propertyDebtForFinancialYears = data.propertyDebtForFinancialYears.map((l) => Object.assign(new Ledger(), l));
    }

    return new TaxDetails(
      data.taxInCurrentYear,
      data.propertyDebt,
      data.propertyDebtInPastYear,
      ledgers,
      propertyDebtForFinancialYears,
      data.availableRebates?.map((r) => Object.assign(new Ledger(), r)) ?? [],
      data.penalty,
      data.totalPenaltyAmount ?? 0
    );
  }
}

export abstract class BaseService {

  protected constructor() {
  }

  defaultSearchWhere(term: string) {
    return ''
  }

  /**
   * This method return value string for graphql. For example in where, in data in etc...
   * Expand for all types in the future. Add toStringBuilder from PropertyService
   *
   * @param field
   * */
  getFieldString(field: any): string {
    if (field === null) return 'null';
    if (typeof field === 'boolean') return `${field}`;

    return typeof field === 'number' ? `${field}` : `"${field}"`;
  }
}

<div class="container py-4">
  <div class="row">
    <div class="col-md-6">

      <fieldset class="border px-3">
        <legend class="w-auto">NDC Verification Status</legend>

        <div *ngIf="loading" class="spinner-border text-primary m-3" role="status">
          <span class="visually-hidden"></span>
        </div>

        <div *ngIf="!loading" class="mb-3 h5">
          <div *ngIf="ndcFile" class="text-success"><i class="bx bx-check-shield mr-1"></i>Verified</div>
          <div *ngIf="!ndcFile" class="text-danger"><i class="bx bx-error-circle mr-1"></i>Not Verified</div>
        </div>

        <ul *ngIf="!loading && ndcFile" class="list-group mb-3">
          <li class="list-group-item" [routerLink]="'/property/' + ndcFile.property?.id" role="button">
            <div class="row">
              <div class="col-sm-3">Property UID</div>
              <div class="col-sm-9 text-secondary">
                <abbr title="Open property page">{{ndcFile.property?.property_uid}}</abbr>
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-3">Owner Name</div>
              <div class="col-sm-9 text-secondary">{{ndcFile.property?.owners?.[0]?.first_name}}
                {{ndcFile.property?.owners?.[0]?.last_name}}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-3">Address</div>
              <div class="col-sm-9 text-secondary">{{ndcFile.property?.property_name}},
                {{ndcFile.property?.address_house_number}},
                {{ndcFile.property?.colony?.name}}, {{ndcFile.property?.ward?.name}}, {{ndcFile.property?.landmark}},
                {{ndcFile.property?.city?.name}}, {{ndcFile.property?.pincode}}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-3">Category</div>
              <div class="col-sm-9 text-secondary">{{propertyCategories[ndcFile.property.property_category] || ''}}
              </div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-3">Issued by</div>
              <div class="col-sm-9 text-secondary">{{ndcFile.department_name}}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-3">Issued on</div>
              <div class="col-sm-9 text-secondary">{{ndcFile.issued_at | date : 'dd-M-yyyy hh:mm a'}}</div>
            </div>
          </li>
        </ul>
      </fieldset>

    </div>
  </div>

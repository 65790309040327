import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import {BrowserModule} from "@angular/platform-browser";
import {AppRoutingModule} from "../app-routing.module";
import {MatDialogModule} from "@angular/material/dialog";
import {AuthenticationService} from "../services";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { PipesModule } from '../pipes/pipes.module';

@NgModule({
  declarations: [
    MainComponent
  ],
  imports: [
    MatDialogModule,
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    PipesModule
  ],
  providers:[
    AuthenticationService
  ]
})
export class LayoutsModule { }

<div class="container py-4">
    <div class="row">
      <div class="col-md-6">
  
        <fieldset class="border px-3">
          <legend class="w-auto">Payment Verification Status</legend>
  
          <div *ngIf="loading" class="spinner-border text-primary m-3" role="status">
            <span class="visually-hidden"></span>
          </div>
  
          <div *ngIf="!loading" class="mb-3 h5">
            <div *ngIf="payment && payment.status==PaymentStatuses.Success" class="text-success"><i class="bx bx-check-shield mr-1"></i>Payment Recieved</div>
            <div *ngIf="!payment" class="text-danger"><i class="bx bx-error-circle mr-1"></i>Transaction Not Found</div>
            <div *ngIf="payment && payment.status!=PaymentStatuses.Success" class="text-danger"><i class="bx bx-error-circle mr-1"></i>Payment Not Successfull</div>
          </div>
  
          <ul *ngIf="!loading && payment" class="list-group mb-3">
            <li class="list-group-item" [routerLink]="'/property/' + payment.property?.id" role="button">
              <div class="row">
                <div class="col-sm-3">Property UID</div>
                <div class="col-sm-9 text-secondary">
                  <abbr title="Open property page">{{payment.property?.property_uid}}</abbr>
                </div>
              </div>
            </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-sm-3">Transaction ID</div>
                <div class="col-sm-9 text-secondary">{{payment.transaction_id}}
                </div>
              </div>
            </li>
            <li class="list-group-item">
                <div class="row">
                  <div class="col-sm-3">Amount</div>
                  <div class="col-sm-9 text-secondary">{{payment.amount}}
                  </div>
                </div>
              </li>
            <li class="list-group-item">
              <div class="row">
                <div class="col-sm-3">Payment Status</div>
                <div class="col-sm-9 text-secondary"> <div
                    class="{{STATUSES_STYLES[payment.status]}}">{{Payment.PAYMENT_STATUSES[payment.status] | titlecase}}</div></div>
              </div>
            </li>
          </ul>

        </fieldset>
  
      </div>
    </div>
  
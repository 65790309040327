import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {propertyRouting} from "./property.routing";
import {PhotosSliderComponent} from './photos-slider/photos-slider.component';
import {MapComponent} from './map/map.component';
import {PropertyDetailsComponent} from './property-details/property-details.component';
import {OwnerDetailsComponent} from './owner-details/owner-details.component';
import {TaxDetailsComponent} from './tax-details/tax-details.component';
import {FloorsDetailsComponent} from './floors-details/floors-details.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowserModule} from "@angular/platform-browser";
import {AgmCoreModule, GoogleMapsAPIWrapper, PolygonManager} from "@agm/core";
import {
  BuildingUtilityInformationComponent
} from './building-utility-information/building-utility-information.component';
import {CommercialDetailsComponent} from './commercial-details/commercial-details.component';
import {PropertyTaxLedgerComponent} from './property-tax-ledger/property-tax-ledger.component';
import {AddressDetailsComponent} from "./address-details/address-details.component";
import {BuildingInformationComponent} from "./building-information/building-information.component";
import {NgxPrintModule} from "ngx-print";
import {FormsModule} from "@angular/forms";
import {
  TaxCalculationCurrentYearComponent
} from "./tax-calculation-current-year/tax-calculation-current-year.component";
import {PipesModule} from "../../pipes/pipes.module";
import { NdcRequestComponent } from './ndc-request/ndc-request.component';
import {DirectivesModule} from './../../directives/directives.module';
import { SharedModule } from 'src/app/shared-module/shared-module.module';
import { InformPaymentCreditComponent } from './inform-payment-credit/inform-payment-credit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreditRequestComponent } from './credit-request/credit-request.component';


@NgModule({
    declarations: [
        IndexComponent,
        PhotosSliderComponent,
        MapComponent,
        PropertyDetailsComponent,
        OwnerDetailsComponent,
        TaxDetailsComponent,
        TaxCalculationCurrentYearComponent,
        FloorsDetailsComponent,
        BuildingUtilityInformationComponent,
        CommercialDetailsComponent,
        PropertyTaxLedgerComponent,
        AddressDetailsComponent,
        BuildingInformationComponent,
        NdcRequestComponent,
        InformPaymentCreditComponent,
        CreditRequestComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        BrowserModule,
        propertyRouting,
        AgmCoreModule,
        NgxPrintModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        SharedModule,
        NgSelectModule,
    ],
    exports: [
        PropertyDetailsComponent,
        OwnerDetailsComponent
    ],
    providers: [
        GoogleMapsAPIWrapper,
        PolygonManager,
    ]
})
export class PropertyModule {
}

<div class="failure-payment">
  <div class="failure-payment-card" *ngIf="!loading; else loadingBlock">

    <h1 class="failure-payment__payment-amount">
      ₹ {{payment?.amount}}

      <svg xmlns="http://www.w3.org/2000/svg" class="failure-payment__success-icon" viewBox="0 0 24 24">
        <path
          d="M9.172 16.242 12 13.414l2.828 2.828 1.414-1.414L13.414 12l2.828-2.828-1.414-1.414L12 10.586 9.172 7.758 7.758 9.172 10.586 12l-2.828 2.828z"></path>
        <path
          d="M12 22c5.514 0 10-4.486 10-10S17.514 2 12 2 2 6.486 2 12s4.486 10 10 10zm0-18c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8 3.589-8 8-8z"></path>
      </svg>
    </h1>

    <h2 class="failure-payment__message">
      {{'property_search.payment_failure' | translate}}
    </h2>

    <div class="failure-payment__transaction-details">
      <p class="failure-payment__transaction-row">
        {{'property_search.transaction_id' | translate}} 
        <span class="failure-payment__transaction-id">
          {{transactionId}}
        </span>
      </p>
      <p class="failure-payment__transaction-date">
      </p>
    </div>

  </div>
</div>

<ng-template #loadingBlock>
  <div class="success-payment__loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'property_search.loading' | translate}}</span>
    </div>
  </div>
</ng-template>

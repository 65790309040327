import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NdcFormData } from 'src/app/models/ndc-request-form-data';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from 'src/app/services/language.service';
import { NdcRequestService } from 'src/app/services/ndc-request.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-ndc-request',
  templateUrl: './ndc-request.component.html',
  styleUrls: ['./ndc-request.component.scss']
})
export class NdcRequestComponent implements OnInit, OnDestroy{
  @Input() property_id!:number;

  showLoader = false;
  uploadedFiles: { name: string, path: string, url: string }[] = [];
  errors: { emailError: string, fileUploadError: string } = { emailError: '', fileUploadError: '' };
  formData: NdcFormData = new NdcFormData();
  showFileFormatError: boolean = false;
  readonly EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  readonly PDF_ICON_URL = "./../../../../assets/images/pdf_icon.svg";
  readonly ALLOWED_FILE_FORMATS = ['png', 'jpg', 'pdf']

  private destroy$ = new Subject();

  constructor(private httpService: HttpClient,
    private ndcRequestService: NdcRequestService,
    private modalService: NgbModal, private commonService:CommonService, public languageService:LanguageService) { }

  ngOnInit(): void {
    this.formData.property_id = this.property_id;
  }

  onSubmit(): void {
    let hasError = false;
    if (!this.formData.condition) {
      return;
    }

    this.errors = { emailError: '', fileUploadError: '' };
    if (!this.formData.email) {
      this.errors.emailError = this.languageService.translate('home.email_address_is_required');
      hasError = true;
    } else if (!this.EMAIL_REGEX.test(this.formData.email)) {
      this.errors.emailError =this.languageService.translate('home.invalid_email_address');
      hasError = true;
    }

    if (this.uploadedFiles.length < 1) {
      this.errors.fileUploadError = this.languageService.translate('home.upload_files');
      hasError = true;
    }

    if (hasError) {
      return;
    }
    
    this.createNdcRequest();


  }

  private createNdcRequest() {
    const formData = clone(this.formData);
    formData.proofs = this.uploadedFiles;
    this.showLoader = true;
    this.ndcRequestService.createNdcRequest(formData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res:any) => {
      this.showLoader = false;
      this.modalService.dismissAll();
      if(res && res.data.CreateNdcRequest) {
        this.commonService.showSuccessMessage('property_search.ndc_request_created');
      }else if(res?.errors?.[0]?.message) {
        Swal.fire('Error', res.errors[0].message, 'error');
      }

    }, (error:any) => {
      this.showLoader = false;
      this.commonService.showErrorMessage('home.something_went_wrong');
    })
  }

  afterFileUpload(fileData:any) {
    this.uploadedFiles.push(fileData);
  }

  removeFile(index:number) {
    this.uploadedFiles.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Ledger, Property} from '../../../models';
import {LedgerService} from '../../../services/ledger.service';
import {capitalize, cloneDeep} from 'lodash';
import TaxDetails from '../../../@types/tax-details';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {PropertyService} from '../../../services/property.service';
import {PropertyCategories} from '../../../enums/property-categories';
import { currentFinancialYear } from 'src/app/helpers/current-financial-year';

@Component({
  selector: 'app-tax-calculation-current-year',
  templateUrl: './tax-calculation-current-year.component.html',
  styleUrls: ['./tax-calculation-current-year.component.scss'],
})
export class TaxCalculationCurrentYearComponent implements OnInit {
  private destroy$ = new ReplaySubject();

  Property = Property;

  loading = true;
  /* Summary of tax in current year and  arrears for the last year */
  subTotalTax: number = 0;
  taxCalculation!: any;
  PropertyCategories = PropertyCategories;
  _ledgerData!:any;

  @Input('property') property!: Property;
  @Input() set ledgerData(data:any) {
    this._ledgerData = data;
    this.changeCurrentYearTaxIfMismatched();
  }

  constructor(
    private router: Router,
    private ledgerService: LedgerService,
    private propertyService: PropertyService,
  ) {
  }

  ngOnInit(): void {
    this.initTaxCheck();
  }

  capitalize(s: string) {
    return capitalize(s);
  }

  private initTaxCheck() {
    const successfulRes = (details: TaxDetails | undefined) => {
      if (!details) return;

      this.taxCalculation = cloneDeep(details);
      this.subTotalTax = details.propertyDebtInPastYear + details.taxInCurrentYear;
      this.changeCurrentYearTaxIfMismatched();
    };

    this.propertyService.taxCalculation(this.property)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        successfulRes,
        () => this.loading = false,
        () => this.loading = false,
      );
  }

  rate2000(rate: number) {
    return Math.fround(rate / 2000) ?? '';
  }

  /**
   * Change current year tax if current year tax and ledger amout doesn't match 
   */
  private changeCurrentYearTaxIfMismatched() {
    if(this.taxCalculation && this._ledgerData) {
      const currentYearTaxFromCalculation = this.taxCalculation?.taxCalculation[0]?.tax;
      const currentYearTaxFromLedger = this._ledgerData.filter((el:any) => el.financial_year === currentFinancialYear() && el.transaction_type === Ledger.TAX_BILL_TYPE)[0]?.debit_amount;
      if(currentYearTaxFromCalculation && currentYearTaxFromLedger && currentYearTaxFromCalculation !== currentYearTaxFromLedger) {
        this.taxCalculation.taxCalculation[0].tax = currentYearTaxFromLedger;
      }
    }
  }

}

<div class="crs-request-cont">
    <div class="modal-header">
        <div class="title">
            <h5>Documents Required to upload</h5>
        </div>
        <button class="close_btn" mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27"
            viewBox="0 0 37 37" fill="none" (click)="closeModal()">
            <rect width="37" height="37" rx="18.5" fill="#747474" fill-opacity="0.2" />
            <path d="M12 25L25 12" stroke="#232930" stroke-width="2" />
            <path d="M25 25L12 12" stroke="#232930" stroke-width="2" />
          </svg>
        </button>
    </div>
    <div class="modal-body">
        <div class="flex_container">
            <div class="flex_item">
                <label for="pattaIssuingOrg"
                    class="required-field">{{'patta_application.patta_current_status.patta_issuing_organization' |
                    translate}}</label>
                <ng-select id="pattaIssuingOrg"
                    placeholder="{{'patta_application.patta_current_status.patta_issuing_organization_placeholder' | translate}}"
                    [(ngModel)]="selectedPattaIssuingOrg"
                    [items]="pattaIssuingOrg" bindValue="id" bindLabel="name"
                    (change)="onPattaIssueSelectionChange()"
                    (clear)="onClearPattaIssueSelection()">
                </ng-select>
            </div>
            <div *ngIf="selectedPattaIssuingOrg " class="flex_item">
                <label for="schemeName"
                    class="required-field">Scheme Name</label>
                <ng-select 
                    id="schemeName"
                    placeholder="Select Scheme Name"
                    [(ngModel)]="selectedSchemeName"
                    [items]="schemeName" bindValue="id" bindLabel="name"
                    (clear)="onClearSchemeName()">
                </ng-select>
            </div>
            <div *ngIf="selectedSchemeName" class="flex_item">
                <label for="pattaIssuingOrg"
                    class="required-field">Allotment Type</label>
                <ng-select 
                    id="pattaIssuingOrg"
                    placeholder="Select Allotment Type"
                    [(ngModel)]="selectedAllottmentType"
                    [items]="allotmentType" bindValue="id" bindLabel="name">
                </ng-select>
            </div>
        </div>
        
        <table *ngIf="selectedAllottmentType" class="table table-bordered">
          <thead>
            <tr class="text-center">
              <th>Sr. No</th>
              <th>Document Name</th>
              <th>Mandatory/Optional</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let doc of requiredDocs; let i = index">
                <td class="text-center">{{i + 1}}</td>
                <td class="cell-padding">{{doc.name}}</td>
                <td class="text-center">
                    <span *ngIf="doc.mandatory" class="badge_theme mandatory">Mandatory*</span>
                    <span *ngIf="!doc.mandatory" class="badge_theme optional">Optional</span>
                </td>
            </tr>
          </tbody>
        </table>
    </div>
</div>



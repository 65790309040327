export enum GenderTypes {
  Female = 0,
  Male = 1,
  Transgender = 2
}

export const GenderTypesNames: Record<GenderTypes, string> = {
  [GenderTypes.Female]: 'Female',
  [GenderTypes.Male]: 'Male',
  [GenderTypes.Transgender]: 'Transgender',
}

export function getFormattedGenderTypes(): { id: GenderTypes, name: string }[] {
  return Object.entries(GenderTypesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}

export function getFormattedGenderTypesExcept(genderTypes: GenderTypes[]): { id: GenderTypes, name: string }[] {
  return Object.entries(GenderTypesNames).filter(el => !genderTypes.includes(+el[0])).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}



import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'dlc-rates', loadChildren: () => import('./pages/dlc-rates/dlc-rates.module').then(m => m.DlcRatesModule) },
  { path: 'patta-verification', loadChildren: () => import('./pages/patta-verification/patta-verification.module').then(m => m.PattaVerificationModule) },
  { path: 'contact-us', loadChildren: () => import('./pages/citizen-query/citizen-query.module').then(m => m.CitizenQueryModule) },
  { path: 'my-selfAssessment', loadChildren: () => import('./pages/my-self-assessment/my-self-assessment.module').then(m => m.MySelfAssessmentModule) },
  { path: 'patta-application', loadChildren: () => import('./pages/patta-application/patta-application.module').then(m => m.PattaApplicationModule) },
  { path: 'patta-application/details', loadChildren: () => import('./pages/patta-property-details/patta-property-details.module').then(m => m.PattaPropertyDetailsModule) }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Component, OnInit} from '@angular/core';
import {PropertyService} from "../../../services/property.service";
import {Property} from "../../../models";
import {ActivatedRoute} from "@angular/router";
import {Search} from "../../../@types";

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss'],
})
export class PropertiesListComponent implements OnInit {
  page = 1;
  perPage = 15;
  collectionTotal = 0;
  _grid = false;

  private _loading = false;

  properties: Property[] = [];
  private defaultProperties: Property[] = [];

  constructor(
    private currentRoute: ActivatedRoute,
    private propertyService: PropertyService
  ) {
    for (let i = 0; i < this.perPage; i++) {
      this.defaultProperties.push(new Property());
    }
  }

  ngOnInit(): void {
    this.update();
  }

  update() {
    this.currentRoute.queryParams.subscribe((queryParams) => {
      this.loading = true;

      this.propertyService.paginate(this.page, this.perPage, queryParams as Search).subscribe((result) => {
        this.loading = false;
        this.properties = result.data as Property[];
        this.collectionTotal = result.paginatorInfo.total;
      });
    })
  }

  get grid(): boolean {
    return this._grid;
  }

  set grid(value: boolean) {
    this._grid = value;
  }

  get loading () {
    return this._loading;
  }

  set loading (value) {
    this.properties = this.defaultProperties;
    this._loading = value;
  }
}

import {Component, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SignupComponent} from "../signup/signup.component";
import {OtpConfirmComponent} from "../otp-confirm/otp-confirm.component";
import {AuthenticationService} from "../../services";
import {CityService} from '../../services/city.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  error = '';

  user: any = {
    mobileNumber: "",
    rememberFlag: false,
  }
  recentDate: Date = new Date();
  expirationTime: Date = new Date();
  isLoading: boolean = false;

  constructor(
    public cs: CityService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<any>,
    public authenticationService: AuthenticationService
  ) {
  }

  ngOnInit(): void {
  }

  signIn() {
    this.error = '';
    this.isLoading =true;
    this.authenticationService.signInOTP(this.user.mobileNumber).subscribe(
      (otpToken) => {
        this.isLoading =false;
        if (!otpToken) return;

        this.dialogRef.close();
        this.dialog.open(OtpConfirmComponent, {
          data: {otpToken: otpToken, phoneNumber: this.user.mobileNumber, remember: this.user.rememberFlag},
        });
      },
      ((e) => {
        this.error = e.message;
        this.isLoading =false;
      })
    );
  }

  openSignUpModal() {
    this.dialogRef.close();
    this.dialog.open(SignupComponent);
  }
}

<div class="crs-request-cont">
    <div class="modal-header">
        <div class="title">
            <ng-container [ngSwitch]="data?.name">
                <h5 class="modal-title mb-2" *ngSwitchCase="'home.track_raised_objection_status_sub_menu'">Check Current Status of Raised Objection</h5>
                <h5 class="modal-title mb-2" *ngSwitchCase="'home.track_patta_raised_objection_status'">{{'widget.check_current_status_of_objection_raised_on_patta' | translate}}</h5>
                <h5 class="modal-title mb-2" *ngSwitchDefault>Check Current Status of Objection</h5>
            </ng-container>
            
        </div>
        <button class="close_btn" mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27"
            viewBox="0 0 37 37" fill="none" (click)="closeModal()">
            <rect width="37" height="37" rx="18.5" fill="#747474" fill-opacity="0.2" />
            <path d="M12 25L25 12" stroke="#232930" stroke-width="2" />
            <path d="M25 25L12 12" stroke="#232930" stroke-width="2" />
          </svg></button>
    </div>
    <div class="modal-body" *ngIf="!showLoader; else loadingBlock">
        <div>
            <p>{{objectionHeadingMessage.enter_objection_number}}</p>
            <section class="flex-container">
            <input type="text" class="form-control"
            [(ngModel)]="objectionNumber"
            [ngModelOptions]="{standalone: true}"
     >
        <button class="btn btn-primary btn-submit" [disabled]="!objectionNumber" (click)="getCorrectionRequestStatus(objectionNumber)">{{objectionHeadingMessage.submit}}</button>
        </section>
            <div class="invalid-tooltip" *ngIf="isCorrectionRequestExist == false">
                <span>
                    {{objectionHeadingMessage.correction_request_msg}}
                </span>
            </div>
            </div>
        <ng-container *ngIf="isCorrectionRequestExist">
            <div class="crs-card-title">{{objectionHeadingMessage.summary}}</div>
            <table class="table" role="presentation">
                <tbody>
                    <tr *ngFor="let detail of headings">
                        <td class="details-title">{{detail.name}} :</td>
                        <td [innerText]="getDetailsValue(detail.key)">{{correctionRequestDetails.objection_number}}
                        </td>
                    </tr>
                    <tr *ngIf="data.name === 'home.track_raised_objection_status_sub_menu'">
                        <td class="details-title">
                            <span class="details-title">Current Status:</span>
                         
                        </td>
                        <td style="color: white; ">
                            <span *ngIf="correctionRequestDetails.status === ObjectionStatuses.Pending" class="badge bg-primary">Pending</span>
                            <span *ngIf="correctionRequestDetails.status === ObjectionStatuses.UnderReview" class="badge bg-warning ms-1">Review</span>
                            <span *ngIf="correctionRequestDetails.status === ObjectionStatuses.MissingDoc" class="badge bg-info ms-1">Missing Documents</span>
                            <span *ngIf="correctionRequestDetails.status === ObjectionStatuses.Rejected" class="badge bg-danger ms-1">Rejected</span>
                            <span *ngIf="correctionRequestDetails.status === ObjectionStatuses.Accepted" class="badge bg-success ms-1">Accepted</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
       

    </div>
</div>

<ng-template #loadingBlock>
  <div class="loading__spinner">
    <div class="spinner-border" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

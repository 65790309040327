import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {NdcFile} from '../models/ndc-file';

@Injectable({
  providedIn: 'root'
})
export class NdcFileService {

  constructor(private apollo: Apollo) {
  }

  public getOne(urlKey: string): Observable<NdcFile | undefined> {
    return this.apollo.query<any>({
      query: gql`query NdcFileQuery($url_key: String!) {
          NdcFile(url_key: $url_key){
            property {
              id
              property_uid
              property_name
              address_house_number
              colony { id, name }
              ward { id, name }
              landmark
              city { id, name }
              pincode
              property_category
              owners { first_name, last_name }
            }
            certificate_number
            department_name
            issued_at
          }
        }`,
      variables: {
        url_key: urlKey
      }
    }).pipe(
      map((result: any) => result?.data?.NdcFile)
    );
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {propertyCorrectionRouting} from "./property-correction.routing";
import {WidgetsModule} from '../../widgets/widgets.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {IndexComponent} from './index/index.component';
import {FormComponent} from './form/form.component';
import {NgbDatepickerModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {GeneralInformationComponent} from './general-information/general-information.component';
import {VerticalTitleComponent} from './vertical-title/vertical-title.component';
import {DirectivesModule} from '../../directives/directives.module';
import {UtilityDetailsComponent} from './utility-details/utility-details.component';
import {MatRadioModule} from '@angular/material/radio';
import {OwnerDetailsComponent} from './owner-details/owner-details.component';
import {PopupCardComponent} from './popup-card/popup-card.component';
import {FloorDetailsComponent} from './floor-details/floor-details.component';
import {ReviewComponent} from './review/review.component';
import { DocumentProofsComponent } from './document-proofs/document-proofs.component';
import {NgxMaskModule} from 'ngx-mask';
import { PipesModule } from "../../pipes/pipes.module";


@NgModule({
    declarations: [
        IndexComponent,
        FormComponent,
        GeneralInformationComponent,
        VerticalTitleComponent,
        UtilityDetailsComponent,
        OwnerDetailsComponent,
        PopupCardComponent,
        FloorDetailsComponent,
        ReviewComponent,
        DocumentProofsComponent,
    ],
    imports: [
        CommonModule,
        propertyCorrectionRouting,
        WidgetsModule,
        NgSelectModule,
        FormsModule,
        NgbNavModule,
        NgbDatepickerModule,
        DirectivesModule,
        MatRadioModule,
        NgxMaskModule.forRoot(),
        PipesModule
    ]
})
export class PropertyCorrectionModule {
}

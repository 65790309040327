
<div  class="account-pages my-5 pt-5">
    <div  class="container">
        <div  class="row">
            <div  class="col-lg-12">
                <div  class="text-center mb-5">
                    <h1  class="display-2 fw-medium">404</h1>
                    <h4  class="text-uppercase">Sorry, page not found</h4>
                    <div  class="mt-5 text-center"><a  [routerLink]="'/'" class="btn btn-primary" ng-reflect-router-link="/" href="/">Back to HomePage</a></div>
                </div>
            </div>
        </div>
    </div>
</div>

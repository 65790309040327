import {MatDialog} from "@angular/material/dialog";

export class Button {
  name: string = '';
  route!: string;
  function: Function = () => {
  };
  href!: string;
  class!: string[];
  dialog!: MatDialog;
  children: Button[] = [];
  fragment!:string;
  fragmentRoute!:string;
  redirectionRoute!:string; // Navigate based on condition
  redirectionFn: Function = () => {

  }

  constructor(properties: any) {
    if (properties.name) {
      this.name = properties.name.toString();
    }
    if (properties.route) {
      this.route = properties.route.toString();
    }
    if (properties.function) {
      this.function = properties.function;
    }
    if (properties.href) {
      this.href = properties.href.toString();
    }
    if (properties.dialog) {
      this.dialog = properties.dialog;
    }
    if (properties.class) {
      this.class = properties.class;
    }
    if (properties.class) {
      this.class = properties.class;
    }
    if (properties.children) {
      this.class = properties.children.forEach((button: Button) => this.children.push(button));
    }
    if(properties.fragment) {
      this.fragment = properties.fragment;
    }

    if(properties.fragmentRoute) {
      this.fragmentRoute = properties.fragmentRoute;
    }

    if(properties.redirectionFn) {
      this.redirectionFn = properties.redirectionFn;
    }

    if(properties.redirectionRoute) {
      this.redirectionRoute = properties.redirectionRoute;
    }
  }
}

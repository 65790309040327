import {District} from "./district";

export class City {
  id!: number;
  district = new District();
  name!: string;
  description!: string;
  gps_coordinates!: { latitude: number, longitude: number }[];
  gps_compressed_coordinates!: string;
  created_at:  Date = new Date();
  updated_at:  Date = new Date();
}

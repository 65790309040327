import { Component, OnInit } from '@angular/core';
import { CityService } from './services/city.service';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  favIcon: any = document.querySelector('#favIcon')
  lang!: string;
  constructor(public cs: CityService,
    public languageService: LanguageService) {
    var path = 'assets/images/' + cs.city + '/favicon.ico';
    this.favIcon.href = path;
  }
}






import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {gql} from '@apollo/client/core';
import {Ledger, Property} from '../models';
import TaxDetails from '../@types/tax-details';
import {GraphQLConditions} from '../@types/graphql-conditions';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class LedgerService {

  constructor(private apollo: Apollo) {
  }

  ledgersByProperty(propertyID: number): Observable<Ledger[]> {
    return this.apollo.query({
      query: gql`{ Ledgers: ${LedgerService.ledgersByPropertyQuery(propertyID)} }`,
      fetchPolicy: 'network-only',
    }).pipe(
      map((result: any) => result.data && result.data.Ledgers),
      map((result: any) => result ?? []),
    );
  }

  taxDetails(propertyId: number): Observable<TaxDetails | undefined> {
    return this.apollo.query({
      query: this.taxDetailsQuery(propertyId),
      fetchPolicy: 'no-cache',
    }).pipe(
      map(
        (res: any) => res?.data?.TaxDetails
          ? TaxDetails.create(res?.data?.TaxDetails)
          : undefined,
      ),
    );
  }

  getAll(where: () => string): Observable<Ledger[]> {
    return this.apollo
      .query({
        query: gql`{ ${LedgerService.getAllQuery({columns: this.needleColumns(), where: where()})} }`,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((result: any) => result.data && result.data.Ledgers),
        map((result: any) => result ?? []),
      );
  }

  ledgersNewProperty(property: Property): Observable<{ledgers: Ledger[], penalties: Ledger[], rebates: Ledger[]}> {
    const floorsDetails = property.floors?.reduce((acc, floor) => acc + ` {
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? 0}
        property_sub_type_id: ${floor.propertySubType?.id ?? 0}
        property_category: ${floor.property_category ?? 0}
        from_year: ${floor.from_year ?? 0}
        upto_year: ${floor.upto_year ?? 0}
    }`, '');

    const ownersDetails = property.owners?.reduce((acc, owner) => acc + ` {
      first_name: "${owner.first_name}"
      last_name: "${owner.last_name}"
      gender: ${owner.gender}
      email: "${owner.email}"
    }`, '');

    return this.apollo.query({
      query: gql`{ details: LedgersNewProperty(
      property: {
        ${property.property_category ? `property_category :${property.property_category}` : ``}
        ${property.property_type_id ? `property_type_id :${property.property_type_id}` : ``}
        ${property.property_sub_type_id ? `property_sub_type_id :${property.property_sub_type_id}` : ``}
        ${property.city?.id ? `city_id: ${property.city?.id}` : ''}
        ${(property.colony?.id ?? property.colony_id) ? `colony_id: ${property.colony?.id ?? property.colony_id}` : ''}
        ${property.multi ? `multi: ${property.multi}` : ''}
        ${property.building_construction_year ? `building_construction_year: ${property.building_construction_year}` : ''}
        ${property.plot_area ? `plot_area: ${property.plot_area}` : ''}        
        ${(property.vacant_area || property.vacant_area === 0) ? `vacant_area: ${property.vacant_area}` : ''}
        ${property.road_width ? `road_width: ${property.road_width}` : ''}        
        floors: [ ${floorsDetails} ]
        owners: [ ${ownersDetails} ]
      }
    )   }`,
      fetchPolicy: "no-cache"
    }).pipe(
      map((res: any) => res.data.details)
    )
  }

  static getAllQuery(conditions: GraphQLConditions) {
    return ` Ledgers(${conditions?.where ?? ''}, ${conditions?.orderBy ?? ''}) {
          ${conditions.columns}
        }
    `;
  }

  static ledgersByPropertyQuery(propertyID: number = 0) {
    return `
      Ledgers(
        orderBy: [{column: "transaction_datetime", order: ASC }, {column: "id", order: ASC }],
        where: {
          HAS: {
            relation: "property",
            condition: {
              column: ID,
              operator: LIKE,
              value: ${propertyID}
            }
          }
        }
      ) {
          id,
          transaction_datetime,
          payment{id, payment_date, payment_method, amount, user{id, first_name, last_name, gender, phone_number, email}, },
          property { id, vacant_area, property_category, plot_area},
          financial_year,
          transaction_type,
          debit_amount,
          credit_amount,
          balance,
          remarks,
          created_at,
          updated_at,
      }
    `;
  }

  private taxDetailsQuery(propertyId: number) {
    return gql`{TaxDetails(property_id: ${propertyId}) {
        penalty {
          transaction_datetime
          property_id
          property_uid
          financial_year
          transaction_type
          debit_amount
          credit_amount
          balance
          remarks
          created_at
          updated_at
        }
        totalPenaltyAmount,
        availableRebates {
          transaction_datetime
          property_id
          property_uid
          financial_year
          transaction_type
          debit_amount
          credit_amount
          rebate_type
          balance
          remarks
          created_at
          updated_at
        }
        ledgersInCurrentYear {
          id,
          financial_year,
          transaction_type,
          debit_amount,
          credit_amount,
          balance
        },
        taxInCurrentYear,
        propertyDebt,
        propertyDebtInPastYear,
        propertyDebtForFinancialYears {
          id,
          financial_year,
          transaction_type,
          debit_amount,
          credit_amount,
          balance
        },
      }}`;
  }

  needleColumns() {
    return `
      id,
      transaction_datetime,
      payment {
        id,
        payment_date
        amount
        transaction_id,
        payment_source,
        payment_method,
        status,
        user{
          first_name,
          last_name
        }
        paymentCheque { payer_name, payer_email, payer_mobile}
        paymentCash { payer_name, payer_email, payer_mobile}
        paymentDemandDraft { payer_name, payer_email, payer_mobile}
      },
      property {
        id
        property_uid
      },
      financial_year,
      property_uid,
      transaction_type,
      debit_amount,
      credit_amount,
      balance,
      remarks,
      created_at
    `;
  }

  ledgersSelfAssessmentProperty(selfAssessment: any): Observable<{ledgers: Ledger[], penalties: Ledger[], rebates: Ledger[]}> {
    const floorsDetails = selfAssessment.floor_details?.reduce((acc: any, floor: any) => acc + ` {
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? 0}
        property_sub_type_id: ${floor.propertySubType?.id ?? 0}
        property_category: ${floor.property_category ?? 0}
        from_year: ${floor.from_year ?? 0}
        upto_year: ${floor.upto_year ?? 0}
    }`, '');

    const ownersDetails = selfAssessment.owner_details?.reduce((acc: any, owner: any) => acc + ` {
      first_name: "${owner.first_name}"
      last_name: "${owner.last_name}"
      gender: ${owner.gender}
      email: "${owner.email}"
    }`, '');

    return this.apollo.query({
      query: gql`{ details: LedgersNewProperty(
      property: {
        ${selfAssessment.property_details.city?.id ? `city_id: ${selfAssessment.property_details.city?.id}` : ''}
        ${(selfAssessment.property_details.colony?.id ?? selfAssessment.property_details.colony_id) ? `colony_id: ${selfAssessment.property_details.colony?.id ?? selfAssessment.property_details.colony_id}` : ''}
        ${selfAssessment.property_details.multi ? `multi: ${selfAssessment.property_details.multi}` : ''}
        ${selfAssessment.property_details.building_construction_year ? `building_construction_year: ${selfAssessment.property_details.building_construction_year}` : ''}
        ${selfAssessment.property_details.plot_area ? `plot_area: ${selfAssessment.property_details.plot_area}` : ''}        
        ${selfAssessment.property_details.vacant_area ? `vacant_area: ${selfAssessment.property_details.vacant_area}` : ''}
        ${selfAssessment.property_details.road_width ? `road_width: ${selfAssessment.property_details.road_width}` : ''}        
        floors: [ ${floorsDetails} ]
        owners: [ ${ownersDetails} ]
      }
    )   }`,
      fetchPolicy: "no-cache",
      errorPolicy: 'all'
    }).pipe(
      map((res: any) => {
        if(res?.errors && res.errors[0]?.message) {
          Swal.fire('Error', res.errors[0].message, 'error');
          return {};
        }
        return res?.data?.details || {};
      })
    )
  }
}

import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {DocumentProofType} from '../models';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentProofTypeService {

  constructor(
    private apollo: Apollo
  ) {
  }

  getAll(): Observable<DocumentProofType[]> {
    return this.apollo.watchQuery({
      query: gql` { items: DocumentProofTypes { id name } }`
    }).valueChanges.pipe(
      map((result: any) => result?.data?.items ?? []),
    );
  }
}

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {myPropertiesRouting} from "./my-properties.routing";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    myPropertiesRouting,
    NgbModule,
    FormsModule
  ]
})
export class MyPropertiesModule { }

import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {Property} from '../models';
import {gql} from '@apollo/client/core';
import {Observable} from 'rxjs';
import {PropertyTaxCalculation} from '../@types/tax-calculation';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TaxCalculationService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  getTaxCalculationByProperty(property: Property): Observable<PropertyTaxCalculation | undefined> {
    return this.apollo.query({
      query: gql`{details: ${TaxCalculationService.getTaxCalculationByPropertyQuery(property)}}`,
      fetchPolicy: 'no-cache',
    }).pipe(map((res: any) => {
        if (!res?.data?.details || typeof res?.data?.details !== 'object') return undefined;
        const details = res?.data?.details;

        return Array.isArray(details.taxCalculation) && typeof details.totalTax === 'string' ? details : undefined;
      }),
    );
  }

  static getTaxCalculationByPropertyQuery(property: Property) {
    const floorsDetails = property.floors?.reduce((acc, floor) => acc + ` {
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? 0}
        property_sub_type_id: ${floor.propertySubType?.id ?? 0}
        property_category: ${floor.property_category ?? 0}
        from_year: ${floor.from_year ?? 0}
        upto_year: ${floor.upto_year ?? 0}
        floor_number :  ${floor.floor_number}
    }`, '');

    return `TaxCalculationForProperty(
      property: {
        ${property.property_category ? `property_category: ${property.property_category}` : ''}
        ${property.property_type_id ? `property_type_id: ${property.property_type_id}`:''}
        ${property.property_sub_type_id ? `property_sub_type_id: ${property.property_sub_type_id}`:''}
        ${property.road_width ? `road_width: ${property.road_width}` : ''}
        ${property.plot_area ? `plot_area: ${property.plot_area}` : ''}
        ${property.city?.id ? `city_id: ${property.city?.id}` : ''}
        ${property.vacant_area ? `vacant_area: ${property.vacant_area}` : ''}
        ${property.road_location ? `road_location: "${property.road_location}"` : ''}
        ${property.sub_divided_plots ? `sub_divided_plots: ${property.sub_divided_plots}` : ''}
        ${property.multi !== undefined || property.multi !== null ? `multi: ${property.multi ? 'true' : 'false'}` : ''}
        ${(property.colony_id ?? property.colony?.id ) ? `colony_id: ${property.colony_id ?? property.colony?.id}` : ''}
        floors: [ ${floorsDetails} ]
      }
    ) {
        taxCalculation {
          propertyCategory
          areaType
          area
          rate
          tax
        }
        totalTax
    }`;
  }
  getTaxCalculationByPropertyForSelfAssessment(selfAssessment: any): Observable<PropertyTaxCalculation | undefined> {
    return this.apollo.query({
      query: gql`{details: ${TaxCalculationService.getTaxCalculationByPropertyQueryForSelfAssessment(selfAssessment.property_details,selfAssessment.floor_details)}}`,
    }).pipe(map((res: any) => {
        if (!res?.data?.details || typeof res?.data?.details !== 'object') return undefined;
        const details = res?.data?.details;

        return Array.isArray(details.taxCalculation) && typeof details.totalTax === 'string' ? details : undefined;
      }),
    );
  }
  static getTaxCalculationByPropertyQueryForSelfAssessment(property: any, floors : any) {
    const floorsDetails = floors?.reduce((acc : any, floor : any) => acc + ` {
        carpet_area: ${floor.carpet_area ?? 0}
        property_type_id: ${floor.propertyType?.id ?? floor.property_type_id}
        property_sub_type_id: ${floor.propertySubType?.id ?? floor.property_sub_type_id}
        property_category: ${floor.property_category ?? 0}
        upto_year: ${floor.upto_year ?? 0}
        from_year: ${floor.from_year ?? 0}
        floor_number :  ${floor.floor_number}
   }`, '');

    return `TaxCalculationForProperty(
      property: {
        property_category: ${property.property_category}
        property_type_id: ${property.property_type_id ?? property.propertyType?.id}
        property_sub_type_id: ${property.property_sub_type_id ?? property.propertySubType?.id}
        road_width: ${property.road_width}
        plot_area: ${property.plot_area}
        city_id: ${property.city.id ?? property.city_id}
        vacant_area: ${property.vacant_area}
        road_location: "${property.road_location}"
        colony_id: ${property.colony_id ?? property.colony?.id}
        floors: [ ${floorsDetails} ]
      }
    ) {
        taxCalculation {
          propertyCategory
          areaType
          area
          rate
          roadLocation
          tax
        }
        exempted
        totalTax
    }`;
  }
}

import {Citizen, Floor, Property} from '../../../models';
import {ValidationRules} from '../../../@types/validation';

export const PropertyValidationRules: { [T in keyof Property | string]?: ValidationRules } = {
  property_name: 'max:40',
  address_house_number: 'required|min:1|max:10',
  // nearby_houseno: 'required|min:1|max:5',
  landmark: 'required|min:1',
  pincode: 'required|min:6|max:6',
  // property_category: 'required',
  // landuse_change: 'required',
  // sub_divided_plots: 'required',
  // property_usage_id: 'required',
  // construction_type_id: 'required',
  // building_permission: 'required',
  building_construction_year: `required|numeric|min:1900|max:${new Date().getFullYear()}`,
  plot_allotment_year: `nullable|numeric|min:1900|max:${new Date().getFullYear()}`,
  // length: 'required|numeric',
  // breadth: 'required|numeric',
  plot_area: 'required|numeric',
};

export const OwnerValidationRules: { [T in keyof Citizen | string]?: ValidationRules } = {
  fullName: 'required|min:2|max:40',
  gender: 'required',
  // relation: 'required',
  // relation_name: 'required',
  // phone_number: 'required|string|size:10',
  email: 'nullable|email',
  uid_number: 'required_with:document_proof_type_id',
};

export const OwnerEditValidationRules: { [T in keyof Citizen | string]?: ValidationRules } = {
  fullName: 'required|min:2|max:40',
  gender: 'required',
  // relation: 'required',
  // relation_name: 'required',
  phone_number: 'required|min:10',
  // email: 'nullable|email',
  // uid_number: 'required_with:document_proof_type_id',
};

export const FloorValidationRules: { [T in keyof Floor | string]?: ValidationRules } = {
  floor_number: 'required',
  carpet_area: 'required',
  property_construction_type_id: 'required',
  // propertyType: 'required',
  // propertySubType: 'required',
  // property_usage_id: 'required',
  floor_construction_year: 'required|integer|min:1900|max:2100',
  // no_of_toilet: 'required|integer|max:20',
  // no_of_room: 'required|integer|max:20',
};

export const FloorDetailsValidationRules: { [T in keyof Floor | string]?: ValidationRules } = {
  floor_number: 'required',
  property_category: 'required',
  propertyType: 'required',
  propertySubType: 'required',
  from_year: 'required',
  upto_year: 'required',
};

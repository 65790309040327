<div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)"
     *ngIf="!loading; else loadingBlock">
  <h4 class="card-title" style="padding: 18px 0 0 16px">{{'property_search.required_proofs' | translate}}</h4>
  <table class="table responsive-flex-table">
    <thead>
    <th>{{'property_search.document_proof' | translate}}</th>
    <th width="25%">{{'property_search.proof_type' | translate}}</th>
    <th>{{'property_search.upload_document' | translate}}</th>
    <th>{{'property_search.attached_file' | translate}}</th>
    <th>{{'property_search.action' | translate}}</th>
    </thead>
    <tbody>
    <tr *ngFor="let documentProof of DOCUMENTS_PROOFS; let i = index">
      <td>{{documentProof.proofType.name}}<span class="text-danger" *ngIf="documentProof.mandatory">*</span></td>
      <td>
        <ng-select bindLabel="name"
                   bindValue="id"
                   [items]="documentProof.documentProofs"
                   [ngModel]="documentProof.selectedDocumentProof"
                   (ngModelChange)="selectDocumentProof($event, i)"
                   [searchable]="false"
                   placeholder="{{'property_search.select_document_proof' | translate}}"
        ></ng-select>
      </td>
      <td>
        <div class="position-relative">
          <button class="btn bg-success text-white" [disabled]="isFileUploading">
            {{'property_search.upload' | translate}} 
          </button>
          <input type="file" accept=".pdf,.png,.jpeg"
                 class="form-control mr-2 upload-input"
                 aria-label="Upload"
                 (change)="onFileChange($event, i)"
                 [disabled]="isFileUploading"
          >
        </div>
      </td>
      <td class="attached-doc">{{documentProof.fileName}}</td>
      <td>
        <i class="bi bi-trash-fill" style="font-size: 20px; cursor: pointer"
           (click)="clearSelectedFile(i)"
        ></i>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<ng-template #loadingBlock>
  <div class="loading" style="height: 75vh;width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">{{'property_search.loading' | translate}}</span>
    </div>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import {Apollo, gql} from 'apollo-angular';
import {map} from 'rxjs/operators';
import {from, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Payment} from '../models';
import {AuthenticationService} from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private apollo: Apollo
  ) {
  }

  paginateForCurrentUser(perPage: number, page: number, fields: string, where?: string): Observable<{ data: Payment[], total: number } | undefined> {
    const id = this.authService.user?.id;

    if (!id) return from([]);

    const whereCondition = where ? `where: ${where}` : '';

    return this.apollo.query({
      query: gql`{
        UserPaymentsPaginate(
            user_id: ${id},
            first: ${perPage},
            page: ${page},
            ${whereCondition},
            orderBy: {column: "payment_date", order: DESC },
        ) { data { ${fields} }, paginatorInfo { total } }
      }`,
      fetchPolicy: 'no-cache',
    }).pipe(
      map(
        (res: any) => res?.data?.UserPaymentsPaginate?.data
          ? {data: res.data.UserPaymentsPaginate.data, total: res.data.UserPaymentsPaginate.paginatorInfo.total}
          : undefined
      ),
    );
  }

  getOneByTransactionId(transactionId: number, fields: string): Observable<Payment | undefined> {
    return this.apollo.query({
      query: gql`{PaymentByTransactionId(transaction_id: "${transactionId}") { ${fields} } }`
    }).pipe(
      map((res: any) => res?.data?.PaymentByTransactionId),
      map((res: any) => res ? Object.assign(new Payment(), res) : undefined)
    );
  }

  sendReceiptEmail(transactionId: string): Observable<boolean> {
    return this.http.get(environment.apiBaseUrl + 'email/payment/' + transactionId).pipe(
      map((res: any) => res?.status)
    )
  }

  getFormToken(propertyId: string): Observable<string | undefined> {
    return this.apollo.query({
      query: gql`{token: GetFormToken(propertyId: ${propertyId})}`
    }).pipe(
      map((res: any) => res?.data?.token)
    )
  }

  receiptHtml(transactionId: string): Observable<string> {
    return this.http.get(`${environment.apiBaseUrl}payment/${transactionId}/view`, {responseType: 'text'}).pipe(
      map(res => res.toString())
    )
  }
}

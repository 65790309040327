<div class="table-hold text-ledger" id="tax-ledger">
  <table class="table table-bordered th-bg responsive-flex-table">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.property_tax_ledger' | translate}}
        <span class="btn-collapse-table mt-6" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
        </span>
        <span class="print-icon print-icon_header-table btn-collapse-table">
          <button printSectionId="tax-ledger" ngxPrint [useExistingCss]="true">
            <i class="bi bi-printer"></i>
          </button>
        </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <!--  -->
    <table class="table table-bordered th-bg ledger_property pos-rel">
      <thead class="tax-main-head">
     
        <th>{{'property_search.financial_year' | translate}}</th>
        <th>{{'property_search.date' | translate}}</th>
        <th>{{'property_search.particulars' | translate}}</th>
        <th>{{'property_search.debit' | translate}}</th>
        <th>{{'property_search.credit' | translate}}</th>
        <th>{{'property_search.balance' | translate}}</th>
      
      </thead>
      <tbody *ngFor="let item of items; let i = index">
      <tr class="ledger-item" >
        <td data-th="Financial Year:">
          {{ item.financial_year }}-{{
          item.financial_year && item.financial_year + 1
          }}
        </td>
        <td data-th="Date:">
          {{ item?.transaction_datetime | date: "dd-MM-yyyy hh:mm:ss" }}
        </td>
        <td data-th="Particulars:">
          {{ formatParticulars(item) }}
        </td>
        <td data-th="Debit:">{{ item?.debit_amount }}</td>
        <td data-th="Credit:">{{ item?.credit_amount }}</td>
        <td data-th="Balance:" #balance>{{ item | rebateBalanceCalculator : i }}</td>
      </tr>
      <tr class="collabs-section" *ngIf="isRowOpened(i)">
        <td colspan="8" class="collabs-td">
          <div class="yr-details-main">
            <div class="payment-overview">
              <table class="table table-bordered th-bg">
                <thead>
                <tr>
                  <th colspan="2">{{'property_search.payment_overview' | translate}}</th>
                </tr>
                </thead>
                <tr>
                  <td>
                    {{'property_search.invoice_date' | translate}}<br/><strong>{{
                    item?.transaction_datetime | date: "d-M-y"
                    }}</strong>
                  </td>
                  <td>
                    {{'property_search.paid_by' | translate}}
                    <br/>
                    <strong>
                      {{ item?.payment?.user?.first_name ?? "" }} {{ item?.payment?.user?.last_name ?? "" }}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td>
                    {{'property_search.amount_paid' | translate}}<br/><strong class="text-primary">{{
                    item.credit_amount
                    }}</strong>
                  </td>
                  <td>
                    <button class="btn btn-primary" [routerLink]="'/invoice/1'">
                      {{'property_search.view_invoice' | translate}}
                    </button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </td>
      </tr>
      </tbody>
      <tr class="ledger-item" *ngIf="penalty">
        <td colspan="2">
          {{'property_search.penalty' | translate}}
        </td>
        <td data-th="Particulars:"></td>
        <td data-th="Debit:">{{ penalty?.debit_amount | number:'1.2'}}</td>
        <td data-th="Credit:">{{ penalty?.credit_amount | number:'1.2'}}</td>
        <td data-th="Balance:" #balance>{{ penalty | rebateBalanceCalculator | number:'1.2'}}</td>
      </tr>

      <tr *ngFor="let rebate of rebates; let i = index" class="ledger-item">
        <td colspan="2">
          {{formatParticulars(rebate)}}
        </td>
        <td data-th="Particulars:"></td>
        <td data-th="Debit:">{{ rebate?.debit_amount }}</td>
        <td data-th="Credit:">{{ rebate?.credit_amount }}</td>
        <td data-th="Balance:" #balance>{{ rebate | rebateBalanceCalculator | number:'1.2'}}</td>
      </tr>

      <tr>
        <td colspan="2"><strong>{{'property_search.closing_balance' | translate}}</strong></td>
        <td colspan="3"></td>
        <td><strong>{{ balanceCells?.last?.nativeElement.textContent }}</strong></td>
      </tr>
    </table>
  </div>
</div>


<ng-template #arrowUp>
  <span class="btn-collapse-table mt-6">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-mobile-links',
  templateUrl: './mobile-links.component.html',
  styleUrls: ['./mobile-links.component.scss']
})
export class MobileLinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { Component, OnInit } from '@angular/core';
import { Property } from 'src/app/models';

@Component({
  selector: 'app-recently-viewed-property',
  templateUrl: './recently-viewed-property.component.html',
  styleUrls: ['./recently-viewed-property.component.scss']
})
export class RecentlyViewedPropertyComponent implements OnInit {
  page = 1;
  perPage = 10;
  collectionTotal = 0;
  _grid = false;
  private _loading = false;
  properties: Property[] = [];
  isRecentlyViewedAvailable! : boolean;

  constructor( ) { }

  ngOnInit(): void {
    this.loading = true;
    let propertyArrStr :string | null; 
    propertyArrStr = localStorage.getItem('propertyArr');
    if(propertyArrStr != null){
      this.loading = false;
      this.isRecentlyViewedAvailable = true;
      this.properties = JSON.parse(propertyArrStr) 
    }else{
      this.isRecentlyViewedAvailable = false;
    }
  }
  get grid(): boolean {
    return this._grid;
  }

  set grid(value: boolean) {
    this._grid = value;
  }

  get loading () {
    return this._loading;
  }

  set loading (value) {
    this._loading = value;
  }

}

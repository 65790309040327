import {ConfigService} from '../services/config.service';

const START_MONTH_OF_FINANCIAL_YEAR = 4;

export function currentFinancialYear() {
  return ConfigService.getValue('financial_year') ?? currentFinancialYearByDate(new Date());
}

export function currentFinancialYearByDate(currentDate: Date) {
  return currentDate.getMonth() < START_MONTH_OF_FINANCIAL_YEAR ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
}

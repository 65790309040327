import { Component, OnInit, Inject, Renderer2 } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Photo } from 'src/app/models';
import { CommonService } from 'src/app/services/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-photo-viewer-dialog',
  templateUrl: './photo-viewer-dialog.component.html',
  styleUrls: ['./photo-viewer-dialog.component.scss']
})
export class PhotoViewerDialogComponent implements OnInit {
  photos!: Photo[] | any;
  photoIndex!: number;
  currentFile!: { name: string, path: string };
  currentPhotoIndex!: number;
  rotateDegree: number = 90;
  rotateFlag: boolean = false; 
  viewerOpen = false;
  imageId = 'img'
  viewUrl!: string;
  constructor(
    public dialogRef: MatDialogRef<PhotoViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    public commonService: CommonService
  ) { 
    this.photos = this.data.photos;
    this.photoIndex = this.data.photoIndex;
  }

  ngOnInit(): void {
    this.currentFile = {
      name: this.photos[this.photoIndex]?.photoType?.name || '',
      path: this.photos[this.photoIndex]?.url || this.photos[this.photoIndex]?.path,
    };
    this.currentPhotoIndex = this.photoIndex;
    this.viewUrl = `${environment.apiBaseUrl}${this.currentFile.path}`;
  }
  
  slidePhoto(index: number) {
    this.currentPhotoIndex = this.changePhotoIndex(index);
    this.currentFile = {
      name: this.photos[this.currentPhotoIndex]?.photoType?.name || '',
      path: this.photos[this.currentPhotoIndex]?.url || this.photos[this.currentPhotoIndex]?.path,
    };
    this.viewUrl = `${environment.apiBaseUrl}${this.currentFile.path}`;
    this.refreshRotateDegree();
  }

  changePhotoIndex(index: number) {
    return index < 0 ? this.photos.length - 1 : index > this.photos.length - 1 ? 0 : index;
  }

  refreshRotateDegree() {
    this.rotateDegree = 0;
    setTimeout(() => this.rotate(), 0);
  }

  rotate() {
    const image: HTMLImageElement = document.querySelector('#image') as HTMLImageElement;
    const container = document.querySelector('#container');

    this.rotateFlag = this.rotateDegree % 180 != 0;

    const height = this.rotateDegree % 180 == 0 ? image.height : image.width;

    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.rotateDegree}deg)`,
    );

    this.renderer.setStyle(
      container,
      'height',
      `${height}px`,
    );

    this.rotateDegree += 90;
  }
  
  close() {
    this.dialogRef.close();
  }

}

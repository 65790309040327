import {Component, Input, OnInit} from '@angular/core';
import {Property} from "../../../models";

@Component({
  selector: 'app-commercial-details',
  templateUrl: './commercial-details.component.html',
  styleUrls: ['./commercial-details.component.scss']
})
export class CommercialDetailsComponent implements OnInit {

  Property = Property;
  isCollapsed: boolean = true;

  BUSINESS_TYPE: { [key: number]: string } = {
    1: 'Proprietorship',
    2: 'Partnership',
    3: 'Corporation',
    4: 'Limited Liability Company',
    5: 'LLC',
  };

  @Input('property') property!: Property;

  constructor() { }

  ngOnInit(): void {
  }

  yesNoNothing(value?: boolean) {
    if (value === null || value === undefined) return '';

    return value ? 'Yes' : 'No';
  }
}

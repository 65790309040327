<div *ngIf="open" class="viewer-container">
    <div class="viewer-content">
      <a *ngIf = "showRotate" class="rotate-viewer" (click)="rotateImage()">
        <i class="bi bi-arrow-clockwise close-button"></i>
      </a>
      <a class="close-viewer" (click)="close.emit()">
        <i class="bi bi-x-circle close-button"></i>
      </a>
      <a class="zoom_in" (click)="zoomIn()">
        <i class="bi bi-zoom-in close-button"></i>
      </a>
      <a class="zoom_out" (click)="zoomOut()">
        <i class="bi bi-zoom-out close-button"></i>
      </a>
      <div class="img_container" cdkDrag>
        <img class="main-img" [src]="imageURL" id="{{id}}" style="cursor:grab;" [ngStyle]="{ transform: 'rotate('+ rotationAmount + 'deg) scale(' + scale + ')' }"/>
      </div>
    </div>
  </div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable(
    { providedIn: 'root' }
)
export class LanguageService {
    constructor(private http: HttpClient) { }
    selectedLang!: string;
    defaultLang = localStorage.getItem('current-language') || 'en';
    currentLanguage = new BehaviorSubject(this.selectedLang);
    currentLangData = {};
    allLangData: any = {};

    load(langCode = this.defaultLang) {
        if (this.allLangData[langCode]) {
            this.setLangData(langCode, this.allLangData[langCode]);
            return;
        }
        this.http.get(`./assets/languages/${langCode}.json`)
            .toPromise()
            .then(data => {
                this.setLangData(langCode, data);
                this.allLangData[langCode] = data;
            });
    }

    changeLang(langCode: string) {
        this.defaultLang = langCode;
        this.load(langCode);
        localStorage.setItem('current-language', langCode);
    }

    translate(keys: string):string {
        const keysArr = keys.split(".");
        let value: any = this.currentLangData;
        keysArr.forEach(el => {
            value = value[el.trim()];
        })
        return value || keys;
    }

    private setLangData(langCode: string, langData: any) {
        this.selectedLang = langCode;
        this.currentLangData = langData;
        this.currentLanguage.next(langCode);
    }
}

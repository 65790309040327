<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.property_details' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg  property-details">
      <tbody>
      <tr>
        <th width="20%">{{'property_search.property_uid_service_no' | translate}}</th>
        <td width="40%">{{property.property_uid}}</td>
        <th>{{'property_search.property_category' | translate}}</th>
        <td>{{Property.PROPERTY_CATEGORIES[property.property_category]}}</td>
      </tr>
      <tr>
        <th>{{'property_search.property_type' | translate}}</th>
        <td>{{property.propertyType?.name}}</td>
        <th>{{'property_search.property_sub_type' | translate}}</th>
        <td>{{property.propertySubType?.name}}</td>
      </tr>

      <tr>
        <th>{{'property_search.property_usage' | translate}}</th>
        <td>{{property.property_usage_id && Property.PROPERTY_USAGES[property.property_usage_id]}}</td>
        <th>{{'property_search.land_use_change' | translate}}</th>
        <td>{{ yesNoNothing(property.landuse_change) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.old_property_id' | translate}}</th>
        <td>{{property?.old_house_tax_no ?? ''}}</td>
        <th>{{'property_search.length_in_ft' | translate}}</th>
        <td>{{property?.length}}</td>
      </tr>

      <tr>
        <th>{{'property_search.breadth_in_ft' | translate}}</th>
        <td>{{property?.breadth}}</td>
        <th>{{'property_search.plot_area_in_sqyd' | translate}}</th>
        <td>{{property?.plot_area}}</td>
      </tr>

      <tr>
        <th>{{'property_search.total_built_up_area_in_sqft' | translate}}</th>
        <td>{{property?.totalCarpetAreaCY ?? ''}}</td>
        <th>{{'property_search.plinth_area_in_sqyd' | translate}}</th>
        <td>{{property?.plinth_area}}</td>
      </tr>

      <tr>
        <th>{{'property_search.rebate' | translate}}</th>
        <td>{{property.rebate?.name}}</td>
        <th>{{'property_search.multi_story_commercial_complex' | translate}}</th>
        <td>{{ yesNoNothing(property.multi) }}</td>
      </tr>

      <tr>
        <th>{{'property_search.vacant_area_in_sqyd' | translate}}</th>
        <td>{{property?.vacant_area}}</td>
        <th>{{'property_search.exemption' | translate}}</th>
        <td>{{property?.exemption}}</td>
      </tr>

      <tr>
        <th>{{'property_search.ownership_type' | translate}}</th>
        <td>{{property?.ownershipType?.name}}</td>
        <th>{{'property_search.road_width_inft' | translate}}</th>
        <td>{{property?.road_width}}</td>
      </tr>
      <tr>
        <th>{{'property_search.sub_divided_plots' | translate}}</th>
        <td>{{ yesNoNothing(property.sub_divided_plots) }}</td>
        <ng-container *ngIf="property.sub_divided_plots">
          <th>{{'property_search.year_of_subdivision' | translate}}</th>
          <td>{{property.subdivision_year}}</td>
        </ng-container>
      </tr>
        <tr *ngIf="property.sub_divided_plots">
          <th>{{'property_search.parent_id' | translate}}</th>
          <td>{{property.parent_property_uid}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>


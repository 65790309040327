import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";
import {SuccessPaymentComponent} from './success-payment/success-payment.component';
import {FailurePaymentComponent} from './failure-payment/failure-payment.component';
import {ViewComponent} from './view/view.component';

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
          {
            path: 'payment/:id',
            component: IndexComponent
          },
          {
            path: 'payment/:transactionId/success',
            component: SuccessPaymentComponent,
          },
          {
            path: 'payment/:transactionId/failure',
            component: FailurePaymentComponent,
          },
          {
            path: 'payment/:transactionId/view',
            component: ViewComponent,
          }
        ]
    },
];

export const paymentRouting = RouterModule.forChild(routes);

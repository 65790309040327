export function getFinancialYears(from: number, to: number) {
  const rotation = from > to ? -1 : 1;
  const diff = Math.abs(from - to);
  const years = [];

  for (let i = 0; i <= diff; i++) {
    years.push(from + (i * rotation))
  }

  return years;
}

<div class="mb-4" *ngIf="colonies && businessTypes; else loadingBlock">
  <!-- Location Start -->
  <div class="row mb-4">
    <div class="col-md-3 col-12">
      <app-vertical-title title="{{'property_search.location_&_address_information'| translate}}"
                          subTitle="{{'property_search.current_location_information_of_the_property'| translate}}"
      ></app-vertical-title>
    </div>
    <div class="col-md-9 col-12">
      <div class="row mb-4">
        <div class="col-6">
          <label for="property-name">{{'property_search.property_complex_name'| translate}}</label>
          <input id="property-name" type="text" class="form-control" placeholder="{{'property_search.enter_property_name'| translate}}"
                 [(ngModel)]="property.property_name"
                 appInputError
                 [(error)]="errors.property_name"
          >
        </div>
        <div class="col-6">
          <label for="old-tax-uid" class="w-100">
            <div class="d-flex align-items-center justify-content-between">
              <p class="m-0 p-0">{{'property_search.old_property_id_service_no'| translate}}</p>
              <div *ngIf="isLoadingOldProperty">
                <div class="spinner-border text-primary" role="status" style="width: 15px; height: 15px">
                  <span class="sr-only">{{'property_search.loading'| translate}}</span>
                </div>
              </div>

              <div *ngIf="!isLoadingOldProperty && property.old_house_tax_no && !isOldPropertyUnderFocus">
                <p *ngIf="isOldPropertyFound" class="m-0 p-0 d-flex align-items-center">
                  <i class="bx bxs-check-circle" style="color:#42d414; font-size: 22px"></i>
                </p>
                <p *ngIf="!isOldPropertyFound" class="m-0 p-0 d-flex align-items-center">
                  <i class="bx bxs-x-circle d-flex align-items-center"
                     style="color:#d41417; margin-right: 5px; font-size: 22px"
                  ></i>
                  {{'property_search.not_found'| translate}}
                </p>
              </div>
            </div>
          </label>
          <input id="old-tax-uid" type="text" class="form-control" placeholder="{{'property_search.enter_old_tax_id_service_no'| translate}}"
                 [(ngModel)]="property.old_house_tax_no"
                 (focus)="isOldPropertyUnderFocus = true"
                 (blur)="findOldProperty(property.old_house_tax_no)"
                 appInputError
                 [(error)]="errors.old_house_tax_no"
          >
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-md-6 col-12">
          <label for="house-number">{{'property_search.plot_house_shop_no'| translate}}<span class="text-danger">*</span></label>
          <input id="house-number" type="text" class="form-control" placeholder="{{'property_search.enter_house_number'| translate}}"
                 [(ngModel)]="property.address_house_number"
                 appInputError
                 [(error)]="errors.address_house_number"
          >
        </div>
       
       
      </div>

     

      <div class="row mb-4">
        <div class="col-6">
          <label for="ward">{{'property_search.ward'| translate}}<span class="text-danger">*</span></label>
         
          <input disabled id="ward" type="text" class="form-control"
                 placeholder="{{'property_search.enter_ward'| translate}}"
                 [(ngModel)]="property.ward.name"
          >
        </div>
        <div class="col-6">
          <label for="colony">{{'property_search.colony'| translate}}<span class="text-danger">*</span>
            <span *ngIf="property.colony?.id && property?.colony?.establishment_year">
              ( {{'property_search.establishment_year'| translate}} : {{property?.colony?.establishment_year}} )
            </span></label>
          <app-typeahead-input
                  id="colony"
                  [printField]="'name'"
                  [loadItems]="loadColonies"
                  [currentItem]="property.colony"
                  (currentItemChange)="property.colony = $any($event)"
                  appInputError
                  [(error)]="errors.colony_id"
          ></app-typeahead-input>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-6">
          <label for="landmark">{{'property_search.landmark'| translate}}<span class="text-danger">*</span></label>
          <input id="landmark" type="text" class="form-control" placeholder="{{'property_search.enter_landmark'| translate}}"
                 [(ngModel)]="property.landmark"
                 appInputError
                 [(error)]="errors.landmark"
          >
        </div>

        <div class="col-6">
          <label for="pin-code">{{'property_search.pin_code'| translate}}<span class="text-danger">*</span></label>
          <input id="pin-code" type="text" class="form-control" placeholder="{{'property_search.enter_pin_code'| translate}}"
                 [(ngModel)]="property.pincode"
                 appInputError
                 [(error)]="errors.pincode"
                 mask="000000"
          >
        </div>
      </div>
    </div>

  </div>
  <!-- Location End -->

  <hr>


  <!-- Building Information Start -->
  <div class="row mb-4">
    <div class="col-md-3 col-12">
      <app-vertical-title title="{{'property_search.building_information'| translate}}"
      subTitle="{{'property_search.current_building_information_of_the_property'| translate}}">
    </app-vertical-title>
 
    </div>

    <div class="col-md-9 col-12">


      <div class="row mb-4">
        <div class="col-md-6 col-12">
          <label for="road-width">{{'property_search.road_width_in_ft'| translate}}</label>
          <input disabled id="road-width" type="text" class="form-control" placeholder="{{'property_search.enter_road_width'| translate}}"
                 [(ngModel)]="property.road_width"
                 appInputError
                 [(error)]="errors.road_width"
          >
        </div>

        <div class="col-md-6 col-12">
          <label for="approximate-year-of-building-construction">{{'property_search.approximate_year_of_building_construction'| translate}}<span
                  class="text-danger">*</span></label>
          <input id="approximate-year-of-building-construction" type="text" class="form-control"
                 placeholder="{{'property_search.enter_approximate_year_of_building_construction'| translate}}"
                 [(ngModel)]="property.building_construction_year"
                 appInputError
                 [(error)]="errors.building_construction_year"
                 mask="0000"
          >
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-12">
         
          <label>{{'property_search.multi_storey_apartment_complex'| translate}}</label>
          <ng-select  
            bindLabel = "name"
            bindValue = "value"
            [(ngModel)] = "property.multi">
           <ng-option [value] = "true">{{'home.yes'| translate}}</ng-option>
           <ng-option [value] = "false">{{'home.no'| translate}}</ng-option>
          </ng-select>
        </div>
        <div class="col-md-6 col-sm-12 mmb-4">
          <label for="plot_allotment_year">{{'property_search.plot_allotment_year'| translate}}</label>
          <input id="plot_allotment_year" type="text" class="form-control"
                 placeholder="{{'property_search.enter_plot_allotment_year'| translate}}"
                 [(ngModel)]="property.plot_allotment_year"
                 appInputError
                 [(error)]="errors.plot_allotment_year"
                 mask="0000"
          >
        </div>
       
      </div>
    </div>

  </div>
  <!-- Building Information End -->

  <hr>

  <!-- Area Information Start -->
  <div class="row">
    <div class="col-md-3 col-12">
      <app-vertical-title title="{{'property_search.area_information'| translate}}"
                          subTitle="{{'property_search.current_area_information_of_the_property'| translate}}"
      ></app-vertical-title>
    </div>

    <div class="col-md-9 col-12">

      <div class="row ">
        <div class="col-12">
          <label for="plot-area">{{'property_search.plot_area_in_sqyd'| translate}}<span class="text-danger">*</span></label>
          <input id="plot-area" type="text" class="form-control" placeholder="{{'property_search.enter_plot_area'| translate}}"
                 [(ngModel)]="property.plot_area"
                 appInputError
                 [(error)]="errors.plot_area"
          >
        </div>
      </div>
    </div>

  </div>
  <!-- Area Information End -->

</div>


<div class="d-flex justify-content-end">
  <button class="btn btn-primary" (click)="nextForm()">{{'property_search.next'| translate}}</button>
</div>

<ng-template #loadingBlock>
  <div class="loader__general">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'property_search.loading'| translate}}</span>
    </div>
  </div>
</ng-template>

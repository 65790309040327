import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {MapComponent} from './map/map.component';

const routes: Routes = [
    {
        path: 'properties',
        component: MainComponent,
        children: [
          {
            path: 'map',
            component: MapComponent
          },
        ]
    },
];

export const propertiesMapRouting = RouterModule.forChild(routes);

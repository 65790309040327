import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { NdcFormData } from '../models/ndc-request-form-data';


@Injectable({
    providedIn: 'root'
})

export class NdcRequestService {
    constructor(private apollo: Apollo) {
    }

    public createNdcRequest(formData:NdcFormData): any {
        return this.apollo.mutate({
            mutation: this.createNdcRequestQuery(formData),
            errorPolicy: 'all'
        })
    }

    private createNdcRequestQuery(formData:NdcFormData) {
        return gql`mutation {
            CreateNdcRequest(property_id: ${formData.property_id}, email: "${formData.email}", proofs: ${this.proofsQuery(formData.proofs)})
          }`
    }

    private proofsQuery(proofs:any[]) {
        let proofQuery = '';
        proofs.forEach(el => {
            proofQuery += `{path: "${el.path}", name: "${el.name}"}`;
        })
        return `[${proofQuery}]`;
    }
}
<div class="mb-4">
  <div class="row mb-4 mmb-4 d-flex align-items-end justify-content-between">
    <div class="col-7 col-sm-6 col-lg-5 col-xl-4">
      <label for="ownership-type">Ownership Type<span class="text-danger">*</span></label>
      <ng-select id="ownership-type" placeholder="Select Ownership Type"
                 [(ngModel)]="property.ownershipType"
                 [compareWith]="ownershipTypeCompareFn"
      >+
        <ng-option *ngFor="let ownershipType of OWNERSHIP_TYPES" [value]="ownershipType">
          {{ownershipType.name}}
        </ng-option>
      </ng-select>
    </div>
    <div class="col-5 col-lg-4 d-flex justify-content-end">
      <button class="btn btn-primary" (click)="addOwner(ownerFormModal)" [disabled]="disableAddButton()">Add</button>
    </div>
  </div>
  <!-- Owner Details End -->

  <div class="card mmb-4">
    <div class="card-body">
      <div class="card-title mb-4" style="font-size: 16px"><strong>Owner Listing</strong></div>
      <div class="table-responsive" *ngIf="property?.owners?.length && property.owners.length > 0; else noOwners">

        <table class="table responsive-flex-table">
          <thead>
          <th>#</th>
          <th>Owner Name</th>
          <th>Gender</th>
          <th>Phone Number</th>
          <th>Relation</th>
          <th>Relative Name</th>
          <th width="80px">Actions</th>
          </thead>
          <tbody *ngIf="property?.owners?.length && property.owners.length > 0; else noOwners">
          <tr *ngFor="let owner of property.owners; let i = index">
            <td>{{i + 1}}</td>
            <td>{{(owner.first_name ?? '') + ' ' + (owner.last_name ?? '')}}</td>
            <td>{{GENDER_TYPES[owner.gender]?.name }}</td>
            <td>{{owner.phone_number}}</td>
            <td>{{CITIZEN_RELATION[owner.relation] ?? ''}}</td>
            <td>{{owner.relation_name ?? ''}}</td>
            <td width="80px">
              <div class="d-flex justify-content-between mob_align_right" style="font-size: 18px">
                <i class="bi bi-pencil-fill mr-2 text-primary" style="cursor: pointer"
                   (click)="editOwner(ownerFormModal, owner)"></i>
                <i class="bi bi-x-lg text-danger" style="cursor: pointer" (click)="deleteOwner(i)"></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">Previous</button>

  <button class="btn btn-primary" (click)="nextForm()">Next</button>
</div>

<!-- Owner Modal -->
<ng-template #ownerFormModal let-modal>
  <div class="modal-header">
    <div class="title">
      <h5 class="modal-title mb-2">Owner Details</h5>
      <h6 class="modal-sub-title">Fill required information</h6>
      <i class="fa fa-close fa-times" (click)="modal.dismiss();"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="first-name">First Name <span class="text-danger">*</span></label>
        <input id="first-name" class="form-control"
               placeholder="Enter First Name"
               [(ngModel)]="formOwner.first_name"
               appInputError
               [(error)]="ownerFormErrors.fullName"
        >
      </div>
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="last-name">Last Name<span class="text-danger">*</span></label>
        <input id="last-name" class="form-control"
               placeholder="Enter Last Name"
               [(ngModel)]="formOwner.last_name"
               appInputError
               [(error)]="ownerFormErrors.fullName"
        >
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="relation-type">Relation Type<span class="text-danger" *ngIf = "formOwner.relation_name?.trim()">*</span></label>
        <ng-select id="relation-type"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="Select Relation Type"
                   [items]="RELATION_TYPES"
                   [(ngModel)]="formOwner.relation"
                   appInputError
                   [(error)]="ownerFormErrors.relation"
                   
        ></ng-select>
        <!-- <ng-select id="relation-type"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="Select Relation Type"
                   [items]="RELATION_TYPES"
                   [(ngModel)]="formOwner.relation"
                   appInputError
                   [(error)]="ownerFormErrors.relation"
                   [disabled]="!formOwner.relation_name?.trim()"
        ></ng-select> -->
      </div>
      <div class="col-md-6 col-sm-12 mmb-4">
        <label for="relation-name">Relative Name</label>
        <input id="relation-name" class="form-control"
               placeholder="Enter Relation Name"
               [(ngModel)]="formOwner.relation_name"
        >
      </div>
      
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="gender">Gender<span class="text-danger">*</span></label>
        <ng-select id="gender"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="Select Gender"
                   [items]="GENDER_TYPES"
                   [(ngModel)]="formOwner.gender"
                   appInputError
                   [(error)]="ownerFormErrors.gender"
        ></ng-select>
      </div>
    </div>

    <div class="row mb-4 mmb-4">
      <div class="col-12">
        <label for="phone-number">Phone Number<span class="text-danger">*</span></label>
        <input id="phone-number" class="form-control" [(ngModel)]="formOwner.phone_number"
               placeholder="Enter Phone Number"
               appInputError
               maxlength="10"
               oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
               [(error)]="ownerFormErrors.phone_number"
               mask="0*"
        >
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="ownerSubmit(modal)">Submit</button>
  </div>
</ng-template>

<!-- No owners -->
<ng-template #noOwners>
  <!-- <tbody>
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">No owners</td>
  </tr>
  </tbody> -->
  <p class="no_data">No owners</p>
</ng-template>

<div class="container">

    <!-- Start breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page"><a [routerLink]="'/'">Payment</a></li>
            <li class="breadcrumb-item active" aria-current="page">Payment Receipt</li>
        </ol>
    </nav>
    <!-- End breadcrumb -->

    <!-- Start Receipt Buttons -->
    <div class="receipt-title w-100 d-flex justify-content-between">
        <h2>Payment Receipt</h2>
        <div class="receipt-buttons">
            <button type="button" class="btn btn-sm btn-outline-secondary"><i class="bi bi-file-earmark-pdf"></i>
                Download</button>
            <button type="button" class="btn btn-sm btn-outline-secondary"><i class="bi bi-printer"></i> Print</button>
            <button type="button" class="btn btn-sm btn-outline-secondary"><i class="bi bi-envelope"></i> Mail</button>
        </div>
    </div>
    <!-- Start Receipt Buttons -->



    <div class="invoice-receipt">
        <!-- Start Receipt Header -->
        <div class="w-100 invoice-header">
            <div class="row justify-content-between align-items-end">
                <div class="col-lg-4 col-md-5 col-sm-5 invoice-logo">
                    <img src="assets/images/invoice-logo.png" alt="MCJS">
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 text-right transaction-id">
                    <h2>Transaction ID: <span class="text-primary">12345678</span></h2>
                </div>
            </div>
        </div>

        <!-- Start Receipt Header -->

        <!-- Start Sub Header Details -->
        <div class="sub-header">
            <div class="row justify-content-between mb-2">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <strong>Property Service No.:</strong> <span>098754357</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                    <strong>Payment Mode:</strong> <span>Card Payment</span>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                    <strong>Invoice Date:</strong> <span>05 July 2021</span>
                </div>

                <div class="col-lg-6 col-md-6 col-sm-6 col-6 text-right">
                    <strong>Transaction Status:</strong> <span class="text-success"><strong> Successfull</strong></span>
                </div>
            </div>
        </div>
        <!-- End Sub Header Details -->

        <!-- Start Invoice Details -->
        <div class="table-hold invoice-table">
            <table class="table table-bordered th-bg">
                <tr>
                    <th colspan="6">Invoice Details</th>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>Rajesh Kumar</td>

                    <td>Mobile Number</td>
                    <td>8197595073</td>

                    <td>Email Address</td>
                    <td>adb@gmail.com</td>
                </tr>
                <tr>
                    <td>Property Category</td>
                    <td>Residential</td>

                    <td>Property Usage</td>
                    <td>Residential</td>

                    <td>Property Type</td>
                    <td>Residential</td>
                </tr>
                <tr>
                    <td>Total Area (SQ.FT)</td>
                    <td>34553.52</td>

                    <td class="address-title">Address</td>
                    <td colspan="3" class="address-value">House No 1233 , Colony 234, Ward no 10, ector 34 , District 9, Rajasthan, PIN :
                        856645</td>
                </tr>
            </table>
        </div>
        <!-- End Invoice Details -->

        <!-- Start Payment Details -->
        <div class="table-hold payment-details">
            <table class="table table-bordered th-bg">
                <thead>
                    <tr>
                        <th width="75%">Payment Details</th>
                        <th width="25%">Amount in <span class="rupes-icon"> &#x20b9; </span></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Tax Amount</td>
                        <td>14453</td>
                    </tr>
                    <tr>
                        <td>Penalty</td>
                        <td class="text-danger">+1445</td>
                    </tr>

                    <tr>
                        <td>100% Rebate Penalty</td>
                        <td class="text-success">-1445</td>
                    </tr>

                    <tr>
                        <td><strong>1% Bank Charges</strong></td>
                        <td class="text-danger">+144.53</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td><strong>Total Amount</strong></td>
                        <td>14597.53</td>
                    </tr>
                    <tr>
                        <td><strong>Paid Amount</strong></td>
                        <td class="text-primary">10,000.00</td>
                    </tr>
                    <tr>
                        <td><strong>Balance Tax Amount</strong></td>
                        <td>4597.53</td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <!-- End Payment Details -->
    </div>


</div>
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  readonly PDF_ICON_URL = "./../../../../assets/images/pdf_icon.svg";
  readonly ALLOWED_FILE_FORMATS = ['png', 'jpg', 'jpeg', 'pdf'];
  document_type_id!: string;
  @Input() label!:string;
  @Input() description!:string;
  @Input() uploadApiUrl!:string;
  @Input() id!:string;
  @Input() isRequired: boolean = false;
  @Input() 
  set reset(value:any) {
    this.uploadedFiles = [];
  }
  @Input() maxFileSize!:number;
  @Output() onFileUpload = new EventEmitter();
  @Output() removeFile = new EventEmitter();

  showFileFormatError!: boolean;
  showLoader!: boolean;
  uploadedFiles: any = [];
  errorMessage!:string;

  constructor(private httpService: HttpClient) {

  }
  
  ngOnInit(): void {
  }

  onFileSelection(event: any) {
    this.errorMessage = "";
    const selectedFile = event?.target?.files;
    if(this.maxFileSize && selectedFile[0]?.size/1024/1024 > this.maxFileSize) {
      this.errorMessage = `File size should not be more than ${this.maxFileSize} MB`;
      return;
    }
    this.uploadFile(selectedFile);
  }

  uploadFile(selectedFile: any) {
    this.showFileFormatError = false;
    if (!this.isFileFormatAllowed(selectedFile)) {
      this.errorMessage = `Only ${this.ALLOWED_FILE_FORMATS.join(', ')} files are allowed`;
      return;
    }
    selectedFile = selectedFile[0];
    const fd = new FormData();
    if(this.id) {
    this.document_type_id = this.id === 'attachments' ? '30' : '31';
    fd.append('document_type_id', this.document_type_id);
   }
    fd.append('file', selectedFile, selectedFile.name);
    this.showLoader = true;
    this.httpService.post<any>(environment.apiBaseUrl + this.uploadApiUrl, fd).subscribe(
      (res) => {
        const fileUrl = selectedFile.type === 'application/pdf' ? this.PDF_ICON_URL : `${environment.apiBaseUrl}${res?.data.path}`
        this.onFileUpload.emit({ name: selectedFile.name, document_type_id: this.document_type_id, path: res?.data.path, url: fileUrl, id: this.id });
        this.uploadedFiles.push({ name: selectedFile.name, document_type_id: this.document_type_id, path: res?.data.path, url: fileUrl, id: this.id });
        this.showLoader = false;
      },
      (error: any) => {
        this.showLoader = false;
        Swal.fire('Error', 'Something went wrong', 'error');
      },
    );
  }

  removeImage(index: number, ) {
    this.uploadedFiles.splice(index, 1);
    this.removeFile.emit({index: index, id: this.id});
  }

  private isFileFormatAllowed(files: any[]): boolean {
    files = Array.from(files);
    return files.every((file:any) => {
      const format = file.type.split('/')[1] || '';
      return this.ALLOWED_FILE_FORMATS.includes(format);
    })
  }

}

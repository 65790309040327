<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.address_details' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg  property-details">
      <tbody>
      <tr>
        <th>{{'property_search.property_name' | translate}}</th>
        <td>{{property.property_name}}</td>
        <th>{{'property_search.house_number' | translate}}</th>
        <td>{{property.address_house_number}}</td>
      </tr>

      <tr>
        <th>{{'property_search.adjacent_house_number' | translate}}</th>
        <td>{{property.nearby_houseno}}</td>
        <th>{{'property_search.address_line_2' | translate}}</th>
        <td>{{property.address_line2}}</td>
      </tr>

      <tr>
        <th>{{'property_search.sector' | translate}}</th>
        <td>{{property.sector?.name}}</td>
        <th>{{'property_search.ward' | translate}}</th>
        <td>{{property.ward?.name}}</td>
      </tr>

      <tr>
        <th>{{'property_search.street_road_name' | translate}}</th>
        <td>{{property.street?.name}}</td>
        <th>{{'property_search.colony' | translate}}</th>
        <td>{{property.colony?.name}} {{property?.colony?.establishment_year ? '( Establishment Year '+ commonService.getYearValueAsFY(property.colony.establishment_year, true)+ ' )' : '' }}</td>
      </tr>

      <tr>
        <th>{{'property_search.pin_code' | translate}}</th>
        <td>{{property.pincode}}</td>
        <th>{{'property_search.society' | translate}}</th>
        <td>{{property.society?.name}}</td>
      </tr>

      <tr>
        <th>{{'property_search.district_name' | translate}}:</th>
        <td>{{property.district?.name}}</td>
        <th>{{'property_search.address_landmark' | translate}}</th>
        <td>{{property.landmark}}</td>
      </tr>

      <tr>
        <th>{{'property_search.location_main_road' | translate}}</th>
        <td>{{property.road_location}}</td>
        <th>{{'property_search.state_name' | translate}}</th>
        <td>{{property.district.state.name}}</td>
      </tr>

      <tr>
        <th>{{'property_search.city_town_name' | translate}}</th>
        <td>{{property.city?.name}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>


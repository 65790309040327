import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Citizen, Floor, Ledger, Property} from '../../../models';
import {
  FieldResolvers, FLOOR_FIELD_RESOLVERS,
  OWNER_FIELD_RESOLVERS,
  PROPERTY_FIELD_NAME_RESOLVERS,
  PROPERTY_FIELD_RESOLVERS,
} from '../data/field-resolvers';
import {isEqual, uniq} from 'lodash';
import {TaxCalculationService} from '../../../services/tax-calculation.service';
import {PropertyTaxCalculation} from '../../../@types/tax-calculation';
import {
  FieldChange,
  PropertyCorrectionRequest,
  ModelChanges, TableChanges,
  ModelFieldsChanges, UploadedProofs,
} from '../../../@types/property-correction';
import {Apollo} from 'apollo-angular';
import {LedgerService} from '../../../services/ledger.service';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';
import { currentFinancialYear } from 'src/app/helpers/current-financial-year';
import { CommonService } from 'src/app/services/common.service';

enum ModelChangesTypes {
  Old = 1,
  Updated = 2,
  Deleted = 3,
  New = 4,
}

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {

  @Input() isSubmitting = false;

  @Input() oldIdProperty!: Property;
  @Input() oldProperty!: Property;
  @Input() updatedProperty!: Property;
  @Input() oldPropertyId: string | undefined;

  @Output('submit') private submitEmitter = new EventEmitter<PropertyCorrectionRequest>();

  isAgree = false;
  isValidateRemark: boolean = false;
  citizenRemark!: string;
  isTaxCalculationLoading = true;
  currentTaxCalculation!: PropertyTaxCalculation;
  updatedTaxCalculation!: PropertyTaxCalculation;
  differentPropertyKeys: (keyof Property)[] = [];
  ownersChanges: ModelChanges<Citizen> = {old: [], updated: [], deleted: [], new: []};
  floorsChanges: ModelChanges<Floor> = {old: [], updated: [], deleted: [], new: []};
  ledgers: Ledger[] = [];

  readonly GROUND_FLOOR: number = 0;
  readonly PROPERTY_FIELD_RESOLVERS = PROPERTY_FIELD_RESOLVERS;
  readonly PROPERTY_FIELD_NAME_RESOLVERS = PROPERTY_FIELD_NAME_RESOLVERS;
  readonly OWNER_RELATIONS = Citizen.RELATION;
  readonly FLOOR_NUMBER_NAMES = Floor.FLOOR_NUMBER;
  readonly PROPERTY_CATEGORIES = Property.PROPERTY_CATEGORIES;
  readonly PROPERTY_USAGES = Property.PROPERTY_USAGES;
  readonly MODEL_CHANGE_TYPE_NAMES: Record<ModelChangesTypes, string> = {
    [ModelChangesTypes.Old]: '',
    [ModelChangesTypes.Updated]: 'updated',
    [ModelChangesTypes.Deleted]: 'deleted',
    [ModelChangesTypes.New]: 'new',
  };
  readonly MODEL_CHANGE_TYPE_CLASSES: Record<ModelChangesTypes, string> = {
    [ModelChangesTypes.Old]: '',
    [ModelChangesTypes.Updated]: 'updated-alert',
    [ModelChangesTypes.Deleted]: 'deleted-alert',
    [ModelChangesTypes.New]: 'new-alert',
  };

  readonly PROPERTYIES_TO_BE_COMPARED_WITH_ID = [
    'propertyType',
    'propertySubType'
  ]
  readonly LEDGER_TYPE_NAMES = Ledger.LEDGER_TYPES;

  readonly GENDER_TYPES = getFormattedGenderTypes();

  private uploadedProofs: UploadedProofs | undefined;

  oldTaxIdChanged!: boolean;

  @Output('previousForm') previousFormEmitter = new EventEmitter();
  showOwnerDetailsTable!: boolean;
  showFloorDetailsTable!: boolean;

  constructor(
    private apollo: Apollo,
    private taxCalculationService: TaxCalculationService,
    private ledgerService: LedgerService,
    private commonService :CommonService
  ) {
  }

  ngOnInit(): void {
    this.differentPropertyKeys = this.getDifferentKeys(
      PROPERTY_FIELD_RESOLVERS,
      this.oldProperty,
      this.updatedProperty,
    );

    this.oldTaxIdChanged = this.checkOldTaxId();
    this.ownersChanges = this.getModelChanges(this.oldProperty.owners, this.updatedProperty.owners, 'id');
    this.floorsChanges = this.getModelChanges(this.oldProperty.floors, this.updatedProperty.floors, 'id');

    this.initLedgers();

    this.loadCurrentTaxCalculation();
    this.loadUpdatedTaxCalculation();
    if(this.floorsChanges.updated?.length || this.floorsChanges.deleted?.length || this.floorsChanges.new?.length) {
      this.calculateVacantArea();
    }

    this.showOwnerDetailsTable = this.hasChanges(this.ownersChanges);
    this.showFloorDetailsTable = this.hasChanges(this.floorsChanges);
  }

  private hasChanges(data:any):boolean {
    return data['new'].length || data['updated'].length || data['deleted'].length
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  loadCurrentTaxCalculation() {
    this.taxCalculationService.getTaxCalculationByProperty(this.oldProperty).subscribe(
      (taxCalculation) => {
        this.isTaxCalculationLoading = false;
        if (!taxCalculation) return;

        this.currentTaxCalculation = taxCalculation;
      },
      () => this.isTaxCalculationLoading = false,
    );
  }

  loadUpdatedTaxCalculation() {
    this.taxCalculationService.getTaxCalculationByProperty(this.updatedProperty).subscribe(
      (taxCalculation) => {
        this.isTaxCalculationLoading = false;
        if (!taxCalculation) return;

        this.updatedTaxCalculation = taxCalculation;
      },
      () => this.isTaxCalculationLoading = false,
    );
  }

  getLedgerTableName() {
    return this.oldPropertyId && this.oldPropertyId !== this.oldProperty.old_house_tax_no
      ? 'Ledger data for service number'
      : 'Current Ledger data';
  }

  submit() {
    if (!this.uploadedProofs) {
      this.commonService.showErrorMessage('property_search.upload_all_proofs_and_select_their_proof_types');
      return;
    }

    this.submitEmitter.emit({
      proofs: this.uploadedProofs,
      citizenRemark: this.citizenRemark,
      propertyChanges: this.getFieldChanges(PROPERTY_FIELD_RESOLVERS, this.differentPropertyKeys, this.updatedProperty),
      floorsChanges: this.getFloorsChangesRequest(),
      ownersChanges: this.getOwnersChangesRequest(),
    });
  }

  getDifferentKeys<T extends object>(fieldResolvers: FieldResolvers<T>, oldModel: T, updatedModel: T): (keyof T)[] {
    return uniq([...Object.keys(oldModel), ...Object.keys(updatedModel)]).filter((k) => {
      const resolver = (fieldResolvers as any)[k];
      if(this.PROPERTYIES_TO_BE_COMPARED_WITH_ID.includes(k)) {
        return (oldModel as any)[k]?.id !== (updatedModel as any)[k]?.id;
      }
      return resolver && resolver?.forDisplay(oldModel) !== resolver?.forDisplay(updatedModel);
    }) as unknown as (keyof T)[];
  }

  getNormalizedModelChanges<T>(changes: ModelChanges<T>): { changeType: ModelChangesTypes, item: T }[] {
    return [
      ...changes.updated.map((c) => ({changeType: ModelChangesTypes.Updated, item: c})),
      ...changes.deleted.map((c) => ({changeType: ModelChangesTypes.Deleted, item: c})),
      ...changes.new.map((c) => ({changeType: ModelChangesTypes.New, item: c})),
    ];
  }

  getFieldChanges<T extends object>(fieldResolver: FieldResolvers<T>, fields: (keyof T)[], model: T): FieldChange<T>[] {
    const changes: FieldChange<T>[] = [];

    fields.forEach((k: keyof T) => {
      const res = fieldResolver[k]?.forRequest(model);

      if (!res) return;

      changes.push({
        field: res[0] as any,
        new_value: res[1],
      });
    });

    return changes;
  }

  setProofs(uploadedProofs: UploadedProofs | undefined) {
    this.uploadedProofs = uploadedProofs;
  }

  private getModelChanges<T extends object>(
    oldItems: T[],
    updatedItems: T[],
    compareField: keyof T,
  ): ModelChanges<T> {
    const changes: ModelChanges<T> = {old: [], updated: [], deleted: [], new: []};

    changes.new = updatedItems.filter((o) => !o[compareField]);

    oldItems.forEach((oldItem) => {
      const updatedItem = updatedItems.find((item) => item[compareField] === oldItem[compareField]);

      if (!updatedItem) {
        changes.deleted.push(oldItem);
        return;
      }

      const field: keyof ModelChanges<T> = isEqual(oldItem, updatedItem) ? 'old' : 'updated';
      changes[field].push(updatedItem);
    });

    return changes;
  }

  private getFloorsChangesRequest(): TableChanges<Floor> {
    const fieldChanges: ModelFieldsChanges<Floor>[] = [];

    this.floorsChanges.updated.forEach((f) => {
      const ff = this.oldProperty.floors.find((ff) => ff.id === f.id) as Floor;

      if (!ff) return;

      fieldChanges.push({
        id: f.id,
        changes: this.getFieldChanges(FLOOR_FIELD_RESOLVERS, this.getDifferentKeys(FLOOR_FIELD_RESOLVERS, f, ff), f),
      });
    });

    return {
      fieldChanges,
      new: this.floorsChanges.new.map((f) => this.getRequestNewModel(FLOOR_FIELD_RESOLVERS, f)),
      deleted: this.floorsChanges.deleted.map((o) => o.id),
    };
  }

  private getOwnersChangesRequest(): TableChanges<Citizen> {
    const fieldChanges: ModelFieldsChanges<Citizen>[] = [];

    this.ownersChanges.updated.forEach((o) => {
      const oo = this.oldProperty.owners.find((oo) => oo.id === o.id) as Citizen;

      if (!oo) return;

      fieldChanges.push({
        id: o.id,
        changes: this.getFieldChanges(OWNER_FIELD_RESOLVERS, this.getDifferentKeys(OWNER_FIELD_RESOLVERS, o, oo), o),
      });
    });

    return {
      fieldChanges,
      new: this.ownersChanges.new.map((o) => this.getRequestNewModel(OWNER_FIELD_RESOLVERS, o)),
      deleted: this.ownersChanges.deleted.map((o) => o.id),
    };
  }

  private getRequestNewModel<T extends object>(fieldResolvers: FieldResolvers<T>, model: T): Record<keyof T, string> {
    const res: any = {};

    Object.getOwnPropertyNames(model).forEach((k: any) => {
      const resolver = fieldResolvers[k as keyof T];

      if (!resolver) return;

      const data = resolver.forRequest(model);

      res[data[0]] = data[1];
    });

    return res;
  }

  private initLedgers() {
    if (!this.oldPropertyId) {
      this.loadLedgers(this.updatedProperty.id);
    } else {
      this.loadLedgers(parseInt(this.oldPropertyId));
    }
  }

  toInt(s: string) {
    return parseInt(s);
  }

  public validateRemark() {
    let words = this.citizenRemark.split(' ').length;
    this.isValidateRemark = words > 500;
  }

  private loadLedgers(id: number) {
    this.ledgerService.ledgersByProperty(id).subscribe((data) => this.ledgers = data);
  }

  checkOldTaxId() {
    return this.differentPropertyKeys.includes('old_house_tax_no');
  }

  private calculateVacantArea(){
    const currentYear = currentFinancialYear();
    let groundFloors = this.updatedProperty.floors?.filter((f) => f.floor_number === this.GROUND_FLOOR && +f.from_year <= currentYear && +f.upto_year >= currentYear);
    let groundFloorCarpetAreaSum = groundFloors.reduce((sum,current) => {
      return sum + parseInt(current.carpet_area?.toString())
    },0);
    let groundFloorCarpetAreaSuminSqyds = groundFloorCarpetAreaSum/9
    this.updatedProperty.vacant_area = Math.round((this.updatedProperty.plot_area - groundFloorCarpetAreaSuminSqyds + Number.EPSILON) * 100) / 100;
  }
}


import { PropertySubType } from "./property-sub-type";

export class PropertyType {
  id!: number;
  property_category!: number;
  name!: string;
  description!: string;
  form!: boolean
  enable!: boolean;
  propertySubTypes: PropertySubType[] = [];

  getIncludedFields(): Array<keyof PropertyType> {
    return [];
  }

  getExcludedFields(): Array<keyof PropertyType> {
    return [];
  }
}

export const MIX_PROPERTY_TYPE = {
  id: 133,
  name: "Mix Use"
  
}

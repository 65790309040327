import {BaseModel} from "./base-model";
import {PropertyType} from "./property-type";

export class PropertySubType extends BaseModel {
  id!: number;
  name!: string;
  enable!: boolean;
  propertyType!: PropertyType;
  property_type_id!: number;
  description!: string;
  form! : boolean;

  getIncludedFields(): Array<keyof PropertySubType> {
    return [];
  }

  getExcludedFields(): Array<keyof PropertySubType> {
    return [];
  }
}

export const MIX_PROPERTY_SUB_TYPE =  {
  id: 138,
  name: "Mix Use"
}







import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {PolygonCoordinate} from '../../../@types/polygon-coordinate';
import * as L from 'leaflet';
import {environment} from '../../../../environments/environment';
import {Property} from '../../../models';
import {getPropertyMapIcon} from '../../../global/property-map-icons';
import {PropertyCategories} from '../../../enums/property-categories';
import {Clipboard} from '@angular/cdk/clipboard';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit {

  readonly zoom = 16;
  readonly mainTile = environment.mapTileUrl;
  @ViewChild('tooltip') tooltip!: NgbTooltip
  tooltipShouldOpen = false
  @Input() set property(p: Property | undefined) {
    this._property = p;

    if (p) this.initByProperty(p);
  }

  get property() {
    return this._property;
  }

  private map!: L.Map;
  private center: [number, number] = [26.237074, 73.022738];
  private propertyMarker!: L.Marker;
  private polygons: L.Polygon[] = [];
  private _property!: Property | undefined;
  constructor(
    private clipboard: Clipboard,
  ) {
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  private updatePropertyMarker(center: [number, number]) {
    this.propertyMarker?.remove();

    this.propertyMarker = (new L.Marker(center, {
      icon: this.resolvePropertyIcon(),
    }));

    if (!this.map) return;

    this.propertyMarker.addTo(this.map);

    this.map.flyTo(this.center);
  }

  private resolvePropertyIcon() {
    const icon = this.property?.property_category
      ? getPropertyMapIcon(this.property.property_category)
      : getPropertyMapIcon(PropertyCategories.AnyOther);

    return new L.Icon({
      iconUrl: icon,
      iconSize: [23, 40],
      iconAnchor: [12, 38],
      popupAnchor: [-2, -37],
    });
  }

  private setPolygons(polygons: PolygonCoordinate[][]) {
    this.polygons?.forEach((p) => p?.remove());

    this.polygons = polygons.map((pol) => {
      const newPol = new L.Polygon(pol);

      if (this.map) newPol.addTo(this.map);

      return newPol;
    });
  }

  /**
   * Map Initializers
   * */
  private initMap() {
    this.map = L.map('property-map', {
      center: this.center,
      zoom: this.zoom,
    });

    this.initLayer();
    this.updatePropertyMarker(this.center);
    if (this.property) this.initByProperty(this.property);
  }

  private initLayer() {
    // Add base map
    L.tileLayer(environment.baseMapTileUrl, { subdomains:['mt0','mt1','mt2','mt3']}).addTo(this.map);

    //Add drone image layer
    const tiles = L.tileLayer(this.mainTile);

    tiles.addTo(this.map);
  }

  private initByProperty(property: Property) {
    if (property.latitude && property.longitude) {
      this.center = [property.latitude, property.longitude];
      this.updatePropertyMarker(this.center);
    }

    if (property.polygon_coordinates) this.setPolygons(this.preparePolygons(property.polygon_coordinates));
  }

  private preparePolygons(coordinates: number[][] | undefined) {
    if (!coordinates) return [];

    const updatedCoordinates = coordinates.map(coordinate => {
      return {
        lat: coordinate[1],
        lng: coordinate[0],
      };
    });

    return [updatedCoordinates];
  }

  /**
   * Copy to clipboard property location 
   * Format for clipboard data latitude,longitude
   */

  copyLatLng() {
    let latLngText = `${this.property?.latitude},${this.property?.longitude}`;
    this.clipboard.copy(latLngText);
    this.tooltip.close()
    this.tooltip.ngbTooltip = "Copied!"
    this.tooltipShouldOpen = true
    this.tooltip.open()
  }

  /* Show tooltip if not opened */

  openTooltipIfShouldOpenWhenHidden() {
    if (this.tooltipShouldOpen) {
      this.tooltipShouldOpen = false
      this.tooltip.open()
    }else{
      this.tooltip.ngbTooltip = "Copy Coordinates"
    }
  }

}

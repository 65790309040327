import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toIndianValue',
})
export class ToIndianValuePipe implements PipeTransform {
  constructor() {
  }

  transform(val: number | string | undefined) {

    if (typeof val == 'string') {
      val = Number(val);
    } else if (typeof val == 'undefined') {
      return '';
    }
    return val.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
      style: 'currency',
      currency: 'INR',
    });
  }
}

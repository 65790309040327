import {Component, OnInit} from '@angular/core';
import {TableService} from "../../../services/table.service";
import {Observable} from "rxjs";
import {ObjectionService} from "../../../services/objection.service";
import {Objection} from "../../../models/objection";
import {getFormattedObjectionStatuses, ObjectionStatuses} from "../../../enums/objection-statuses";
import {ColonyService} from 'src/app/services/colony.service';
import {WardService} from '../../../services/ward.service';
import {AuthenticationService} from "../../../services";

type Filter = {
  value?: number | undefined,
  toRequest: () => string,
}

type Filters = {
  ward: Filter,
  colony: Filter,
  status: Filter,
}

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  ObjectionStatuses = ObjectionStatuses;

  items$!: Observable<Objection[]> | Observable<any>;
  items: Objection[] = [];
  tableService: TableService<Objection>;
  search = '';

  readonly FORMATTED_OBJECTION_STATUSES = getFormattedObjectionStatuses();

  private currentFilters: Filters = {
    ward: {
      toRequest() {
        return this.value ? `{HAS: {relation: "property", condition: {column: "ward_id" value: ${this.value}}}}` : '';
      }
    },
    colony: {
      toRequest() {
        return this.value ? `{HAS: {relation: "property", condition: {column: "colony_id" value: ${this.value}}}}` : '';
      }
    },
    status: {
      toRequest() {
        return this.value ? `{column: "status", value: ${this.value}}` : '';
      }
    }
  }

  constructor(
    public objectionService: ObjectionService,
    public wardService: WardService,
    public colonyService: ColonyService,
    public authService: AuthenticationService
  ) {
    this.tableService = objectionService.table;
  }

  ngOnInit(): void {
    this.tableService.whereCondition = () => `where: {AND: [
      { OR: [
        {HAS: {relation: "user", condition: {column: "id" operator: LIKE value: "${this.authService.user?.id}"}}}
      ]}
    ]}`
    this.items$ = this.tableService.items$;
    this.items$.subscribe(objections => this.items = objections);
  }

  setFilter(name: keyof Filters, value: any) {
    const newValue = typeof value === 'string' && value?.trim() === '' ? undefined : value;
    const oldValue = this.currentFilters[name].value;

    this.currentFilters[name].value = newValue;

    if (oldValue !== newValue) this.applyConditions();
  }

  applyConditions() {
    const searchCondition = this.getSearchCondition();
    const filtersCondition = Object.values(this.currentFilters).reduce((acc, v) => acc + v.toRequest() + ' ', '');

    this.tableService.whereCondition = () => `where: {AND: [
      { OR: [
        {HAS: {relation: "user", condition: {column: "id" operator: LIKE value: "${this.authService.user?.id}"}}}
      ]}
    ]}`

    this.tableService.paginate();
  }

  private getSearchCondition() {
    return this.search?.trim() === '' ? '' : `
      { OR: [
        {HAS: {relation: "property", condition: {column: "property_uid" operator: LIKE value: "%${this.search}%"}}}
        {HAS: {relation: "user", condition: {column: "first_name" operator: LIKE value: "%${this.search}%"}}}
        {HAS: {relation: "user", condition: {column: "last_name" operator: LIKE value: "%${this.search}%"}}}
        {HAS: {relation: "user", condition: {column: "phone_number" operator: LIKE value: "%${this.search}%"}}}
        {HAS: {relation: "property", condition: {column: "colony_id" operator: LIKE value: "%${this.search}%"}}}
      ]}
    `
  }
}

<label [for]="inputId">{{ label }}</label>
<input
  type="text"
  class="form-control"
  [id]="inputId"
  [placeholder]="placeholder"
  [ngModel]="inputModel"
  (ngModelChange)="inputModelChange.emit($event)"
  (focus)="focus$.next($any($event).target.value)"
  (click)="click$.next($any($event).target.value)"
  [ngbTypeahead]="suitableTerms"
  #instance="ngbTypeahead"
/>

<div class="container-fluid header-color">
  <div class="container header-container">
    <header>
      <div class="row header-inner justify-content-between align-items-center flex-wrap-mobile">
        <div class="col-lg-1 col-md-2 col-sm-6 col-6">
          <div class="logo justify-content-start align-items-center">
            <img src="assets/images/{{cs.city}}/logo-light.png" style="max-height: 60px;" alt="MCJS" routerLink="/">
          </div>
        </div>

        <div class="col-lg-11 col-md-12 col-sm-12 d-flex flex-wrap-mobile col-12 ">
          <div class="navbar-wrap">
            <nav class="navbar navbar-expand-lg  p-0">
              <div class="container">
                <button class="navbar-toggler" type="button" (click)="collapse.toggle()"
                  [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
                  <span class="navbar-toggler-icon"><i class="bi bi-list"></i></span>
                </button>
                <div class="collapse navbar-collapse" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                  <ul ngbNav #nav="ngbNav" class="navbar-nav">
                    <li class="nav-item" *ngFor="let button of navButtons" ngbDropdown #myDrop="ngbDropdown">
                      <a *ngIf="!button.children.length" class="nav-link" [class.active]="isRouteActive(button.route)"
                        [routerLink]="button.route">{{button.name | translate}}</a>

                      <a href (click)="false" class="nav-link dropdown-toggle" id="navbarDropdown" ngbDropdownToggle
                        *ngIf="button.children.length" [class.active]="isRouteActive(button.children)">{{button.name |
                        translate}}</a>
                      <div ngbDropdownMenu *ngIf="button.children.length" aria-labelledby="navbarDropdown">
                        <ng-container *ngFor="let childBtn of button.children">
                          <button ngbDropdownItem *ngIf="!childBtn.children?.length" [routerLink]="childBtn.route"
                            (click)="childBtn.function($event); scrollTo(childBtn.fragment, childBtn.fragmentRoute)">{{childBtn.name
                            | translate}}</button>

                          <ng-container *ngIf="childBtn.children?.length">
                            <div class="dropdown-submenu">
                              <button ngbDropdownItem>{{childBtn.name | translate}}</button>
                              <div class="dropdown-menu">

                                <button ngbDropdownItem *ngFor="let subChildBtn of childBtn.children"
                                  [routerLink]="subChildBtn.route"
                                  (click)="subChildBtn.redirectionFn(subChildBtn.redirectionRoute); subChildBtn.function($event); scrollTo(subChildBtn.fragment, subChildBtn.fragmentRoute)">{{subChildBtn.name
                                  | translate}}
                                </button>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>

                      </div>
                    </li>

                    <li class="nav-item seprate">
                      <div class="header-buttons d-flex justify-content-end align-items-center"
                        *ngIf="auth.user === null">

                        <button class="btn btn-primary btn-login" *ngFor="let button of accountButtons"
                          (click)="button.function($event)">{{button.name | translate}}</button>
                      </div>

                      <div class="header-buttons d-flex justify-content-end align-items-center"
                        *ngIf="auth.user === undefined">
                        {{'home.loading_user' | translate}}
                      </div>

                      <div class="login-options d-flex justify-content-end align-items-center" *ngIf="!!auth.user">

                        <div ngbDropdown class="d-inline-block notification-icon" *ngIf="notifications.length > 0">
                          <button class="" id="notificationsDropdown" ngbDropdownToggle>
                            <i class="bi bi-bell"></i>
                          </button>
                          <div ngbDropdownMenu aria-labelledby="notificationsDropdown"
                            class="notification-list-wrapper">
                            <ul class="notification-list">
                              <li *ngFor="let notification of notifications">
                                <p>{{notification.message}}</p>
                                <div class="noti-date">{{notification.datetime | date: 'dd MMM yyyy'}}</div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <div ngbDropdown class="d-flex wd-100 justify-content-end dropdown-user">
                          <button class="btn btn-primary" id="userinnerpages" ngbDropdownToggle>{{auth?.user?.first_name
                            ?? '' | slice:0:1 }} {{auth?.user?.last_name ?? '' |slice:0:1}}</button>
                          <div ngbDropdownMenu aria-labelledby="userinnerpages">
                            <button ngbDropdownItem routerLink="/my-profile">{{'home.my_profile' | translate}}</button>
                            <button ngbDropdownItem routerLink="/my-properties">{{'home.my_property' | translate}}</button>
                            <button ngbDropdownItem routerLink="/payment-history">{{'home.payment_history' | translate}}</button>
                            <button ngbDropdownItem routerLink="/credit-request">{{'home.credit_requests' | translate}}</button>
                            <button ngbDropdownItem routerLink="/my-objections/list">{{'home.my_raised_objection' | translate}}</button>
                            <button ngbDropdownItem routerLink="/my-selfAssessment/list">{{'home.my_self_assessment' | translate}}</button>
                            <button ngbDropdownItem (click)="signOut($event)">{{'home.logout' | translate}}</button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="nav-item seprate seprate-lang">
                      <div ngbDropdown class="d-flex justify-content-end  dropdown-user">
                        <button class="btn btn-primary" id="languageSelection" ngbDropdownToggle>{{langName}}</button>
                        <div ngbDropdownMenu aria-labelledby="languageSelection">
                          <button ngbDropdownItem (click)="onLangChange('en')">English - EN</button>
                          <button ngbDropdownItem (click)="onLangChange('hi')">हिन्दी - HI</button>
                        </div>
                      </div>
                    </li>
                  </ul>


                </div>
              </div>

            </nav>
          </div>
        </div>
      </div>

    </header>
  </div>
</div>

<div class="main-content-wrapper">
  <router-outlet></router-outlet>
</div>

<section class="sponsors-logo" *ngIf="!showFooter">
  <ul>
    <li>
      <img src="assets/images/goi-web-directory.png" class="img-fluid sponsor">
    </li>
    <li>
      <img src="assets/images/my-gov.png" class="img-fluid sponsor">
    </li>
    <li>
      <img src="assets/images/Indiagovin.png" class="img-fluid sponsor">
    </li>
    <li>
      <img src="assets/images/pmindia-logo.png" class="img-fluid sponsor">
    </li>
    <li>
      <img src="assets/images/incredible-india.png" class="img-fluid sponsor">
    </li>
    <li>

      <img src="assets/images/Make_In_India.png" class="img-fluid sponsor">


    </li>

  </ul>
</section>
<footer class="main-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-1 col-md-2 col-sm-3 col-12">
        <img src="assets/images//{{cs.city}}/icon-logo-light.png" class="img-fluid footer-logo">
      </div>
      <div class="col-lg-8 col-md-6 col-sm-5 col-12 d-flex-container wrap">
        <ul class="footer-link">
          <h4>{{'home.general_label' | translate}}</h4>
          <li><a [routerLink]="['/property-search']">{{'home.property_search_label' | translate}}</a></li>
          <li><a [routerLink]="['/self-assessment-form']">{{'home.self_assessment_label' | translate}}</a></li>
          <li><a [routerLink]="['/dlc-rates']">{{'home.dlc_rates_label' | translate}}</a></li>
          <li><a [routerLink]="['/properties/map']">{{'home.map_view_label' | translate}}</a></li>
        </ul>
        <ul class="footer-link">
          <h4>{{'home.reach_us_label' | translate}}</h4>
          <li><a [routerLink]="['/contact-us']">{{'home.contact_us_label' | translate}}</a></li>
        </ul>
        <ul class="footer-link">
          <h4>{{'home.legal_label' | translate}}</h4>
          <li><a [routerLink]="['/term-of-service']">{{'home.term_of_service_label' | translate}}</a></li>
          <li><a [routerLink]="['/private-policy']">{{'home.privacy_policy_label' | translate}}</a></li>
          <li><a [routerLink]="['/refund-and-cancellation-policy']">{{'home.refund_and_cancellation_policy_label' |
              translate}}</a></li>
        </ul>
        <p class="copyright">{{'home.copyright' | translate}}

        </p>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-12" *ngIf="cs.city === 'bharatpur'">
        <h4>{{'home.official_address_label' | translate}}</h4>
        <p>{{'home.municipal_corporation_of_bharatpur_label' | translate}}</p>
        <p>{{'home.contact_address_label' | translate}}</p>
        <p>{{'home.contact_email' | translate}}</p>
        <p>{{'home.contact_number' | translate}}</p>
        <h4>{{'home.official_website' | translate}}</h4>
        <figure class="social-links">
          <i class='bx bx-link-external'><a
              href='https://urban.rajasthan.gov.in/content/raj/udh/nagar-nigam-bharatpur/en/home.html#'></a></i>
        </figure>
      </div>

      <div class="col-lg-3 col-md-4 col-sm-4 col-12" *ngIf="cs.city === 'jodhpur'">
        <h4>{{'home.helpline_number_jodhpur' | translate}}</h4>
        <p>{{'home.municipal_corporation_of_jodhpur_label' | translate}}</p>
        <p>{{'home.contact_address_jodhpur_label' | translate}}</p>
        <p>{{'home.contact_email_jodhpur' | translate}}</p>
        <p>{{'home.contact_number_jodhpur' | translate}}</p>
        <h4>{{'home.official_website_jodhpur' | translate}}</h4>
        <figure class="social-links">
          <a href='http://jodhpurmc.org/'><i class='bx bx-link-external'></i></a>
          <i class='bx bx-facebook'><a href='https://www.facebook.com/NNJSouth/'></a></i>
          <i class='bx bx-twitter'><a href='https://twitter.com/NNJSouth?s=09'></a></i>
        </figure>
      </div>
      <div class="col-lg-3 col-md-4 col-sm-4 col-12" *ngIf="cs.city === 'jodhpurNorth'">
        <h4>{{'home.helpline_number_jodhpurNorth' | translate}}</h4>
        <p>{{'home.municipal_corporation_of_jodhpurNorth_label' | translate}}</p>
        <p>{{'home.contact_address_jodhpurNorth_label' | translate}}</p>
        <p>{{'home.contact_email_jodhpurNorth' | translate}}</p>
        <p>{{'home.contact_number_jodhpurNorth' | translate}}</p>
        <h4>{{'home.official_website_jodhpur' | translate}}</h4>
        <figure class="social-links">
          <a href='http://jodhpurmc.org/'><i class='bx bx-link-external'></i></a>
          <i class='bx bx-facebook'><a href='https://www.facebook.com/NNJSouth/'></a></i>
          <i class='bx bx-twitter'><a href='https://twitter.com/NNJSouth?s=09'></a></i>
        </figure>
      </div>



    </div>
  </div>

</footer>
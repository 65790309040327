import { Pipe } from "@angular/core";

@Pipe({
    name: 'currencyINR'
})

export class CurrencyInrPipe {

    transform(val: number | string | undefined) {
        if(typeof  val == 'string'){
            val = Number(val);
          } else if (typeof  val == 'undefined'){
            return '0';
        }

        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 2,
        }).format(Number(val)).replace(/[₹]/g, '')
    }
}
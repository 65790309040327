<div class="map-page">
  <div class="map-wrapper">
    <div id="map" #map></div>

    <div class="loading-container-spinner-block loader-wrapper text-primary" *ngIf="loadingProperties">
      <div class="spinner-border" role="status">
        <span class="sr-only">{{'property_search.loading' | translate}}</span>
      </div>
    </div>
  </div>

  <app-sidebar [currentZoom]="currentZoom"
               [requiredZoom]="requiredZoom"
               [nearbyProperties]="nearbyProperties"
               [searchMapProperty]="searchMapProperty"
               [landmarks]="landmarks"
               (search)="search($event)"
               (flyTo)="flyToMap($event)"
  ></app-sidebar>
</div>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  @Input() url!: SafeResourceUrl;
  @Output() close = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {propertiesMapRouting} from './properties-map.routing';
import {MapComponent} from './map/map.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {WidgetsModule} from '../../widgets/widgets.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule} from '@angular/forms';
import {PipesModule} from "../../pipes/pipes.module";
import {PropertyInfoCardComponent} from './property-info-card/property-info-card.component';
import { DirectivesModule } from 'src/app/directives/directives.module';


@NgModule({
  declarations: [
    MapComponent,
    SidebarComponent,
    PropertyInfoCardComponent,
  ],
  imports: [
    CommonModule,
    propertiesMapRouting,
    WidgetsModule,
    NgSelectModule,
    FormsModule,
    PipesModule,
    DirectivesModule
  ],
  exports: [
    MapComponent,
  ],
})
export class PropertiesMapModule {
}

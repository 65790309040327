<div [ngClass]="{'form-required': isRequired}" class="form-label" *ngIf="label">{{label}}</div>
<div *ngIf="description"> {{description}}</div>
<label for="file-upload-{{id}}" class="file-upload" dragDrop (onFileDrop)="uploadFile($event)">
    <img src="./../../../../assets/images/file_upload.svg" alt="">
    <div>{{'patta_verification.create_objection_form.id_of_objector_placeholder'| translate}}</div>
</label>
<input type="file" class="form-control" id="file-upload-{{id}}" (change)="onFileSelection($event)"
    accept="{{'.' + ALLOWED_FILE_FORMATS.join(', .')}}">
<span class="text-danger" style="font-size: 11px;"
    *ngIf="errorMessage"><strong>{{errorMessage}}</strong></span>
<div>
    <div class="uploaded-img-cont d-inline-block" *ngFor="let file of uploadedFiles; let i = index">
        <button class="close-btn" (click)="removeImage(i)">X</button>
        <img [src]="file.url" alt="" [title]="file.name">
    </div>
</div>

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {City, Colony, District, Photo, Property, Ward} from '../../../models';
import {WardService} from '../../../services/ward.service';
import {of, Subject} from 'rxjs';
import {ColonyService} from '../../../services/colony.service';
import {getFormattedErrorsByValidator, Validated, ValidationRules} from '../../../@types/validation';
import {cloneDeep} from 'lodash';
// @ts-ignore
import Validator from 'Validator/src/validator';
import {ConfigService} from '../../../services/config.service';
import {NgxDropzoneChangeEvent} from 'ngx-dropzone';
import {environment} from '../../../../environments/environment';
import Swal from 'sweetalert2';
import {HttpClient} from '@angular/common/http';
import { CityService } from 'src/app/services/city.service';

export const PropertyValidationRules: { [T in keyof Property | string]?: ValidationRules } = {
  multi: 'required',
  building_construction_year: `required|numeric|min:1900|max:${new Date().getFullYear()}`,
  plot_allotment_year: `nullable|numeric|min:1900|max:${new Date().getFullYear()}`,
  road_width: 'required',
  address_house_number: 'required',
  plot_area: 'required|numeric',
  vacant_area: 'nullable|numeric',
  pincode:'required|numeric',
  landmark: 'required'
};

@Component({
  selector: 'app-general-information',
  templateUrl: './general-information.component.html',
  styleUrls: ['./general-information.component.scss'],
})
export class GeneralInformationComponent implements OnInit {

  @Input() property!: Property;
  @Input() colonyCoordinates!:Colony;
  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output() selectedColonyCoords = new EventEmitter<Colony>();
  @ViewChild('video') video!: any;
  @ViewChild('canvas') canvas!: any;

  readonly YES_NO_OPTIONS = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];

  colonies: Colony[] = [];
  wards: Ward[] = [];
  errors: { [key in keyof Property]?: string } = {};
  isWardsLoading = true;
  isColoniesLoading = false;
  newCoordinates!: {latitude: number, longitude: number};
  selectedWard!:Ward;
  selectedColony!:Colony;
  latLongValue!:string;

  constructor(
    public wardService: WardService,
    public colonyService: ColonyService,
    public configService: ConfigService,
    public httpService: HttpClient,
    public cs:CityService
  ) {
  }

  ngOnInit(): void {
    const city = {id: this.cs.id, name: this.cs.cityName};
    const district = {id: this.cs.districtId, name: this.cs.districtName};
    this.property.state_id = this.cs.stateId;
    this.property.district = Object.assign(new District(), district);
    this.property.city = Object.assign(new City(), city);

    this.wardService.getAll(undefined, true).subscribe((c) => {
      this.wards = c;
      this.isWardsLoading = false;
    });

    if (this.property.ward && this.property.ward.id) {
      this.getColonies(this.property.ward);
    }

    this.normalizeProperty(this.property);

    if(this.colonyCoordinates) {
      this.selectedColony = this.colonyCoordinates;
    }

    if(this.property.latitude && this.property.longitude) {
      this.viewOnMap();
    }

  }

  setWardId(val: Ward | undefined) {
    if (val === this.property.ward) return;

    this.property.ward = val as Ward ?? new Ward();

    this.property.colony = undefined as any;
  }

  loadColonies = (search: string) => {
    return of(
      this.property.ward?.id
        ? this.colonies.filter(
          (c) => c.wards.find((w) => w.id === this.property.ward.id) && this.filterByStr(search, c.name),
        ).slice(0, 10)
        : this.colonies.filter((c) => this.filterByStr(search, c.name)).slice(0, 10),
    );
  };

  loadWards = (search: string) => {
    return of(this.wards.filter((w) => this.filterByStr(search, w.name)));
  };

  nextForm() {
    const validated = this.validate();
    this.errors = validated.errors;
    if (validated.isSuccess) return this.nextFormEmitter.emit();
  }

  viewOnMap() {
    this.newCoordinates = {latitude: this.property.latitude, longitude: this.property.longitude};
  }

  useMyLocation() {
    if (!navigator.geolocation) return;

    navigator.geolocation.getCurrentPosition((geolocation) => {
      this.property.latitude = geolocation.coords.latitude;
      this.property.longitude = geolocation.coords.longitude;
      this.viewOnMap();
    });
  }

  newFiles($event: NgxDropzoneChangeEvent) {
    $event.addedFiles.forEach((file) => {
      this.uploadFile(file, (res) => this.uploadResponse(file, res));
    });
  }

  delete(i: any) {
    this.property.photos.splice(i, 1);
  }

  capturePhoto(event: Event) {
    // @ts-ignore
    const file = event?.target?.files?.[0];

    if (!file) return;

    this.uploadFile(file, (res) => this.uploadResponse(file, res));
  }

  private filterByStr(search: string, str: string) {
    return str.toLowerCase().includes(search.trim().toLowerCase());
  }

  private validate(): Validated<Property> {
    let rules = cloneDeep(PropertyValidationRules);

    const propertyValidated = Validator.make(this.property, rules);
    const relationValidation = this.validateRelation();
    propertyValidated.fails();
    const errors = getFormattedErrorsByValidator(propertyValidated.getErrors());

    if (this.property.latitude && !this.property.longitude) {
      errors.longitude = 'Incompletely completed coordinates';
    }

    if (!this.property.latitude && this.property.longitude) {
      errors.latitude = 'Incompletely completed coordinates';
    }

    return {
      isSuccess: !propertyValidated.fails() && relationValidation.isSuccess,
      errors: {...errors, ...relationValidation.errors},
    };
  }

  private validateRelation(): Validated<Property> {
    const requiredMessage = 'This is required field';

    const errors: { isSuccess: boolean, errors: Record<string, string> } = {isSuccess: true, errors: {}};

    const setError = (field: string, message: string) => {
      errors.isSuccess = false;
      errors.errors[field] = message;
    };

    if (!this.property.ward) setError('ward_id', requiredMessage);
    if (!this.property.colony || this.property.colony_id===undefined) setError('colony', requiredMessage);

    return errors;
  }

  private normalizeProperty(property: Property) {
    if (property.colony && !property.colony.id) {
      (property as any).colony = undefined;
    }
    if (property.ward && !property.ward.id) {
      (property as any).ward = undefined;
    }
    if (property.ownershipType && !property.ownershipType.id) {
      (property as any).ownershipType = undefined;
    }
  }

  private uploadFile(file: File, callback: (res: any) => any) {
    const fd = new FormData();
    fd.append('file', file, file.name);

    this.httpService.post<any>(environment.apiBaseUrl + 'photos/upload', fd).subscribe(
      (res) => {
        callback(res);
      },
      (res: any) => {
        const body = res.error;

        Swal.fire(
          'Error',
          body?.errors?.file?.[0] ?? body?.message ?? 'Something Went Wrong',
          'error',
        );
      },
    );
  }

  private uploadResponse = (file: File, res: any) => {
    this.property.photos.push(Object.assign(new Photo(), {
      name: file.name,
      url: res.data.path,
    }));
  };

  getColonies(ward: Ward, clearPreviousValue = false) {
    this.colonies = [];
    this.selectedWard = ward;
    if (clearPreviousValue) this.property.colony = undefined as any;
    if (!ward || !ward.id) return;
    this.isColoniesLoading = true;
    this.colonyService.getAll(ward.id, true).subscribe((c) => {
      this.colonies = c;
      this.isColoniesLoading = false;
    });
  }
  getLatLng(latlng: number[]) {
    if(latlng != undefined){
      this.property.latitude = latlng[0]; 
      this.property.longitude = latlng[1]; 
    }else{
      this.property.latitude = undefined; 
      this.property.longitude = undefined; 
    }
  }

  onColonyChange(colony:Colony):void {
    this.selectedColony = colony;
    this.selectedColonyCoords.emit(this.selectedColony);
  }

  /**
   *  Assign values if correct format is entered (lat,long)
   */

   onLatLongValueChange() {
    if(!this.latLongValue) {
      this.property.latitude = undefined;
      this.property.longitude = undefined;
      return;
    }
    const latLongArr = this.latLongValue.split(',');
    if(Number(latLongArr[0]?.trim()) && Number(latLongArr[1]?.trim())){
      this.property.latitude = Number(latLongArr[0].trim()) as any;
      this.property.longitude = Number(latLongArr[1].trim()) as any;
    }
   
  }

  /**
   * On Latitute Longitude field blur check if lat,long format is correct
   */
   afterLatLongInput() {
    if(!this.latLongValue) return;
    const latLongArr = this.latLongValue.split(',');
    if(Number(latLongArr[0]?.trim()) && Number(latLongArr[1]?.trim())) return;
    Swal.fire('Error', 'Incorrect Latitude,Longitude format', 'error');
  }
  
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ndcStatusRouting} from "./ndc-status.routing";
import {IndexComponent} from './index/index.component';


@NgModule({
  declarations: [
    IndexComponent
  ],
  imports: [
    CommonModule,
    ndcStatusRouting
  ]
})
export class NdcStatusModule { }

<!-- Start Title and Filter Options -->
<div class="listing-head mb-4 d-flex align-items-center justify-content-between">
  <div class="flex_coulmn">
    <h2 class="m-0">{{'property_search.properties' | translate}}</h2>
    <div class="property-items" *ngIf="collectionTotal">{{'property_search.total_properties' | translate}}: <span> {{collectionTotal}} </span></div>
  </div>  

  <div class="view-options d-flex align-items-center">

    <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="grid">
      <label ngbButtonLabel class="">
        <input ngbButton type="radio" [value]="false"> <i class="bi bi-list-task"></i>
      </label>
      <label ngbButtonLabel class="">
        <input ngbButton type="radio" [value]="true"> <i class="bi bi-grid-3x3-gap"></i>
      </label>
    </div>
  </div>
</div>

<app-list-view *ngIf="grid === false" [properties]="properties" [page]="page" [perPage]="perPage"></app-list-view>

<app-grid-view *ngIf="grid === true" [properties]="properties"></app-grid-view>

<!-- Start pagination -->
<div class="row justify-content-center">
  <ngb-pagination [(page)]="page" (pageChange)="update()" [pageSize]="perPage" [maxSize]="4" [rotate]="true"
    [collectionSize]="collectionTotal"></ngb-pagination>
</div>
<!-- End pagination -->

export class Ward {
  id!: number;
  city_id!: number;
  name!: string;
  description!: string;
  gps_coordinates!: { latitude: number, longitude: number }[]
  gps_compressed_coordinates!: string
  created_at:  Date = new Date();
  updated_at:  Date = new Date();

  getIncludedFields(): Array<keyof Ward> {
    return [];
  }

  getExcludedFields(): Array<keyof Ward> {
    return [];
  }
}

export class NdcFormData {
    property_id!: number;
    email!: string;
    condition?: boolean;
    proofs!: Proofs[];
}

class Proofs {
    url?:string;
    path!: string;
    name!: string;
}
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {merge, Observable, OperatorFunction, ReplaySubject, Subject, Subscription} from "rxjs";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {auditTime, distinctUntilChanged, filter, switchMap, takeUntil, tap} from "rxjs/operators";
import {IServicePaginate} from "../../@types/service-paginate";
import {IServiceGetOne} from "../../@types/service-get-one";
import {BaseService} from "../../services/base-service.service";

interface ItemsSource extends BaseService, IServicePaginate<any>, IServiceGetOne<any> {
}

type SearchInputSettings = {
  auditTime: number,
}

/**
 +------------------------------------------------------------+
 |                    Using only for models                   |
 +------------------------------------------------------------+
 * */
@Component({
  selector: '~app-search-input-by-api',
  templateUrl: './search-input-by-api.component.html',
  styleUrls: ['./search-input-by-api.component.scss']
})
export class SearchInputByApiComponent implements OnInit, OnDestroy {

  clearSubscription!: Subscription;
  fetchOneSubscription!: Subscription;

  private destroy$ = new ReplaySubject();

  searchInputSettings: SearchInputSettings = {
    auditTime: 500,
  };

  @ViewChild('instance', {static: true}) instance!: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  errorExists = false;

  _localInputModel: string | number | { [key: string]: string } = '';
  loadingModel = false;

  @Input() inputId: string = 'searchInputId';
  @Input() label: string = '';
  @Input() showRequired = false;
  @Input() placeholder: string = '';
  @Input() inputStyles: object = {};
  @Input() perPage: number = 1000;
  @Input() itemsSource!: ItemsSource

  @Input() where: (term?: string) => string = (term: string = '') => this.itemsSource.defaultSearchWhere(term);

  /**
   *  NEVER TRY USE SETTER IN THIS CLASS!!!
   *
   *  THIS SETTER USING FOR EXTERNAL SOURCE
   *
   *  If you want change external source use inputModelChange
   *  automatically _localInputModel will be change if they not compare
   * */
  @Input() set inputModel(val: string | number | undefined) {
    if (val === this._localInputModel) return;

    const foreignType = typeof val;

    if (!val || foreignType === 'string' || foreignType === 'number') {
      if (typeof this._localInputModel === "object" && this._localInputModel[this.bindValue] === val) return;

      this._localInputModel = val ?? '';
    }
  }

  get inputModel() {
    if (typeof this._localInputModel === 'object') {
      return this._localInputModel[this.bindValue];
    }

    return this._localInputModel;
  }

  @Output() inputModelChange = new EventEmitter<string>();

  /** Using for output */
  @Input() bindLabel: string = ''

  /** Using for emit from component */
  @Input() bindValue: string = ''

  /** Formatting output in popup list */
  @Input() resultFormatter: (item: any) => string = (item) => item[this.bindLabel];

  /** Formatting output in input */
  @Input() inputFormatter: (item: any) => string = (item) => item[this.bindLabel];

  @Input() clear$!: Observable<any>;

  @Input() afterInit$!: Observable<any>;

  ngOnInit() {
    this.clearSubscription = this.clear$?.subscribe(() => {
      this.clear();
    });

    this.afterInit$?.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (!this.inputModel) return;
      this.loadingModel = true;

      this._localInputModel = this.inputModel.toString();
      this.itemsSource.getOne(this.bindValue, parseInt(this._localInputModel)).pipe(takeUntil(this.destroy$)).subscribe(
        (item) => {
          this.loadingModel = false;
          if (!item || !item[this.bindValue]) return;

          this._localInputModel = item;
          this.inputModelChange.emit(item[this.bindValue]);
        },
      );
    })
  }

  ngOnDestroy() {
    if (this.clearSubscription) this.clearSubscription.unsubscribe()
    if (this.fetchOneSubscription) this.fetchOneSubscription.unsubscribe()

    this.destroy$.next(true);
    this.destroy$.complete();
  }

  /** Get items from itemsSource */
  suitableTerms$: OperatorFunction<any, readonly any[]> = (text$: Observable<string>) => {
    const auditedText$ = text$.pipe(
      auditTime(this.searchInputSettings.auditTime),
      distinctUntilChanged()
    );

    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(auditedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      switchMap((term) => this.itemsSource
        ? this.loadItems(term)
        : []
      ),
    );
  }

  inputModelChangeEvent($event: any) {
    this.errorExists = false;

    if (typeof $event === 'string') {
      this._localInputModel = $event;
    }

    if ($event[this.bindValue] === undefined) {
      this.inputModelChange.emit('');
    }
  }

  selectItem($event: any) {
    if ($event.item[this.bindValue]) {
      this._localInputModel = $event.item;
      this.inputModelChange.emit($event.item[this.bindValue]);
    }
  }

  prefetch() {
    if (this._localInputModel === '' || typeof this._localInputModel === 'object') return

    if (this.fetchOneSubscription) this.fetchOneSubscription.unsubscribe()

    /** Need if user entered text but not selected item or if items has not been loaded */
    this.fetchOneSubscription = this.itemsSource.getOne(this.bindLabel, this._localInputModel).subscribe((item) => {
      if (!item || !item[this.bindValue]) {
        this.errorExists = true;
        return;
      }

      this.inputModelChange.emit(item[this.bindValue]);
      this._localInputModel = item;
    });
  }

  focusout() {
    this.loadingModel = false;
  }

  private clear() {
    this._localInputModel = '';
    this.inputModel = '';
    this.inputModelChange.emit('');
    this.instance.writeValue('')
    this.errorExists = false;
  }

  private loadItems(term: string = '') {
    this.loadingModel = true;

    return this.itemsSource.paginate(1, this.perPage, this.where(term)).pipe(
      tap(() => this.loadingModel = false)
    )
  }

  focus($event: FocusEvent) {
    this.errorExists = false;
    this.focus$.next(($event?.target as any)?.value);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CityService } from '../../../services/city.service';
import { Confetti } from './confetti';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyService } from '../../../services/property.service';
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {
  private destroy$ = new ReplaySubject();
  landmarks: any = [];
  loadingLandmarks!:boolean;

  // for flower animation 
  showCanvas!:boolean;
  @ViewChild('canvas', {static: false}) canvasEl!:ElementRef<HTMLCanvasElement>;
  ctx!:CanvasRenderingContext2D | null;
  width = window.innerWidth;
  height = window.innerHeight;

  constructor(public cs: CityService,
    private activatedRoute: ActivatedRoute,
    private propertyService: PropertyService,) {
  }
  
  
  confs!:Confetti[];

  ngOnInit() {
    this.loadLandmarks();
  }

  private loadLandmarks() {
    this.loadingLandmarks = true;
    const cityId = localStorage.getItem('cityId');
    this.propertyService.getAllLandmarks(Number(cityId))
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((landmarks: any) => {
      this.landmarks = landmarks.data;
      this.loadingLandmarks = false;
    });
  }

  // Loop function for animation loop

  loop() {
    const requestId = requestAnimationFrame(this.loop.bind(this));
    let animationCompletionCount = 0;
    this.ctx?.clearRect(0, 0, this.width, this.height);
    this.confs.forEach((conf: Confetti) => {
      // Add +1 if flower animation is completed for a class
      if(conf.isAnimationCompleted){
        animationCompletionCount++;
      }
      conf.update();
      conf.draw();
    });
    // Break the loop and hide canvas when all the flowers reach at bottom so that the website can be used after animation.
    if(animationCompletionCount >= this.confs.length) {
      cancelAnimationFrame(requestId);
      this.showCanvas = false;
    }
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      if(params['launch']){
        /**
         * Putting inside setTimeout to avoid ExpressionChangedAfterItHasBeenCheckedError error due to angular change detection cycle
         */
        setTimeout(() => {
          this.showCanvas = true;
        }, 0)
        
        setTimeout(() => {
          this.ctx = this.canvasEl.nativeElement.getContext('2d');
          const confNum = Math.floor(this.width / 4);
          this.confs = new Array(confNum).fill('').map(_ => new Confetti(this.ctx, this.width, this.height));
          this.loop();
        }, 1)
      }
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}


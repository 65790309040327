<div class="ndc-request-cont">
    <div class="modal-header">
        <div class="title">
            <h5 class="modal-title mb-2">{{'property_search.request_no_due_certificate' | translate}}</h5>
        </div>
    </div>
    <div class="modal-body">
        <div class="modal-sub-title">{{'property_search.enter_details_and_upload_all_required_documents' | translate}}</div>
        <form (submit)="onSubmit()">
            <div class="row">
                <div class="col-12 form-group">
                    <label for="email-address">{{'property_search.email_address' | translate}}</label>
                    <input type="text" class="form-control" id="email-address" placeholder="{{'property_search.enter_email_address' | translate}}" [(ngModel)] = "formData.email" [ngModelOptions]="{standalone: true}">
                    <span class="text-danger" style="font-size: 11px;" *ngIf="errors.emailError"><strong>{{errors.emailError}}</strong></span>
                </div>
                <div class="col-12 form-group">
                    <file-upload label = "Owner ID & Address Proof" description = "Upload documents matching owner details" 
                    (onFileUpload) = "afterFileUpload($event)" (removeFile)="removeFile($event)" uploadApiUrl="document-proof/upload"></file-upload>
                </div>
                <div class="col-12 form-group d-flex">
                    <input type="checkbox" id="ndc-terms" class="ndc-terms" [(ngModel)] = "formData.condition" [ngModelOptions]="{standalone: true}">
                    <label for="ndc-terms">{{'property_search.terms' | translate}}</label>
                </div>
                <div class="col-12">
                    <button class="btn btn-primary float-right submit-btn" [disabled]="!formData.condition">{{'property_search.submit' | translate}}</button>
                </div>
            </div>
        </form>
    </div>
</div>
<ng-container *ngIf = "showLoader">
    <div class="position-absolute w-100 d-flex align-items-center justify-content-center p-4 h-100">
      <div class="spinner-border" role="status">
        <span class="sr-only">{{'property_search.loading' | translate}}</span>
      </div>
    </div>
</ng-container>
import {Property} from './property';
import {User} from './user';
import {ObjectionStatuses} from '../enums/objection-statuses';
import {ObjectionPropertyDetail} from './objection-property-detail';
import {ObjectionStatusHistory} from './objection-status-history';
import {ObjectionsOwnerModification} from './objections-owner-modification';
import {ObjectionOwnerNew} from './objection-owner-new';
import {ObjectionsOwnerDelete} from './objections-owner-delete';
import {ObjectionsFloorModification} from './objections-floor-modification';
import {ObjectionNewFloor} from './objection-new-floor';
import {ObjectionsFloorDelete} from './objections-floor-delete';
import {ObjectionDocument} from './objection-document';

export class Objection {
  id!: number;
  objection_no!: string;
  property = new Property();
  user = new User();
  reviewer = new User();
  status!: ObjectionStatuses;
  citizen_remark!: string;

  documents: ObjectionDocument[] = [];
  objectionPropertyDetails: ObjectionPropertyDetail[] = [];
  objectionOwnerModifications: ObjectionsOwnerModification[] = [];
  objectionOwnerNews: ObjectionOwnerNew[] = [];
  objectionOwnerDeletes: ObjectionsOwnerDelete[] = [];
  objectionFloorModifications: ObjectionsFloorModification[] = [];
  objectionNewFloors: ObjectionNewFloor[] = [];
  objectionFloorDeletes: ObjectionsFloorDelete[] = [];
  objectionStatusHistories: ObjectionStatusHistory[] = [];

  created_at: Date = new Date();
  updated_at: Date = new Date();
}

<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.floor_wise_information' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg table-res">
      <thead>
      <tr>
        <th>{{'property_search.floor_number' | translate}}</th>
        <th>{{'property_search.from_year' | translate}}</th>
        <th>{{'property_search.upto_year' | translate}}</th>
        <th>{{'property_search.property_category' | translate}}</th>
        <th>{{'property_search.property_type' | translate}}</th>
        <th>{{'property_search.property_sub_type' | translate}}</th>
        <th>{{'property_search.property_usage' | translate}}</th>
        <th>{{'property_search.carpet_area_in_sqft' | translate}}</th>
        <th>{{'property_search.construction_type' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let floor of floors">
        <td data-th="Floor Number: ">{{FLOOR_NUMBER[floor.floor_number]}}</td>
        <td data-th="From Year">{{floor.from_year ? floor.from_year + '-' + (toInt(floor.from_year) + 1) : ''}}</td>
        <td data-th="Upto Year">{{floor.upto_year ? floor.upto_year + '-' + (toInt(floor.upto_year) + 1) : ''}}</td>
        <td data-th="Property Category:">{{PROPERTY_CATEGORIES[floor.property_category]}}</td>
        <td data-th="Property Type">{{floor.propertyType?.name}}</td>
        <td data-th="Property Sub Type:">{{floor.propertySubType?.name}}</td>
        <td data-th="Property Usage:">{{PROPERTY_USAGE[floor.property_usage_id]}}</td>
        <td data-th="Carpet Area">{{floor?.carpet_area}}</td>
        <td data-th="Construction Type">{{CONSTRUCTION_TYPES[floor.property_construction_type_id]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>

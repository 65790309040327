import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Property} from '../../../models';
import {Validated, ValidationErrors} from '../../../@types/validation';
import {isBoolean} from 'lodash';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-utility-details',
  templateUrl: './utility-details.component.html',
  styleUrls: ['./utility-details.component.scss']
})
export class UtilityDetailsComponent implements OnInit {

  @Input() property!: Property;
  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('previousForm') previousFormEmitter = new EventEmitter();

  errors: { [key: string]: string } = {};

  readonly FORMATTED_WATER_SUPPLY_SOURCES = Property.formattedWaterSupplySources();
  readonly FORMATTED_TOWER_LOCATIONS = Property.formattedTowerLocations();
  readonly YES_NO_OPTIONS = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];

  constructor(private languageService : LanguageService) {
  }

  ngOnInit(): void {
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  nextForm() {
    const validated = this.validate();

    if (validated.isSuccess) return this.nextFormEmitter.emit();

    this.errors = validated.errors;
    window.scrollTo({top: 0, behavior: 'smooth'})
  }

  compare(a: any, b: any) {
    return a?.id.toString() === b.toString();
  };

  private validate(): Validated<Property> {
    const requiredMessage = this.languageService.translate('home.this_field_is_required');

    const validation: Validated<Property> = {
      isSuccess: true,
      errors: {}
    }

    const setError = (field: keyof Property, message: string) => {
      validation.isSuccess = false;
      validation.errors[field] = message;
    };

    if (!this.property.water_connection_number) setError('water_connection_number', requiredMessage)
    if (!isBoolean(this.property.water_connection)) setError('water_connection', requiredMessage)
    if (!isBoolean(this.property.electricity_connection)) setError('electricity_connection', requiredMessage)

    if (this.property.electricity_connection && !this.property.electricity_connection_number) {
      setError('electricity_connection_number', requiredMessage)
    }

    if (this.property.mobile_tower && !this.property.mobile_tower_placement) {
      setError('mobile_tower_placement', requiredMessage)
    }

    return validation;
  }
}

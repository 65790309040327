<div class="row list-item" *ngIf="property.id; else skeleton">
  <div class="col-1 col" data-th=" ">
    <img [src]="commonService.getAbsoluteImageUrl(property.photos[0]?.url) ?? stockPhotoUrl" class="img-fluid gird-img">

  </div>
  <!-- <div class="col-1 col" data-th="S.No:">

  {{index}}
  

  </div> -->
  <div class="col-1 col" data-th="Service No.:">{{property?.property_uid}}</div>
  <div class="col-1 col" data-th="ward.:">{{property?.ward?.name}}</div>
  <div class="col-2 col" data-th="Property Category:"><span class="roundeed-rectangle">
    {{property?.property_category && PROPERTY_CATEGORIES[property?.property_category]}}</span> 
  </div>
  <div class="col-1 col" data-th="Property Name:">{{property?.property_name ?? ''}}</div>
  <div class="col-2 col" data-th="Owner Name:">{{property?.owners[0]?.first_name ?? ''}} {{property?.owners[0]?.last_name ?? ''}} </div>
  <div class="col-1 col" data-th="Plot Area:">{{property?.plot_area}}</div>
  <div class="col-2 col" data-th="Address:">
    {{(property?.address_house_number != null)? property?.address_house_number + ', ' : ''}}
    {{(property?.address_line2 != null)? property?.address_line2 + ', ' : ''}}
    {{(property?.street?.name != null)? property?.street?.name + ', ' : ''}}
    {{(property?.colony?.name != null)? property?.colony?.name + ', ' : ''}}<br>
    {{(property?.ward?.name != null)? 'Ward ' + property?.ward?.name : ''}}
  </div>
  <div class="col-1 col" data-th="Action:">
      <a [routerLink]="'/property/'+property.id" class="btn btn-sm btn-primary " (click) ="addToRecentlyViewed(property)" target="_blank">{{'property_search.view' | translate}}</a>
  </div>
</div>

<ng-template #skeleton>
  <div class="row list-item">
    <div class="col-1 col">
      {{index}}
    </div>
    <div class="col-2 col"><span class="ssc-line"></span></div>
    <div class="col-2 col"><span class="ssc-line"></span></div>
    <div class="col-1 col"><span class="ssc-line"></span></div>
    <div class="col-2 col"><span class="ssc-line"></span></div>
    <div class="col-1 col"><span class="ssc-line"></span></div>
    <div class="col-2 col"><span class="ssc-line"></span></div>
    <div class="col-1 col"><span class="ssc-line"></span></div>
  </div>
</ng-template>

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Ledger, Property} from '../../../models';
import {ActivatedRoute, Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {cloneDeep} from 'lodash';
import {PropertyCorrectionRequest} from '../../../@types/property-correction';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class FormComponent implements OnInit, OnDestroy {

  updatingProperty!: Property;
  oldServiceProperty: Property | undefined;
  ledgers: Ledger[] = [];
  oldIdProperty!: Property | undefined;

  @Input() isSubmitting = false;

  @Input() set property(val) {
    this.ledgers = val.ledgers;
    this._property = val;
    this.updatingProperty = cloneDeep(val);
  }

  @Output('submit') submitEmitter = new EventEmitter<PropertyCorrectionRequest>();

  get property() {
    return this._property;
  }

  readonly FIRST_FORM_ID = 1;
  readonly LAST_FORM_ID = 4;

  private _property!: Property;
  private _currentFormId: number = this.FIRST_FORM_ID;
  private maxUsedForm = this.currentFormId;
  private destroy$ = new ReplaySubject();

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.updateRouteParameter();

    this.currentRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((param) => {
      const form = parseInt(param.form);

      if (isNaN(form) || this.maxUsedForm < form) {
        this.updateRouteParameter();
        return;
      }

      this._currentFormId = form;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  prevForm() {
    if (this.currentFormId === this.FIRST_FORM_ID) return;

    this._currentFormId--;
    this.updateRouteParameter();
  }

  nextForm() {
    if (this.currentFormId === this.LAST_FORM_ID) return;

    this._currentFormId++;
    this.maxUsedForm = this.currentFormId;
    this.updateRouteParameter();
  }

  submit(propertyCorrectionRequest: PropertyCorrectionRequest) {
    this.submitEmitter.emit(propertyCorrectionRequest);
  }

  updateRouteParameter() {
    this.router.navigate([], {
      relativeTo: this.currentRoute,
      queryParams: {
        form: this.currentFormId,
      },
    });
  }

  setOldProperty($event: Property | undefined) {
    this.oldServiceProperty = $event;
    this.ledgers = $event?.ledgers ?? this.property.ledgers;
    this.oldIdProperty = $event;
  }

  get currentFormId() {
    return this._currentFormId;
  }
}

<div class="container" *ngIf="objection; else loadingBlockDiv">

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">My Raised Objections</li>
    </ol>
  </nav>

  <div class="listing-head mb-4 align-items-center ">
    <div class="row">
      <h3 class="mb-2 mr-3">Raised Objection details</h3>
      <span *ngIf="objection.status === ObjectionStatuses.Pending" class="badge bg-primary ms-1 mb-2 ml-2 text-center"
            style="color: white; padding-top: 6px">Pending</span>
      <span *ngIf="objection.status === ObjectionStatuses.UnderReview" class="badge bg-warning ms-1 mb-2 ml-2"
            style="color: white; padding-top: 6px">Review</span>
      <span *ngIf="objection.status === ObjectionStatuses.MissingDoc" class="badge bg-info ms-1 mb-2 ml-2"
            style="color: white;padding-top: 6px">Missing Documents</span>
      <span *ngIf="objection.status === ObjectionStatuses.Rejected" class="badge bg-danger ms-1 mb-2 ml-2"
            style="color: white; padding-top: 6px">Rejected</span>
      <span *ngIf="objection.status === ObjectionStatuses.Accepted" class="badge bg-success ms-1 mb-2"
            style="color: white; padding-top: 6px">Accepted</span>
    </div>
    <p>Details of modifications</p>
    <div class="row" *ngIf="objection.status === ObjectionStatuses.Rejected">
      <p class="ml-3">Raised Objection rejected please raise new Objection.</p>
      <a class="btn btn-primary mr-3" style="margin-left: auto;"
         [routerLink]="'/properties/' + this.propertyId + '/correction'">Raise
        Request
      </a>
    </div>
  </div>

  <div class="search-wrap mb-4">

    <div class="card-title">Updated Details</div>

    <div class="table-responsive">
      <table class="table responsive-flex-table mb-0">

        <thead class="thead-white">
       
          <th>Field Name</th>
          <th>New Data</th>
          <th>Status</th>
        
        </thead>
        <tbody *ngIf="objection; else loadingBlockTable">
        <tr *ngFor="let detail of objection.objectionPropertyDetails; let i = index">
          <th scope="row">{{PROPERTY_FIELD_NAME_RESOLVERS[detail.field] ?? detail.field}}</th>
          <td>
            {{
            PROPERTY_FIELD_RESOLVERS[detail.field] ?
                    PROPERTY_FIELD_RESOLVERS[detail.field](detail.show_new_value) :
                    ((detail.show_new_value && detail.show_new_value !== '') ? detail.show_new_value : '')
            }}
          </td>
          <td>
            <p *ngIf="detail.status" style="color: green">Accepted</p>
            <p *ngIf="!detail.status && detail.status !==null" style="color: red">Rejected</p>
            <p *ngIf="detail.status===null" style="font-weight: 500; color: #3451af;">Pending</p>
          </td>
        </tr>
        </tbody>
      </table>

    </div>
  </div>

  <div class="search-wrap mb-4" *ngIf="owners.length > 0">
    <div class="row">
      <div class="card-title">Modified Owner Details</div>
      <ng-container *ngIf="owners.length > 0">
        <p *ngIf="ownerStatus === OwnerFloorStatus.Accepted" class="section-alert mr-3" style="color: green; margin-left: auto">Accepted</p>
        <p *ngIf="ownerStatus === OwnerFloorStatus.Rejected" class="section-alert mr-3" style="color: red; margin-left: auto">Rejected</p>
        <p *ngIf="ownerStatus === OwnerFloorStatus.Pending" class="section-alert mr-3" style="margin-left: auto;  font-weight: 500; color: #3451af;">Pending</p>
      </ng-container>
    </div>
    <div class="table-responsive">
      <table class="table responsive-flex-table mb-0">

        <thead class="thead-white">
       
          <th>#</th>
          <th>Owner Name</th>
          <th>Gender</th>
          <th>Mobile Number</th>
          <th>Relation</th>
          <th>Relative Name</th>
          <th>Action</th>
       
        </thead>
        <tbody *ngIf="owners.length > 0; else noOwners">
        <ng-container *ngFor="let updateOwner of owners; let i = index">
          <tr class="table-active" *ngIf="updateOwner.type === MODEL_CHANGES_TYPES.Updated">
            <td></td>
            <td>{{ updateOwner.owner?.updatedOwner?.last_name ? updateOwner.owner?.updatedOwner?.first_name + ' ' + updateOwner.owner?.updatedOwner?.last_name : updateOwner.owner?.updatedOwner?.first_name }}</td>
            <td>{{ GENDER_TYPES[updateOwner.owner?.updatedOwner?.gender]?.name }}</td>
            <td>{{ (updateOwner.owner?.updatedOwner?.phone_number ?? '') }}</td>
            <td>{{OWNER_RELATIONS[updateOwner.owner?.updatedOwner?.relation] ?? ''}}</td>
            <td>{{updateOwner.owner?.updatedOwner?.relation_name}}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateOwner.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateOwner.type]}}
                </span>
            </td>
          </tr>
          <tr *ngIf="updateOwner.type === MODEL_CHANGES_TYPES.New">
            <td>{{ i + 1 }}</td>
            <td>{{ updateOwner.owner?.last_name ? updateOwner.owner?.first_name + ' ' +updateOwner.owner?.last_name : updateOwner.owner?.first_name}}</td>
            <td>{{GENDER_TYPES[updateOwner.owner?.gender]?.name}}</td>
            <td>{{ (updateOwner.owner?.phone_number ?? '') }}</td>
            <td>{{OWNER_RELATIONS[updateOwner.owner?.relation] ?? ''}}</td>
            <td>{{updateOwner.owner?.relation_name}}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateOwner.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateOwner.type]}}
                </span>
            </td>
          </tr>
          <tr *ngIf="updateOwner.type === MODEL_CHANGES_TYPES.Deleted">
            <td> {{ i + 1 }}</td>
            <td>{{ updateOwner.owner?.citizen.last_name ? updateOwner.owner?.citizen.first_name + ' ' +updateOwner.owner?.citizen.last_name : updateOwner.owner?.citizen.first_name }}</td>
            <td>{{ GENDER_TYPES[updateOwner.owner?.citizen.gender]?.name }}</td>
            <td>{{ (updateOwner.owner?.citizen.phone_number ?? '') }}</td>
            <td>{{OWNER_RELATIONS[updateOwner.owner?.citizen.relation] ?? ''}}</td>
            <td>{{updateOwner.owner?.citizen.relation_name}}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateOwner.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateOwner.type]}}
                </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>

    </div>
  </div>

  <div class="search-wrap mb-4" *ngIf="floors.length > 0">
    <div class="row">
      <div class="card-title">Modified Floor Details</div>
      <ng-container *ngIf="floors.length > 0">
        <p *ngIf="floorStatus === OwnerFloorStatus.Accepted" class="section-alert mr-3" style="color: green; margin-left: auto; font-weight: 500;">Accepted</p>
        <p *ngIf="floorStatus === OwnerFloorStatus.Rejected" class="section-alert mr-3" style="color: red; margin-left: auto; font-weight: 500;">Rejected</p>
        <p *ngIf="floorStatus === OwnerFloorStatus.Pending" class="section-alert mr-3" style="margin-left: auto; font-weight: 500; color: #3451af;">Pending</p>
      </ng-container>
    </div>

    <div class="table-responsive">
      <table class="table responsive-flex-table mb-0">

        <thead class="thead-white">
          <th>Floor</th>
          <th>From Year</th>
          <th>Upto Year</th>
          <th>Property Category</th>
          <th>Property type</th>
          <th>Property Sub-type</th>
          <th>Property Usage</th>
          <th>Carpet Area</th>
          <th>Action</th>
        </thead>
        <tbody *ngIf="floors.length > 0; else noFloors">
        <ng-container *ngFor="let updateFloor of floors; let i = index">
          <tr class="table-active" *ngIf="updateFloor.type === MODEL_CHANGES_TYPES.Updated">
            <td>{{ FLOOR_NUMBERS[updateFloor.floor?.updatedFloor?.floor_number] ?? '' }}</td>
            <td>{{updateFloor.floor?.updatedFloor?.from_year ? updateFloor.floor?.updatedFloor?.from_year + '-' + (toInt(updateFloor.floor?.updatedFloor?.from_year) + 1) : ''}}</td>
            <td>{{updateFloor.floor?.updatedFloor?.upto_year ? updateFloor.floor?.updatedFloor?.upto_year + '-' + (toInt(updateFloor.floor?.updatedFloor?.upto_year) + 1) : ''}}</td>
            <td>{{ PROPERTY_CATEGORIES[updateFloor.floor?.updatedFloor?.property_category] ?? '' }}</td>
            <td>{{ updateFloor.floor?.updatedFloor?.propertyType?.name }}</td>
            <td>{{ updateFloor.floor?.updatedFloor?.propertySubType?.name }}</td>
            <td>{{ PROPERTY_USAGES[updateFloor.floor?.updatedFloor?.property_usage_id] ?? ''  }}</td>
            <td>{{ updateFloor.floor?.updatedFloor?.carpet_area }}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateFloor.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateFloor.type]}}
                </span>
            </td>
          </tr>
          <tr *ngIf="updateFloor.type === MODEL_CHANGES_TYPES.New">
            <td>{{ FLOOR_NUMBERS[updateFloor.floor?.floor_number] ?? '' }}</td>
            <td>{{updateFloor.floor?.from_year ? updateFloor.floor?.from_year + '-' + (toInt(updateFloor.floor?.from_year) + 1) : ''}}</td>
            <td>{{updateFloor.floor?.upto_year ? updateFloor.floor?.upto_year + '-' + (toInt(updateFloor.floor?.upto_year) + 1) : ''}}</td>
            <td>{{ PROPERTY_CATEGORIES[updateFloor.floor?.property_category] ?? '' }}</td>
            <td>{{ updateFloor.floor?.propertyType?.name }}</td>
            <td>{{ updateFloor.floor?.propertySubType?.name  }}</td>
            <td>{{ PROPERTY_USAGES[updateFloor.floor?.property_usage_id] ?? ''  }}</td>
            <td>{{ updateFloor.floor?.carpet_area }}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateFloor.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateFloor.type]}}
                </span>
            </td>
          </tr>
          <tr *ngIf="updateFloor.type === MODEL_CHANGES_TYPES.Deleted">
            <td>{{ FLOOR_NUMBERS[updateFloor.floor?.floor?.floor_number] ?? '' }}</td>
            <td>{{updateFloor.floor?.floor?.from_year ? updateFloor.floor?.floor?.from_year + '-' + (toInt(updateFloor.floor?.floor?.from_year) + 1) : ''}}</td>
            <td>{{updateFloor.floor?.floor?.upto_year ? updateFloor.floor?.floor?.upto_year + '-' + (toInt(updateFloor.floor?.floor?.upto_year) + 1) : ''}}</td>
            <td>{{ PROPERTY_CATEGORIES[updateFloor.floor?.floor?.property_category] ?? '' }}</td>
            <td>{{ updateFloor.floor?.floor?.propertyType?.name}}</td>
            <td>{{ updateFloor.floor?.floor?.propertySubType?.name  }}</td>
            <td>{{ PROPERTY_USAGES[updateFloor.floor?.floor?.property_usage_id] ?? ''  }}</td>
            <td>{{ updateFloor.floor?.floor?.carpet_area }}</td>
            <td>
                <span [class]="MODEL_CHANGE_TYPE_CLASSES[updateFloor.type]">
                  {{MODEL_CHANGE_TYPE_NAMES[updateFloor.type]}}
                </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="search-wrap mb-4">
    <div class="mb-4">
      <div class="card-title">Current Tax</div>
      <div class="table-responsive" *ngIf="!currentTaxLoading; else LoadingSpinnerBlockUDTax">
        <table class="table responsive-flex-table mb-0">
          <thead class="thead-white">
            <th>SI No</th>
            <th>Usage Category</th>
            <th>Area Type</th>
            <th>Area(sq yd)</th>
            <th>DLC Rate</th>
            <th>Rate/2000</th>
            <th>Tax</th>
          </thead>
          <tbody *ngIf="currentTaxCalculation; else estimatedUDTaxAlternative">
          <tr *ngFor="let details of currentTaxCalculation!.taxCalculation; let i = index">
            <td>{{i + 1}}</td>
            <td>{{PROPERTY_CATEGORIES[details.propertyCategory] ?? ''}}</td>
            <td>{{details.areaType}}</td>
            <td>{{details.area}}</td>
            <td>{{details.rate ?? ''}}</td>
            <td>{{details.rate ? (details.rate / 2000).toFixed(2) : ''}}</td>
            <td>{{details.tax}}</td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td>Total Tax</td>
            <td>{{currentTaxCalculation.totalTax}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="search-wrap mb-4">
    <div class="mb-4">
      <div class="card-title">Estimated UD Tax</div>
      <div class="table-responsive" *ngIf="!updatedTaxLoading; else LoadingSpinnerBlockUDTax">
        <table class="table responsive-flex-table  mb-0">
          <thead class="thead-white">
            <th>SI No</th>
            <th>Usage Category</th>
            <th>Area Type</th>
            <th>Area(sq yd)</th>
            <th>DLC Rate</th>
            <th>Rate/2000</th>
            <th>Tax</th>
          </thead>
          <tbody *ngIf="updatedTaxCalculation; else estimatedUDTaxAlternative">
          <tr *ngFor="let details of updatedTaxCalculation!.taxCalculation; let i = index">
            <td>{{i + 1}}</td>
            <td>{{PROPERTY_CATEGORIES[details.propertyCategory] ?? ''}}</td>
            <td>{{details.areaType}}</td>
            <td>{{details.area}}</td>
            <td>{{details.rate ?? ''}}</td>
            <td>{{details.rate ? (details.rate / 2000).toFixed(2) : ''}}</td>
            <td>{{details.tax}}</td>
          </tr>
          <tr>
            <td colspan="5"></td>
            <td>Total Tax</td>
            <td>{{updatedTaxCalculation.totalTax}}</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="search-wrap mb-4">
    <div class="mb-5">
      <h3 class="card-title">Required Proofs</h3>
      <div class="table-responsive">
        <table class="table responsive-flex-table mb-0">

          <thead class="thead-white">
            <th>Document Proof</th>
            <th width="25%">Proof Type</th>
            <th>Attached File</th>
           </thead>
          <tbody>
          <tr *ngFor="let document of objection.documents; let i = index">
            <td>{{PROOF_TYPES[document.proof_type_id - 1]?.name}}</td>
            <td>{{document.documentProofType.name}}</td>
            <td>
              <a href="" (click)="$event.preventDefault(); openDocumentModal(modalFile, i)">
                {{document.name}}
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="search-wrap mb-4" *ngIf="objection.citizen_remark">
    <div class="card-title">Citizen Remark</div>
    <div class="position-relative mb-2">
      <textarea id="citizen-remark"
                class="form-control"
                style="resize: none"
                disabled
                [(ngModel)]="objection.citizen_remark"
      ></textarea>
    </div>
  </div>

  <div class="search-wrap mb-4">
    <div class="card-title">Status History</div>
    <ul class="verti-timeline list-unstyled">
      <li class="event-list">
        <div class="event-timeline-dot">
          <i class="bx bx-right-arrow-circle"></i>
        </div>
        <div class="media">
          <div class="mr-4">
            <p class="status-date">
              {{ objection.created_at | date: "d MMM"}}
            </p>
          </div>
          <div><strong>Created</strong> Objection</div>
        </div>
      </li>
      <li class="event-list" *ngFor="let action of objection.objectionStatusHistories">
        <div class="event-timeline-dot">
          <i class="bx bx-right-arrow-circle"></i>
        </div>
        <div class="media">
          <div class="mr-4">
            <h5 class="status-date">
              {{ action.created_at | date: "d MMM"}}
            </h5>
          </div>
          <div><strong>{{OBJECTION_STATUSES_NAMES[action.status] ?? ''}}</strong> {{action.remark}}</div>
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #estimatedUDTaxAlternative>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">Tax can not be calculated</td>
  </tr>
  </tbody>
</ng-template>

<ng-template #loadingBlockTable>
  <tbody>
  <tr>
    <th colspan="100">
      <div class="loading d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </th>
  </tr>
  </tbody>
</ng-template>

<ng-template #loadingBlockDiv>
  <div class="loading" style="height: 75vh;width: 100%; display: flex; justify-content: center; align-items: center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #LoadingSpinnerBlockUDTax>
  <tbody>
  <tr>
    <td colspan="100">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</ng-template>

<ng-template #ledgersLoadingAlternative>
  <tbody *ngIf="isLedgersLoading">
  <tr>
    <td colspan="100">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </td>
  </tr>
  </tbody>

  <tbody *ngIf="!ledgers && !isLedgersLoading">
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">Error occured</td>
  </tr>
  </tbody>

  <tbody *ngIf="ledgers && ledgers.length===0  && !isLedgersLoading">
    <tr>
      <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">Ledger not available</td>
    </tr>
    </tbody>

</ng-template>

<ng-template #noLedgers>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">No Ledgers data for this property.
    </td>
  </tr>
  </tbody>
</ng-template>


<ng-template #noOwners>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">No Owner modifications.
    </td>
  </tr>
  </tbody>
</ng-template>

<ng-template #noFloors>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 15px; font-weight: 600; color: #6c757d">No Floor modifications
    </td>
  </tr>
  </tbody>
</ng-template>

<ng-template #modalFile>
  <div class="doc-modal">
    <div class="doc-modal__header">
      <h3>{{ modalDocument.name }}</h3>
      <div class="doc-modal__btns">
        <i class="bi bi-arrow-clockwise icon text-primary" (click)="rotate()" *ngIf="!isDocFile"></i>
        <i class="bi bi-download icon text-primary" (click)="download()"></i>
        <i class="bi bi-x-circle icon text-primary" (click)="closeModal()"></i>
      </div>
    </div>

    <div class="doc-modal__block" id="container">
      <img id="image" class="doc-modal__block-image"
           loading="lazy"
           [src]="modalDocument.path | getFileUrl | safeUrl"
           [alt]="modalDocument.name"
           *ngIf="!isDocFile"
      >
      <iframe [src]="modalDocument.path | getFileUrl | safeUrl"
              *ngIf="isDocFile"
              class="doc-modal__block-frame"
      ></iframe>
    </div>
  </div>
</ng-template>

import {Property} from "./property";
import {PhotoType} from './photo-type';

export class Photo {
  id!: number;
  property = new Property();
  url!: string;
  latitude!: number;
  longitude!: number ;
  photoType = new PhotoType();
  name!: string;
  created_at: Date = new Date();
  updated_at: Date = new Date();
}

import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {myObjectionsRouting} from './my-objections.routing';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {ListComponent} from './list/list.component';
import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
  declarations: [
    IndexComponent,
    ListComponent,
  ],
  imports: [
    CommonModule,
    myObjectionsRouting,
    NgbModule,
    FormsModule,
    PipesModule,
  ],
})
export class MyObjectionsModule {
}

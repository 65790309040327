<div class="doc-modal">
  <div class="doc-modal__header">
    <h3>{{ currentFile.name }}</h3>
    <div class="doc-modal__btns">
      <i class="bi bi-arrow-clockwise icon text-primary" (click)="rotate()"></i>
      <ng-container>
        <i class="bi bi-arrow-left icon text-primary" (click)="slidePhoto(currentPhotoIndex - 1)"></i>
        <i class="bi bi-arrow-right icon text-primary" (click)="slidePhoto(currentPhotoIndex + 1)"></i>
        <i class="bi bi-fullscreen icon text-primary" (click)="viewerOpen = true" title="View in fullscreen"></i>
      </ng-container>
      <i class="bi bi-x-circle icon text-primary" (click)="close()"></i>
    </div>
  </div>

  <div class="doc-modal__block" id="container">
    <img id="image" class="doc-modal__block-image" loading="lazy" [src]="commonService.getAbsoluteUrl(currentFile.path)"
      [alt]="currentFile.name">
  </div>
</div>
<app-image-viewer [imageURL]="commonService.getAbsoluteUrl(currentFile.path)" [open]="viewerOpen" [id]="imageId"
  (close)="viewerOpen = false">
</app-image-viewer>

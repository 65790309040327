<td colspan="100" [style.height]="loadingHeight" *ngIf="loading; else noItems">
    <div class="no-content-row__table-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </td>
  
  <ng-template #noItems>
    <tr>
      <td colspan="100">
        <h3 class="text-muted no-content-row__no-items-header">{{ noItemsMessage }}</h3>
      </td>
    </tr>
  </ng-template>
  
import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {Street} from "../models";
import {map} from "rxjs/operators";
import {gql} from "@apollo/client/core";
import {BaseService} from './base-service.service';
import {IServicePaginate} from '../@types/service-paginate';
import {IServiceGetOne} from '../@types/service-get-one';

@Injectable({
  providedIn: 'root'
})
export class StreetService extends BaseService implements IServicePaginate<Street>, IServiceGetOne<Street> {

  constructor(private apollo: Apollo) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public getOne(column: string, value: any): Observable<Street | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.StreetPaginate && result.data.StreetPaginate.data),
      map((result: any) => result && result[0])
    )
  }

  public paginate(page: number = 1, perPage: number = 10, term: string = ''): Observable<Street[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, term)
    }).valueChanges.pipe(
      map((result: any) => result?.data?.StreetPaginate?.data ?? [])
    );
  }

  /**
   * GraphQL Queries
   * */
  private getOneQuery(column: string, value: any) {
    return gql`{
      StreetPaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: "${value}" }
      ) {
        data {
          id,
          name,
          created_at,
          updated_at,
        }
      }
    }`;
  }

  private paginateQuery(page: number = 1, perPage: number = 10, term: string = '') {
    const where = term.trim().startsWith('where') ? term : `where: {
          column: NAME, operator: LIKE, value: "${term}%"
        }`;

    return gql`{
      StreetPaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {
          id,
          name
        }
      }
    }`
  }
}

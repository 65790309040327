import {Component, Input, OnInit} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import {Property} from "../../../models";

@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent implements OnInit {
  Property = Property;
  isCollapsed: boolean = false;

  @Input('property') property!: Property;

  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
  }
}

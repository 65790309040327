import {Component, OnInit} from '@angular/core';
import {Property} from "../../../models";
import {PropertyService} from "../../../services/property.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from '../../../services';
import Swal from 'sweetalert2';
import {PropertyCategories} from '../../../enums/property-categories';
import { currentFinancialYear } from 'src/app/helpers/current-financial-year';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NdcRequestComponent } from '../ndc-request/ndc-request.component';
import TaxDetails from 'src/app/@types/tax-details';
import { TaxBillService } from 'src/app/services/tax-bill.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {FileSaverService} from 'ngx-filesaver';
import { CityService } from 'src/app/services/city.service';
import { LanguageService } from 'src/app/services/language.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  public PropertyCategories = PropertyCategories;
  propertyId!: number;
  property!: Property;
  categoryEl!: number;
  categoryFl: boolean = true;
  paymentExists: boolean = false;
  totalTaxAmount!: number;
  ledgerData!:any;
  readonly TAX_BILL_FILE_NAME = "Tax_Bill.pdf";

  private destroy$ = new Subject();
  disableDownloadTaxBtn!: boolean;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private propertyService: PropertyService,
    public authService: AuthenticationService,
    private modalService: NgbModal,
    private taxBillService: TaxBillService,
    private fileSaverService: FileSaverService,
    public cs:CityService,
    public languageService:LanguageService,
    public commonService: CommonService
  ) {
  }

  ngOnInit() {
    this.propertyId = this.currentRoute.snapshot.params.id;
    this.checkPaymentExistCurrentYear();
    this.currentRoute.params.subscribe((routeParams) => {
      const id = routeParams.id;

      if (!id) {
        this.router.navigate(['property-search']);
        return;
      }

      const successfulGetProperty = (property: Property | undefined) => {
        if (!property) {
          this.router.navigate(['property-search']);
          return
        }
        this.property = property;

        this.categoryEl = property.property_category;
        if (this.property.floors.length == 0){
          this.categoryFl = false;
        }
        else {
          for (let propertyKey in this.property.floors){
            if(this.property.floors[propertyKey].property_category === PropertyCategories.Commercial){
              this.categoryFl = true
            }
          }
        }
      }

      const errorGetProperty = () => {
        this.router.navigate(['property-search']);
      }

      this.propertyService.getOneById(id).subscribe(
        successfulGetProperty,
        errorGetProperty
      );
    });
  }
  checkPaymentExistCurrentYear(){
    this.propertyService.isPaymentExistsInYear(this.propertyId, currentFinancialYear())
    .subscribe((pmtExists) => {
      this.paymentExists = pmtExists;
    });
  }
  getPrintAll() {
    window.print();
  }
  private async redirectPopUp(url: string, message: string) {
    await  Swal.fire({
      title: this.languageService.translate('home.error'),
      html: message,
      icon: 'error',
    }).then(() => this.router.navigate([url]));
  }

  private showLoginWarning(){
    Swal.fire(this.languageService.translate('home.login_to_proceed'), '', 'warning');
  }
  raiseObjection() {
    if (!this.authService.user) {
      this.showLoginWarning();
      return;
    }
    if(this.property?.has_objection) {
      this.commonService.showErrorMessage('home.raise_correction_request_for_property_msg');
      
      return;
    }
    //To check if payment exist in current year if true it does not redirect
    if (this.paymentExists) {
      this.redirectPopUp('/property/' + this.propertyId, 'Payment Exist in Current Year');
      return;
    }
    this.router.navigate(['/properties/' + this.propertyId + '/correction']);
    
  }
  informPaymentCredit() {
    if (!this.authService.user) {
      this.showLoginWarning();
      return;
    }
    this.router.navigate(['/property/' + this.propertyId + '/inform-payment-credit']);
    
  }
  openNdcRequestForm():void {
    if(!this.authService.user){
      this.showLoginWarning();
      return;
    }
    if(this.totalTaxAmount >0) {
      Swal.fire(this.languageService.translate('home.kindly_pay_your_due_ud_tax'), '', 'warning');
      return;
    }
    const modalRef = this.modalService.open(NdcRequestComponent, { scrollable: true, centered: true, size: 'l' });
    modalRef.componentInstance.property_id = this.property.id;
  }

  getTaxDetails(taxDetail:TaxDetails) {
    this.totalTaxAmount = taxDetail?.debtWithAllRebates();
  }

  downloadTaxBill() {
    if (!this.authService.user) {
      this.showLoginWarning();
      return;
    }


    this.disableDownloadTaxBtn = true;
    this.taxBillService.getTaxBillDocument(this.property.id)
    .pipe(takeUntil(this.destroy$))
    .subscribe(res => {
      this.disableDownloadTaxBtn = false;
      if(res) {
        this.fileSaverService.save(res, this.TAX_BILL_FILE_NAME)
      }else {
        this.commonService.showErrorMessage('home.something_went_wrong');
       
      }
    }, error => {
      this.disableDownloadTaxBtn = false;
      this.commonService.showErrorMessage('home.something_went_wrong');
      
    })
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

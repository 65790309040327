import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../services";
import {PaymentService} from '../../../services/payment.service';
import {Payment} from '../../../models';
import {PaymentStatuses} from '../../../enums/payment-statuses';
import {environment} from '../../../../environments/environment';
import {PaymentSources} from '../../../enums/payment-sources';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit, OnDestroy {
  Payment = Payment;
  PaymentStatuses = PaymentStatuses;
  PaymentSources = PaymentSources;

  private loadSub!: Subscription;
  mainSearchModel: string = '';

  STATUSES_STYLES = {
    [PaymentStatuses.NotProcessed]: 'text-warning',
    [PaymentStatuses.Success]: 'text-success',
    [PaymentStatuses.Failure]: 'text-danger',
    [PaymentStatuses.Abandoned]: 'text-danger',
  }

  private _page = 1;

  perPage = 10;
  totalPayments = 0;
  payments: Payment[] = [];
  private paymentFields = `transaction_id, payment_date, amount, status, payment_method, payment_source,
   property { property_uid }, payuPayment {payment_url}`;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private paymentService: PaymentService,
  ) {}

  ngOnInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
    this.loadSub?.unsubscribe();
  }

  getDownLoadLink(transactionId: string) {
    return environment.apiBaseUrl + 'download/pdf/payment/' + transactionId;
  }

  load() {
    this.loadSub?.unsubscribe();

    this.loadSub = this.paymentService.paginateForCurrentUser(
      this.perPage,
      this.page,
      this.paymentFields,
      this.getWhereCondition()
    ).subscribe((p) => {
      if (!p) return;

      this.payments = p.data;
      this.totalPayments = p.total;
    })
  }

  set page(v) {
    this._page = v;
    this.load();
  }

  get page() {
    return this._page;
  }

  isPaymentStatus(): string{
    if(this.mainSearchModel == 'Success'){
      return '1'
    } else if(this.mainSearchModel == 'Failure'){
      return '2'
    }

    return this.mainSearchModel;
  }

  isPaymentMethod(): string{
    switch(this.mainSearchModel){
      case 'Cash': {
        return '1'
      }
      case 'Cheque': {
        return '2'
      }
      case 'DemandDrafts': {
        return '3'
      }
      case 'CreditCard': {
        return '4'
      }
      case 'DebitCard': {
        return '5'
      }
      case 'NetBanking': {
        return '6'
      }
      case 'UPI': {
        return '7'
      }
      default: {
        return this.mainSearchModel
      }
    }
  }

  getWhereCondition(): string{
    return `
    {
      AND:
      [{column: STATUS, operator: NEQ, value: ${PaymentStatuses.Abandoned}},
      {OR:
        [
          {column: TRANSACTION_ID, operator: LIKE, value: "%${this.mainSearchModel}%"},
          {column: PAYMENT_DATE, operator: LIKE, value: "%${this.mainSearchModel}%"},
          {column: AMOUNT, operator: LIKE, value: "%${this.mainSearchModel}%"},
          {column: STATUS, operator: LIKE, value: "%${this.isPaymentStatus()}%"},
          {column: PAYMENT_METHOD, operator: LIKE, value: "%${this.isPaymentMethod()}%"},
          {
          HAS: {
            relation: "property",
            condition: {
              column: PROPERTY_UID, operator: LIKE, value: "%${this.mainSearchModel}%"
            }

            }
          }
        ]
      }]
    }
  `
  }
}

import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Payment} from '../../../models';
import {PaymentService} from '../../../services/payment.service';
import {PaymentSources} from '../../../enums/payment-sources';
import {PaymentStatuses} from '../../../enums/payment-statuses';

@Component({
  selector: 'app-failure-payment',
  templateUrl: './failure-payment.component.html',
  styleUrls: ['./failure-payment.component.scss'],
})
export class FailurePaymentComponent implements OnInit {

  private destroy$ = new ReplaySubject();

  loading = true;

  transactionId: string;
  payment!: Payment;
  @ViewChild('printBlock') printBlock!: ElementRef;

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private paymentService: PaymentService,
  ) {
    this.transactionId = this.currentRoute.snapshot.params?.transactionId;
  }

  ngOnInit() {
    const fields = `amount, payment_date, payment_source, status`;

    this.paymentService.getOneByTransactionId(
      this.transactionId as unknown as number, fields,
    ).pipe(takeUntil(this.destroy$)).subscribe((p) => {
      if (
        !p || p.status !== PaymentStatuses.Failure ||
        ![PaymentSources.Payu, PaymentSources.Paytm, PaymentSources.CCAvenue, PaymentSources.Razorpay].includes(p.payment_source)
      ) {
        this.router.navigate(['']);
        return;
      }

      this.payment = p;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
    <tr>
      <th colspan="7" class="fz-16">{{'property_search.owner_occupier_attendant_details' | translate}}
        <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg table-res">
      <thead>
      <tr>
        <th>{{'property_search.type' | translate}}</th>
        <th>{{'property_search.name' | translate}}</th>
        <th>{{'property_search.gender' | translate}}</th>
        <th>{{'property_search.relation_type' | translate}}</th>
        <th>{{'property_search.relative_name' | translate}}</th>
        <th>{{'property_search.ownership_share' | translate}}</th>
        <th>{{'property_search.profession' | translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let owner of property.owners">
        <td data-th="Type: ">{{'property_search.owner' | translate}}</td>
        <td data-th="Name: ">{{owner?.first_name ?? ''}} {{owner?.last_name ?? ''}}</td>
        <td data-th="Gender: ">{{GENDER_TYPES[owner.gender]?.name}}</td>
        <td data-th="Relation: ">{{ owner.relation ? RELATION[owner.relation] : '' }}</td>
        <td data-th="Relation name: ">{{owner?.relation_name}}</td>
        <td data-th="Ownership Share:">{{owner?.ownership_share}}</td>
        <td data-th="Profession:">{{ owner?.profession ? PROFESSION[owner.profession] : '' }}</td>
      </tr>
      <tr *ngFor="let occupier of property.occupiers">
        <td data-th="Type: ">{{'property_search.occupier' | translate}}</td>
        <td data-th="Name: ">{{occupier?.first_name ?? ''}} {{occupier?.last_name ?? ''}}</td>
        <td data-th="Gender: "><span *ngIf="occupier.gender">{{GENDER_TYPES[occupier.gender]?.name}}</span></td>
        <td data-th="Relation: "><span *ngIf="occupier.relation">{{RELATION[occupier.relation]}}</span></td>
        <td data-th="Relation name: ">{{occupier?.relation_name}}</td>
        <td data-th="Ownership Share:">{{occupier?.ownership_share}}</td>
        <td data-th="Profession:">{{ occupier?.profession ? PROFESSION[occupier.profession] : '' }}</td>
      </tr>

      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>

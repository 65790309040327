import {DocumentProofTypes} from './document-proof-types';

export enum ProofTypes {
  Registry = 1,
  OldTaxBill = 2,
  AddressProof = 3,
  IdentityProof = 4,
  // AnyOtherRelevantDocs = 5,
  // PropertyPhoto = 6
}

export const ProofTypesNames: Record<ProofTypes, string> = {
  [ProofTypes.Registry]: 'Registry',
  [ProofTypes.AddressProof]: 'Address Proof',
  [ProofTypes.IdentityProof]: 'Identity Proof',
  [ProofTypes.OldTaxBill]: 'Old Tax Bill',
};
// [ProofTypes.AnyOtherRelevantDocs]: 'Any Other Relevant Docs',
// [ProofTypes.PropertyPhoto]: 'Property Photo',

export const ProofTypesProofs: Record<ProofTypes, { id: number, name: string }[]> = {
  [ProofTypes.Registry]: [{
    id: DocumentProofTypes.Registry,
    name: 'Registry',
  }],
  [ProofTypes.OldTaxBill]: [
    {id: DocumentProofTypes.OldTaxBill, name: 'Old Tax Bill'},
  ],
  [ProofTypes.AddressProof]: [
    {id: DocumentProofTypes.DrivingLicense, name: 'Driving License'},
    {id: DocumentProofTypes.RationCard, name: 'Ration Card'},
    {id: DocumentProofTypes.VoterIdentity, name: 'Voter Identity'},
    {id: DocumentProofTypes.Others, name: 'Others'},
  ],
  [ProofTypes.IdentityProof]: [{id: DocumentProofTypes.DrivingLicense, name: 'Driving License'},
    {id: DocumentProofTypes.AadharCard, name: 'Aadhar Card'},
    {id: DocumentProofTypes.RationCard, name: 'Ration Card'},
    {id: DocumentProofTypes.VoterIdentity, name: 'Voter Identity'},
    {id: DocumentProofTypes.Others, name: 'Others'}],
};

export function getFormattedProofTypes(): { id: ProofTypes, name: string }[] {
  return Object.entries(ProofTypesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}

export function getFormattedMainProofs(): { id: ProofTypes, proof: { id: number, name: string }[] }[] {
  return Object.entries(ProofTypesProofs).map((entity) => ({id: Number.parseInt(entity[0]), proof: entity[1]}));
}

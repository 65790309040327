import {Component, Input, OnInit} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import {Photo, Property} from '../../../models';

@Component({
  selector: 'app-property-info-card',
  templateUrl: './property-info-card.component.html',
  styleUrls: ['./property-info-card.component.scss']
})
export class PropertyInfoCardComponent implements OnInit {

  Property = Property;
  formattedPhotos: Photo[] = [];
  loading: boolean = true;

  @Input() property!: Property;
  @Input() photos: Photo[] = [];

  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
  }

}

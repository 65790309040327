import { Injectable } from '@angular/core';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';
import {ReplaySubject} from 'rxjs';
import {Config} from '../@types/config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private static settings: Record<string, any>;
  private isInitSubject = new ReplaySubject();

  constructor(
    private apollo: Apollo
  ) { }

  static getValue(key: keyof Config): any {
    return ConfigService.settings?.[key];
  }

  init() {
    this.apollo.query({
      query: gql`{HostSettings}`
    }).subscribe((res: any) => {
      ConfigService.settings = res.data.HostSettings ?? {};
      this.isInitSubject.next();
    })
  }

  getValue(key: string): any {
    return ConfigService.settings?.[key];
  }

  isInit$() {
    return this.isInitSubject.asObservable();
  }
}

import {parseFullName} from 'parse-full-name';

export class SearchMapProperty {

  propertyName: string | undefined;
  oldPropertyUID: string | undefined;
  fathersName: string | undefined;
  mobile: string | undefined;

  propertyUID: string | undefined;
  houseNumber: string | undefined;
  holderName: string | undefined;

  wardId: number | undefined;
  colonyId: number | undefined;
  streetId: number | undefined;
  landmark: string | undefined;
  selectedLandmark: string | undefined;
  propertyCategoryId: number | string | undefined;
  property_type_id!: number;
  property_sub_type_id!: number;

  getGraphQLCondition(): string {
    if (!this.valid()) return ''

    return this.propertyUID
      ? this.getPropertyUIDCondition()
      : this.getAddressCondition();
  }

  valid() {
    return !!(this.propertyUID 
        || this.houseNumber || this.holderName || this.wardId 
        || this.colonyId || this.streetId || this.oldPropertyUID 
        || this.propertyName || this.fathersName || this.mobile 
        || this.landmark || this.propertyCategoryId
        || this.property_type_id || this.property_sub_type_id)
  }

  private getPropertyUIDCondition() {
    return `where: {column: "property_uid", value: "${this.propertyUID}"}`
  }

  private getAddressCondition() {
    let where = `where: { AND: [ `;

    const parsedFullName = this.holderName ? parseFullName(this.holderName) : undefined;

    if (parsedFullName) {
      where += ` {HAS: { relation: "owners", condition: { OR: [
        ${parsedFullName.first?.toString() !== '' ? `{column: "first_name", operator: LIKE, value: "%${parsedFullName.first}%"}` : ''}
        ${parsedFullName.last?.toString() !== '' ? ` {column: "last_name", operator: LIKE, value: "%${parsedFullName.last}%"}` : ''}
        ${parsedFullName.last?.toString() !== '' ? `{column: "first_name", operator: LIKE, value: "%${parsedFullName.last}%"},` : ''}
        ${parsedFullName.first?.toString() !== '' ? ` {column: "last_name", operator: LIKE, value: "%${parsedFullName.first}%"}` : ''}
      ] } } } `;
    }
    if (this.houseNumber) {
      where += ` {column: "address_house_number", value: "${this.houseNumber}"} `;
    }
    if (this.wardId) where += ' ' + `{column: "ward_id", value: ${this.wardId}}`;
    if (this.colonyId) where += ' ' + `{column: "colony_id", value: ${this.colonyId}}`;
    if (this.streetId) where += ' ' + `{column: "street_id", value: ${this.streetId}}`;


    if (this.propertyName) {
      where += ` {column: "property_name", operator: LIKE, value: "%${this.propertyName}%"} `;
    }
    if (this.oldPropertyUID) {
      where += ` {column: "old_house_tax_no", operator: LIKE, value: "${this.oldPropertyUID}"} `;
    }

    // if(this.landmark){
    //   where += `{column: "landmark", operator: LIKE, value: "%${this.landmark}%"} `; 
    // }
    if(this.propertyCategoryId) {
      where += ' ' + `{column: "property_category", value: ${this.propertyCategoryId}}`;
    }
    if(this.property_type_id) {
      where += ' ' + `{column: "property_type_id", value: ${this.property_type_id}}`;
    }
    if(this.property_sub_type_id) {
      where += ' ' + `{column: "property_sub_type_id", value: ${this.property_sub_type_id}}`;
    }
    if(this.mobile){
      where += `{AND:[
        {HAS: {relation: "owners", condition: {column: "phone_number", value: "${this.mobile}" }}}
      ]}`;
    }

    if (this.fathersName) {
      where += `{AND:[
        {HAS: {relation: "owners", condition: {column: "relation", value: "${2}" }}}
        {HAS: {relation: "owners", condition: {column: "relation_name", operator: LIKE, value: "%${this.fathersName}%" }}}
      ]}`;
    }

    return where + ']}';
  }
}

import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { City } from '../models';


const bharatpurConfig = {
  name: 'bharatpur',
  mapCenterCoordinate: [27.220454, 77.495830],
  id:115,
  cityName: 'Bharatpur',
  districtName: 'Bharatpur',
  districtId: 46,
  stateId: 1
}

const jodhpurConfig = {
  name: 'jodhpur',
  mapCenterCoordinate: [26.2389, 73.0243],
  id:5,
  cityName: 'Jodhpur',
  districtName: 'Jodhpur',
  districtId: 5,
  stateId: 1
}

const alwarConfig = {
  name: 'alwar',
  mapCenterCoordinate: [27.5633382470884, 76.6201506781413],
  id:126,
  cityName: 'Alwar',
  districtName: 'Alwar',
  districtId: 5,
  stateId: 1
}

const jodhpurNorthConfig = {
  name: 'jodhpurNorth',
  mapCenterCoordinate: [26.33955937, 73.03224966],
  id:122,
  cityName: 'Jodhpur North',
  districtName: 'Jodhpur',
  districtId: 5,
  stateId: 1
}

const kotputliConfig = {
  name: 'kotputli',
  mapCenterCoordinate: [27.705949, 76.194206],
  id:121,
  cityName: 'Kotputli',
  districtName: 'Kotputli',
  districtId: 43,
  stateId: 1
}

const domainToCityMap: any = {
  'cptjns.ycspl.in': jodhpurConfig,
  'nnjsouth.in': jodhpurConfig,
  'mcaudtax.in': alwarConfig,
  'alwar-dev.ycspl.in': alwarConfig,
  'cptjns-dev.ycspl.in': jodhpurConfig,
  'cptbns.ycspl.in': bharatpurConfig,
  'cptbns-dev.ycspl.in': bharatpurConfig,
  'udtbharatpurmc.co.in': bharatpurConfig,
  'kotputli-dev.ycspl.in':kotputliConfig,
  'udtaxkotputli.org':kotputliConfig,
  'localhost:4200': alwarConfig,
  'localhost:4300': bharatpurConfig,
  'cnj-dev.ycspl.in' : jodhpurNorthConfig,
  'nnjnorth.in': jodhpurNorthConfig
};

@Injectable({
  providedIn: 'root',
})
export class CityService {
  public city!: string;
  public id!:number;
  public cityName!:string;
  public districtName!: string;
  public districtId!:number;
  public stateId!:number;
  public mapCenterCoordinates: [number, number] = [26.2389, 73.0243];

  constructor(private apollo: Apollo) {
    
    this.city = environment.defaultCity;
    if (domainToCityMap[document.location.host] !== undefined) {
      this.city = domainToCityMap[document.location.host].name;
      this.mapCenterCoordinates = domainToCityMap[document.location.host].mapCenterCoordinate;
      this.id = domainToCityMap[document.location.host].id;
      this.cityName = domainToCityMap[document.location.host].cityName;
      this.districtName = domainToCityMap[document.location.host].districtName;
      this.districtId = domainToCityMap[document.location.host].districtId;
      this.stateId = domainToCityMap[document.location.host].stateId;
      localStorage.setItem('cityId', this.id.toString());
    }
  }

  /**
   * 
   * @returns Cities from Database
   */

  public getAll(): Observable<City[]> {
    return this.apollo.watchQuery({
      query: this.getAllQuery(),
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.Cites),
      map((result: any) => result ?? []),
    );
  }

  /**
   * GraphQL Queries
   * */
  private getAllFields() {
    return `
      id,
      name,
      access_penalties,
      description,
      district_id,
      district {
        id,
        name
      }
    `;
  }

  private getAllQuery() {
    return gql`{ Cites { ${this.getAllFields()} } }`;
  }
}
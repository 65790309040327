import {Component, OnInit} from '@angular/core';
import {Property, User} from "../../../models";
import {CitizenService} from "../../../services/citizen.service";
import {Observable, ReplaySubject} from "rxjs";
import {map, takeUntil} from "rxjs/operators";
import {AuthenticationService} from "../../../services";
import {cloneDeep} from "@apollo/client/utilities";
import {OtpConfirmComponent} from "../../../dialogs/otp-confirm/otp-confirm.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import Swal from "sweetalert2";
import {Apollo, gql} from "apollo-angular";


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  destroy$ = new ReplaySubject();
  userModel!:  User;
  checkNumberToken: string = '';

  constructor(
    public auth: AuthenticationService,
    public apollo: Apollo
  ) {
  }

  ngOnInit(): void {
    this.setUser();
  }

  updateUser() {
    if(this.ifUserChange()){
      if(this.userModel.phone_number !== this.auth.user?.phone_number){
        this.checkNewNumber();
      } else {
        this.update();
      }
    } else {
      this.errorDataNotChanged();
    }
  }

  checkNewNumber(){
    this.auth.phoneNumberConfirmation().subscribe((token) => {
      this.checkNumberToken = token.data.phoneNumberConfirmation;

      Swal.fire({
        title: 'Confirm OTP',
        input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#556ee6',
        cancelButtonColor: '#f46a6a',
        allowOutsideClick: true
      }).then(text => {
        this.auth.phoneNumberCheckOtp(this.checkNumberToken, text.value).subscribe((res) => {
          this.update();
        })
      });
    });
  }

  update(){
    this.updateQuery(this.userModel.id, this.userModel).subscribe((user) => {
        this.successUserUpdate()
      },
      (err) => {
        this.errorUserUpdated();
      }
    )
  }

  updateQuery(id: number, user: User): Observable<User> {
    return this.apollo.mutate({
      mutation: gql`
        mutation {
          updateUserUnprotected(
            id: ${id}
            first_name: "${user.first_name}"
            last_name: "${user.last_name}"
            phone_number: "${user.phone_number}"
            email: "${user.email}"
          ) {
            id,
            first_name,
            last_name,
            phone_number,
            email,
          }
        }`
    }).pipe(
      map((result: any) => result.data && result.data.updateUser)
    );
  }

  ifUserChange() :boolean{
    return !(this.userModel.first_name == this.auth.user?.first_name &&
      this.userModel.last_name == this.auth.user?.last_name &&
      this.userModel.phone_number == this.auth.user?.phone_number &&
      this.userModel.email == this.auth.user?.email);
  }

  setUser(){
    const normalizedUser = Object.assign((new User()), this.auth.user);
    this.userModel = cloneDeep(normalizedUser);
  }

  successUserUpdate(): void {
    Swal.fire('Success!', 'User updated!', 'success');
  }

  errorUserUpdated(): void {
    Swal.fire('Error', 'Failed to update a user, try again later!', 'error');
  }

  errorDataNotChanged(): void {
    Swal.fire('Error', 'Edit data to update user information', 'error');
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

<div class="mb-4">
  <!-- Floor Details Start -->
  <div class="row mb-4">
    <div class="col-md-3 col-6">
      <app-vertical-title title="{{'property_search.floor_details' | translate}}"
                          subTitle="{{'property_search.floor_details_of_property' | translate}}"
      ></app-vertical-title>
    </div>
    <div class="col-md-4 col-6">
      <label for="floor-count">{{'property_search.floor_count' | translate}}</label>
      <input class="form-control" type="number" id="floor-count" placeholder="{{'property_search.enter_floor_count' | translate}}"
             [(ngModel)]="property.floor_count"
      >
    </div>
  </div>
  <!-- Floor Details End -->

  <hr>

  <div class="d-flex justify-content-end mb-4">
    <button class="btn btn-primary" (click)="addFloor(floorFormModal)">{{'property_search.add' | translate}}</button>
  </div>

  <div class="card mb-4">
    <div class="card-body">
      <div class="card-title mb-4" style="font-size: 16px"><strong>{{'property_search.floor_listing' | translate}}</strong></div>

      <table class="table responsive-flex-table">
        <thead>
        <th>{{'property_search.floor' | translate}}</th>
        <th>{{'property_search.from_year' | translate}}</th>
        <th>{{'property_search.upto_year' | translate}}</th>
        <th>{{'property_search.property_category' | translate}}</th>
        <th>{{'property_search.property_type' | translate}}</th>
        <th>{{'property_search.property_sub_type' | translate}}</th>
        <th>{{'property_search.property_usage' | translate}}</th>
        <th>{{'property_search.carpet_area_in_sqft' | translate}}</th>
        <th width="80px">{{'property_search.action' | translate}}</th>
        </thead>
        <tbody *ngIf="property?.floors?.length && property.floors.length > 0; else noFloors">
        <tr *ngFor="let floor of property.floors; let i = index">
          <td>{{FLOOR_NAMES[floor.floor_number] ?? ''}}</td>
          <td>{{floor.from_year ? floor.from_year + '-' + (toInt(floor.from_year) + 1) : ''}}</td>
          <td>{{floor.upto_year ? floor.upto_year + '-' + (toInt(floor.upto_year) + 1) : ''}}</td>
          <td>{{PROPERTY_CATEGORIES[floor.property_category]}}</td>
          <td>{{floor.propertyType?.name ?? ''}}</td>
          <td>{{floor.propertySubType?.name ?? ''}}</td>
          <td>{{PROPERTY_USAGES[floor.property_usage_id]}}</td>
          <td>{{floor.carpet_area ?? ''}}</td>
          <td width="80px">
            <div class="d-flex justify-content-between mob_align_right" style="font-size: 18px">
              <i class="bi bi-pencil-fill mr-2 text-primary" style="cursor: pointer"
                 (click)="editFloor(floorFormModal, floor)"
              ></i>
              <i class="bi bi-x-lg text-danger" style="cursor: pointer" (click)="deleteFloor(i)"></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="card" *ngIf="oldServiceProperty && oldServiceProperty.floors.length">
    <div class="card-body">
      <div class="card-title mb-4" style="font-size: 16px"><strong>{{'property_search.old_service_floors' | translate}}</strong></div>

      <table class="table responsive-flex-table">
        <thead>
        <th>{{'property_search.floor' | translate}}</th>
        <th>{{'property_search.from_year' | translate}}</th>
        <th>{{'property_search.upto_year' | translate}}</th>
        <th>{{'property_search.property_category' | translate}}</th>
        <th>{{'property_search.property_type' | translate}}</th>
        <th>{{'property_search.property_sub_type' | translate}}</th>
        <th>{{'property_search.property_usage' | translate}}</th>
        <th>{{'property_search.carpet_area_in_sqft' | translate}}</th>
        </thead>
        <tbody>
        <tr *ngFor="let floor of oldServiceProperty.floors; let i = index">
          <td>{{FLOOR_NAMES[floor.floor_number] ?? ''}}</td>
          <td>{{floor.from_year ? floor.from_year : ''}}</td>
          <td>{{floor.upto_year ? floor.upto_year : ''}}</td>
          <td>{{PROPERTY_CATEGORIES[floor.property_category]}}</td>
          <td>{{floor.propertyType?.name ?? ''}}</td>
          <td>{{floor.propertySubType?.name ?? ''}}</td>
          <td>{{PROPERTY_USAGES[floor.property_usage_id]}}</td>
          <td>{{floor.carpet_area ?? ''}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">{{'property_search.previous' | translate}}</button>

  <button class="btn btn-primary" (click)="nextForm()">{{'property_search.next' | translate}}</button>
</div>

<ng-template #floorFormModal let-modal>
  <div class="modal-header">
    <div class="title">
      <h5 class="modal-title mb-2">{{'property_search.floor_details' | translate}}</h5>
      <h6 class="modal-sub-title">{{'property_search.fill_required_information' | translate}}</h6>
      <i class="fa fa-close fa-times" (click)="modal.dismiss();"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row mb-4">
      <div class="col-12">
        <label for="floor-number">{{'property_search.floor_number' | translate}}<span class="text-danger">*</span></label>
        <ng-select id="floor-number"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="{{'property_search.select_floor_number' | translate}}"
                   [items]="FORMATTED_FLOOR_NUMBERS"
                   [(ngModel)]="formFloor.floor_number"
                   [disabled]="floorFormMode === FLOOR_FORM_EDIT_MODE"
                   appInputError
                   [(error)]="floorFormErrors.floor_number"
                   [disabled]="disableFloorNumber"
        ></ng-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label>{{'property_search.from_year' | translate}}<span class="text-danger">*</span></label>
        <ng-select placeholder="{{'property_search.select_year' | translate}}" [(ngModel)]="formFloor.from_year" [compareWith]="compareFinancialYear"
                   appInputError
                   [(error)]="floorFormErrors.from_year">
          <ng-option *ngFor="let year of financialYears" [value]="year" [disabled]="yearsWithPayments.includes(year)">
            {{year + '-' + (year + 1)}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label>{{'property_search.upto_year' | translate}}<span class="text-danger">*</span></label>
        <ng-select placeholder="{{'property_search.select_year' | translate}}" [(ngModel)]="formFloor.upto_year" [compareWith]="compareFinancialYear"
                   appInputError
                   [(error)]="floorFormErrors.upto_year">
          <ng-option *ngFor="let year of financialYears" [value]="year" [disabled]="yearsWithPayments.includes(year)">
            {{year + '-' + (year + 1)}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label for="property-category">{{'property_search.property_category' | translate}}<span class="text-danger">*</span></label>
        <ng-select id="property-category"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="{{'property_search.select_property_category' | translate}}"
                   [items]="FORMATTED_PROPERTY_CATEGORIES_WITHOUT_MIX"
                   [ngModel]="formFloor.property_category"
                   (ngModelChange)="setPropertyCategory($event)"
                   appInputError
                   [(error)]="floorFormErrors.property_category"
        ></ng-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label for="property-type">{{'property_search.property_type' | translate}}<span class="text-danger">*</span></label>
        <ng-select id="property-type"
                   placeholder="{{'property_search.select_property_type' | translate}}"
                   [(ngModel)]="formFloor.propertyType"
                   appInputError
                   [(error)]="floorFormErrors.property_type"
                   (change) = "getPropertySubtype($event)"
        >
      <ng-option *ngFor="let data of propertyTypeData" [value] = "data">{{data.name}}</ng-option>
    </ng-select>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <label for="property-sub-type">{{'property_search.property_sub_type' | translate}}<span class="text-danger">*</span></label>
       
        <ng-select id="property-sub-type"
                   placeholder="{{'property_search.select_sub_property_type' | translate}}"
                   [(ngModel)]="formFloor.propertySubType"
                   appInputError
                   [(error)]="floorFormErrors.property_sub_type"
        >
        <ng-option *ngFor="let data of propertySubTypeData" [value] = "data">{{data.name}}</ng-option>
      </ng-select>
      </div>
    </div>


    <div class="row mb-4">
      <div class="col-12">
        <label for="property-usage">{{'property_search.property_usage' | translate}}</label>
        <ng-select id="property-usage"
                   bindValue="id"
                   bindLabel="name"
                   placeholder="{{'property_search.select_property_usage' | translate}}"
                   [items]="FORMATTED_PROPERTY_USAGES"
                   [(ngModel)]="formFloor.property_usage_id"
                   appInputError
                   [(error)]="floorFormErrors.property_usage_id"
        ></ng-select>
      </div>
    </div>

   
    <div class="row mb-4">
      <div class="col-12">
        <label>{{'property_search.carpet_area_in_sqft' | translate}}<span class="text-danger">*</span></label>
        <input class="form-control" type="text"
               placeholder="{{'property_search.enter_carpet_area' | translate}}"
               [(ngModel)]="formFloor.carpet_area"
               appInputError
               [(error)]="floorFormErrors.carpet_area"
               [disabled]="disableCarpetArea"
        >
      </div>
    </div>

    
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="floorSubmit(modal)">{{'property_search.submit' | translate}}</button>
  </div>
</ng-template>

<!-- No Floors -->
<ng-template #noFloors>
  <tbody>
  <tr>
    <td colspan="100" style="font-size: 18px; font-weight: 600; color: #6c757d">{{'property_search.no_floors' | translate}}</td>
  </tr>
  </tbody>
</ng-template>

import {Payment} from "./payment";
import {Property} from "./property";
import {RebateTypes} from '../enums/rebate-types';

export class Ledger {
  static TAX_BILL_TYPE = 1;
  static REBATE_TYPE = 2;
  static PENALTY_TYPE = 3;
  static PAYMENT_TYPE = 4;
  static CHEQUE_RETURN = 5;

  static LEDGER_TYPES: {[key: number]: string} = {
    [Ledger.TAX_BILL_TYPE]: 'tax bill',
    [Ledger.REBATE_TYPE]: 'rebate',
    [Ledger.PENALTY_TYPE]: 'penalty',
    [Ledger.PAYMENT_TYPE]: 'bill payment(Receipt)',
    [Ledger.CHEQUE_RETURN]: 'cheque return',
  };

  id!: number;
  transaction_datetime: Date = new Date();
  payment: Payment = new Payment();
  property: Property = new Property();
  financial_year!: number;
  transaction_type!: number;
  debit_amount!: number;
  credit_amount!: number;
  balance!: number;
  remarks!: string;
  rebate_type!: RebateTypes;

  created_at: Date = new Date();
  updated_at: Date = new Date();
}

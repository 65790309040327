import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";

const routes: Routes = [
  {
    path: 'ndc-status',
    component: MainComponent,
    children: [
      {
        path: ':urlKey',
        component: IndexComponent
      },
    ]
  }
];

export const ndcStatusRouting = RouterModule.forChild(routes);

<section class="container-fluid launch-page">

  <div class="row launch-row">
    <div class="col-12 col-lg-4 col-md-4">
      <header class="text-data">
        <h4>WebPortal Launch</h4>
        <h2> Property Tax Information Management 
          System <br><strong>Bharatpur Nagar Nigam</strong></h2>
      </header>
      <div class="d-flex w-100 wrap members">
        <article class="member">
            <img src="assets/images/ashok-gahlot.png" class="img-circle">
            <section class="member-title">
                <h5>Sh. Ashok Gehlot  </h5>
                  <p>Hon'ble Chief Minister
                    of Rajasthan</p> 
                   
            </section>
        </article>
        <article class="member">
            <img src="assets/images/shanti-k.png" class="img-circle">
            <section class="member-title">
                <h5>Sh. Shanti K. Dharival   </h5>
                  <p>Minister, UDH & Local
                    Self Govt.</p> 
                   
            </section>
        </article>
        <article class="member">
            <img src="assets/images/bharatpur/abhijit.png" class="img-circle">
            <section class="member-title">
                <h5>Sh. Abhijeet Kumar   </h5>
                  <p>Mayor, Nagar Nigam
                    Bharatpur</p> 
                   
            </section>
        </article>
        <article class="member">
            <img src="assets/images/bharatpur/Akhilesh.png" class="img-circle">
            <section class="member-title">
                <h5>Sh. Akhilesh Kumar Pipal   </h5>
                  <p>Commissioner, Nagar Nigam
                    Bharatpur </p> 
                   
            </section>
        </article>
      </div>
      <button class="btn  btn-launch" [routerLink]="['']" [queryParams]="{ launch:1 }">Launch Now <img src="assets/images/right-arow.png" class="anim"> </button>
    </div>
    <div class="col-12 col-lg-8 col-md-8">
        <img src="../../../../assets/images/bharatpur/launch.png" class="img-fluid bmc-logo">
    </div>
  </div>


</section>

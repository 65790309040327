<div class="table-hold tax-due-table" *ngIf="loading === false; else loadingBlock">
  <table class="table table-bordered th-bg">
    <thead>
    <tr>
      <th colspan="7">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div>{{'property_search.tax_calculation_current_year' | translate}}</div>
        </div>
      </th>
    </tr>
    <tr>
      <th>{{'property_search.usage_category' | translate}}</th>
      <th>{{'property_search.area_type' | translate}}</th>
      <th>{{'property_search.area_sq_yd' | translate}}</th>
      <th>{{'property_search.dlc_rate_per_sqmt' | translate}}</th>
      <th>{{'property_search.dlc_rate' | translate}}</th>
      <th>{{'property_search.tax' | translate}}</th>
    </tr>
    </thead>
    <tbody *ngIf="taxCalculation; else noTaxCalculationBlock">
    <tr *ngFor="let currentTaxCalculation of taxCalculation.taxCalculation">
      <td data-th="Usage Category">{{Property.PROPERTY_CATEGORIES[currentTaxCalculation.propertyCategory] ?? ''}}</td>
      <td data-th="Area Type">{{currentTaxCalculation.areaType}}</td>
      <td data-th="Area (sq yd)">{{currentTaxCalculation.area}}</td>
      <td data-th="Rate (per Sq.Mt.)">{{currentTaxCalculation.rate ?? ''}}</td>
      <td data-th="Rate/2000">{{currentTaxCalculation.rate ? (currentTaxCalculation.rate / 2000).toFixed(2) : ''}}</td>
      <td data-th="Tax">{{currentTaxCalculation.tax}}</td>
    </tr>
    <tr *ngIf="property.property_category === PropertyCategories.Mix">
      <td colspan="6"><section class="d-flex justify-content-between mob-fix"><strong>{{'property_search.total_tax' | translate}}</strong> <span class="pdr-25">{{taxCalculation.totalTax}}</span></section></td>
     
    </tr>
    </tbody>
  </table>
</div>

<ng-template #loadingBlock>
  <div class="table-hold tax-due-table loading-container" id="tax-due">
    <table class="table table-bordered th-bg">
      <thead>
      <tr>
        <th colspan="6">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div>{{'property_search.tax_due_summary' | translate}}</div>
            <div class="print-icon print-icon_header-table">
            </div>
          </div>
        </th>
      </tr>
      </thead>
    </table>

    <div class="loading-container-spinner-block">
      <div class="spinner-border" role="status">
        <span class="sr-only">{{'property_search.loading' | translate}}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noTaxCalculationBlock>
  <tbody>
  <tr>
    <th colspan="6">
      <div style="display: flex; justify-content: center; align-items: center;">
        <div>{{'property_search.tax_calculation_can_not_be_calculated' | translate}}</div>
      </div>
    </th>
  </tr>
  </tbody>
</ng-template>

export * from './citizen';
export * from './city'
export * from './district'
export * from './document-proof-type'
export * from './ownership-type'
export * from './payment'
export * from './payu'
export * from './payu-payment'
export * from './floor';
export * from './ledger';
export * from './photo';
export * from './property';
export * from './property-category'
export * from './property-status'
export * from './property-sub-type'
export * from './property-type'
export * from './ratezone'
export * from './rebate'
export * from './sector'
export * from './society'
export * from './state'
export * from './ward'
export * from './user';
export * from './street';
export * from './colony';

<ul ngbNav [activeId]="currentFormId" class="form-steppes" #nav="ngbNav">
  <li class="activated-step" [ngbNavItem]="FIRST_FORM_ID" [disabled]="true">
    <a ngbNavLink>1. {{' property_search.general_information_tab' | translate}}</a>
    <ng-template ngbNavContent>
      <app-general-information [property]="updatingProperty"
                               [oldProperty]="property"
                               [isOldPropertyFound]="!!oldServiceProperty"
                               (newOldProperty)="setOldProperty($event)"
                               (nextForm)="nextForm()"
      ></app-general-information>
    </ng-template>
  </li>
  <!--  <li [class.activated-step]="currentFormId >= 2" [ngbNavItem]="2" [disabled]="true">-->
  <!--    <a ngbNavLink>{{'2. utility details' | uppercase}}</a>-->
  <!--    <ng-template ngbNavContent>-->
  <!--      <app-utility-details [property]="updatingProperty"-->
  <!--                           (previousForm)="prevForm()"-->
  <!--                           (nextForm)="nextForm()"-->
  <!--      ></app-utility-details>-->
  <!--    </ng-template>-->
  <!--  </li>-->
  <li [class.activated-step]="currentFormId >= 2" [ngbNavItem]="2" [disabled]="true">
    <a ngbNavLink>2. {{'property_search.owner_details_tab' | translate}}</a>
    <ng-template ngbNavContent>
      <app-owner-details [property]="updatingProperty"
                         (previousForm)="prevForm()"
                         (nextForm)="nextForm()"
      ></app-owner-details>
    </ng-template>
  </li>
  <li [class.activated-step]="currentFormId >= 3" [ngbNavItem]="3" [disabled]="true">
    <a ngbNavLink>3. {{'property_search.floor_details_tab' | translate}}</a>
    <ng-template ngbNavContent>
      <app-floor-details [property]="updatingProperty"
                         [oldServiceProperty]="oldServiceProperty"
                         [ledgers]="ledgers"
                         (previousForm)="prevForm()"
                         (nextForm)="nextForm()"
      ></app-floor-details>
    </ng-template>
  </li>
  <li [class.activated-step]="currentFormId === LAST_FORM_ID" [ngbNavItem]="LAST_FORM_ID" [disabled]="true">
    <a ngbNavLink>4. {{'property_search.review_tab' | translate}}</a>
    <ng-template ngbNavContent>
      <app-review [oldProperty]="property"
                  [updatedProperty]="updatingProperty"
                  [isSubmitting]="isSubmitting"
                  [oldPropertyId]="oldServiceProperty?.id?.toString()"
                  (previousForm)="prevForm()"
                  (submit)="submit($event)"
      ></app-review>
    </ng-template>
  </li>
</ul>

<div class="mb-4" [ngbNavOutlet]="nav"></div>

export enum ObjectionStatuses {
  Pending = 1,
  UnderReview = 2,
  MissingDoc = 3,
  Accepted = 4,
  Rejected = 5,
}

export const ObjectionStatusesNames: Record<ObjectionStatuses, string> = {
  [ObjectionStatuses.Pending]: 'Pending',
  [ObjectionStatuses.UnderReview]: 'Under Review',
  [ObjectionStatuses.MissingDoc]: 'Missing Doc',
  [ObjectionStatuses.Accepted]: 'Accepted',
  [ObjectionStatuses.Rejected]: 'Rejected',
}

export function getFormattedObjectionStatuses(): { id: ObjectionStatuses, name: string }[] {
  return Object.entries(ObjectionStatusesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}

export const OwnerFloorStatus = {
  Pending: null,
  Accepted: true,
  Rejected: false
}

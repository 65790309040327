<div class="container content-container">
    <div class="row mt-4 mr-5">
       <div class="col-md-12 mr-5">
          <div>
            <h2>Refund & Cancellation Policy</h2>
               <p>
                  Last updated: <!-- -->15 July 2022
               </p>
               <p>The online payment once received will not be refunded for successful bill payment services. Cancellation of applications / bill payments shall not be entertained.</p>
               <p>By Default all failure Transactions are automatically refunded as per the standard policy of the respective bank.. However for the same to reflect in your account it may take specific time as per the standard policy of the respective bank. Generally refunds will be reflected within a standard number of Days in Case of Net Banking/Debit Card Transaction &a standard number of Days (Next Billing Cycle) for Credit Card Transactions.</p>
               <p>We request all Tax payers  to kindly verify the Tax bill  Details carefully before making any payment. In case if any queries kindly raise a correction requests or contact <span *ngIf="cs.city === 'bharatpur'">
                  Bharat Municipal Corporation, Bharatpur, Rajasthan.
               </span>
             <span *ngIf="cs.city === 'jodhpur'">
               Municipal Corporation of Jodhpur, South, Rajasthan.
             </span></p> 
       </div>
    </div>
 </div>
<div class="container pro-detila-page" *ngIf="property">

  <ol class="breadcrumb">
    <li class="breadcrumb-item"><a [routerLink]="'/'">{{'property_search.home' | translate}}</a></li>
    <li class="breadcrumb-item active" aria-current="page">{{'property_search.property_details' | translate}}</li>
  </ol>

  <div class="property-details__header">
    <h2 class="m-0">{{'property_search.property_details' | translate}}</h2>

    <div class="d-flex">
      <button class="btn btn-primary mr-2"
        (click)="informPaymentCredit()">{{'property_search.inform_payment_credit' | translate}}
      </button>
      <button class="btn btn-primary mr-2" (click)="downloadTaxBill()"
        [disabled]="disableDownloadTaxBtn">{{(disableDownloadTaxBtn ? 'property_search.downloading' : 'property_search.download_tax_bill') | translate}}</button>
      <button class="btn btn-primary mr-2" (click)="openNdcRequestForm()">
        {{'property_search.request_ndc' | translate}}</button>
      <button *ngIf="property.source != 'old_survey'" class="btn btn-primary mr-2"
        (click)="raiseObjection()">{{'property_search.raise_objection' | translate}}
        
      </button>

      <div class="print-icon">
        <button printSectionId="all-data" ngxPrint [useExistingCss]="true"
          styleSheetFile="assets/styles-for-print.scss">
          <i class="bi bi-printer"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="row details-sections">
    <div class="col-lg-7 col-md-7 col-sm-6 col-12" *ngIf="property.photos[0]">
      <app-photos-slider [photos]="property.photos"></app-photos-slider>
    </div>

    <div class="col-lg-5 col-md-5 col-sm-6 col-12 map-mob" *ngIf="property.latitude">
      <app-map [property]="property"></app-map>
    </div>
  </div>

  <div id="all-data">
    <app-tax-details [property]="property" (taxData)="getTaxDetails($event)"></app-tax-details>
    <app-tax-calculation-current-year [property]="property" [ledgerData] = "ledgerData"></app-tax-calculation-current-year>
    <app-property-details [property]="property" [floors]="property.floors"></app-property-details>
    <app-address-details [property]="property"></app-address-details>
    <app-building-information [property]="property"></app-building-information>
    <app-building-utility-information [property]="property" *ngIf="cs.city === 'jodhpur'"></app-building-utility-information>
    <app-commercial-details [property]="property" *ngIf="categoryEl === PropertyCategories.Commercial || categoryFl">
    </app-commercial-details>
    <app-owner-details [owners]="property.owners" [property]="property"></app-owner-details>
    <app-floors-details [property]="property" [floors]="property.floors"></app-floors-details>
    <app-property-tax-ledger [property]="property" (ledgerData)="ledgerData = $event"></app-property-tax-ledger>
  </div>
</div>
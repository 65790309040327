
<button class="nav-button left" (click)="showPrevious()" *ngIf="documentIndex > 0">
    <span aria-hidden="true" class="carousel-control-prev-icon"></span>
</button>

<app-image-viewer [imageURL]="currentPhotoUrl" [open]="openImageViewer" [id] = "imageId" (close)="close.emit()">
</app-image-viewer>

<app-pdf-viewer *ngIf="!openImageViewer" [url]="pdfFileUrl" (close)="close.emit()"></app-pdf-viewer>


<button class="nav-button right" (click)="showNext()" *ngIf="documentIndex < documents.length - 1">
    <span aria-hidden="true" class="carousel-control-next-icon"></span>
</button>
import { Injectable } from '@angular/core';
import TaxPayRequest from '../@types/tax-pay-request';
import { Observable } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

interface RazorpayOrder {
  order_id: string | null,
  callback_url: string,
  cancel_url: string,
}

@Injectable({
  providedIn: 'root'
})
export class RazorpayService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  public createRazorpayOrder(info: TaxPayRequest): Observable<RazorpayOrder> {
    return this.apollo.mutate({
      mutation: gql`mutation{ createRazorpayOrder(
        first_name: "${info.firstName}",
        last_name: "${info.lastName}",
        email: "${info.email}",
        phone: "${info.phoneNumber}",
        property_id: ${info.propertyId}
        amount: ${info.amount},
      ) {order_id, callback_url, cancel_url} }`,
    }).pipe(
      map((res: any) => res?.data?.createRazorpayOrder)
    );
  }

  public startPayment(params: RazorpayOrder & TaxPayRequest): void {
    const form = document.createElement('form');
    form.style.display = 'none';
    form.method = 'POST';
    form.action = 'https://api.razorpay.com/v1/checkout/embedded';

    const data = {
      key_id: environment.razorpayKey,
      "prefill[name]": params.firstName + ' ' + params.lastName,
      "prefill[email]": params.email,
      "prefill[contact]": params.phoneNumber,
      "notes[property_id]": params.propertyId,
      order_id: params.order_id,
      callback_url: params.callback_url,
      cancel_url: params.cancel_url,
    }

    for (const [key, value] of Object.entries(data)) {
      let input;
      input = document.createElement('input');
      input.name = key;
      input.id = key;
      input.value = (value || '').toString();
      form.appendChild(input);
    }

    document.body.appendChild(form);
    form.submit();
  }
}

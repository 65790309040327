import {Photo} from './photo';
import {Citizen} from './citizen';
import {Floor} from './floor';
import {Street} from './street';
import {Colony} from './colony';
import {City} from './city';
import {District} from './district';
import {Ward} from './ward';
import {Sector} from './sector';
import {PropertyType} from './property-type';
import {PropertySubType} from './property-sub-type';
import {OwnershipType} from './ownership-type';
import {Rebate} from './rebate';
import {User} from './user';
import {Society} from './society';
import {PropertyCategories} from '../enums/property-categories';
import {BusinessType} from './business-type';
import {WaterSupplySource} from '../enums/water-supply-source';
import {RoadTypes} from '../enums/road-types';
import {TowerLocations} from '../enums/tower-locations';
import {Ledger} from './ledger';
import {groupBy} from 'lodash';
import { currentFinancialYear } from '../helpers/current-financial-year';

export class Property {
  static SELF_OCCUPIED = 1;
  static RENTED = 2;
  static SELF_OCCUPIED_AND_RENTED = 3;

  static readonly PROPERTY_CATEGORIES: { [key: number]: string } = {
    [PropertyCategories.Residential]: 'Residential',
    [PropertyCategories.Mix]: 'Mix',
    [PropertyCategories.Institutional]: 'Institutional',
    [PropertyCategories.Commercial]: 'Commercial',
    [PropertyCategories.Industry]: 'Industry',
    [PropertyCategories.Vacant]: 'Vacant',
    [PropertyCategories.AnyOther]: 'Other',
  };

  static readonly CONSTRUCTION_TYPES: { [key: number]: string } = {
    1: 'PAKKA(CTG-A)',
    2: 'PAKKA(CTG-B)',
    3: 'PAKKA(CTG-C)',
    4: 'PAKKA(TIN SHED)',
    5: 'KACHHA(KHAMGHAR)',
    6: 'VACANT LAND AREA',
    7: 'Super RCC',
    8: 'RCC/ RBC',
    9: 'ACC',
    10: 'Wooden Batton',
  };

  static readonly PROPERTY_USAGES: { [key: number]: string } = {
    [Property.SELF_OCCUPIED]: 'Self Occupied',
    [Property.RENTED]: 'Rented',
    [Property.SELF_OCCUPIED_AND_RENTED]: 'Self Occupied + Rented',
  };

  static readonly ROAD_TYPES: Record<RoadTypes, string> = {
    [RoadTypes.CCRoad]: 'CC Road',
    [RoadTypes.BTRoad]: 'BT Road',
    [RoadTypes.InterlockingTile]: 'Interlocking Tile',
    [RoadTypes.KankarRoad]: 'Kankar Road',
    [RoadTypes.KatchRoad]: 'Katch Road',
  };

  static readonly TOWER_LOCATIONS: Record<TowerLocations, string> = {
    [TowerLocations.RoofMounted]: 'Roof Mounted',
    [TowerLocations.Ground]: 'Ground',
  };

  static readonly WATER_SUPPLY_SOURCES: { [key: number]: string } = {
    [WaterSupplySource.PHD]: 'PHD',
    [WaterSupplySource.Bore]: 'Bore',
    [WaterSupplySource.PHDAndBore]: 'PHD + Bore',
    [WaterSupplySource.Well]: 'Well',
    [WaterSupplySource.Community]: 'Community',
  };

  id!: number;
  property_uid!: string;
  property_name!: string;
  address_house_number!: string;
  property_usage_id!: number;
  private _vacant_area!: number;
  floor_count!: number;
  exemption!: string;
  property_category!: number;
  ward_id: number | undefined;
  sector_id: number | undefined;
  property_type_id: number | undefined;
  property_sub_type_id: number | undefined;
  colony_id: number | undefined;
  plinth_area!: number;
  constructed_area!: number;
  latitude!: any;
  longitude!: any;
  address_line2!: string;
  pincode!: string;
  landmark!: string;
  nearby_houseno!: string;
  property_status!: number;
  permanent_address!: string;
  old_house_tax_no!: string;
  landuse_change!: number;
  sub_divided_plots!: boolean;
  subdivision_year!:number;
  parent_id!:number;
  parent_property_uid!:string | null;
  survey_denied!: boolean;
  denied_reason!: string;
  construction_type_id!: number;
  building_permission!: boolean;
  building_permission_number!: string;
  building_permission_year!: number;
  building_permission_num_floor!: number;
  business_type_id!: number;
  use_building_permission_id!: number;
  building_permission_use_type!: string;
  use_building_permission_matches_actual!: boolean;
  building_construction_year!: number;
  plot_allotment_year!: number;
  water_connection!: boolean;
  electricity_connection!: boolean;
  electricity_connection_number!: string;
  sewerage_line!: boolean;
  sewerage_line_connection!: boolean;
  septic_tank!: boolean;
  compost_pit!: boolean;
  rainwater_harvesting!: boolean;
  toilet!: boolean;
  parking!: boolean;
  street_light!: boolean;
  fire_fighting_system!: boolean;
  mobile_tower!: boolean;
  mobile_tower_placement!: TowerLocations;
  free_hold_patta!: boolean;
  one_time_deposited!: boolean;
  lease_deed_patta!: boolean;
  plot_area!: number;
  length!: number;
  breadth!: number;
  first_financial_year!: number;
  taxDueAmount!: number;
  multi!: boolean;
  road_name!: string;
  road_location!: string;
  trade_license!: boolean;
  name_of_shop!: string;
  advertisement_on_building!: boolean;
  solar_plant_or_unit!: boolean;
  building_permission_use_case!: number;
  water_connection_number!: string;
  establishment_year!: number;
  items_traded!: string;
  occupation_year!: number;
  polygon_coordinates!: any[];
  license_number!: string;
  license_date!: Date | { year: number, month: number, day: number };
  road_type_id!: RoadTypes;
  road_width!: number;
  is_closed!: boolean;
  source!: string;
  has_objection!: string;

  created_at: Date = new Date();
  updated_at: Date = new Date();
  state_id!: number;
  ward = new Ward();
  street = new Street();
  district = new District();
  city = new City();
  sector = new Sector();
  private _colony = new Colony();
  propertyType = new PropertyType();
  propertySubType = new PropertySubType();
  rebate = new Rebate();
  ownershipType = new OwnershipType();
  society = new Society();
  createdBy = new User();
  businessType!: BusinessType;
  private _owners: Citizen[] = [];
  private _occupiers: Citizen[] = [];
  photos: Photo[] = [];
  floors: Floor[] = [];
  ledgers: Ledger[] = [];

  static formattedPropertyCategories() {
    return Object.entries(Property.PROPERTY_CATEGORIES)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedPropertyCategoriesWithoutMix() {
    return Object.entries(Property.PROPERTY_CATEGORIES).filter((entity) => Number.parseInt(entity[0]) !== PropertyCategories.Mix)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedConstructionTypes() {
    return Object.entries(Property.CONSTRUCTION_TYPES)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedPropertyUsages() {
    return Object.entries(Property.PROPERTY_USAGES)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedWaterSupplySources() {
    return Object.entries(Property.WATER_SUPPLY_SOURCES)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedRoadTypes() {
    return Object.entries(Property.ROAD_TYPES)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedTowerLocations() {
    return Object.entries(Property.TOWER_LOCATIONS)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  propertyCategoryByFloors(): PropertyCategories | undefined {
    if (!this.floors || this.floors.length === 0) {
      return undefined;
    }

    const groupedByCategory = groupBy(this.floors, (f) => f.property_category);
    const categories = Object.keys(groupedByCategory);

    return categories.length > 1 ? PropertyCategories.Mix : (categories[0] as unknown as PropertyCategories);
  }

  totalCarpetArea() {
    // @ts-ignore
    return this.floors?.reduce((acc, f) => acc + Number.parseFloat(f.carpet_area ?? 0), 0) ?? 0;
  }

  totalCarpetAreaCY(){
    const currentYear = currentFinancialYear();
    let totalCarpetArea = 0;
    this.floors?.forEach((el: Floor) => {
      if(+el.upto_year >=currentYear) {
        totalCarpetArea += +el.carpet_area
      }
    })
    return totalCarpetArea;
  }

  getVacantAreaCalculation():number {
    let vacant_area: number;
    const currentYear = currentFinancialYear();
    let groundFloors = this.floors?.filter((f) => f.floor_number === 0 && +f.from_year <= currentYear && +f.upto_year >= currentYear && f.carpet_area!=null);
    let groundFloorCarpetAreaSum = groundFloors.reduce((sum,current) => {
      return sum + parseInt(current.carpet_area?.toString())
    },0);
    let groundFloorCarpetAreaSuminSqyds = groundFloorCarpetAreaSum/9
    if(groundFloorCarpetAreaSuminSqyds < 0) { groundFloorCarpetAreaSuminSqyds = 0; }
    vacant_area = Math.round((this.plot_area - groundFloorCarpetAreaSuminSqyds + Number.EPSILON) * 100) / 100;
    return vacant_area;
  }

  public get colony() {
    return this._colony;
  }
  
  public set colony(value: Colony) {
    this._colony = value;
    this.colony_id=this._colony?.id;
  }

  public get vacant_area(): number {
    this.vacant_area=this.getVacantAreaCalculation();
    return this._vacant_area;
  }

  public set vacant_area(value: number) {
    this._vacant_area = value;
  }

  get owners() {
    return this._owners;
  }

  set owners(o) {
    this._owners = [...o];
  }

  get occupiers() {
    return this._occupiers;
  }

  set occupiers(o) {
    this._occupiers = o;
  }
}

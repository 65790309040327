<div class="table-hold">
  <table class="table table-bordered th-bg">
    <tbody>
      <tr>
        <th colspan="7" class="fz-16">{{'property_search.building_information' | translate}}
          <span class="btn-collapse-table" *ngIf="isCollapsed; else arrowUp">
            <i class="bi bi-caret-down-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
               aria-controls="collapseExample"></i>
          </span>
      </th>
    </tr>
    </tbody>
  </table>
  <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <table class="table table-bordered th-bg property-details">
      <tbody>
        <tr>
          <th>{{'property_search.building_permission' | translate}}</th>
          <td>{{ yesNoNothing(property.building_permission) }}</td>
          <th>{{'property_search.year_of_building_permission' | translate}}</th>
          <td>{{property.building_permission_year}}</td>
        </tr>

        <tr>
          <th>{{'property_search.floor_count' | translate}}</th>
          <td>{{property.floor_count}}</td>
          <th>{{'property_search.building_permission_for_number_of_floor' | translate}} </th>
          <td>{{property.building_permission_num_floor}}</td>
        </tr>

        <tr>
          <th>{{'property_search.building_permission_use_case' | translate}}</th>
          <td>{{Property.PROPERTY_CATEGORIES[property.building_permission_use_case]}}</td>
          <th>{{'property_search.building_permission_matches_use_case' | translate}}</th>
          <td>{{ yesNoNothing(property.use_building_permission_matches_actual) }}</td>
        </tr>

        <tr>
          <th>{{'property_search.free_hold_patta' | translate}}</th>
          <td>{{ yesNoNothing(property.free_hold_patta) }}</td>
          <th>{{'property_search.one_time_deposited' | translate}}</th>
          <td>{{ yesNoNothing(property.one_time_deposited) }}</td>
        </tr>
        <tr>
          <th>{{'property_search.lease_deed_patta' | translate}}</th>
          <td>{{ yesNoNothing(property.lease_deed_patta) }}</td>
          <th>{{'property_search.type_of_construction' | translate}}</th>
          <td>{{Property.CONSTRUCTION_TYPES[property.construction_type_id]}}</td>
        </tr>

        <tr>
          <th>{{'property_search.building_construction_year' | translate}}</th>
          <td>{{commonService.getYearValueAsFY(property.building_construction_year)}}</td>
          <th>{{'property_search.approx_age_of_building' | translate}} </th>
          <td>{{getApproxAge(property.building_construction_year)}}</td>
        </tr>

      </tbody>
    </table>
  </div>
</div>

<ng-template #arrowUp>
  <span class="btn-collapse-table">
    <i class="bi bi-caret-up-fill" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed"
       aria-controls="collapseExample"></i>
  </span>
</ng-template>

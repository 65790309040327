import { Injectable } from "@angular/core";
import { Apollo, gql } from "apollo-angular";
import { map } from "rxjs/operators";
import { SearchMapPattaProperty } from "src/app/@types/search-map-patta-property";
import { RadiusCondition } from '../../@types/radius-condition';
import { UploadedProofs } from "src/app/@types/property-correction";
import  ObjectionDetails  from "src/app/@types/objection-details"
import { CommonService } from "src/app/services/common.service";
import { Observable } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class PattaVerificationService {
  constructor(
    private apollo: Apollo, 
    private commonService: CommonService) { }
  
  getPattaApplicationStatus(code: string, token: string) {
    return this.apollo
      .watchQuery({
        query: this.getPattaApplicationStatusQuery(code, token),
      })
      .valueChanges.pipe(map((result: any) => result.data.PattaApplicationStatus || []));
  }

  getPattaCategories() {
    return this.apollo
      .query({
        query: this.getPattaPropertiesQuery(),
      })
      .pipe(map((result: any) => result.data.PattaApplicationMasterData.property_categories || []));
  }

  sendOtp(data:{application_number: string, mobile_number:number}) {
    return this.apollo.watchQuery({
      query: this.sendOtpQuery(data),
      fetchPolicy: 'no-cache'
    }).valueChanges.pipe(map((res:any) => res?.data?.PattaApplicationOtp));
  }

  getPattaApplicationStatusQuery(code: string, token: string) {
    return gql`{
    PattaApplicationStatus(
      code: "${code}"
      token: "${token}"
      ) {
        success
        message
        data {
          id
          status_id
          status
          application_number
          patta_category_id
          patta_category
          dispatch_number
          date_of_release
          lease_type
          documents {
            id
            path
          }
          property {
            property_name
            address_house_number
            pincode
            landmark
            address_line
            plot_area
            khasra_number
            owners {
              first_name
              last_name
            }
            city {
              name
            }
            ward {
              name
            }
            colony {
              name
              khasra_number
              scheme_name
              tehsil 
              village
            }
            district {
              name
              state {
                name
              }
            }
          }
        }
      }
    }`;
  }

  getPattaPropertiesQuery() {
    return gql`{
      PattaApplicationMasterData{
        property_categories {
          id
          name
        }
      }
    }`;
  }

  private sendOtpQuery(data:{application_number: string, mobile_number:number}) {
    const applicationNumber = data.application_number ? `"${data.application_number}"` : null;
    const mobileNumber = data.mobile_number ? `"${data.mobile_number}"` : null;
    return gql `
            query {
              PattaApplicationOtp(
                application_number: ${applicationNumber}
                mobile_number: ${mobileNumber}
              ) {
                success
                message
                data {
                  token
                }
              }
            }`
  }

  getPattaPropertiesForMap(southwest: L.LatLng | undefined, northeast: L.LatLng | undefined, searchMapPattaProperty: SearchMapPattaProperty, isSearchMode: boolean): Observable<any[]> {
    const where = searchMapPattaProperty?.getGraphQLCondition();
    let whereShapeQuery = '';
    if (!isSearchMode) {
      whereShapeQuery = `edgesCondition: {
        beginLat: ${northeast!.lat},
        beginLng: ${northeast!.lng}
        endedLat: ${southwest!.lat}
        endedLng: ${southwest!.lng}
    }`;
    }
    
    const parametersQuery = `(
       ${where ?? ''}
       ${whereShapeQuery}
    )`;
    return this.apollo.watchQuery({
      query: gql`{PattaPropertiesForMap${parametersQuery} ${this.getPattaPropertiesForMapQuery()} }`,
      fetchPolicy: 'no-cache',
    }).valueChanges.pipe(map(
      (res: any) => res?.data?.PattaPropertiesForMap ?? [],
    ));
  }

  getPattaPropertiesForMapByCircle(searchMapPattaProperty?: SearchMapPattaProperty, radiusCondition?: RadiusCondition): Observable<any[]> {
    const where = searchMapPattaProperty?.getGraphQLCondition();

    const radiusQuery = radiusCondition ? `radiusCondition: {
        distance: ${radiusCondition.distance}
        lat: ${radiusCondition.lat}
        lng: ${radiusCondition.lng}
    }` : undefined;

    const parametersQuery = !where && !radiusQuery ? '' : `(
       ${where ?? ''}
       ${radiusQuery ?? ''}
    )`;

    return this.apollo.query({
      query: gql`{ PattaPropertiesForMap${parametersQuery} ${this.getPattaPropertiesForMapQuery()} }`,
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.PattaPropertiesForMap ?? [],
    ));
  }

  getPattaPropertiesForMapQuery() {
    return `{
      id
      property_uid
      city_id
      ward_id
      colony_id
      district_id
      state_id
      landmark
      address_line
      nearby_houseno
      address_house_number
      latitude
      longitude
      plot_area
      created_at
      updated_at
      property_name
      property_category_at_site
      patta_application_id
      polygon_coordinates
      colony {
          id,
          name,
          scheme_name,
          total_plot_approved
      }
      ward {
          id,
          name,
      }
      owners {
         id,
         first_name,
         last_name,
         relative_name
      }
      pattaApplication {
          id
          dispatch_number
          date_of_release
          status_id
          created_at
          application_number
          patta_issuing_organization
          patta_category_id
          lease_type
          documents {
              id
              patta_application_id
              path
              document_type_id
          }
      }
  }`;
}
  getPattaObjectionStatusById(objectionId: number) {
    return this.apollo.query({
      query: this.getPattaObjectionStatusByIdQuery('objection_number', objectionId),
      fetchPolicy: 'no-cache',
    }).pipe(map(
      (res: any) => res?.data?.PattaObjectionDetails.data,
    ));
  }
  
  getPattaObjectionStatusByIdQuery(key: string,id: number) {
    return gql`{
      PattaObjectionDetails(${key}: ${id}) {
        success
        message
        data {
          id
          first_name
          last_name
          contact_number
          Status
          remarks
          objection_number
          objection_type_id
          patta_application_id
          ObjectionType
          Documents {
            id
            path 
          }
          created_at
          admin_remark
          pattaApplication
          {
            id
            application_number
          }
      }
     }
   }`
  }
  
  createPattaObjection(objectionDetailsForm: ObjectionDetails, documents: UploadedProofs[]) {
    const uploadedDocuments: any = `[${documents.map((value: any) => `${this.commonService.objToString(value)}`).join(',')}]`;
    return this.apollo.mutate({
      mutation: gql`mutation{ 
        createOrUpdatePattaObjection(
        first_name: "${objectionDetailsForm.first_name}",
        last_name: "${objectionDetailsForm.last_name}",
        email: "${objectionDetailsForm.email}",
        contact_number: "${objectionDetailsForm.contact_number}",
        remarks: "${objectionDetailsForm.remarks}"
        objection_type_id:${objectionDetailsForm.objection_type_id},
        patta_application_id: ${objectionDetailsForm.patta_application_id},
        PattaObjectionDocument: ${uploadedDocuments}
      ) {
          success
          message
          data {
            id
            first_name
            last_name
            contact_number
            remarks
            objection_number
            objection_type_id
            patta_application_id
            ObjectionType
            created_at
          }
       }
      }`,
    }).pipe(
      map((res: any) => res?.data?.createOrUpdatePattaObjection),
    );
  }
}

import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
declare const L: any;
import 'leaflet';
import 'leaflet-draw';
import 'leaflet-geometryutil';
import 'leaflet.markercluster';
import { CityService } from '../../../services/city.service';
import Swal from 'sweetalert2';
import { Colony, Property, Ward } from 'src/app/models';
import { LatLngExpression } from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit {
  private _ward!:Ward;
  private _colony!:Colony;
  private _newCoords!: number[];
  readonly cssMapLabel = 'map-label';
  @Input() newCoordinates$!: Observable<{ latitude: number, longitude: number }>
  @Input() property!: Property;
  wardPolygon: any;
  colonyPolygon: any;
  @Input()
  set selectedWard(ward: Ward) {
    this._ward = ward;
    if(!ward) return;
    this.drawBoundary(this._ward.gps_compressed_coordinates, this.wardBoundaryColor, 'ward', ward.name);
  }

  @Input()
  set selectedColony(colony: Colony) {
    this._colony = colony;
    if(!colony) return;
    this.drawBoundary(this._colony.gps_compressed_coordinates, this.colonyBoundaryColor, 'colony', colony.name);
  }

  @Input()
  set newCoordinates(coords:any) {
    if(coords && this.map) {
      this.onMarkerUpdate([+coords.latitude, +coords.longitude], true);
    }else if(coords) {
      this._newCoords = [+coords.latitude, +coords.longitude]
    }
    
  }
  @Output() latlng = new EventEmitter<number[]>();

  readonly minZoom = 16;
  readonly maxZoom = 20;
  readonly initialZoom = 13;
  readonly focusZoom = 18;
  readonly mainTile = environment.mapTileUrl;
  readonly wardBoundaryColor = "#0063ff";
  readonly colonyBoundaryColor = "#DBD71F";

  private map!: L.Map;
  private center: [number, number] = [26.2389, 73.0243];
  private marker!: L.Marker;
  editableLayers: any;
  drawControl: any;

  constructor(private cs: CityService) {
    this.center = cs.mapCenterCoordinates;
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initMap();
    }, 500)
  }

  private initMap() {
    this.map = L.map('assessment-map', {
      center: this.center,
      zoom: this.initialZoom,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: 'topright',
      },
    });
    this.initLayer();
    if(!this.wardPolygon && this._ward) {
      this.drawBoundary(this._ward.gps_compressed_coordinates, this.wardBoundaryColor, 'ward', this._ward.name);
    }

    if(!this.colonyPolygon && this._colony) {
      this.drawBoundary(this._colony.gps_compressed_coordinates, this.colonyBoundaryColor, 'colony', this._colony.name);
    }
  }

  private initLayer() {
    this.editableLayers = new L.FeatureGroup();
    this.map.addLayer(this.editableLayers);

    // Add base map layer
    L.tileLayer(environment.baseMapTileUrl, { maxZoom: this.maxZoom, subdomains:['mt0','mt1','mt2','mt3']}).addTo(this.map);

    // Add drone image layer
    const tiles = L.tileLayer(this.mainTile, {
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
    });

    tiles.addTo(this.map);
    this.afterMapUpdate();
    this.showControls(true);
    this.onDelete();
  }
  private afterMapUpdate() {
    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      const type = e.layerType;
      const layer = e.layer
      let polUpdate;
      let markerUpdate;
      if (type === 'marker') {
        markerUpdate = this.onMarkerUpdate(layer);
      }
      if (polUpdate === false || markerUpdate === false) return;
      this.editableLayers.addLayer(layer);
    })
  }
  private onMarkerUpdate(layer: any, isUpdate = false): boolean {
    const latlng = isUpdate ? layer : [layer._latlng.lat, layer._latlng.lng];
    if(!this.colonyPolygon) {
      Swal.fire('Error', 'Select colony to mark property location', 'error');
      setTimeout(() => {
        this.property.latitude = undefined;
        this.property.longitude = undefined;
      }, 0)
     
      return false;
    }
    if (this.colonyPolygon && !this.colonyPolygon.getBounds().contains(latlng)) {
      Swal.fire('Error', 'Can not mark property location outside colony boundary', 'error');
      setTimeout(() => {
        this.property.latitude = undefined;
        this.property.longitude = undefined;
      }, 0)
      return false;
    }
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }

    this.addMarker(latlng);
    if(!isUpdate) {
      this.setPropertyCenterCoords(latlng);
    }
    
    return true;
  }
  setPropertyCenterCoords(latlng: number[] | undefined) {
    this.latlng.emit(latlng);
  }
  onDelete() {
    this.map.on('draw:deleted', ((evt: any) => {
      this.setPropertyCenterCoords(undefined);
    }))
  }
  private addMarker(coords: number[]) {
    this.marker = new L.marker(coords, {
      icon: new L.Icon({
        iconUrl: 'assets/images/map/location-icons/marker-location.png',
        iconSize: [23, 40],
        iconAnchor: [12, 38],
        popupAnchor: [-2, -37],
      })
    }).addTo(this.map);
    this.editableLayers.addLayer(this.marker);
  }

  private showControls(showControls = false) {
    var options = {
      position: 'topright',
      draw: {
        circlemarker: false,
        polyline: false,
        polygon: false,
        circle: false,
        rectangle: false,
        marker: showControls ? this.markerOptions() : false
      },
      edit: {
        featureGroup: this.editableLayers,
        edit: false,
        remove: true
      }
    };

    this.drawControl = new L.Control.Draw(options);
    this.map.addControl(this.drawControl);
  }

  private markerOptions() {
    const myCustomMarker = L.Icon.extend({
      options: {
        shadowUrl: null,
        iconAnchor: new L.Point(12, 12),
        iconSize: new L.Point(24, 24),
        iconUrl: 'assets/images/map/location-icons/spritesheet.svg',
      }
    });

    return {
      marker: false,
      icon: new myCustomMarker(),
    }
  }

  private createPolyline(latLng: [number, number][], polylineColor: string) {
    const coordinates: [number, number][] = latLng.map(coordinate => [coordinate[1], coordinate[0]]);
    return L.polyline(coordinates).setStyle({ color: polylineColor }).addTo(this.map);
  }

  private drawBoundary(coords: string, boundaryColor:string, area:string, name:string) {
    if(area === 'ward' && this.wardPolygon) {
      this.map.removeLayer(this.wardPolygon);
      if(this.colonyPolygon) {
        this.map.removeLayer(this.colonyPolygon);
      }
    }else if(area === 'colony' && this.colonyPolygon) {
      this.map.removeLayer(this.colonyPolygon);
      if(this.marker) {
        this.map.removeLayer(this.marker);
      }
    }

    if(coords && coords !== '[]' && this.map) {
      const polygon = this.createPolyline(JSON.parse(coords), boundaryColor);
      this.createLabel(polygon.getBounds().getCenter(), name, this.cssMapLabel);
      if(area === 'ward') {
        this.wardPolygon = polygon;
      }else {
        this.colonyPolygon = polygon;
        this.map.setZoom(this.focusZoom);
        this.map.flyTo(polygon.getBounds().getCenter());
        if(this._newCoords) {
          this.onMarkerUpdate(this._newCoords, true);
        }
      }
    }
    
  }

  private createLabel(coordinates: LatLngExpression, labelTitle: string, labelClass?: string) {
    const tooltip = (new L.Tooltip({
      permanent: true,
      className: labelClass,
      direction: 'center',
    })).setContent(`<div class="map-label-content">${labelTitle}</div>`);

    return (new L.Marker(coordinates, {
      icon: new L.DivIcon({className: 'bg-none'}),
    })).bindTooltip(tooltip).addTo(this.map);
  }
}

<ul ngbNav [activeId]="currentFormId" class="form-steppes" #nav="ngbNav">
  <li class="activated-step" [ngbNavItem]="FIRST_FORM_ID" [disabled]="true">
    <a ngbNavLink>{{'1. general information' | uppercase}}</a>
    <ng-template ngbNavContent>
      <app-general-information [property]="property"
                               (nextForm)="nextForm()"
                               (selectedColonyCoords)="selectedColonyCoords = $event"
                               [colonyCoordinates] = "selectedColonyCoords"
      ></app-general-information>
    </ng-template>
  </li>
  <li [class.activated-step]="currentFormId >= 2" [ngbNavItem]="2" [disabled]="true">
    <a ngbNavLink>{{'2. owner details' | uppercase}}</a>
    <ng-template ngbNavContent>
      <app-owners [property]="property"
                  (nextForm)="nextForm()"
                  (previousForm)="prevForm()"
      ></app-owners>
    </ng-template>
  </li>
  <li [class.activated-step]="currentFormId >= 3" [ngbNavItem]="3" [disabled]="true">
    <a ngbNavLink>{{'3. floor details' | uppercase}}</a>
    <ng-template ngbNavContent>
      <app-floors-details [property]="property"
                          (nextForm)="nextForm()"
                          (previousForm)="prevForm()"
      ></app-floors-details>
    </ng-template>
  </li>
  <li [class.activated-step]="currentFormId === LAST_FORM_ID" [ngbNavItem]="LAST_FORM_ID" [disabled]="true">
    <a ngbNavLink>{{'4. Know Your Tax' | uppercase}}</a>
    <ng-template ngbNavContent>
      <app-review [property]="property"
                  (previousForm)="prevForm()"
      ></app-review>
    </ng-template>
  </li>
</ul>

<div class="mb-4" [ngbNavOutlet]="nav"></div>

export enum OwnershipTypes {
  Individual = 11,
  Company = 12,
  Partnership = 13,
  Government_Building = 14,
  Muncipality = 15,
  Religious_Trust = 16,
  Charitable_Organisations = 17,
  Other = 18,
}

export const OwnershipTypesNames: Record<OwnershipTypes, string> = {
  [OwnershipTypes.Individual]: 'Individual',
  [OwnershipTypes.Company]: 'Company',
  [OwnershipTypes.Partnership]: 'Partnership',
  [OwnershipTypes.Government_Building]: 'Government Building',
  [OwnershipTypes.Muncipality]: 'Muncipality',
  [OwnershipTypes.Religious_Trust]: 'Religious Trust',
  [OwnershipTypes.Charitable_Organisations]: 'Charitable Organisations',
  [OwnershipTypes.Other]: 'Other',
}

export function getFormattedOwnershipTypes(): { id: OwnershipTypes, name: string }[] {
  return Object.entries(OwnershipTypesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}

<div class="container">
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">faqs</li>
    </ol>
  </nav>

  <!-- <app-home-faq></app-home-faq> -->
  <section class="faq ">
    <div class="container">
      <div class="row">
        <div class="col-12 pd-0">
          <div class="tab-settt">
            <ul ngbNav #nav1="ngbNav" class="nav-tabs">
              <li ngbNavItem>
                <a ngbNavLink>{{'faq_bharatpur.faq_tab_1' | translate}}</a>
                <ng-template ngbNavContent>
                  <div id="accordionExample" class="accordion">

                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                      <!-- <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0"> -->
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_1_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_1_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_1_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_2_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_2_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_2_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_3_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_3_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_3_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_4_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_4_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_4_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_5_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_5_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_5_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_6_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_6_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_6_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_6_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>

                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_7_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_7_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_7_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_7_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.portal_faq_8_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.portal_faq_8_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.portal_faq_8_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.portal_faq_8_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>

                    </ngb-accordion>
                    <!-- <button class="see_all right" routerLink="/faqs">View All</button> -->


                  </div>

                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink>{{'faq_bharatpur.faq_tab_2' | translate}}</a>
                <ng-template ngbNavContent>
                  <div id="accordionExample2" class="accordion">

                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-t-0">
                      <!-- <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="ngb-panel-0"> -->
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_1_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_2_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_1_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_1_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>


                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_2_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_2_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_2_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_2_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>

                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_3_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_3_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_3_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_3_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>


                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_4_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_4_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_4_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_4_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>

                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_5_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_5_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_5_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_5_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>

                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_6_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_6_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_6_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_6_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>


                        </ng-template>
                      </ngb-panel>

                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_7_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_7_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_7_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_7_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>

                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_8_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_8_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_8_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_8_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>

                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_9_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_9_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_9_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_9_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>
                      <ngb-panel>
                        <ng-template ngbPanelTitle>
                          <h4 class="faq-hd" *ngIf="cs.city=='bharatpur'">{{'faq_bharatpur.ud_tax_10_question' | translate}}</h4>
                          <h4 class="faq-hd" *ngIf="cs.city=='jodhpur'">{{'faq_jodhpur.ud_tax_10_question' | translate}}</h4>
                        </ng-template>
                        <ng-template ngbPanelContent>
                          <div [innerHTML]="'faq_bharatpur.ud_tax_10_question_answer' | translate" *ngIf="cs.city=='bharatpur'"></div>
                          <div [innerHTML]="'faq_jodhpur.ud_tax_10_question_answer' | translate" *ngIf="cs.city=='jodhpur'"></div>
                        </ng-template>
                      </ngb-panel>

                    </ngb-accordion>
                    <!-- <button class="see_all right" routerLink="/faqs">View All</button> -->


                  </div>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav1" class="mt-2"></div>
          </div>
        </div>
      </div>


    </div>
  </section>


</div>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IndexComponent} from './index/index.component';
import {paymentHistoryRouting} from "./payment-history.routing";
import {NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from "@angular/forms";
import {WidgetsModule} from "../../widgets/widgets.module";
import { PipesModule } from "../../pipes/pipes.module";

@NgModule({
    declarations: [
        IndexComponent,
    ],
    imports: [
        CommonModule,
        paymentHistoryRouting,
        WidgetsModule,
        NgbPaginationModule,
        FormsModule,
        PipesModule
    ]
})
export class PaymentHistoryModule {
}


import {Component, ElementRef, Input, OnInit, QueryList, ViewChildren} from '@angular/core';
import printJS from 'print-js/src/index.js';
import {Floor, Ledger, Property} from '../../../models';
import {GenderTypes, GenderTypesNames} from '../../../enums/genderTypes';
import {OwnershipTypesNames} from '../../../enums/ownership-types';
import {PropertyTaxCalculation} from '../../../@types/tax-calculation';
import {RebateTypes} from '../../../enums/rebate-types';
import {maxBy} from 'lodash';
import { getFormattedGenderTypes } from '../../../enums/genderTypes';
import { CityService } from 'src/app/services/city.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-review-pdf',
  templateUrl: './review-pdf.component.html',
  styleUrls: ['./review-pdf.component.scss'],
})
export class ReviewPdfComponent implements OnInit {

  @Input() property!: Property;
  @Input() taxCalculation!: PropertyTaxCalculation;
  @Input() selfAssessmentFormPreSave!: any;
  @Input() ledgersDetails!: { ledgers: Ledger[], penalties: Ledger[], rebates: Ledger[] };
  @Input() disabled = false;
  @ViewChildren('balance') balanceCells?: QueryList<ElementRef>;

  readonly GENDER_NAMES = getFormattedGenderTypes();
  readonly PROPERTY_CATEGORIES_NAMES = Property.PROPERTY_CATEGORIES;
  readonly OWNERSHIP_TYPES_NAMES = OwnershipTypesNames;
  readonly FLOOR_NUMBER = Floor.FLOOR_NUMBER;
  readonly LEDGER_TYPES = Ledger.LEDGER_TYPES;
  readonly REBATE_TYPES = RebateTypes;


  constructor(public cs: CityService,
    public commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  print() {
    setTimeout(() => printJS({
      printable: 'print-self-assessment-form',
      type: 'html',
      maxWidth: 1300,
      documentTitle: 'Payment Receipt',
      style: `
        * {
          box-sizing: border-box;
        }
        @page {
          size: 288mm 474mm;
          margin: 0;
        }
        @media print {
          html, body {
            width: 288mm;
            height: 474mm;
          }
        }
        body {
          width: 100%;
          margin: 0px auto;
          padding: 0px;
          font-family: 'Poppins', sans-serif;
          font-size: 9.5pt;
        }
        .main-page {
          margin: 15px;
        }
        table {
          border-spacing: 0;
          width: 100%;
          margin-bottom: 15px;
        }
        
        table, td, th {
          border: 1px solid black;
          border-collapse: collapse;
          color: #2e4764;
        }
        
        th {
          width: 80px;
          height: 35px;
          padding: 5px;
          word-break: break-all;
          text-align: center;
          vertical-align: center;
        }
        
        td {
          padding: 5px;
          width: 80px;
          border: 1px solid black;
          text-align: center;
          color: #2e4764;
          vertical-align: center;
        }
        
        .font-size-600 {
          font-weight: 600;
        }
        
        .service {
          margin-bottom: 15px;
          padding: 0px;
        }
        
        .service td, .service th {
          text-align: left;
        }
        
        .service td:nth-child(even) {
          width: 50px;
        }
        
        .service h4 {
          margin-top: 0;
          margin-bottom: 0;
        }
        
        tr.text-left td {
          text-align: left !important;
        }
        
        img.center {
          display: block;
          margin: 0 auto;
        }
        .header {
          display: flex;
          justify-content: space-between;
          align-items: center
        }
        .header-title {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
        .underline {
          text-decoration: underline;
        }
        
        h1, h2, h3, h4 {
          text-align: center;
        }
        
        p {
          margin: 5px 0;
        }
        
        .red {
          color: red;
        }
        
        img {
          margin: auto;
          display: block;
        }
   `,
    }));
  }

  isRebateExists() {
    return !!this.ledgersDetails?.rebates?.reduce((acc, l) => acc + l.credit_amount, 0);
  }

  isPenaltyExists() {
    return !!this.ledgersDetails?.penalties[0]?.debit_amount;
  }

  getRebateByType(type: RebateTypes): Ledger | null {
    return this.ledgersDetails.rebates.find((l) => l.rebate_type === type) as (Ledger | null);
  }

  isExempted() { 
    const exemptedTaxes = this.taxCalculation?.taxCalculation?.filter((f) => f.tax ==='Exempted').length;
    if(exemptedTaxes>0 && exemptedTaxes===this.taxCalculation?.taxCalculation?.length){
      return true;
    }
    return false;
  }

  lastYearDebt() {
    const lastFinancialYear = maxBy(this.ledgersDetails.ledgers, (l) => l.financial_year)?.financial_year ?? 0;

    const notLastFinancialYearLedgers = this.ledgersDetails.ledgers.filter((l) => l.financial_year !== lastFinancialYear);

    return notLastFinancialYearLedgers[notLastFinancialYearLedgers.length - 1]?.balance ?? 0;
  }

  getTotalAmountDue() {
    return Number.parseFloat(this.balanceCells?.last?.nativeElement.textContent)
      + this.ledgersDetails.penalties.reduce((acc, l) => acc + l.debit_amount, 0)
      - this.ledgersDetails.rebates.filter((l) => l.transaction_type !== RebateTypes.Date)
        .reduce((acc, l) => acc + l.credit_amount, 0);
  }

  getOwnerNames() { 
    if(this.property.owners.length>1){
        return this.property.owners.map(owner => owner.last_name ? owner.first_name +' ' + owner.last_name : owner.first_name).join(',');
    }else{
        return this.property.owners[0].last_name ? this.property.owners[0].first_name +' ' + this.property.owners[0].last_name : this.property.owners[0].first_name;
    }
  }

  getOwnerGender() { 
    if(this.property.owners.length>1){
        return this.property.owners.map(owner => this.GENDER_NAMES[owner.gender].name ?? '').join (',');
    }else{
        return this.GENDER_NAMES[this.property.owners[0].gender].name ?? '';
    }
  }


}

<div class="sidebar">
  <div class="sidebar__property-search block-search">
    <div class="row ">
      <div class="col-12 mb-4">
        <div class="input-wrapper">
          <label for="service-no">{{'property_search.service_no' | translate}}</label>
          <input class="form-control" type="text" id="service-no" placeholder="{{'property_search.enter_service_number' | translate}}"
            [(ngModel)]="searchMapProperty.propertyUID">
        </div>
      </div>
      <div class="col-6">
        <div class="input-wrapper">
          <label for="account-holder">{{'property_search.property_category' | translate}}</label>
          <ng-select placeholder="{{'property_search.select_property_category' | translate}}" bindValue="id" bindLabel="name"
            [compareWith]="selectCompareWithFn" [items]="PROPERTY_CATEGORIES"
            [(ngModel)]="searchMapProperty.propertyCategoryId" (change)="onPropertyCategoryChange($event)">
          </ng-select>
        </div>
      </div>
      <div class="col-6">
        <div class="dropdown-wrapper">
          <label for="account-holder">{{'property_search.ward_number' | translate}}</label>
          <ng-select placeholder="{{'property_search.select_ward_number' | translate}}" bindValue="id" bindLabel="name"
            [compareWith]="selectCompareWithFn" [items]="wards" [loading]="wards.length === 0"
            [(ngModel)]="searchMapProperty.wardId" (change)="getColonyDetails(searchMapProperty.wardId)">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dropdown-wrapper">
          <label for="colony">{{'property_search.colony' | translate}}</label>
          <ng-select placeholder="{{'property_search.select_colony' | translate}}" bindValue="id" [compareWith]="selectCompareWithFn"
            [items]="colonies" [loading]="loadingColonies" [typeahead]="colonyInput$"
            [(ngModel)]="searchMapProperty.colonyId" (change)="updateLandmarks(searchMapProperty.colonyId)">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              {{ item  | translate }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dropdown-wrapper">
          <label for="address-landnmark">{{'property_search.landmark' | translate}}</label>
          <ng-select placeholder="{{'property_search.navigate_to_landmark' | translate}}" [(ngModel)]="searchMapProperty.selectedLandmark" [searchable]="true" bindValue="id" [items]="landmarkFiltered"
            (change)="flyToLandmark($event)">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              {{ item | translate }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <label for="latitude">{{'property_search.latitude_&_longitude' | translate}}</label>
        <input id="latitude" type="string" class="form-control" placeholder="{{'property_search.enter_latitude_longitude' | translate}}"
          [(ngModel)]="latLongValue" (input)="onLatLongValueChange()" (blur) = "afterLatLongInput()" inputValidation = "latLong">
      </div>
    </div>
    <div class="d-flex justify-evenly map-search mb-4">
      <button class="btn btn-primary" [disabled]="!latLongInput.latitude || !latLongInput.longitude"
        (click)="flyToLocation()">{{'property_search.fly_to_location' | translate}}</button>
    </div>

    <div class="row">
      <!-- <div class="col-6 mb-4">
            <div class="input-wrapper">
              <label for="address-landnmark">landmark</label>
                <input class="form-control" type="text" id="address-landnmark" placeholder="Enter landmark"
                    [(ngModel)]="searchMapProperty.landmark"
                >
            </div>
          </div> -->
      <div class="col-6 mb-4">
        <div class="input-wrapper">
          <label for="account-holder-mobile">{{'property_search.mobile_number' | translate}}</label>
          <input class="form-control" type="text" id="account-holder-mobile" placeholder="{{'property_search.enter_mobile_number' | translate}}"
            [(ngModel)]="searchMapProperty.mobile">
        </div>
      </div>
      <div class="input-wrapper col-6 ">
        <label for="account-holder">{{'property_search.account_holders_name' | translate}}</label>
        <input class="form-control" type="text" id="account-holder" placeholder="{{'property_search.enter_account_holders_name' | translate}}"
          [(ngModel)]="searchMapProperty.holderName">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="input-wrapper">
          <app-search-input-by-api bindValue="id" bindLabel="name" label="Street" [perPage]="10"
            [itemsSource]="streetService" [placeholder]="'Enter Street'" [inputModel]="searchMapProperty.streetId"
            (inputModelChange)="setId('streetId', $event)" [afterInit$]="afterInit$"></app-search-input-by-api>
        </div>
      </div>
      <div class="col-6">
        <div class="input-wrapper">
          <label for="address-house-no">{{'property_search.address_house_number' | translate}}</label>
          <input class="form-control" type="text" id="address-house-no" placeholder="{{'property_search.enter_address_house_number' | translate}}"
            [(ngModel)]="searchMapProperty.houseNumber">
        </div>
      </div>
    </div>

    <p class="advanced_search" (click)="OnClick()">{{'property_search.advanced_search' | translate}} <span class="advanced_search_arrow_1"></span><span
        class="advanced_search_arrow_2"></span></p>
    <div *ngIf="advancedBufferLocal">
      <div class="row">
        <div class="col-6 indent">
          <div class="input-wrapper">
            <label for="propertyName">{{'property_search.property_name' | translate}}</label>
            <input class="form-control" type="text" id="propertyName" placeholder="{{'property_search.enter_property_name' | translate}}"
              [(ngModel)]="searchMapProperty.propertyName">
          </div>
        </div>
        <div class="col-6 indent">
          <div class="input-wrapper">
            <label for="oldPropertyUID">{{'property_search.old_property_uid' | translate}}</label>
            <input class="form-control" type="text" id="oldPropertyUID" placeholder="{{'property_search.enter_old_property_uid' | translate}}"
              [(ngModel)]="searchMapProperty.oldPropertyUID">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 indent">
          <div class="input-wrapper">
            <label for="fathersName">{{'property_search.fathers_name' | translate}}</label>
            <input class="form-control" type="text" id="fathersName" placeholder="{{'property_search.enter_fathers_name' | translate}}"
              [(ngModel)]="searchMapProperty.fathersName">
          </div>
        </div>
        <div class="col-6 indent">
          <div class="input-wrapper">
            <label for="property-type">{{'property_search.service_no' | translate}}Property Type</label>
            <ng-select id="property-type" placeholder="{{'property_search.select_property_type' | translate}}"
              [(ngModel)]="searchMapProperty.property_type_id" [loading]="loadingPropertyTypes"
              (focus)="getPropertyType()" (change)="onPropertyTypeChange($event)" bindLabel="name" bindValue="id">
              <ng-option *ngFor="let propertyType of propertyTypeData" value="{{propertyType.id}}">
                {{propertyType.name}}</ng-option>
            </ng-select>
          </div>
        </div>

        <div class="col-6 indent">
          <div class="input-wrapper">
            <label for="property-sub-type">{{'property_search.property_sub_type' | translate}}</label>
            <ng-select id="property-sub-type" placeholder="{{'property_search.select_property_sub_type' | translate}}"
              [(ngModel)]="searchMapProperty.property_sub_type_id" [loading]="loadingPropertySubTypes"
              (focus)="getPropertySubType()" bindLabel="name" bindValue="id">
              <ng-option *ngFor="let propertySubType of propertySubTypeData" value="{{propertySubType.id}}">
                {{propertySubType.name}}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-evenly map-search">
      <button class="btn btn-primary mr-2 " (click)="search()">{{'property_search.search' | translate}}</button>
      <button class="btn btn-dark" (click)="toList()">{{'property_search.view_list' | translate}}</button>
    </div>
  </div>
  <div class="sidebar__info">
    <h6 class="text-primary">{{(nearbyProperties.length === 0 ? 'property_search.about' : 'property_search.nearby_properties') | translate}}</h6>
    <p class="sub-data" *ngIf="nearbyProperties.length > 0">{{nearbyProperties.length}} {{'property_search.properties_nearby' | translate}}</p>
    <ng-container *ngIf="nearbyProperties.length === 0">
      <div class="sidebar__card-info mb-4 new-label">
        <p class="rquored_hd">{{'property_search.required_level' | translate}}: {{requiredZoom}} <span>{{'property_search.current_level' | translate}} : {{currentZoom}}</span></p>
        <p class="indicator"><strong>{{'property_search.max_zoom' | translate}}: 20</strong> </p>
      </div>

      <div class="sidebar__card-info">
        <p>{{'property_search.nearby_properties' | translate}}</p>
        <p>{{'property_search.select_a_property_marker_to_see_nearby_properties' | translate}}</p>
      </div>
    </ng-container>

    <div class="sidebar__nearby-property-card" *ngFor="let property of nearbyProperties">
      <app-property-info-card [property]="property" [photos]="property.photos"></app-property-info-card>
    </div>
  </div>
</div>

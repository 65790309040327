import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {from, Observable, ReplaySubject, Subscription} from "rxjs";
import {map} from "rxjs/operators";
import {User} from "../models";
import {ApolloClient, gql, InMemoryCache} from "@apollo/client/core";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  subscription!: Subscription;
  bearerToken!: string | null;
  _user!: User | null | undefined;
  userSubject = new ReplaySubject<User | null | undefined>();
  client!: ApolloClient<any>;
  expirationTime: Date = new Date();

  get user(): User | null | undefined {
    return this._user
  }

  set user(value: User | null | undefined) {
    this._user = value;
    this.userSubject.next(this._user);
  }

  constructor() {
    this.userSubject.next(null);
    this.client = new ApolloClient({
      uri: environment.apiBaseUrl + 'graphql',
      cache: new InMemoryCache()
    });
    this.checkUserStatus();
  }

  signUpOTP(first_name: string, last_name: string, email: string, phone: string): Observable<any> {
    return from(this.client.mutate({
      mutation: gql`mutation{registrationOTP(first_name:"${first_name}",last_name:"${last_name}",phone:"${phone}",email:"${email}",)}`,
      fetchPolicy: 'no-cache',
    }));
  }

  phoneNumberConfirmation(): Observable<any> {
    return from(this.client.mutate({
      mutation: gql`mutation{phoneNumberConfirmation}`,
      context: {headers: {Authorization: `Bearer ${this.bearerToken}`}},
      fetchPolicy: 'no-cache',
    }));
  }

  phoneNumberCheckOtp(token: string, code: string): Observable<boolean> {
    return from(this.client.mutate({
      mutation: gql`mutation{ checkOTP(token:"${token}", code: "${code}") }`,
      fetchPolicy: 'no-cache',
    })).pipe(map((response: any) => {
      if (!response?.data?.checkOTP) return false;

      this.bearerToken = response.data.checkOTP;
      localStorage.setItem('bearerToken', response.data.checkOTP);
      this.user = undefined;
      this.checkUserStatus();

      return true;
    }));
  }

  signInOTP(phone: string): Observable<string> {
    return from(this.client.mutate({
      mutation: gql`mutation{loginOTP(phone:"${phone}")}`,
      fetchPolicy: 'no-cache',
    })).pipe(
      map((value: any) => value.data.loginOTP)
    );
  }

  checkOTP(token: string, code: string, remember = false): Observable<boolean> {
    return from(this.client.mutate({
      mutation: gql`mutation{ checkOTP(token:"${token}", code: "${code}") }`,
      fetchPolicy: 'no-cache',
    })).pipe(map((response: any) => {
      if (!response?.data?.checkOTP) return false;

      this.bearerToken = response.data.checkOTP;
      localStorage.setItem('bearerToken', response.data.checkOTP);

      if (remember) {
        this.expirationTime.setTime(Date.now() + (7*24*60*60*1000));
        localStorage.setItem('expirationTime', this.expirationTime.toString());
      } else {
        this.expirationTime.setTime(Date.now() + (4*60*60*1000));
        localStorage.setItem('expirationTime', this.expirationTime.toString());
      }

      this.setExpTimer();
      this.user = undefined;
      this.checkUserStatus();

      return true;
    }));
  }

  resendOTP(prevToken: string): Observable<string | undefined> {
    return from(this.client.mutate({
      mutation: gql`mutation{resendOTP(prevToken: "${prevToken}")}`,
      fetchPolicy: 'no-cache',
    })).pipe(map((res: any) => res?.data?.resendOTP ?? undefined));
  }

  signOut(): void {
    this.bearerToken = null;

    this.user = null;

    localStorage.removeItem('bearerToken');
    localStorage.removeItem('expirationTime');
  }

  private checkUserStatus(): void {
    this.bearerToken = localStorage.getItem('bearerToken');
    this.expirationTime = new Date(localStorage.getItem('expirationTime') + '');

    if (this.bearerToken) {
      this.expirationTime = new Date(localStorage.getItem('expirationTime') + '');
      (this.expirationTime.getTime() > Date.now()) ? this.getUserInfo() : this.signOut();
      this.setExpTimer();
    } else {
      this.signOut();
    }
  }

  private getUserInfo() {
    this.subscription?.unsubscribe();

    this.subscription = from(this.client.query({
      query: gql`query{me{id,first_name,last_name, phone_number, email, gender,     portal_notifications {
        id
        message,
        created_at
      }}}`,
      context: {headers: {Authorization: `Bearer ${this.bearerToken}`}},
      fetchPolicy: 'no-cache',
    })).subscribe(
      (response: any) => {
        if (response?.data?.me) {
          this.user = Object.assign(new User, response.data.me);
        } else {
          this.signOut();
        }
      },
      () => this.signOut()
    );
  }

  private setExpTimer() {
    setTimeout(() => {
      this.signOut();
    },this.expirationTime.getTime() - Date.now())
  }
}

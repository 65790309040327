import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {getFormattedMainProofs, getFormattedProofTypes, ProofTypes} from '../../../enums/proof-types';
import {Apollo} from 'apollo-angular';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {UploadedProofs} from '../../../@types/property-correction';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/services/language.service';


type DocumentProofs = {
  proofType: { id: number, name: string },
  documentProofs: { id: number, name: string }[],
  selectedDocumentProof: number | undefined
  path: string | undefined,
  fileName: string | undefined,
  mandatory: boolean
}[];

@Component({
  selector: 'app-document-proofs',
  templateUrl: './document-proofs.component.html',
  styleUrls: ['./document-proofs.component.scss'],
})
export class DocumentProofsComponent implements OnInit {
  @Input() oldTaxId!: boolean;

  selectedFile!: File;
  isFileUploading = false;

  DOCUMENT_TYPES = getFormattedProofTypes();
  DOCUMENT_MAIN_PROOFS = getFormattedMainProofs();

  DOCUMENTS_PROOFS: DocumentProofs = [];

  @Output('uploadedProofs') uploadedProofsEmitter = new EventEmitter<UploadedProofs | undefined>();

  private currentDocumentProofIndex!: number;
  loading = true;

  constructor(
    public apollo: Apollo,
    public httpService: HttpClient,
    public languageService:LanguageService
  ) {
  }

  ngOnInit(): void {
    this.setDocumentProofs();
    this.loading = false;
  }

  setDocumentProofs() {
    this.DOCUMENT_TYPES.forEach((type) => {
      this.DOCUMENTS_PROOFS.push({
        proofType: type,
        documentProofs: this.DOCUMENT_MAIN_PROOFS[type.id - 1]?.proof,
        selectedDocumentProof: undefined,
        path: undefined,
        fileName: undefined,
        mandatory: type.id != ProofTypes.OldTaxBill ? true : this.oldTaxId,
      });
    });

    this.DOCUMENTS_PROOFS.sort((a, b) => {
      return a.proofType.id != ProofTypes.OldTaxBill ? -1 : 1;
    });
  }

  onFileChange(event: any, documentProofIndex: number) {
    this.selectedFile = event.target.files[0];
    this.currentDocumentProofIndex = documentProofIndex;
    this.isFileUploading = false;
    this.uploadFile();
  }

  selectDocumentProof(selectedDocumentProof: any, documentProofIndex: number) {
    this.DOCUMENTS_PROOFS[documentProofIndex].selectedDocumentProof = selectedDocumentProof;
    this.emitUploadedFiles();
  }

  clearSelectedFile(documentProofIndex: number) {
    this.DOCUMENTS_PROOFS[documentProofIndex].path = undefined;
    this.DOCUMENTS_PROOFS[documentProofIndex].fileName = undefined;
    this.uploadedProofsEmitter.emit(undefined);
  }

  uploadFile() {
    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);

    this.isFileUploading = true;

    this.httpService.post<any>(environment.apiBaseUrl + 'document-proof/upload', fd).subscribe(
      (res) => {
        this.DOCUMENTS_PROOFS[this.currentDocumentProofIndex].path = res.data.path;
        this.DOCUMENTS_PROOFS[this.currentDocumentProofIndex].fileName = this.selectedFile.name;
        this.emitUploadedFiles();
        this.isFileUploading = false;
      },
      (res: any) => {
        const body = res.error;

        Swal.fire(
          
          this.languageService.translate('home.error'),
          body?.errors?.file?.[0] ?? body?.message ??
          this.languageService.translate('home.something_went_rong'), 'error',
          
        );

        this.uploadedProofsEmitter.emit(undefined);
        this.isFileUploading = false;
      },
    );
  }

  private emitUploadedFiles() {
    const count = this.DOCUMENTS_PROOFS.length;
    const data: UploadedProofs = [];

    for (let i = 0; i < count; ++i) {
      const documentProof = this.DOCUMENTS_PROOFS[i];

      if (!documentProof.path || !documentProof.selectedDocumentProof) {
        if (!documentProof.mandatory) continue;

        this.uploadedProofsEmitter.emit(undefined);
        return;
      }

      data.push({
        path: documentProof.path,
        proof_type_id: documentProof.proofType.id,
        document_type_id: documentProof.selectedDocumentProof,
        name: documentProof.fileName ?? '',
      });
    }
    this.uploadedProofsEmitter.emit(data);
  }
}

export enum Cities {
  Jodhpur = 5,
  Bharatpur = 115,
  Kotputli = 121,
  JodhpurNorth = 122,
  Alwar = 126,
}
export const CitiesNames: Record<Cities, string> = {
  [Cities.Jodhpur]: 'Jodhpur',
  [Cities.Bharatpur]: 'Bharatpur',
  [Cities.Kotputli]: 'Kotputli',
  [Cities.JodhpurNorth]: 'Jodhpur North',
  [Cities.Alwar]: 'Alwar'
}

export function getFormattedCities(): { id: Cities, name: string }[] {
  return Object.entries(CitiesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}

import {Citizen, Floor, Property} from '../../../models';
import {isBoolean} from 'lodash';

const getFormattedMonth = (month: number) => {
  let date = new Date(2020, month, 21);

  return date.toLocaleString('en-in', {month: 'short'});
}

const formattedDate = (date: Date | { day: number, month: number, year: number }) => {
  let newDate = (
    date instanceof Date
      ? date
      : new Date(`${date.year}-${date.month - 1}-${date.day}`)
  );

  return `${newDate.getDate()} ${getFormattedMonth(newDate.getMonth() + 1)} ${newDate.getFullYear()}`;
}

export type FieldResolver<T extends object> = {
  forDisplay: (a: T) => string,
  // column in db, value
  forRequest: (a: T) => [string, string],
}

export type FieldResolvers<T extends object> = { [key in keyof T]?: FieldResolver<T> }

export const PROPERTY_FIELD_RESOLVERS: FieldResolvers<Property> = {
  // Relations
  ward: {
    forDisplay: (p) => p.ward?.name ?? 'Unknown',
    forRequest: (p) => ['ward_id', p.ward?.id?.toString() ?? null]
  },
  colony_id: {
    forDisplay: (p) => p.colony?.name ?? 'Unknown',
    forRequest: (p) => ['colony_id', p.colony?.id?.toString() ?? null]
  },
  sector: {
    forDisplay: (p) => p.sector?.name ?? 'Unknown',
    forRequest: (p) => ['sector_id', p.sector?.id?.toString() ?? null]
  },
  propertyType: {
    forDisplay: (p) => p.propertyType?.name ?? 'Unknown',
    forRequest: (p) => ['property_type_id', p.propertyType?.id?.toString() ?? null]
  },
  propertySubType: {
    forDisplay: (p) => p.propertySubType?.name ?? 'Unknown',
    forRequest: (p) => ['property_sub_type_id', p.propertySubType?.id?.toString() ?? null]
  },
  businessType: {
    forDisplay: (p) => p.businessType?.name ?? 'Unknown',
    forRequest: (p) => ['business_type_id', p.businessType?.id?.toString() ?? null]
  },
  vacant_area: {
    forDisplay: (p) => p.vacant_area?.toString() ?? 'Unknown',
    forRequest: (p) => ['vacant_area', p.vacant_area?.toString() ?? null]
  },

  // From Enum
  property_category: {
    forDisplay: (p) => Property.PROPERTY_CATEGORIES[p.property_category] ?? 'Unknown',
    forRequest: (p) => ['property_category', p.property_category?.toString()]
  },
  property_usage_id: {
    forDisplay: (p) => Property.PROPERTY_USAGES[p.property_usage_id] ?? 'Unknown',
    forRequest: (p) => ['property_usage_id', p.property_usage_id?.toString()]
  },
  construction_type_id: {
    forDisplay: (p) => Property.CONSTRUCTION_TYPES[p.construction_type_id] ?? 'Unknown',
    forRequest: (p) => ['construction_type_id', p.construction_type_id?.toString()]
  },
  building_permission_use_type: {
    forDisplay: (p) => Property.PROPERTY_CATEGORIES[p.building_permission_use_type as any] ?? 'Unknown',
    forRequest: (p) => ['building_permission_use_type', p.building_permission_use_type?.toString()]
  },
  water_connection_number: {
    forDisplay: (p) => Property.WATER_SUPPLY_SOURCES[p.water_connection_number as unknown as number] ?? 'Unknown',
    forRequest: (p) => ['water_connection_number', p.water_connection_number?.toString()]
  },
  road_type_id: {
    forDisplay: (p) => Property.ROAD_TYPES[p.road_type_id] ?? 'Unknown',
    forRequest: (p) => ['road_type_id', p.road_type_id?.toString()]
  },
  mobile_tower_placement: {
    forDisplay: (p) => Property.TOWER_LOCATIONS[p.mobile_tower_placement] ?? 'Unknown',
    forRequest: (p) => ['mobile_tower_placement', p.mobile_tower_placement?.toString()]
  },

  // boolean
  landuse_change: {
    forDisplay: (p) => isBoolean(p.landuse_change) ? (p.landuse_change ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['landuse_change', p.landuse_change?.toString()]
  },
  sub_divided_plots: {
    forDisplay: (p) => isBoolean(p.sub_divided_plots) ? (p.sub_divided_plots ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['sub_divided_plots', p.sub_divided_plots?.toString()]
  },
  building_permission: {
    forDisplay: (p) => isBoolean(p.building_permission) ? (p.building_permission ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['building_permission', p.building_permission?.toString()]
  },
  water_connection: {
    forDisplay: (p) => isBoolean(p.water_connection) ? (p.water_connection ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['water_connection', p.water_connection?.toString()]
  },
  electricity_connection: {
    forDisplay: (p) => isBoolean(p.electricity_connection) ? (p.electricity_connection ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['electricity_connection', p.electricity_connection?.toString()]
  },
  free_hold_patta: {
    forDisplay: (p) => isBoolean(p.free_hold_patta) ? (p.free_hold_patta ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['free_hold_patta', p.free_hold_patta?.toString()]
  },
  one_time_deposited: {
    forDisplay: (p) => isBoolean(p.one_time_deposited) ? (p.one_time_deposited ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['one_time_deposited', p.one_time_deposited?.toString()]
  },
  lease_deed_patta: {
    forDisplay: (p) => isBoolean(p.lease_deed_patta) ? (p.lease_deed_patta ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['lease_deed_patta', p.lease_deed_patta?.toString()]
  },
  advertisement_on_building: {
    forDisplay: (p) => isBoolean(p.advertisement_on_building) ? (p.advertisement_on_building ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['advertisement_on_building', p.advertisement_on_building?.toString()]
  },
  sewerage_line: {
    forDisplay: (p) => isBoolean(p.sewerage_line) ? (p.sewerage_line ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['sewerage_line', p.sewerage_line?.toString()]
  },
  septic_tank: {
    forDisplay: (p) => isBoolean(p.septic_tank) ? (p.septic_tank ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['septic_tank', p.septic_tank?.toString()]
  },
  compost_pit: {
    forDisplay: (p) => isBoolean(p.compost_pit) ? (p.compost_pit ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['compost_pit', p.compost_pit?.toString()]
  },
  solar_plant_or_unit: {
    forDisplay: (p) => isBoolean(p.solar_plant_or_unit) ? (p.solar_plant_or_unit ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['solar_plant_or_unit', p.solar_plant_or_unit?.toString()]
  },
  rainwater_harvesting: {
    forDisplay: (p) => isBoolean(p.rainwater_harvesting) ? (p.rainwater_harvesting ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['rainwater_harvesting', p.rainwater_harvesting?.toString()]
  },
  toilet: {
    forDisplay: (p) => isBoolean(p.toilet) ? (p.toilet ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['toilet', p.toilet?.toString()]
  },
  parking: {
    forDisplay: (p) => isBoolean(p.parking) ? (p.parking ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['parking', p.parking?.toString()]
  },
  street_light: {
    forDisplay: (p) => isBoolean(p.street_light) ? (p.street_light ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['street_light', p.street_light?.toString()]
  },
  fire_fighting_system: {
    forDisplay: (p) => isBoolean(p.fire_fighting_system) ? (p.fire_fighting_system ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['fire_fighting_system', p.fire_fighting_system?.toString()]
  },
  mobile_tower: {
    forDisplay: (p) => isBoolean(p.mobile_tower) ? (p.mobile_tower ? 'Yes' : 'No') : 'Unknown',
    forRequest: (p) => ['mobile_tower', p.mobile_tower?.toString()]
  },
  multi: {
    forDisplay: (p) =>  p.multi ? 'Yes' : 'No',
    forRequest: (p) => ['multi', p.multi?.toString()]
  },

  // other
  property_name: {
    forDisplay: (p) => p.property_name ?? 'Unknown',
    forRequest: (p) => ['property_name', p.property_name ?? null]
  },
  old_house_tax_no: {
    forDisplay: (p) => p.old_house_tax_no ?? 'Unknown',
    forRequest: (p) => ['old_house_tax_no', p.old_house_tax_no ?? null]
  },
  property_uid: {
    forDisplay: (p) => p.property_uid ?? 'Unknown',
    forRequest: (p) => ['property_uid', p.property_uid ?? null]
  },
  address_house_number: {
    forDisplay: (p) => p.address_house_number ?? 'Unknown',
    forRequest: (p) => ['address_house_number', p.address_house_number ?? null]
  },
  nearby_houseno: {
    forDisplay: (p) => p.nearby_houseno ?? 'Unknown',
    forRequest: (p) => ['nearby_houseno', p.nearby_houseno ?? null]
  },
  landmark: {
    forDisplay: (p) => p.landmark ?? 'Unknown',
    forRequest: (p) => ['landmark', p.landmark ?? null]
  },
  pincode: {
    forDisplay: (p) => p.pincode ?? 'Unknown',
    forRequest: (p) => ['pincode', p.pincode ?? null]
  },
  building_construction_year: {
    forDisplay: (p) => p.building_construction_year?.toString() ?? 'Unknown',
    forRequest: (p) => ['building_construction_year', p.building_construction_year?.toString() ?? null]
  },
  plot_allotment_year: {
    forDisplay: (p) => p.plot_allotment_year?.toString() ?? 'Unknown',
    forRequest: (p) => ['plot_allotment_year', p.plot_allotment_year?.toString() ?? null]
  },
  establishment_year: {
    forDisplay: (p) => p.establishment_year?.toString() ?? 'Unknown',
    forRequest: (p) => ['establishment_year', p.establishment_year?.toString() ?? null]
  },
  building_permission_year: {
    forDisplay: (p) => p.building_permission_year?.toString() ?? 'Unknown',
    forRequest: (p) => ['building_permission_year', p.building_permission_year?.toString() ?? null]
  },
  road_width: {
    forDisplay: (p) => p.road_width?.toString() ?? 'Unknown',
    forRequest: (p) => ['road_width', p.road_width?.toString() ?? null]
  },
  name_of_shop: {
    forDisplay: (p) => p.name_of_shop?.toString() ?? 'Unknown',
    forRequest: (p) => ['name_of_shop', p.name_of_shop?.toString() ?? null]
  },
  items_traded: {
    forDisplay: (p) => p.items_traded?.toString() ?? 'Unknown',
    forRequest: (p) => ['items_traded', p.items_traded?.toString() ?? null]
  },
  license_number: {
    forDisplay: (p) => p.license_number?.toString() ?? 'Unknown',
    forRequest: (p) => ['license_number', p.license_number?.toString() ?? null]
  },
  length: {
    forDisplay: (p) => p.length?.toString() ?? 'Unknown',
    forRequest: (p) => ['length', p.length?.toString() ?? null]
  },
  breadth: {
    forDisplay: (p) => p.breadth?.toString() ?? 'Unknown',
    forRequest: (p) => ['breadth', p.breadth?.toString() ?? null]
  },
  plot_area: {
    forDisplay: (p) => p.plot_area?.toString() ?? 'Unknown',
    forRequest: (p) => ['plot_area', p.plot_area?.toString() ?? null]
  },
  electricity_connection_number: {
    forDisplay: (p) => p.electricity_connection_number?.toString() ?? 'Unknown',
    forRequest: (p) => ['electricity_connection_number', p.electricity_connection_number?.toString() ?? null]
  },
  license_date: {
    forDisplay: (p) => {
      if (!p.license_date) return 'Unknown';

      return formattedDate(p.license_date)
    },
    forRequest: (p) => {
      return ['license_date', p.license_date ? formattedDate(p.license_date) : 'null']
    },
  }
}

export const OWNER_FIELD_RESOLVERS: FieldResolvers<Citizen> = {
  first_name: {
    forDisplay: (o) => o.first_name ?? 'Unknown',
    forRequest: (o) => ['first_name', o.first_name ?? null]
  },
  last_name: {
    forDisplay: (o) => o.last_name ?? 'Unknown',
    forRequest: (o) => ['last_name', o.last_name ?? null]
  },
  relation: {
    forDisplay: (o) => o.relation?.toString() ?? 'Unknown',
    forRequest: (o) => ['relation', o.relation?.toString() ?? null]
  },
  relation_name: {
    forDisplay: (o) => o.relation_name ?? 'Unknown',
    forRequest: (o) => ['relation_name', o.relation_name ?? null]
  },
  phone_number: {
    forDisplay: (o) => o.phone_number ?? 'Unknown',
    forRequest: (o) => ['phone_number', o.phone_number ?? null]
  },
  uid_number: {
    forDisplay: (o) => o.uid_number ?? 'Unknown',
    forRequest: (o) => ['uid_number', o.uid_number ?? null]
  },
  email: {
    forDisplay: (o) => o.email ?? 'Unknown',
    forRequest: (o) => ['email', o.email ?? null]
  },
  profession: {
    forDisplay: (o) => o.profession?.toString() ?? 'Unknown',
    forRequest: (o) => ['profession', o.profession?.toString() ?? null]
  },
  bpl_no: {
    forDisplay: (o) => o.bpl_no ?? 'Unknown',
    forRequest: (o) => ['bpl_no', o.bpl_no ?? null]
  },

  // Boolean
  gender: {
    forDisplay: (o) =>  Number.isInteger(o.gender) ? (o.gender ? 'Yes' : 'No') : 'Unknown',
    forRequest: (o) => ['gender', o.gender?.toString()]
  },
  bpl_card: {
    forDisplay: (o) => isBoolean(o.bpl_card) ? (o.bpl_card ? 'Yes' : 'No') : 'Unknown',
    forRequest: (o) => ['bpl_card', o.bpl_card?.toString()]
  },
}

export const FLOOR_FIELD_RESOLVERS: FieldResolvers<Floor> = {
  floor_number: {
    forDisplay: (f) => f.floor_number?.toString() ?? 'Unknown',
    forRequest: (f) => ['floor_number', f.floor_number?.toString() ?? null]
  },
  floor_construction_year: {
    forDisplay: (f) => f.floor_construction_year?.toString() ?? 'Unknown',
    forRequest: (f) => ['floor_construction_year', f.floor_construction_year?.toString() ?? null]
  },
  no_of_room: {
    forDisplay: (f) => f.no_of_room?.toString() ?? 'Unknown',
    forRequest: (f) => ['no_of_room', f.no_of_room?.toString() ?? null]
  },
  no_of_toilet: {
    forDisplay: (f) => f.no_of_toilet?.toString() ?? 'Unknown',
    forRequest: (f) => ['no_of_toilet', f.no_of_toilet?.toString() ?? null]
  },
  property_category: {
    forDisplay: (f) => f.property_category?.toString() ?? 'Unknown',
    forRequest: (f) => ['property_category', f.property_category?.toString() ?? null]
  },
  carpet_area: {
    forDisplay: (f) => f.carpet_area?.toString() ?? 'Unknown',
    forRequest: (f) => ['carpet_area', f.carpet_area?.toString() ?? null]
  },
  property_construction_type_id: {
    forDisplay: (f) => Property.CONSTRUCTION_TYPES[f.property_construction_type_id] ?? 'Unknown',
    forRequest: (f) => ['property_construction_type_id', f.property_construction_type_id?.toString() ?? null]
  },
  property_usage_id: {
    forDisplay: (f) => Property.PROPERTY_USAGES[f.property_usage_id] ?? 'Unknown',
    forRequest: (f) => ['property_usage_id', f.property_usage_id?.toString() ?? null]
  },
  from_year: {
    forDisplay: (f) => f.from_year ?? 'Unknown',
    forRequest: (f) => ['from_year', f.from_year?.toString() ?? null]
  },
  upto_year: {
    forDisplay: (f) => f.upto_year ?? 'Unknown',
    forRequest: (f) => ['upto_year', f.upto_year?.toString() ?? null]
  },

  // Relations
  propertyType: {
    forDisplay: (f) => f.propertyType?.name ?? 'Unknown',
    forRequest: (f) => ['property_type_id', f.propertyType?.id?.toString() ?? null]
  },
  propertySubType: {
    forDisplay: (f) => f.propertySubType?.name ?? 'Unknown',
    forRequest: (f) => ['property_sub_type_id', f.propertySubType?.id?.toString() ?? null]
  },
}

export const PROPERTY_FIELD_NAME_RESOLVERS: { [key in keyof Property]?: string } = {
  property_name: 'Property Name',
  property_uid: 'Property UID',
  address_house_number: 'House Number',
  nearby_houseno: 'Near-by House Number',
  road_name: 'Street/Road Name',
  landmark: 'Landmark',
  pincode: 'Pin code',
  property_category: 'Property Category',
  landuse_change: 'Land Use Change',
  sub_divided_plots: 'Sub Divided Plots',
  property_usage_id: 'Property Usage',
  construction_type_id: 'Construction Type',
  business_type_id: 'Business Type',
  building_permission: 'Building permission',
  building_permission_year: 'Year of building permission',
  building_permission_num_floor: 'Building permission for number of floor',
  building_permission_use_type: 'Type of use for building permission',
  building_construction_year: 'Approximate year of building construction',
  plot_allotment_year:'Plot Allotment Year',
  length: 'Length',
  breadth: 'Breadth',
  plot_area: 'Plot Area (In sq yd)',
  water_connection: 'Water Supply Source',
  water_connection_number: 'Water Meter',
  electricity_connection: 'Electricity connection',
  old_house_tax_no: 'Old Service No',
  vacant_area: 'Vacant Area',
  electricity_connection_number: 'Electricity connection',
  free_hold_patta: 'Free Hold Patta',
  one_time_deposited: 'One Time Deposited',
  lease_deed_patta: 'Lease Deed Patta',
  advertisement_on_building: 'Advertisement on building',
  sewerage_line: 'Sewerage',
  septic_tank: 'Septic Tank',
  compost_pit: 'Compost Pit',
  solar_plant_or_unit: 'Solar Plant/Unit',
  rainwater_harvesting: 'Rain Water Harvesting',
  toilet: 'Toilet',
  parking: 'Parking',
  name_of_shop: 'Name Of The Firm',
  establishment_year: 'Establishment Year',
  items_traded: 'Items Traded',
  occupation_year: 'Occupation Year',
  license_number: 'License Number',
  license_date: 'License Date',
  road_type_id: 'Road Type',
  road_width: 'Road Width',
  street_light: 'Street Light',
  fire_fighting_system: 'Fire Fighting System',
  mobile_tower: 'Mobile Towers',
  mobile_tower_placement: 'Tower Location',
  ward: 'Ward',
  sector: 'Sector',
  colony_id: 'Colony',
  propertyType: 'Property Type',
  propertySubType: 'Property Sub Type',
  multi: 'Multi Storey/ Complex'
}

import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/guards/auth.guard';
import {MainComponent} from "../../layouts/main/main.component";
import { CreditRequestComponent } from './credit-request/credit-request.component';
import {IndexComponent} from "./index/index.component";
import { InformPaymentCreditComponent } from './inform-payment-credit/inform-payment-credit.component';

const routes: Routes = [
    {
        path: 'property',
        component: MainComponent,
        children: [
          {
            path: ':id',
            component: IndexComponent
          },
          {
            path: ':propertyId/inform-payment-credit',
            component: InformPaymentCreditComponent,
            canActivate: [AuthGuard]
          },
        ]
    },
    {
      path: 'credit-request',
      component: MainComponent,
      canActivate: [AuthGuard],
      children: [
        {
          path: '',
          component: CreditRequestComponent
        },
      ]
  },
];

export const propertyRouting = RouterModule.forChild(routes);

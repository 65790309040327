import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {LayoutsModule} from "./layouts/layouts.module";
import {AppComponent} from "./app.component";
import {HomeModule} from "./pages/home/home.module";
import {StaticModule} from "./pages/static/static.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDialogModule} from "@angular/material/dialog";
import {DialogsModule} from "./dialogs/dialogs.module";
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HttpClientModule} from "@angular/common/http";
import {PropertySearchModule} from "./pages/property-search/property-search.module";
import {PropertyModule} from "./pages/property/property.module";
import {GraphQLModule} from './graphql.module';
import {PaymentModule} from "./pages/payment/payment.module";
import {InvoiceModule} from "./pages/invoice/invoice.module";
import {NotificationsModule} from "./pages/notifications/notifications.module";
import {PaymentHistoryModule} from "./pages/payment-history/payment-history.module";
import {MyPropertiesModule} from "./pages/my-properties/my-properties.module";
import {AuthGuard} from "./guards/auth.guard";
import {PropertyTaxModule} from "./pages/property-tax/property-tax.module";
import {LeaseRentalModule} from "./pages/lease-rental/lease-rental.module";
import {RentalModule} from "./pages/rental/rental.module";
import {SarasModule} from "./pages/saras/saras.module";
import {AgmCoreModule} from "@agm/core";
import {NgxPrintModule} from "ngx-print";
import {FormsModule} from "@angular/forms";
import {MyProfileModule} from "./pages/my-profile/my-profile.module";
import {FaqsModule} from "./pages/faqs/faqs.module";
import {PropertiesMapModule} from './pages/properties-map/properties-map.module';
import {PropertyCorrectionModule} from './pages/property-correction/property-correction.module';
import {NgbDateFormatter} from './adapters/ngb-date-formatter';
import {MyObjectionsModule} from "./pages/my-objections/my-objections.module";
import {SelfAssessmentFormModule} from './pages/self-assessment-form/self-assessment-form.module';
import {AppInitService, initializeAppFactory} from './services/app-init.service';
import { RouterModule } from '@angular/router';
import { LanguageService } from './services/language.service';
import { PipesModule } from './pipes/pipes.module';
import { LaunchModule } from './pages/launch/launch.module';
import {NdcStatusModule} from './pages/ndc-status/ndc-status.module';
import { PaymentVerificationModule } from './pages/payment-verification/payment-verification.module';

export function appInit(langService: LanguageService) {
  return () => langService.load();
}
@NgModule({
  declarations: [AppComponent],
  imports: [
    MatDialogModule,
    DialogsModule,
    BrowserModule,
    AppRoutingModule,
    LayoutsModule,
    BrowserAnimationsModule,
    NgbModule,
    HttpClientModule,
    GraphQLModule,
    // pages modules
    HomeModule,
    PropertySearchModule,
    PropertyModule,
    PaymentModule,
    InvoiceModule,
    NotificationsModule,
    PaymentHistoryModule,
    MyProfileModule,
    MyPropertiesModule,
    MyObjectionsModule,
    PropertyCorrectionModule,
    PropertyTaxModule,
    FaqsModule,
    PropertiesMapModule,
    LeaseRentalModule,
    RentalModule,
    SarasModule,
    SelfAssessmentFormModule,
    LaunchModule,
    NdcStatusModule,
    PaymentVerificationModule,
    StaticModule,
    // AGM
    AgmCoreModule.forRoot({
      apiKey: '',
    }),
    NgxPrintModule,
    FormsModule,
    RouterModule,
    PipesModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [LanguageService]
    },
    AuthGuard,
    {provide: NgbDateParserFormatter, useClass: NgbDateFormatter},
    {provide: APP_INITIALIZER, useFactory: initializeAppFactory, deps: [AppInitService], multi: true},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

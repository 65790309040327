import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PaymentService} from '../../../services/payment.service';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Payment} from '../../../models';
import {PaymentStatuses} from '../../../enums/payment-statuses';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  private destroy$ = new ReplaySubject();
  Payment = Payment;
  loading: boolean = false;
  transactionId: string = "";
  payment!: Payment;
  PaymentStatuses = PaymentStatuses;

  STATUSES_STYLES = {
    [PaymentStatuses.NotProcessed]: 'text-warning',
    [PaymentStatuses.Success]: 'text-success',
    [PaymentStatuses.Failure]: 'text-danger',
    [PaymentStatuses.Abandoned]: 'text-danger',
  }

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService) { 

  }

  ngOnInit(): void {
    this.transactionId = this.route.snapshot.params.urlKey;
    this.getPaymentReceitptStatus(this.transactionId)
  }

  getPaymentReceitptStatus(transactionId: String){
    const fields = `transaction_id,property {id, property_uid},amount, payment_date, payment_source, status`
    this.loading = true;
    this.paymentService.getOneByTransactionId(
      this.transactionId as unknown as number, fields
    ).pipe(takeUntil(this.destroy$)).subscribe((p) => {
      this.loading=false;
      if(p){
        this.payment = p;
      }
    })
  }

}

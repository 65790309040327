import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Citizen, DocumentProofType, Property} from '../../../models';
import {OwnershipTypes} from '../../../enums/ownership-types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DocumentProofTypeService} from '../../../services/document-proof-type.service';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {
  OwnerEditValidationRules,
} from '../data/validation-rules';
import {cloneDeep} from 'lodash';
// @ts-ignore
import Validator from 'Validator/src/validator';
import {getFormattedErrorsByValidator, Validated, ValidationErrors} from '../../../@types/validation';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';
import { CommonService } from 'src/app/services/common.service';
import Swal from 'sweetalert2';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-owner-details',
  templateUrl: './owner-details.component.html',
  styleUrls: ['./owner-details.component.scss'],
})
export class OwnerDetailsComponent implements OnInit, OnDestroy {

  @Input() property!: Property;
  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('previousForm') previousFormEmitter = new EventEmitter();

  readonly OWNER_FORM_ADD_MODE = 1;
  readonly OWNER_FORM_EDIT_MODE = 2;

  readonly CITIZEN_RELATIONS = Citizen.RELATION;
  readonly FORMATTED_PROFESSIONS = Citizen.formattedProfessions();
  readonly FORMATTED_CITIZEN_RELATIONS = Citizen.formattedRelations();

  readonly YES_NO_OPTIONS = [{id: true, name: 'Yes'}, {id: false, name: 'No'}];

  readonly OWNERSHIP_TYPES: { id: OwnershipTypes, name: string }[] = [
    {id: OwnershipTypes.Individual, name: 'Individual'},
    {id: OwnershipTypes.Partnership, name: 'Partnership'},
    {id:OwnershipTypes.Company, name: 'Company'},
    {id: OwnershipTypes.Government_Building, name:  'Government Building'},
    {id: OwnershipTypes.Muncipality, name: 'Muncipality'},
    {id: OwnershipTypes.Religious_Trust,name: 'Religious Trust'},
    {id: OwnershipTypes.Charitable_Organisations,name:  'Charitable Organisations'},
    {id: OwnershipTypes.Other,name: 'Other'},
  ];

  readonly GENDER_TYPES = getFormattedGenderTypes();

  formOwner = new Citizen();
  documentProofTypes: DocumentProofType[] = [];
  ownerFormErrors: ValidationErrors<{ [key: string]: string }> = {};

  private ownerFormMode = this.OWNER_FORM_ADD_MODE;
  private destroy$ = new ReplaySubject();

  constructor(
    private modalService: NgbModal,
    private documentProofTypeService: DocumentProofTypeService,
    private commonService: CommonService,
    private languageService:LanguageService
  ) {
  }

  ngOnInit(): void {
    this.documentProofTypeService.getAll().pipe(takeUntil(this.destroy$)).subscribe((i) => {
      this.documentProofTypes = i;
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  nextForm() {
    if (!this.property.ownershipType?.id) {
      this.commonService.showErrorMessage('property_search.select_ownership_type')
      return;
    }else if(this.property.ownershipType?.id === OwnershipTypes.Individual && this.property?.owners?.length > 1) {
      this.commonService.showErrorMessage('property_search.more_than_one_owners_can_not_be_added_for_individual_ownership_type');
      return;
    }

    const ownersCount = this.property?.owners?.length ? this.property.owners.length : 0;
    const minOwnersCount = this.property.ownershipType.id === OwnershipTypes.Partnership ? 2 : 1;

    if (ownersCount < minOwnersCount) {
      Swal.fire({
        title:  this.languageService.translate('home.error'),
        text : this.languageService.translate('property_search.the_number_of_owners_must_be_at_least')+' ' +minOwnersCount,
        icon :'error',
        confirmButtonText: this.languageService.translate('home.ok'),
      });
    
      return;
    }

    this.nextFormEmitter.emit();
  }

  ownershipTypeCompareFn(a: any, b: any) {
    return a.id === b.id;
  }

  addOwner(modal: any) {
    this.ownerFormMode = this.OWNER_FORM_ADD_MODE;
    this.formOwner = new Citizen();
    this.openOwnerFormModal(modal);
  }

  editOwner(modal: any, owner: Citizen) {
    this.ownerFormMode = this.OWNER_FORM_EDIT_MODE;
    this.formOwner = Object.assign(new Citizen(), cloneDeep(owner));
    this.openOwnerFormModal(modal);
  }

  deleteOwner(index: number) {
    if (!this?.property?.owners || this.property.owners.length <= 1) return;

    this.property.owners = this.property.owners.filter((v, i) => i !== index);
  }

  ownerSubmit(modal: any) {
    const validated = this.validateOwner(this.formOwner);

    this.ownerFormErrors = validated.errors;

    if (!validated.isSuccess) return;

    modal.dismiss();

    if (this.ownerFormMode === this.OWNER_FORM_ADD_MODE) {
      this.formOwner['temp_id'] = this.property.owners.length + 1;
      this.property.owners.push(this.formOwner);
      return;
    }

    const editingOwnerIndex = this.property.owners.findIndex((el) => {
      if(el.temp_id) {
        return el.temp_id === this.formOwner.temp_id;
      }else {
        return el.id === this.formOwner.id;
      }
    });

    if (editingOwnerIndex === -1) return;

    this.property.owners[editingOwnerIndex] = this.formOwner;
  }

  private openOwnerFormModal(modal: any) {
    this.ownerFormErrors = {};
    this.modalService.open(modal, {scrollable: true, centered: true});
  }

  private validateOwner(owner: Citizen): Validated<Citizen> {
    let rules = cloneDeep(OwnerEditValidationRules);
    if(this.formOwner.relation_name?.trim()){
      rules['relation'] = 'required';
    }else {
      this.formOwner.relation = null as any;
    }
    const errors: { [key: string]: string } = {};
    const validated = Validator.make(owner, rules);

    return {
      isSuccess: !validated.fails(),
      errors: getFormattedErrorsByValidator(validated.getErrors() ?? {}),
    };
  }

  disableAddButton() {
    return (this.property.ownershipType?.id  === OwnershipTypes.Individual && this.property?.owners?.length);
  }
}

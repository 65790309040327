// For launch page flower animation
export class Confetti {
    x!: number;
    y!: number;
    rotation!: number;
    size!:number;
    color!:string;
    flower!:string;
    speed!:number;
    opacity!:number;
    shiftDirection!:number;
    width!:number;
    height!:number;
    isAnimationCompleted!:boolean;
    ctx:CanvasRenderingContext2D | null;
    constructor(ctx:CanvasRenderingContext2D | null, width:number, height:number) {
        this.ctx = ctx;
        this.width = width;
        this.height = height;
        const colours = ['#fde132', '#009bde', '#ff6b00'];
        const flowers = ['01_flower', '02_flower', '03_flower', '05_flower', '06_flower'];
        this.x = Math.round(Math.random() * this.width);
        this.y = Math.round(Math.random() * this.height) - this.height / 2;
        this.rotation = Math.random() * 360;
        const size = Math.random() * (this.width / 60);
        this.size = size < 15 ? 15 : size;
        this.color = colours[Math.floor(colours.length * Math.random())];
        this.flower = flowers[Math.floor(flowers.length * Math.random())];
        this.speed = this.size / 20;
        this.opacity = Math.random();
        this.shiftDirection = Math.random() > 0.5 ? 1 : -1;
    }

    // Set vertical height to a fixed value after flowers reach at bottom; set isAnimationCompleted = false when flower reaches at bottom
    border() {
        if (this.y >= (this.height - 25)) {
            this.y = this.height - 25;
            this.isAnimationCompleted = true;
        }
    }

    // Update value of x and y for flower animation (x -> width, y-> height)
    update() {
        this.y += this.speed;
        if (this.y <= (this.height - 25)) {
            this.x += this.shiftDirection / 3;
            this.rotation += this.shiftDirection * this.speed / 100;
        }
        if (this.y > (this.height - 25)) this.border();
    }

    draw() {
        let img = <CanvasImageSource>document.getElementById(this.flower);
        this.ctx?.drawImage(img, this.x, this.y, 50, 50);
    }


}
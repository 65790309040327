import {cloneDeep} from '@apollo/client/utilities';
import {GraphQLModelFields} from '../@types/graphql-model-fields';

export abstract class BaseModel {
  /**
   * Fields that uses when create and update
   * */
  public abstract getIncludedFields(): Array<keyof this>

  /**
   * Fields that not uses when create and update
   * */
  public abstract getExcludedFields(): Array<keyof this>

  /**
   * Other
   * */
  assignToClone(model: object): this {
    return Object.assign(cloneDeep(this), model);
  }

  getDistinctFields<T extends BaseModel>(model: this): GraphQLModelFields<T> {
    let diffFields: any = {};

    this.getIncludedFields().forEach((prop) => {
      if (this[prop] === model[prop]) return;

      if (this[prop] === undefined && model[prop]) {
        diffFields[prop] = model[prop];
        return;
      }

      if (model[prop] !== undefined && (model[prop] !== this[prop])) {
        diffFields[prop] = model[prop];
        return;
      }

      if ((this[prop] !== undefined || this[prop] !== null) && (model[prop] === undefined || model[prop] === null)) {
        diffFields[prop] = null;
        return;
      }
    });

    return diffFields;
  }

  static canUseInGraphQLModelFields(value: any) {
    if (value === null) return true;

    const type = typeof value;

    return type === 'string' || type === 'number' || type === 'boolean';
  }
}

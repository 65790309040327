export type ValidationRules = string | string[];

export type ValidationErrors<T extends object> = { [key in keyof T]?: string }
export type Validated<T extends object> = { isSuccess: boolean, errors: ValidationErrors<T> };

export function getFormattedErrorsByValidator(errors: Record<string, string[]>): Record<string, string> {
  return Object.getOwnPropertyNames(errors)
    .filter((error) => errors[error]?.length && errors[error].length > 0)
    .reduce((acc, error) => {
      (acc as any)[error] = errors[error][0];
      return acc;
    }, {});
}

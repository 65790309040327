import {Component, OnInit} from '@angular/core';
import {Property} from "../../../models";
import {ReplaySubject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {PropertyService} from "../../../services/property.service";
import {AuthenticationService} from "../../../services";
import {LedgerService} from "../../../services/ledger.service";
import TaxDetails from "../../../@types/tax-details";
import {Ledger} from "../../../models";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {

  PROPERTY_CATEGORIES: {[key: number]: string} = Property.PROPERTY_CATEGORIES;

  destroy$ = new ReplaySubject();
  taxDetails!: TaxDetails;
  properties: Property[] = [];
  propertiesDue: number[] = [];

  subTotalTax: number = 0;
  subTotalPenalty: number = 0;
  subTotalRebate: number = 0;
  subTotalPayment: number = 0;
  grid = true;

  constructor(
    private propertyService: PropertyService,
    private authenticationService: AuthenticationService,
    private ledgerService: LedgerService,
  ) {}

  ngOnInit(): void {
    this.propertyService.paginatePropertyByPhoneNumberOwners(1,1000, this.authenticationService.user?.phone_number + '')
      .pipe(takeUntil(this.destroy$))
      .subscribe((properties) => {
      this.properties = properties;
      this.properties.forEach((prop) => {
        this.getTotalDue(prop.id);
      })
    })
  }

  getTotalDue(propertyID: number) {
    const successfulRes = (details: TaxDetails | undefined) => {
      if (!details) return;
      this.taxDetails = details;
      this.taxDetails.ledgersCurrentYear.forEach((item: Ledger) => {
        if(item.transaction_type !== Ledger.REBATE_TYPE){
          this.subTotalPayment += item.transaction_type === Ledger.PAYMENT_TYPE ? item.credit_amount : item.debit_amount;
        } else if(item.transaction_type === Ledger.REBATE_TYPE){
          this.subTotalRebate += item.credit_amount;
        }
      })
      this.propertiesDue[propertyID] = details.propertyDebtInPastYear + details.taxInCurrentYear + this.subTotalPenalty - this.subTotalRebate - this.subTotalPayment;
    }

    this.ledgerService.taxDetails(propertyID)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        successfulRes,
        () => '',
      )
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CreditRequestStatus } from 'src/app/enums/credit-request-statuses';
import { AuthenticationService } from 'src/app/services';
import { CreditRequestService } from 'src/app/services/credit-request.service';
import { TableService } from 'src/app/services/table.service';

@Component({
  selector: 'app-credit-request',
  templateUrl: './credit-request.component.html',
  styleUrls: ['./credit-request.component.scss']
})
export class CreditRequestComponent implements OnInit {
  CreditRequestStatus = CreditRequestStatus;
  items$!: Observable<any[]> | Observable<any>;
  items: any[] = [];
  tableService: TableService<any>;
  search = '';
  constructor(
    public creditRequestService: CreditRequestService,
    public authService: AuthenticationService
  ) { 
    this.tableService = creditRequestService.table;
  }

  ngOnInit(): void {
    this.items$ = this.tableService.items$;
    this.items$.subscribe(creditRequest => this.items = creditRequest);
  }

}

import { Injectable } from '@angular/core';
import { TableService } from './table.service';
import { Apollo, gql } from 'apollo-angular';
import { BaseService } from './base-service.service';

@Injectable({
  providedIn: 'root'
})
export class CreditRequestService extends BaseService {
  table: TableService<any>;
  constructor(
    private apollo: Apollo,
  ) { 
    super();

    this.table = new TableService<any>({
      apollo,
      queryName: 'CreditRequestPaginateForAuth',
      needleColumns: this.needleColumns(),
      whereCondition: () => '',
    });
  }

  needleColumns() {
    return `
    property {
      id
      property_uid
    }
    user_remark
    reviewed_remark
    status_id
    created_at
    `;
  }
}

<div class="success-payment">
  <div class="success-payment-card" *ngIf="!loading; else loadingBlock">

    <h1 class="success-payment__payment-amount">
      ₹ {{payment?.amount}}
      <svg xmlns="http://www.w3.org/2000/svg" class="success-payment__success-icon" viewBox="0 0 24 24"
           style="transform: ;msFilter:;">
        <path
          d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"></path>
        <path d="M9.999 13.587 7.7 11.292l-1.412 1.416 3.713 3.705 6.706-6.706-1.414-1.414z"></path>
      </svg>
    </h1>

    <h2 class="success-payment__message">
      {{'property_search.payment_successful' | translate}} 
    </h2>

    <div class="success-payment__transaction-details">
      <p class="success-payment__transaction-row">
        {{'property_search.transaction_id' | translate}} 
        <span class="success-payment__transaction-id">
          {{transactionId}}
        </span>
      </p>
      <p class="success-payment__transaction-date">
        {{payment?.payment_date | date: 'dd MMMM yyyy h:mm a'}}
      </p>
    </div>

    <hr>

    <div class="success-payment__menu">
      <button class="success-payment__menu-button" (click)="goToViewLink('/payment/'+transactionId+'/view')">
        <svg xmlns="http://www.w3.org/2000/svg" class="success-payment__menu-button-icon" viewBox="0 0 24 24">
          <path
            d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z"></path>
          <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z"></path>
        </svg>
        {{'property_search.view_receipt' | translate}}
      </button>

      <a class="success-payment__menu-button" [href]="getDownLoadLink()"  target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" class="success-payment__menu-button-icon" viewBox="0 0 24 24">
            <path
              d="M19.903 8.586a.997.997 0 0 0-.196-.293l-6-6a.997.997 0 0 0-.293-.196c-.03-.014-.062-.022-.094-.033a.991.991 0 0 0-.259-.051C13.04 2.011 13.021 2 13 2H6c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2V9c0-.021-.011-.04-.013-.062a.952.952 0 0 0-.051-.259c-.01-.032-.019-.063-.033-.093zM16.586 8H14V5.414L16.586 8zM6 20V4h6v5a1 1 0 0 0 1 1h5l.002 10H6z"></path>
            <path d="M8 12h8v2H8zm0 4h8v2H8zm0-8h2v2H8z"></path>
          </svg>
          {{'property_search.download_receipt' | translate}}
      </a>

      <button class="success-payment__menu-button" (click)="print()">
        <svg xmlns="http://www.w3.org/2000/svg" class="success-payment__menu-button-icon" viewBox="0 0 24 24">
          <path
            d="M19 7h-1V2H6v5H5a3 3 0 0 0-3 3v7a2 2 0 0 0 2 2h2v3h12v-3h2a2 2 0 0 0 2-2v-7a3 3 0 0 0-3-3zM8 4h8v3H8V4zm0 16v-4h8v4H8zm11-8h-4v-2h4v2z"></path>
        </svg>
        {{'property_search.print_receipt' | translate}}
      </button>

      <button class="success-payment__menu-button" (click)="sendReceiptEmail()">
        <svg xmlns="http://www.w3.org/2000/svg" class="success-payment__menu-button-icon" viewBox="0 0 24 24">
          <path
            d="M20 4H6c-1.103 0-2 .897-2 2v5h2V8l6.4 4.8a1.001 1.001 0 0 0 1.2 0L20 8v9h-8v2h8c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm-7 6.75L6.666 6h12.668L13 10.75z"></path>
          <path d="M2 12h7v2H2zm2 3h6v2H4zm3 3h4v2H7z"></path>
        </svg>
        {{'property_search.mail_receipt' | translate}}
      </button>
    </div>
  </div>

  <div style="display: none">
    <div class="print" style="width: 100%;" id="test" #printBlock>
    </div>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="success-payment__loading">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'property_search.loading' | translate}}</span>
    </div>
  </div>
</ng-template>

import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef,MatDialog} from "@angular/material/dialog";

import {AuthenticationService} from "../../services";
import {Subscription} from 'rxjs';
import { SigninComponent } from '../signin/signin.component';
import { CityService } from 'src/app/services/city.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp-confirm',
  templateUrl: './otp-confirm.component.html',
  styleUrls: ['./otp-confirm.component.scss']
})
export class OtpConfirmComponent implements OnInit, OnDestroy {

  private resendSub!: Subscription;
  private readonly CAN_SEND_AFTER = 30;

  otp: any = {code: ''};
  error: string = '';
  phoneNumber!: string;

  countdown = {
    minutes: 0,
    seconds: 0,
  }
  private countdownExpiration = this.CAN_SEND_AFTER;
  private stopCountdown: any;

  resendDisabled = true;
  isLoading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { otpToken: string, phoneNumber: string, remember: boolean },
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public cs:CityService,
    public router: Router

  ) {
    this.resetCountdown();
  }

  ngOnDestroy(): void {
    this.resendSub?.unsubscribe();
  }

  ngOnInit(): void {
    this.phoneNumber = this.data.phoneNumber
  }

  resend() {
    if (this.resendDisabled) return;

    this.resendSub?.unsubscribe();

    this.resendSub = this.authenticationService.resendOTP(this.data.otpToken).subscribe((t) => {
      if (!t) return;

      this.data.otpToken = t;
      this.resetCountdown();
    });
  }
  

  confirm() {
    this.isLoading = true;
    this.authenticationService.checkOTP(this.data.otpToken, this.otp.code, this.data.remember).subscribe((result) => {
      if (result) {
          this.isLoading = false;
          this.dialogRef.close();
          const redirectionRoute = sessionStorage.getItem('redirectionRoute');
          if(redirectionRoute) {
            this.router.navigate([redirectionRoute]);
            sessionStorage.removeItem('redirectionRoute');
          }
        } else {
          this.error = 'Wrong One-Time Password!!!'
        }
      },
      (error) => {
        this.error = error.message;
        this.isLoading = false;
      })
  }

  private resetCountdown() {
    this.resendDisabled = true;
    clearInterval(this.stopCountdown);
    this.countdownExpiration = this.CAN_SEND_AFTER;

    this.stopCountdown = setInterval(() => {
      if (this.countdownExpiration <= 0) {
        this.countdown.minutes = 0;
        this.countdown.seconds = 0;
        this.resendDisabled = false;
        clearInterval(this.stopCountdown);
        return;
      }

      this.countdown.minutes = Math.floor(this.countdownExpiration / 60);
      this.countdown.seconds = Math.floor(this.countdownExpiration % 60);
      this.countdownExpiration--;
    }, 1000)
  }

  backToLogin(){
    this.dialogRef.close();
    this.dialog.open(SigninComponent);

  }

}

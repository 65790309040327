<div class="model-main">
  <button class="dialog-close" mat-dialog-close><svg xmlns="http://www.w3.org/2000/svg" width="27" height="27"
      viewBox="0 0 37 37" fill="none">
      <rect width="37" height="37" rx="18.5" fill="#747474" fill-opacity="0.2" />
      <path d="M12 25L25 12" stroke="#232930" stroke-width="2" />
      <path d="M25 25L12 12" stroke="#232930" stroke-width="2" />
    </svg></button>
  <div class="row m-0 sign-in-row">
    <div class="col-md-6 p-0 hide-on-mobile">
      <div class="sidebar-left" style="background: url(./assets/images/{{cs.city}}/login-sidebar.png); background-size: cover;">
        <p>{{'home.property_tax_managment_made_easy' | translate}}</p>
      </div>
    </div>
    <div class="col-md-6 p-0">
      <div class="login-form " *ngIf ="!isLoading; else loadingBlock">
        <div class="text-center"><img src="./assets/images/{{cs.city}}//logo-dark.png" /></div>
        <h2 class="mb-1">{{'home.login_sign_up' | translate }}</h2>
        <p>{{'home.welcome_back' | translate }}</p>
        <div class=" form-group position-relative">
          <label class="phone-label">{{'home.mobile_no' | translate }}<span class="required">*</span></label>
          <input [(ngModel)]="user.mobileNumber" (input)="error = ''" placeholder="{{'home.enter_mobile_number' | translate}}"
            #mobileNumber="ngModel" required pattern="^[0-9]*$" minlength="10" maxlength="10" class="form-control mb-4"
            [ngClass]="{'is-invalid': mobileNumber.invalid && (mobileNumber.dirty || mobileNumber.touched) || error.length > 0}"
            (keydown.enter)="mobileNumber.valid && signIn()" mask="0000000000">
          <div class="invalid-tooltip"
            *ngIf="mobileNumber.invalid && (mobileNumber.dirty || mobileNumber.touched) || error.length > 0">
            <span *ngIf="mobileNumber.errors?.required">
              {{'home.mobile_number_is_required' | translate}}
            </span>
            <span *ngIf="mobileNumber.errors?.minlength">
              {{'home.mobile_number_must_be_at_least_10_characters_long' | translate }}
            </span>
            <span *ngIf="mobileNumber.valid">
              {{ error == "{{'home.failed_to_fetch' | translate }}" ? "{{'home.mobile_number_not_registered' | translate }}" : error}}
            </span>
          </div>
          <div class="invalid-tooltip"
            *ngIf="mobileNumber.invalid && (mobileNumber.dirty || mobileNumber.touched) || error.length > 0">
            <span *ngIf="mobileNumber.errors?.pattern">
              {{'home.mobile_number_should_only_contain_numbers' | translate }}
            </span>
          </div>
         

        </div>
        <button (click)="signIn()" class="btn"
        [disabled]="user.mobileNumber == ''|| (mobileNumber.invalid && (mobileNumber.dirty || mobileNumber.touched) || error.length > 0)">{{'home.continue' | translate}}</button>
      <!--refernce for  signin page that would remove -->
      <span (click)="openSignUpModal()" class="form-anchor">{{'home.create_an_account' | translate}}</span>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingBlock>
  <div class="loader__general text-center">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'home.loading' | translate }}</span>
    </div>
  </div>
</ng-template>
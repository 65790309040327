import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AuthenticationService } from "./authentication.service";


@Injectable({
    providedIn: 'root'
})
export class TaxBillService {
    constructor(private http: HttpClient,
        private authService: AuthenticationService) {

    }

    getTaxBillDocument(propertyId: number): Observable<Blob | null> {
        const url = `${environment.apiBaseUrl}tax-notice/download`;
        const authHeaders = { 'Authorization': `Bearer ${this.authService.bearerToken}` };
        return this.http.post(url, {propertyId: propertyId}, {
            headers: authHeaders,
            observe: 'response',
            responseType: 'blob',
        }).pipe(map(res => res?.body))
    }
}
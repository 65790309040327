import {City} from "./city";

export class Sector {
  id!: number;
  city = new City();
  name!: string;
  description!: string;
  created_at:  Date = new Date();
  updated_at:  Date = new Date();
}

import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";
import { RecentlyViewedPropertyComponent } from './recently-viewed-property/recently-viewed-property.component';


const routes: Routes = [
    {
        path: 'property-search',
        component: MainComponent,
        children: [
          {
            path: '',
            component: IndexComponent
          },
          {
            path: 'recentlyViewed',
            component: RecentlyViewedPropertyComponent
          },
        ]
    }
];

export const propertySearchRouting = RouterModule.forChild(routes);

import {Component, OnInit, Renderer2} from '@angular/core';
import {Objection} from '../../../models/objection';
import {ObjectionService} from '../../../services/objection.service';
import {Citizen, Floor, Ledger, Property, PropertySubType, PropertyType} from '../../../models';
import {cloneDeep, update} from 'lodash';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client/core';
import {PropertyTypeService} from '../../../services/property-type.service';
import {PropertySubTypeService} from '../../../services/property-sub-type.service';
import {ActivatedRoute, Router} from '@angular/router';
import {PROPERTY_FIELD_NAME_RESOLVERS, PROPERTY_FIELD_RESOLVERS} from '../data/field-resolvers';
import {ObjectionStatuses, ObjectionStatusesNames, OwnerFloorStatus} from '../../../enums/objection-statuses';
import {ObjectionStatusHistory} from '../../../models/objection-status-history';
import Swal from 'sweetalert2';
import {PropertyTaxCalculation} from '../../../@types/tax-calculation';
import {TaxCalculationService} from '../../../services/tax-calculation.service';
import {PropertyService} from '../../../services/property.service';
import {ObjectionPropertyDetail} from '../../../models/objection-property-detail';
import {LedgerService} from '../../../services/ledger.service';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';
import {getFormattedProofTypes} from '../../../enums/proof-types';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {getFileExtensionFromString} from '../../../helpers/get-file-extension-from-string';
import {HttpClient} from '@angular/common/http';
import {getFileUrl} from '../../../helpers/get-file-url';
import { ObjectionsFloorModification } from 'src/app/models/objections-floor-modification';

enum ModelChangesTypes {
  Old = 1,
  Updated = 2,
  Deleted = 3,
  New = 4,
}

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit {

  readonly MODEL_CHANGES_TYPES = ModelChangesTypes;
  readonly PROPERTY_CATEGORIES = Property.PROPERTY_CATEGORIES;
  readonly FLOOR_NUMBERS = Floor.FLOOR_NUMBER;
  readonly PROPERTY_USAGES = Property.PROPERTY_USAGES;
  readonly CITIZEN_RELATION_NAME = Citizen.RELATION;
  readonly PROPERTY_FIELD_RESOLVERS = PROPERTY_FIELD_RESOLVERS;
  readonly PROPERTY_FIELD_NAME_RESOLVERS = PROPERTY_FIELD_NAME_RESOLVERS;
  readonly OBJECTION_STATUSES_NAMES = ObjectionStatusesNames;
  readonly MODEL_CHANGE_TYPE_NAMES: Record<ModelChangesTypes, string> = {
    [this.MODEL_CHANGES_TYPES.Old]: '',
    [this.MODEL_CHANGES_TYPES.Updated]: 'updated',
    [this.MODEL_CHANGES_TYPES.Deleted]: 'deleted',
    [this.MODEL_CHANGES_TYPES.New]: 'new',
  };
  readonly MODEL_CHANGE_TYPE_CLASSES: Record<ModelChangesTypes, string> = {
    [this.MODEL_CHANGES_TYPES.Old]: '',
    [this.MODEL_CHANGES_TYPES.Updated]: 'updated-alert',
    [this.MODEL_CHANGES_TYPES.Deleted]: 'deleted-alert',
    [this.MODEL_CHANGES_TYPES.New]: 'new-alert',
  };
  readonly LEDGER_TYPE_NAMES = Ledger.LEDGER_TYPES;

  readonly PROOF_TYPES = getFormattedProofTypes();
  OwnerFloorStatus = OwnerFloorStatus;
  

  ledgers: Ledger[] = [];
  loading = true;
  isLedgersLoading = false;
  currentTaxLoading = true;
  updatedTaxLoading =true;

  objection!: Objection;
  objectionStatusModel = new ObjectionStatusHistory();
  ObjectionStatuses = ObjectionStatuses;
  notification = {message: '', subject: ''};
  objectionId: number;
  propertyId!:number;
  submitting = false;
  ownerStatus!: boolean;
  floorStatus!: boolean;

  isDocFile = false;

  currentTaxCalculation?: PropertyTaxCalculation;
  updatedTaxCalculation?: PropertyTaxCalculation;

  updatedProperty?: Property;

  private propertyTypes: PropertyType[] = [];
  private propertySubTypes: PropertySubType[] = [];
  oldServiceId?: ObjectionPropertyDetail;
  currentProperty: Property | undefined;
  oldTaxValue!: number;
  oldPropertyId!: number;
  oldFloors: Floor[] = [];

  oldTaxIdChange: boolean = false;
  owners!: { owner: any; type: ModelChangesTypes }[];
  floors!: { floor: any; type: ModelChangesTypes }[];

  readonly GENDER_TYPES = getFormattedGenderTypes();
  readonly OWNER_RELATIONS = Citizen.RELATION;

  modalDocument!: { name: string; path: string; };

  rotateDegree: number = 90;
  rotateFlag: boolean = false;


  constructor(
    private apollo: Apollo,
    private router: Router,
    private currentRoute: ActivatedRoute,
    public taxCalculationService: TaxCalculationService,
    private objectionService: ObjectionService,
    public propertyService: PropertyService,
    public ledgerService: LedgerService,
    private modalService: NgbModal,
    private http: HttpClient,
    private renderer: Renderer2,
  ) {
    this.objectionId = this.currentRoute.snapshot.params.id;

    if (!this.objectionId) {
      this.redirectPopUp('objections', 'Something went wrong!');
      return;
    }
  }

  ngOnInit(): void {
    try {
      this.loading = true;
      this.apollo.query({
        query: gql`{
        objection: ${ObjectionService.getByIdQuery(this.objectionId)}
        propertyTypes: ${PropertyTypeService.getAllQueryRow()}
        propertySubTypes: ${PropertySubTypeService.getAllQueryRow()}
        }`,
      }).pipe(
      ).subscribe(
        (res: any) => {
          if (!res?.data?.objection) {
            this.redirectPopUp('objections', 'Something went wrong!');
            return;
          }
   
          this.objection = Object.assign(new Objection(), res.data.objection);
          this.propertyId=this.objection.property?.id;

          this.propertyTypes = res.data.propertyTypes ?? [];
          this.propertySubTypes = res.data.propertySubTypes ?? [];

          this.owners = this.getOwners();
          this.floors = this.getFloors();

          this.getOldAndNewPropertyData();

          if (this.floors.length > 0 && this.floors[0].floor) {
            this.floorStatus = this.floors[0].floor.status;
          }

          this.ownerStatus = this.getOwnersObjectionStatus();
          this.loading = false;
        },
        () => this.redirectPopUp('objections', 'Something went wrong!'),
      );


    } catch (e) {
      this.redirectPopUp('objections', 'Something went wrong!');
    }
  }

  private getOwnersObjectionStatus() {
    const ownersData = [...this.objection.objectionOwnerDeletes, ...this.objection.objectionOwnerModifications, ...this.objection.objectionOwnerNews];
    return ownersData[0]?.status;
  }

  clear() {
    this.objectionStatusModel = new ObjectionStatusHistory();
    this.notification = {subject: '', message: ''};
  }

  getOwners(): { owner: any, type: ModelChangesTypes }[] {
    if (!this.objection) return [];

    const toFormatterOwner = (owner: any, type: ModelChangesTypes) => ({owner, type});

    const updatedOwners: { oldOwner?: Citizen, updatedOwner?: Citizen }[] = [];


    this.objection.objectionOwnerModifications.forEach((owner) => {
      const ownerId = owner.citizen?.id;

      if (!ownerId) return;

      if (!updatedOwners[ownerId]) updatedOwners[ownerId] = {};
      if (!updatedOwners[ownerId].oldOwner) updatedOwners[ownerId].oldOwner = cloneDeep(owner.citizen);
      if (!updatedOwners[ownerId].updatedOwner) updatedOwners[ownerId].updatedOwner = cloneDeep(owner.citizen);

      // @ts-ignore
      updatedOwners[ownerId].oldOwner[owner.field] = owner.old_value;
      // @ts-ignore
      updatedOwners[ownerId].updatedOwner[owner.field] = owner.new_value;
    });

    return [
      ...updatedOwners.reduce((acc, o) => {
        // @ts-ignore
        acc.push(toFormatterOwner(o, this.MODEL_CHANGES_TYPES.Updated));
        return acc;
      }, []),
      ...this.objection.objectionOwnerNews.reduce((acc, o) => {
        // @ts-ignore
        acc.push(toFormatterOwner(o, this.MODEL_CHANGES_TYPES.New));
        return acc;
      }, []),
      ...this.objection.objectionOwnerDeletes.reduce((acc, o) => {
        // @ts-ignore
        acc.push(toFormatterOwner(o, this.MODEL_CHANGES_TYPES.Deleted));
        return acc;
      }, []),
    ];
  }

  /**
   * Set property type and subtype values for updated properties. Response provides old values.
   */

   private setUpdatedPropertyValues(floor:ObjectionsFloorModification, updatedFloors:{ oldFloor?: Floor, updatedFloor?: Floor }[], floorId:number) {
    if(floor.field === 'property_type_id') {
      const newPropertyTypeId = Number(floor.new_value);
      if(newPropertyTypeId) {
        const newPropertyTypeName = this.propertyTypes.find(el => el.id === newPropertyTypeId)?.name;
        (updatedFloors[floorId] as any).updatedFloor['propertyType'] = {id: newPropertyTypeId, name: newPropertyTypeName || ''};
      }
    }else if(floor.field === 'property_sub_type_id') {
        const newPropertySubTypeId = Number(floor.new_value);
        if(newPropertySubTypeId) {
          const newPropertySubTypeName = this.propertySubTypes.find(el => el.id === newPropertySubTypeId)?.name;
          (updatedFloors[floorId] as any).updatedFloor['propertySubType'] = {id: newPropertySubTypeId, name: newPropertySubTypeName || ''};
        }
    }
  }

  getFloors(): { floor: any, type: ModelChangesTypes }[] {
    if (!this.objection) return [];

    const toFormatterFloor = (floor: any, type: ModelChangesTypes) => ({floor, type});

    const updatedFloors: { oldFloor?: Floor, updatedFloor?: Floor }[] = [];


    this.objection.objectionFloorModifications.forEach((floor) => {
      const floorId = floor.floor?.id;

      if (!floorId) return;

      

      if (!updatedFloors[floorId]) updatedFloors[floorId] = {};
      if (!updatedFloors[floorId].oldFloor) updatedFloors[floorId].oldFloor = cloneDeep(floor.floor);
      if (!updatedFloors[floorId].updatedFloor) updatedFloors[floorId].updatedFloor = cloneDeep(floor.floor);

      // @ts-ignore
      updatedFloors[floorId].oldFloor[floor.field] = floor.old_value;
      // @ts-ignore
      updatedFloors[floorId].updatedFloor[floor.field] = floor.new_value;
      
      this.setUpdatedPropertyValues(floor, updatedFloors, floorId);
    });

    return [
      ...updatedFloors.reduce((acc, f) => {
        // @ts-ignore
        acc.push(toFormatterFloor(f, this.MODEL_CHANGES_TYPES.Updated));
        return acc;
      }, []),
      ...this.objection.objectionNewFloors.reduce((acc, f) => {
        // @ts-ignore
        acc.push(toFormatterFloor(f, this.MODEL_CHANGES_TYPES.New));
        return acc;
      }, []),
      ...this.objection.objectionFloorDeletes.reduce((acc, f) => {
        // @ts-ignore
        acc.push(toFormatterFloor(f, this.MODEL_CHANGES_TYPES.Deleted));
        return acc;
      }, []),
    ];
  }

  getPropertyType(id: number | undefined) {
    // @ts-ignore
    return id ? (this.propertyTypes.find((pt) => pt.id === parseInt(id)) ?? undefined) : undefined;
  }

  getPropertySubType(id: number | undefined) {
    // @ts-ignore
    return id ? (this.propertySubTypes.find((pst) => pst.id === parseInt(id)) ?? undefined) : undefined;
  }

  canEdit() {
    return ![ObjectionStatuses.Rejected, ObjectionStatuses.Accepted]
      .includes(this.objection.status);
  }

  private async redirectPopUp(url: string, message: string) {
    await Swal.fire({
      title: 'Error',
      html: message,
      icon: 'error',
    }).then(() => this.router.navigate([url]));
  }

  loadCurrentTaxCalculation() {
    try{
      this.currentTaxLoading = true;
      if (!this.currentProperty) return;
      this.taxCalculationService.getTaxCalculationByProperty(this.currentProperty).subscribe((res) => {
        //Current tax calculation data
        this.currentTaxCalculation = res;
        this.currentTaxLoading = false;
      }, error => {
        this.currentTaxLoading = false;
      });
    }catch(e){
      this.currentTaxLoading=false;
    }
  }

  loadUpdatedTaxCalculation() {
    try{
      this.updatedTaxLoading=true;
      if (!this.updatedProperty) return;
      this.taxCalculationService.getTaxCalculationByProperty(this.updatedProperty).subscribe((res) => {
        this.updatedTaxCalculation = res;
        this.updatedTaxLoading=false;
      }, error => {
        this.updatedTaxLoading = false;
      });
    }catch(e) {
      this.updatedTaxLoading=false;
    }
  }

  getLedgers(id: number | undefined) {
    try{
      this.isLedgersLoading=true;
      this.ledgerService.getAll(() => `where: { column: PROPERTY_ID value: "${id}"}`)
        .subscribe((res) => {
            this.ledgers = res;
            this.loading = false;
            this.isLedgersLoading=false;
          },
        );
    }catch(e){
      this.isLedgersLoading=false;
    }
  }

  getOldAndNewPropertyData() {
    this.propertyService.getOneById(this.propertyId).subscribe(res => {

      this.currentProperty = res;
      this.updatedProperty = this.getUpdatePropertyByObjection(this.objection, this.currentProperty);
      this.oldServiceId = this.findFieldInPropertyDetails('old_house_tax_no');

      this.loadCurrentTaxCalculation();
      this.loadUpdatedTaxCalculation();

      //Fetching of ledger

      /*if (!this.oldServiceId) {
        this.getLedgers(this.currentProperty?.id);
        this.loading = false;
        return;
      }

      this.oldTaxValue = this.checkFieldValueInPropertyDetails('old_house_tax_no', 'new_value');
      this.propertyService.getOneByUId(this.oldTaxValue).subscribe((res) => {
        this.oldPropertyId = res!.id;
        this.oldFloors = res!.floors;
        this.getLedgers(this.oldPropertyId);
      });

      this.oldTaxIdChange = true;
      this.getLedgers(parseInt(this.oldServiceId!.new_value));*/
    });
  }

  findFieldInPropertyDetails(fieldName: string) {
    return this.objection.objectionPropertyDetails.find(detail => detail.field === fieldName);
  }

  checkFieldValueInPropertyDetails(fieldName: string, nameValue: string) {
    const item = this.objection.objectionPropertyDetails.find(detail => detail.field === fieldName);
    if (!item) return;

    // @ts-ignore
    return item[nameValue];
  }

  getUpdatePropertyByObjection(objection: Objection, property?: Property) {
    const newProperty = cloneDeep(property);

    objection.objectionPropertyDetails.forEach(detail => {
      (newProperty as any)[detail.field] = detail.new_value;
    });

   

    objection.objectionFloorDeletes.forEach(floorDel => {
      if (newProperty?.floors != undefined) {
        newProperty.floors = newProperty?.floors.filter(floor => floor?.id != floorDel?.floor?.id);
      }
    });

    objection.objectionFloorModifications.forEach(floorMod => {
      newProperty?.floors.forEach(floor => {
        if (floor.id != floorMod.floor.id) return floor;
        (floor as any)[floorMod.field] = floorMod.new_value;
        return floor;
      });
    });

    objection.objectionNewFloors.forEach(floorNew => {
      newProperty?.floors.push(floorNew as unknown as Floor);
    });

    return newProperty;
  }

  openDocumentModal(modal: any, documentProofIndex: number) {
    if (!this.objection.documents[documentProofIndex]) {
      Swal.fire('Error', 'Something went wrong', 'error');
      return;
    }

    this.modalDocument = {
      path: this.objection.documents[documentProofIndex].path ?? '',
      name: this.objection.documents[documentProofIndex].name ?? 'File has no name',
    };

    this.isDocFile = this.checkIsDoc(getFileExtensionFromString(this.modalDocument.name));
    this.modalService.open(modal, {size: 'xl', centered: true});
  }

  download() {
    this.http.get(getFileUrl(this.modalDocument.path), {responseType: 'blob'}).subscribe(res => {
      const url = URL.createObjectURL(res);
      this.getDownloadUrl(url, this.modalDocument.name);
      URL.revokeObjectURL(url);
    });

    this.closeModal();
  }

  getDownloadUrl(url: string, fileName: string) {
    const a: HTMLAnchorElement = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  rotate() {
    const image: HTMLImageElement = document.querySelector('#image') as HTMLImageElement;
    const container = document.querySelector('#container');

    this.rotateFlag = this.rotateDegree % 180 != 0;

    const height = this.rotateDegree % 180 == 0 ? image.height : image.width;

    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.rotateDegree}deg)`,
    );

    this.renderer.setStyle(
      container,
      'height',
      `${height}px`,
    );

    this.rotateDegree += 90;
  }


  private checkIsDoc(type: string) {
    return ['pdf'].includes(type);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  toInt(s: string) {
    return parseInt(s);
  }

}


import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {onError} from "@apollo/client/link/error";
import {environment} from "../environments/environment";
import {setContext} from "@apollo/client/link/context";
import {AuthenticationService} from "./services";
import {HttpErrorResponse} from "@angular/common/http";

const uri = environment.apiBaseUrl + 'graphql'; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink, authenticationService: AuthenticationService): ApolloClientOptions<any> {
  const auth = setContext(async (_, {headers}) => {
    // Grab token if there is one in storage or hasn't expired
    let token = localStorage.getItem('bearerToken');

    if (!token) {
      return {};
    }

    // Return the headers as usual
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  });

  const errorHolder = onError(({ graphQLErrors, networkError }) => {
    if(networkError && 'status' in networkError && (networkError as HttpErrorResponse).status === 401){
      authenticationService.signOut();
    }
  });

  const link = ApolloLink.from([auth,errorHolder, httpLink.create({uri})]);

  return {
    link: link,
    cache: new InMemoryCache(),
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthenticationService],
    },
  ],
})
export class GraphQLModule {
}

<div class="container">
    <!-- Start breadcrumb -->
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/property/' + propertyId">{{'property_search.property' | translate}}</a></li>
            <li class="breadcrumb-item active" aria-current="page">{{'property_search.inform_payment_credit' | translate}}</li>
        </ol>
    </nav>
    <div class="listing-head mb-4 align-items-center ">
        <h5 class="disclaimer ">{{'property_search.inform_payment_credit' | translate}}</h5>
    </div>
    <ng-container *ngIf="!loading; else loadingBlock">
        <div class="mb-4">
            <div class="d-flex justify-content-end mb-4 mmb-4">
                <button class="btn btn-primary" (click)="addTransaction(informPaymentCreditFormModal)">{{'property_search.add' | translate}}</button>
            </div>
            <!-- Table -->
            <div class="card mb-4" style="box-shadow: 0px 10px 20px rgba(18, 38, 63, 0.0313726)">
                <div class="table-responsive responsive-flex-table">
                    <table
                        class="table border-top table-hover nowrap datatables no-footer dtr-inline responsive-flex-table">
                        <thead>

                            <th class="not-sortable">{{'property_search.transaction_date' | translate}}</th>
                            <th class="not-sortable">{{'property_search.financial_year' | translate}}</th>
                            <th class="not-sortable">{{'property_search.amount' | translate}}</th>
                            <th class="not-sortable">{{'property_search.action' | translate}}</th>

                        </thead>
                        <tbody>
                            <tr *ngIf="informPaymentTableFormArray.length == 0">
                                <td colspan="100">
                                    <h6 class="text-center">{{'property_search.add_transaction_details_using_add_button' | translate}}</h6>
                                </td>
                            </tr>
                            <tr *ngFor="let item of informPaymentTableFormArray; let i = index">
                                <td>{{item.transaction_date_time |date : 'dd-MM-yyyy'}}</td>
                                <td>{{item.financial_year + '-' + (item.financial_year? item.financial_year + 1 : '')}}
                                </td>
                                <td>{{item.amount}}</td>
                                <td width="80px">
                                    <div class="d-flex justify-content-between mob_align_right" style="font-size: 18px">

                                        <i class="bi bi-x-lg text-danger" style="cursor: pointer"
                                            (click)="deleteinformPaymentCredit(i)"></i>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
            </div>
            <span *ngIf="!isTransactionsAdded" style="color: red">{{'property_search.transaction_details_required' | translate}}</span>
            <div class="col-12 form-group mt-5 no-pd">
                <h5 class="disclaimer no-mr-b">{{'property_search.select_attachments' | translate}}</h5>
                <p class="descri">{{'property_search.upload_required_documents' | translate}}</p>
                <file-upload (onFileUpload)="afterFileUpload($event)" (removeFile)="removeFile($event)"
                    uploadApiUrl="credit-request/upload"></file-upload>
                <span *ngIf="!isDocumentUploaded" style="color: red">{{'property_search.atleast_one_document_required' | translate}}</span>
            </div>
        </div>
        <div class="mb-4">
            <h5 class="disclaimer">{{'property_search.remark' | translate}}</h5>
            <div>
                <textarea class="form-control" rows="5" placeholder="{{'property_search.enter_any_remark_or_note' | translate}}"
                    [(ngModel)]="informPaymentCredit.user_remark"></textarea>
            </div>
        </div>
        <div>
            <h5 class="disclaimer">{{'property_search.disclaimer' | translate}}</h5>

            <div class="form-check d-flex align-items-center disclaimer-checkbox mb-4">
                <input type="checkbox" [(ngModel)]="isAgree">
                <label class="form-check-label">{{'property_search.i_agree' | translate}}</label>
            </div>

            <p class="disclaimer-remark">{{'property_search.declairation_sentence' | translate}}
            </p>
        </div>
        <div class="d-flex justify-content-end mb-4">
            <button class="btn btn-primary" [disabled]="!isAgree" (click)="submit()">{{'property_search.submit' | translate}}</button>
        </div>
    </ng-container>
    <ng-template #informPaymentCreditFormModal let-modal>
        <div class="modal-header">
            <div class="title">
                <h5 class="modal-title mb-2">{{'property_search.inform_payment_credit_details' | translate}}</h5>
                <h6 class="modal-sub-title">{{'property_search.fill_required_information' | translate}}</h6>
                <i class="fa fa-close fa-times" (click)=" modal.dismiss();"></i>

            </div>
        </div>
        <div class="modal-body">
            <div class="row mb-4 mmb-4">
                <div class="col-12">
                    <label for="transaction-date">{{'property_search.transaction_date' | translate}}<span class="text-danger">*</span></label>
                    <div class="form-group date__picker">
                        <div class="input-group">
                            <input class="form-control form-control-sm fz-13" placeholder="dd-mm-yyyy" name="dp"
                                id="transaction-date" [(ngModel)]="transaction_Date" ngbDatepicker #f="ngbDatepicker"
                                [minDate]="minDate" [maxDate]="maxDate">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary citizen-modal__datepicker form-control-sm"
                                    (click)="f.toggle()" type="button">
                                    <i class="bx bx-calendar"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4 mmb-4">
                <div class="col-12">
                    <label>{{'property_search.financial_year' | translate}}<span class="text-danger">*</span></label>
                    <ng-select placeholder="{{'property_search.select_year' | translate}}" [(ngModel)]="informPaymentTableForm.financial_year">
                        <ng-option *ngFor="let year of financialYears" [value]="year">
                            {{year + '-' + (year + 1)}}
                        </ng-option>
                    </ng-select>
                </div>
            </div>
            <div class="row mb-4 mmb-4">
                <div class="col-12">
                    <label>{{'property_search.amount' | translate}}<span class="text-danger">*</span></label>
                    <input class="form-control" type="number" placeholder="{{'property_search.enter_amount' | translate}}"
                        [(ngModel)]="informPaymentTableForm.amount">
                    <span *ngIf="isValidateAmount" style="color: red">{{'property_search.amount_should_be_greater_than_0' | translate}}</span>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" (click)="informPaymentCreditSubmit(modal)"
                [disabled]="transaction_Date == undefined || informPaymentTableForm.financial_year == '' || informPaymentTableForm.amount == null">{{'property_search.submit' | translate}}</button>
        </div>
    </ng-template>

    <ng-template #loadingBlock>
        <div class="loading"
            style="height: 75vh;width: 10%; display: flex; justify-content: center; align-items: center;margin-left: 50%;">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">{{'property_search.loading' | translate}}</span>
            </div>
        </div>
    </ng-template>
import {Component, Input, OnInit} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import {Property} from "../../../models";

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
})
export class ListItemComponent implements OnInit {
  PROPERTY_CATEGORIES: {[key: number]: string} = Property.PROPERTY_CATEGORIES;
  @Input() property: any={};
  @Input() index!: number;
  stockPhotoUrl:string='assets/images/no-image.svg';
  propertyArr : any[] = [];
  filteredProperty :any = {};
  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
  }
  addToRecentlyViewed(property: Property){
    this.filteredProperty.id = property.id
    this.filteredProperty.property_uid = property.property_uid;
    this.filteredProperty.ward_id = property.ward_id;
    this.filteredProperty.property_category = property.property_category;
    this.filteredProperty.owners = property.owners;
    this.filteredProperty.plot_area = property.plot_area;
    this.filteredProperty.address_house_number = property.address_house_number;
    this.filteredProperty.address_line2 = property.address_line2;
    this.filteredProperty.street = property.street;
    this.filteredProperty.colony = property.colony;
    this.filteredProperty.ward = property.ward;
    this.filteredProperty.photos = property.photos;

    this.setPropertyinStorage(this.filteredProperty);
  }
  
  setPropertyinStorage(property: Property) {
    let existingArrStr: any;
    existingArrStr = localStorage.getItem('propertyArr');
    if (existingArrStr != null) {
      let existingArr = JSON.parse(existingArrStr);
      let isPropertyExistIndex = existingArr.findIndex((el: Property) => el.id == property.id); //to check if property already exist in current array
      if (isPropertyExistIndex > -1) {
        existingArr.unshift(existingArr.splice(isPropertyExistIndex, 1)[0]); //if property exist move it to front of queue
        let stringifiedArr = JSON.stringify(existingArr);
        localStorage.setItem('propertyArr', stringifiedArr);
        return;
      };

      existingArr.unshift(property);

      if (existingArr.length > 10) {
        existingArr.pop();
      }
      let stringifiedArr = JSON.stringify(existingArr);
      localStorage.setItem('propertyArr', stringifiedArr);
    }
    else {
      let newArr: Property[] = [];
      newArr.push(property);
      let stringifiedArr = JSON.stringify(newArr);
      localStorage.setItem('propertyArr', stringifiedArr);
    }


  }
}

import {environment} from '../../environments/environment';

export function debug(e: any) {
  if (environment.production) return;
  console.warn('Debug');

  if (e instanceof Error) {
    console.error(e);
    return;
  }

  console.log(e);
}

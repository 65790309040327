import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Colony, Street} from "../models";
import {map} from "rxjs/operators";
import {Apollo} from "apollo-angular";
import {gql} from "@apollo/client/core";
import {BaseService} from './base-service.service';
import {IServicePaginate} from '../@types/service-paginate';
import {IServiceGetOne} from '../@types/service-get-one';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class ColonyService extends BaseService implements IServicePaginate<Street>, IServiceGetOne<Street> {

  constructor(private apollo: Apollo) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "%${term}%"}`;
  }

  public getOne(column: string, value: any): Observable<Colony | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.ColonyPaginate && result.data.ColonyPaginate.data),
      map((result: any) => result && result[0])
    )
  }

  public getAll(id: number | undefined, coordsRequired = false): Observable<Colony[] | []> {
    if (id == undefined) {
      return this.apollo.watchQuery({
        query: this.getAllQuery()
      }).valueChanges.pipe(
        map((result: any) => result.data && result.data.Colonies),
        map((result: any) => result ?? [])
      );
    } else {

      return this.apollo.watchQuery({
        query: this.getAllQueryByWardId(id, coordsRequired)
      }).valueChanges.pipe(
        map((result: any) => result.data && result.data.Colonies),
        map((result: any) => result ?? [])
      );
    }
  }

  public paginate(page: number = 1, perPage: number = 10, where: string = ''): Observable<Colony[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, where),
      errorPolicy: 'all'
    }).valueChanges.pipe(
      map((result: any) => {
        if(result?.errors && result?.errors[0]?.message) {
          Swal.fire('Error', result.errors[0].message, 'error');
          return [];
        }
        return result.data && result.data.ColonyPaginate && result.data.ColonyPaginate.data
      }),
      map((result: any) => result ?? [])
    );
  }

  /**
   * GraphQL Queries
   * */
  private getAllFields() {
    return `
      id,
      name
      sro_zone ,
      establishment_year,
      name_hindi,
      colony_code
    `
  }

  private getOneQuery(column: string, value: any) {
    return gql`{
      ColonyPaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: "${value}" }
      ) {
        data {
          id,
          name,
          city {
            id,
            name
          },
          description,
          establishment_year,
          created_at,
          updated_at,
        }
      }
    }`;
  }

  public getAllActiveColoniesByWardId(ward_id: number | undefined): Observable<Colony[]> {
    return this.apollo.watchQuery({
      query: this.getAllActiveColonyByWardIdQuery(ward_id),
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.Colonies),
      map((result: any) => result ?? []),
    )
  }

  private getAllActiveColonyByWardIdQuery(ward_id: number | undefined) {
    return gql`{
      Colonies(
        where: {
          column: STATUS, operator: LIKE, value: true
          OR: [
            {
              HAS: {
                relation: "wards",
                condition: {
                  column: ID,
                  operator: LIKE,
                  value: ${ward_id}
                }
              }
            }
          ]
        }
      ) {${this.needleColumns()}}}`
  }

  private getAllQuery() {
    return gql`{
      Colonies(
        where: {column: STATUS, operator: LIKE, value: true}
      ) {
        id,
        name,
        city {
          id,
          name
        },
        wards {
          id
          name
        }
        description,
        colony_code,
        establishment_year,
        name_hindi,
        sro_zone,
        created_at,
        updated_at
      }
    }`
  }

  private getAllQueryByWardId(ward_id: number | undefined, coordsRequired = false) {
    return gql`{
      Colonies(
        where: {
          column: STATUS, operator: LIKE, value: true
          OR: [
            {
              HAS: {
                relation: "wards",
                condition: {
                  column: ID,
                  operator: LIKE,
                  value: ${ward_id}
                }
              }
            }
          ]
        }
      ) {
        id,
        name,
        city {
          id,
          name
        },
        description,
        colony_code,
        sro_zone,
        establishment_year,
        created_at,
        updated_at,
        name_hindi,
        ${coordsRequired ? 'gps_compressed_coordinates': ''}
      }
    }`
  }

  private paginateQuery(page: number = 1, perPage: number = 10, where: string = '') {
    return gql`{
      ColonyPaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {${this.getAllFields()}}
      }
    }`
  }

  needleColumns() {
    return `
      id,
      name
      description
      establishment_year
      colony_code
      status
      sro_zone
      gps_compressed_coordinates
      gps_coordinates{
        latitude
        longitude
      }
      wards{
        id
        name
      }
      sectors{
        id
        name
      }
      city {
        id,
        name
        district{
        id
        name
        state{
        id
        name
        }
        }
      }
    `;
  }

}

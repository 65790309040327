<div class="home-banner d-flex-container justify-center align-center" 
style="  background-size: cover; background-image: url('assets/images/{{cs.city}}/slider_background.jpg');">
  <div class="container position-relative">

    <!-- Start Leader Cards-->
  <section class="home-info ">
    <h2>{{'home.banner_text' | translate}} </h2>
      <h4 *ngIf="cs.city=='bharatpur'">{{'home.sub_banner_text_bharatpur' | translate}}</h4>
      <h4 *ngIf="cs.city=='jodhpur'">{{'home.sub_banner_text_jodhpur' | translate}}</h4>
  </section>
    <!-- End Leaders Cards-->
  </div>
</div>
  <div class="homesearch-waper"> 
    <app-search-widget [landmarksData] = "landmarks" [className]="'homesearch'" [loadingLandmarks] = "loadingLandmarks"></app-search-widget>    
  </div>

<app-home-instruction></app-home-instruction>

<app-home-mobile-links></app-home-mobile-links>
<app-home-faq></app-home-faq>


<ng-container  *ngIf = "showCanvas">
  <canvas #canvas height="{{height * 2}}" width="{{width}}"></canvas>
  <img id="01_flower" width="0" height="0" src="assets/images/01.png">
  <img id="02_flower" width="0" height="0" src="assets/images/02.png">
  <img id="03_flower" width="0" height="0" src="assets/images/03.png">
  <img id="05_flower" width="0" height="0" src="assets/images/05.png">
  <img id="06_flower" width="0" height="0" src="assets/images/06.png">
</ng-container>


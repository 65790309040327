import {Component, OnInit} from '@angular/core';
import {Colony, Property} from '../../../models';
import {ReplaySubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {PropertyService} from '../../../services/property.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  property = new Property();
  selectedColonyCoords!:Colony;
  readonly FIRST_FORM_ID = 1;
  readonly LAST_FORM_ID = 4;

  private _currentFormId: number = this.FIRST_FORM_ID;
  private maxUsedForm = this.currentFormId;
  private destroy$ = new ReplaySubject();

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private propertyService: PropertyService,
  ) {
  }

  ngOnInit(): void {
    this.updateRouteParameter();

    this.currentRoute.queryParams.pipe(takeUntil(this.destroy$)).subscribe((param) => {
      const form = parseInt(param.form);

      if (isNaN(form) || this.maxUsedForm < form) {
        this.updateRouteParameter();
        return;
      }

      this._currentFormId = form;
    });

    this.propertyService.getTemporaryUid().subscribe((tempUid) => {
      this.property.property_uid = tempUid;
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  prevForm() {
    if (this.currentFormId === this.FIRST_FORM_ID) return;

    this._currentFormId--;
    this.updateRouteParameter();
  }

  nextForm() {
    if (this.currentFormId === this.LAST_FORM_ID) return;

    this._currentFormId++;
    this.maxUsedForm = this.currentFormId;
    this.updateRouteParameter();
  }

  updateRouteParameter() {
    this.router.navigate([], {
      relativeTo: this.currentRoute,
      queryParams: {
        form: this.currentFormId,
      },
    });
  }

  get currentFormId() {
    return this._currentFormId;
  }
}

<div class="property-slider" *ngIf="!loading">
  <ngb-carousel *ngIf="formattedPhotos && formattedPhotos.length !== 0; else elseBlock" (slide)="changeSlide($event)">

    <ng-template ngbSlide *ngFor="let photo of formattedPhotos" >
      <div id="image" class="picsum-img-wrapper" [ngStyle]="{'background-image': 'url(' + photo?.url  + ')'}" (click)="fullscreenPhoto(photo.url)" > 
      </div>
      <i class="bi bi-fullscreen full-screen-btn text-primary" (click)="fullscreenPhoto(photo.url)" title="View in fullscreen"></i>
    </ng-template>
  </ngb-carousel>
  <i class="bi  bi-arrow-clockwise rotate-btn text-primary" (click)="rotate()"></i>
</div>

<ng-template #elseBlock>
  <div class="no-image" style="background-image: url('assets/images/no-image.svg');">
  </div>
</ng-template>
<app-image-viewer [imageURL]="currentPhotoUrl" [open]="viewerOpen" [id] = "imageId" (close)="viewerOpen = false">
</app-image-viewer>

import {Component, OnInit} from '@angular/core';
import {FaqQuestion} from "../../@types";

@Component({
  selector: 'app-home-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  questionsList: FaqQuestion[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
    this.questionsList.push(new FaqQuestion(
      'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.'
    ));
  }

  faqClick(question: FaqQuestion) {
    this.questionsList.forEach(q => q.isActive = false);
    question.isActive = true;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Floor, Property, PropertySubType, PropertyType} from '../../../models';
import {cloneDeep} from 'lodash';
import Swal from 'sweetalert2';
import {getFormattedErrorsByValidator, Validated, ValidationErrors, ValidationRules} from '../../../@types/validation';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PropertyTypeService} from '../../../services/property-type.service';
import {PropertySubTypeService} from '../../../services/property-sub-type.service';
// @ts-ignore
import Validator from 'Validator/src/validator';
import {getFinancialYears} from '../../../helpers/get-financial-years';
import {currentFinancialYear} from '../../../helpers/current-financial-year';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PropertyCategories } from 'src/app/enums/property-categories';

type FloorRelationValidation = { property_type?: string, property_sub_type?: string };

const FloorDetailsValidationRules: { [T in keyof Floor | string]?: ValidationRules } = {
  floor_number: 'required',
  property_category: 'required',
  propertyType: 'required',
  propertySubType: 'required',
  from_year: 'required',
  upto_year: 'required',
  carpet_area: 'required',
};


@Component({
  selector: 'app-floors-details',
  templateUrl: './floors-details.component.html',
  styleUrls: ['./floors-details.component.scss'],
})
export class FloorsDetailsComponent implements OnInit {

  @Input() property!: Property;

  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('previousForm') previousFormEmitter = new EventEmitter();

  readonly STARTING_YEAR = 2007;
  readonly Property = Property;
  readonly FLOOR_FORM_ADD_MODE = 1;
  readonly FLOOR_FORM_EDIT_MODE = 2;
  readonly GROUND_FLOOR = 0;

  readonly FLOOR_NAMES = Floor.FLOOR_NUMBER;
  readonly PROPERTY_CATEGORIES = Property.PROPERTY_CATEGORIES;
  readonly PROPERTY_USAGES = Property.PROPERTY_USAGES;
  readonly FORMATTED_FLOOR_NUMBERS = Floor.formattedFloorNumber();

  readonly FORMATTED_PROPERTY_CATEGORIES_WITHOUT_MIX = Property.formattedPropertyCategoriesWithoutMix();

  private destroy$ = new Subject();
  private propertyTypesAll: PropertyType[] = [];
  private propertySubTypeAll: PropertySubType[] = [];

  floorFormMode = this.FLOOR_FORM_ADD_MODE;
  formFloor = new Floor();
  floorFormErrors: ValidationErrors<{ [key in keyof (Floor & FloorRelationValidation)]?: string }> = {};
  financialYears: number[] = [];
  propertyTypeData: PropertyType[] = [];
  propertySubTypeData: PropertySubType[] = [];
  disableCarpetArea: boolean = false;
  disableFloorNumber: boolean = false;

  constructor(
    private modalService: NgbModal,
    public propertyTypeService: PropertyTypeService,
    public propertySubTypeService: PropertySubTypeService,
  ) {
  }

  ngOnInit(): void {
    this.getPropertyTypeData();
    this.getPropertySubTypeData();
    const firstDate = this.STARTING_YEAR;
    const currentDate = currentFinancialYear();
    this.financialYears = getFinancialYears(
      firstDate,
      firstDate > currentDate ? firstDate : currentDate,
    );

    //Set Financial year limit based on plot allotement year
    if(this.property.plot_allotment_year !==null &&this.property.plot_allotment_year > 2007) {
    this.financialYears = this.financialYears.filter(year => year >= this.property.plot_allotment_year);
    }

  }

  setMainPropertyCategoryData(){
    //Check if mix use
    const currentYear = currentFinancialYear();
   
    if(!this.isMixUse()){
      var currenyYearFloors=this.property.floors?.filter((f) => +f.upto_year >= currentYear);
      this.property.property_category=currenyYearFloors[0].property_category;
      this.property.propertyType=this.propertyTypesAll.find((el:PropertyType) => el.id === currenyYearFloors[0].propertyType?.id) as PropertyType;
      this.property.propertySubType=this.propertySubTypeAll.find((el: PropertySubType) => el.id === currenyYearFloors[0].propertySubType?.id) as PropertySubType;
    }else{
      this.property.property_category=PropertyCategories.Mix;
      this.property.propertyType = this.propertyTypesAll.find((el:PropertyType) => el.id === 133) as PropertyType;
      this.property.propertySubType = this.propertySubTypeAll.find((el: PropertySubType) => el.id === 138) as PropertySubType;
    }
    this.property.property_type_id=this.property.propertyType.id;
    this.property.property_sub_type_id=this.property.propertySubType.id;
  }


  isMixUse(){
    const currentYear = currentFinancialYear();
    const allDuplcates = new Set(this.property.floors?.filter((f) => +f.upto_year >= currentYear).map(v => v.propertySubType?.id));
    if (allDuplcates.size === 1) {
      return false;
    }
    return true;
  }
  

  nextForm() {
    if (!this.property.multi && this.countOfCYGroundFloor() < 1) {
      Swal.fire('Error', `At least one Ground floor is required for current year!`, 'error');
      return;
    }
    let currentYear = currentFinancialYear().toString() ;
    let countOfCurrentYearFloor = this.property.floors?.findIndex((f) => f.upto_year == currentYear);
    if (countOfCurrentYearFloor < 0) {
      Swal.fire('Error', `At least one floors upto year should be in current year!`, 'error');
      return;
    }

    this.setMainPropertyCategoryData();
    this.nextFormEmitter.emit();
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  private isVacantCategorySelected() {
    return this.property.floors.some((el: Floor) => el.property_category === PropertyCategories.Vacant);
  }

  addFloor(modal: any) {
    this.floorFormMode = this.FLOOR_FORM_ADD_MODE;
    this.formFloor = new Floor();
    this.formFloor.propertyType = undefined as any;
    this.formFloor.propertySubType = undefined as any;
    this.disableCarpetArea = false;
    this.disableFloorNumber = false;
    this.openFloorFormModal(modal);
  }

  editFloor(modal: any, floor: Floor) {
    this.floorFormMode = this.FLOOR_FORM_EDIT_MODE;
    this.formFloor = Object.assign(new Floor(), cloneDeep(floor));
    this.getPropertyTypes(this.formFloor.property_category);
    this.getPropertySubtype(this.formFloor.propertyType);
    this.formFloor.propertyType = this.propertyTypeData.find((el:PropertyType) => floor.propertyType.id === el.id) as PropertyType;
    this.formFloor.propertySubType = this.propertySubTypeData.find((el: PropertySubType) => floor.propertySubType.id === el.id) as PropertySubType;
    if(this.formFloor.property_category === PropertyCategories.Vacant) {
      this.selectDefaultsForVacantCategory(true);
    }else {
      this.selectDefaultsForVacantCategory(false);
    }
    this.openFloorFormModal(modal);
  }

  deleteFloor(index: number) {
    if (!this?.property?.floors || this.property.floors.length === 0) return;

    const deleteFloorNumber = this.property.floors[index].floor_number;

    if (deleteFloorNumber === this.GROUND_FLOOR && this.totalGroundFloor() <= 1) {
      Swal.fire('Error', `Atleast one ground floor is required`, 'error');
      return;
    }

    this.property.floors = this.property.floors.filter((v, i) => i !== index);
  }

  private isSameRecord(isAdd:boolean, el:Floor, newFloorData: Floor) {
    if(isAdd) return false;
    if((el.temp_id && newFloorData.temp_id && (el.temp_id === newFloorData.temp_id)) || (el.id && newFloorData.id && (el.id === newFloorData.id))){
      return true;
    }
    return false;
  }

  private isCarpetAreaMoreThanPlotArea(foorNumber: number):boolean {
    const currentYear = currentFinancialYear();
    let totalCarpetArea = 0;
    this.property.floors?.forEach((floor: Floor) => {
      if(floor.floor_number === foorNumber && +floor.from_year <= currentYear && +floor.upto_year >= currentYear && !this.isSameRecord(this.floorFormMode === this.FLOOR_FORM_ADD_MODE, floor, this.formFloor) && +this.formFloor.from_year <= +floor.upto_year) {
        totalCarpetArea += +floor.carpet_area
      }
    })

    if(+this.formFloor.from_year<=currentYear && +this.formFloor.upto_year >=currentYear){
      totalCarpetArea += +this.formFloor.carpet_area;
    }
    
    return totalCarpetArea / 9 > this.property.plot_area;
  }


  floorSubmit(modal: any) {
    //Duplicate Floor check based on category, type and sub-type 
    const found = this.property.floors.some(el => el.id != this.formFloor.id && el.property_category === this.formFloor.property_category && el.propertyType === this.formFloor.propertyType && el.propertySubType === this.formFloor.propertySubType && el.floor_number === this.formFloor.floor_number && el.from_year === this.formFloor.from_year && el.upto_year === this.formFloor.upto_year);
    if (found) {
      Swal.fire('Error', 'Floor Already exists', 'error');
      return;
    }

    const vacantPlotFound= this.property.floors.some(el => (el.property_category==PropertyCategories.Vacant || this.formFloor.property_category==PropertyCategories.Vacant)&& el.id!==this.formFloor.id && ((this.formFloor.from_year >= el.from_year && this.formFloor.from_year <= el.upto_year) || (this.formFloor.upto_year>=el.from_year && this.formFloor.upto_year<=el.upto_year)));
    if(vacantPlotFound){
      Swal.fire('Error', 'Vacant Plot exists between selected year range.', 'error');
      return;
    }

    //Carpet area validation, Floor wise carpet area should not exceed the total plot area
    if(this.isCarpetAreaMoreThanPlotArea(this.formFloor.floor_number)) {
      Swal.fire('Error', `Floor Wise Carpet Area can not exceed Plot Area for ${Floor.getFloorName(this.formFloor.floor_number)}`, 'error');
      return;
    }
    const validated = this.validateFloor(this.formFloor);

    this.floorFormErrors = validated.errors;

    if (!validated.isSuccess) return;

    modal.dismiss();

    if (this.floorFormMode === this.FLOOR_FORM_ADD_MODE) {
      this.formFloor['id'] = this.property.floors.length + 1;
      this.property.floors.push(this.formFloor);
      return;
    }

    const editingFloorIndex = this.property.floors.findIndex((el) => el.id === this.formFloor.id);

    if (editingFloorIndex === -1) return;

    this.property.floors[editingFloorIndex] = this.formFloor;
  }

  toInt(s: string | number) {
    return typeof s === 'number' ? s : parseInt(s);
  }

  compareFinancialYear(a: any, b: any) {
    return parseInt(a) === parseInt(b);
  }

  /* Setters */
  setPropertyCategory(val: number | undefined) {
    if (val === this.formFloor.property_category) return;
    this.getPropertyTypes(val);
    if(!val) this.propertyTypeData = [];

    this.formFloor.property_category = val as number;
    this.setPropertyType(undefined);

    if(val === PropertyCategories.Vacant) {
      this.selectDefaultsForVacantCategory(true);
    }else {
      this.selectDefaultsForVacantCategory(false);
    }
  }

  private selectDefaultsForVacantCategory(value: boolean) {
    this.disableCarpetArea = value;
    this.disableFloorNumber = value;
    if(value) {
      this.formFloor.carpet_area = 0;
      this.formFloor.floor_number = this.GROUND_FLOOR;
    }
  }

  setPropertyType(val: PropertyType | undefined) {
    // if (val === this.formFloor.propertyType) return;
    if(!val) this.propertySubTypeData = [];
    this.formFloor.propertyType = val as any;
    this.formFloor.propertySubType = undefined as any;
  }

  /* Where Conditions */
  propertyTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_CATEGORY, value: ${this.formFloor.property_category ?? 0}}
  ] }`);

  propertySubTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_TYPE_ID, value: ${this.formFloor.propertyType?.id ?? 0}}
  ] }`);

  private openFloorFormModal(modal: any) {
    if (this.property.property_usage_id !== Property.SELF_OCCUPIED_AND_RENTED) {
      this.formFloor.property_usage_id = this.property.property_usage_id;
    }

    this.modalService.open(modal, {scrollable: true, centered: true});
  }

  private validateFloor(floor: Floor): Validated<Floor & FloorRelationValidation> {
    let rules = cloneDeep(FloorDetailsValidationRules);

    const errors: { [key: string]: string } = {};
    const validated = Validator.make(floor, rules);
    const relationValidated = this.validateRelation(floor);

    if (floor.from_year > floor.upto_year) {
      errors.upto_year = 'Must be more than from year.';
    }

    return {
      isSuccess: !validated.fails() && relationValidated.isSuccess && Object.keys(errors).length === 0,
      errors: {
        ...errors,
        ...getFormattedErrorsByValidator(validated.getErrors() ?? {}),
        ...relationValidated.errors,
      },
    };
  }

  private validateRelation(floor: Floor): Validated<FloorRelationValidation> {
    const errors: FloorRelationValidation = {};
    const message = 'This field is required.';

    if (!this.formFloor.propertyType?.id) errors.property_type = message;
    if (!this.formFloor.propertySubType?.id) errors.property_sub_type = message;

    return {
      isSuccess: Object.getOwnPropertyNames(errors).length === 0,
      errors,
    };
  }

  private countOfCYGroundFloor() {
    let currentYear = currentFinancialYear().toString() ;
    return this.property.floors?.filter((f) => f.upto_year >=currentYear && f.floor_number === this.GROUND_FLOOR)?.length ?? 0;
  }

  private totalGroundFloor(){
    return this.property.floors?.filter((f) =>f.floor_number === this.GROUND_FLOOR)?.length ?? 0;
  }

  getPropertyTypes(category: any): void {
    let id = category;
    if(typeof category === 'object') id = category?.id;
    if(this.propertyTypesAll.length && id) {
      this.propertyTypeData = this.propertyTypesAll.filter((el: PropertyType) => el.property_category === id)
    }
  }

  getPropertySubtype(propertyType: PropertyType) {
    this.setPropertyType(propertyType);
    if(this.propertySubTypeAll.length && propertyType?.id) {
      this.propertySubTypeData = this.propertySubTypeAll.filter((el: PropertySubType) => el.propertyType.id === propertyType.id);
    }
    
  }

  private getPropertyTypeData() {
    this.propertyTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertyType[]) => {
      let filteredData = data.filter(x => x.form == true);
      this.propertyTypesAll = filteredData;
    })
  }
  

  private getPropertySubTypeData() {
    this.propertySubTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertySubType[]) => {
      let filteredData = data.filter(x => x.form == true);
      this.propertySubTypeAll = filteredData;
    })
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

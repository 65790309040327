import {RouterModule, Routes} from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";
import {AuthGuard} from "../../guards/auth.guard";


const routes: Routes = [
  {
    path: 'notifications',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: IndexComponent
      },
    ]
  }
];

export const notificationsRouting = RouterModule.forChild(routes);

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Photo} from '../../../models';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-photos-list',
  templateUrl: './photos-list.component.html',
  styleUrls: ['./photos-list.component.scss']
})
export class PhotosListComponent implements OnInit {

  env = environment;

  @Input() photos: Photo[] = [];
  @Input() canDelete = false;

  @Output('delete') deleteEmitter = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  delete(i: number) {
    this.deleteEmitter.emit(i)
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Floor, Property} from "../../../models";


@Component({
  selector: 'app-floors-details',
  templateUrl: './floors-details.component.html',
  styleUrls: ['./floors-details.component.scss']
})
export class FloorsDetailsComponent implements OnInit {

  Property = Property;
  isCollapsed: boolean = false;


  @Input('property') property!: Property;

  PROPERTY_CATEGORIES: { [key: number]: string } = Property.PROPERTY_CATEGORIES;
  PROPERTY_USAGE: { [key: number]: string } = Property.PROPERTY_USAGES;
  CONSTRUCTION_TYPES: { [key: number]: string } = Property.CONSTRUCTION_TYPES;
  FLOOR_NUMBER: { [key: number]: string } = Floor.FLOOR_NUMBER;


  @Input('floors') floors: Floor[] = [];

  constructor() {
  }

  ngOnInit(): void {
    this.sortFloors()
  }

  sortFloors() {
    this.floors = this.floors.slice().sort((a, b) => {
      if (a.floor_number === b.floor_number) {
        return parseInt(a.upto_year) - parseInt(b.upto_year)
      }
      return a.floor_number - b.floor_number
    })
  }

  toInt(s: string) {
    return parseInt(s);
  }
  
}

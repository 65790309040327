import {Component, Input, OnInit} from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import {Property} from "../../../models";

@Component({
  selector: 'app-building-information',
  templateUrl: './building-information.component.html',
  styleUrls: ['./building-information.component.scss']
})
export class BuildingInformationComponent implements OnInit {
  Property = Property;
  isCollapsed: boolean = true;
  @Input('property') property!: Property;
  floorCount!: number;
  constructor(public commonService: CommonService) {
  }

  ngOnInit(): void {
  }

  yesNoNothing(value?: boolean) {
    if (value === null || value === undefined) return '';

    return value ? 'Yes' : 'No';
  }

  getApproxAge(val: number){
    if(val){
      return (new Date()).getFullYear() - val;
    }
    return "";
  }
}

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="district">District<span class="text-danger">*</span></label>
    <input id="district" type="text" class="form-control" placeholder="Enter District"
      [value]="property.district?.name ?? ''" disabled>
  </div>

  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="municipal-corporation">Municipal Corporation<span class="text-danger">*</span></label>
    <input id="municipal-corporation" type="text" class="form-control" placeholder="Enter Municipal Corporation"
      [value]="property.city?.name ?? ''" disabled>
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="ward">Ward <span class="text-danger">*</span></label>
    <ng-select id="ward" placeholder="Enter Ward" [(ngModel)]="property.ward" appInputError [(error)]="errors.ward_id"
      (change)="getColonies($event, true)">
      <ng-option *ngFor="let ward of wards" [value]="ward">{{ward.name}}</ng-option>
    </ng-select>
    <div *ngIf="isWardsLoading" class="loading d-flex justify-content-center dropdown-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="colony">Colony <span class="text-danger">*</span>
      <span *ngIf="property.colony?.id && property?.colony?.establishment_year">
        ( Establishment Year : {{property?.colony?.establishment_year}} )
      </span></label>
    <ng-select id="colony" placeholder="Enter Colony" [(ngModel)]="property.colony" appInputError
      [(error)]="errors.colony" (change)="onColonyChange($event)">
      <ng-option *ngFor="let colony of colonies" [value]="colony">{{colony.name}}</ng-option>
    </ng-select>
    <div *ngIf="isColoniesLoading" class="loading d-flex justify-content-center dropdown-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="address-house-number">Address House Number<span class="text-danger">*</span></label>
    <input id="address-house-number" type="text" class="form-control" placeholder="Enter Address House Number"
      [(ngModel)]="property.address_house_number" appInputError [(error)]="errors.address_house_number">
  </div>
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="landmark">Landmark<span class="text-danger">*</span></label>
    <input id="landmark" type="text" class="form-control" placeholder="Enter Landmark" [(ngModel)]="property.landmark"
      appInputError [(error)]="errors.landmark">
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="multi">Multi Storey / Apartment / Complex <span class="text-danger">*</span></label>
    <ng-select id="multi" placeholder="Select" bindLabel="name" bindValue="id" [items]="YES_NO_OPTIONS"
      [(ngModel)]="property.multi" appInputError [(error)]="errors.multi"></ng-select>
  </div>
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="road-width">Road Width (In Ft.)<span class="text-danger">*</span></label>
    <input id="road-width" type="text" class="form-control" placeholder="Enter Road Width"
      [(ngModel)]="property.road_width" appInputError [(error)]="errors.road_width" inputValidation="number">
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="construction-year">Construction Year<span class="text-danger">*</span></label>
    <input id="construction-year" type="text" class="form-control" placeholder="Enter Construction Year"
      [(ngModel)]="property.building_construction_year" appInputError [(error)]="errors.building_construction_year"
      mask="0000">
  </div>
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="plot-area">Plot Area (In Sq.yd) <span class="text-danger">*</span></label>
    <input id="plot-area" type="text" class="form-control" placeholder="Enter Plot Area"
      [(ngModel)]="property.plot_area" appInputError [(error)]="errors.plot_area" inputValidation="number">
  </div>
</div>

<div class="row mb-4">
  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="pin_code">Pin Code<span class="text-danger">*</span></label>
    <input id="pin_code" type="text" class="form-control" placeholder="Enter Pin Code" [(ngModel)]="property.pincode"
      inputValidation="number" mask="000000" appInputError [(error)]="errors.pincode">
  </div>

  <div class="col-md-6 col-sm-12 mmb-4">
    <label for="plot_allotment_year">Plot Allotment Year</label>
    <input id="plot_allotment_year" type="text" class="form-control" placeholder="Enter Plot Allotment Year"
      [(ngModel)]="property.plot_allotment_year" appInputError [(error)]="errors.plot_allotment_year" mask="0000">
  </div>
</div>

<div class="property-location mb-4 mmb-4">
  <div class="row align-items-end mb-4">
    <div class="col-md-6 col-sm-12 col-12 mmb-4">
      <label for="road-width">Latitude & Longitude</label>
      <input id="latitude" type="text" class="form-control" placeholder="Enter Latitude,Longitude" [(ngModel)]="latLongValue"
        appInputError [(error)]="errors.latitude" [inputErrorClasses]="['position-absolute']" inputValidation="latLong" (input)="onLatLongValueChange()" (blur) = "afterLatLongInput()">
    </div>

    <div class="col-md-3 col-sm-6 col-6 mmb-4">
      <button class="btn btn-primary w-100" (click)="viewOnMap()">View on map</button>
    </div>

    <div class="col-md-3 col-sm-6 col-6 mmb-4">
      <button class="btn btn-primary w-100" (click)="useMyLocation()">Use my location</button>
    </div>
  </div>
  
  <div class="row">
    <div class="col-12" style="height: 450px;">
      <app-map [property]="property" [selectedWard]="selectedWard" [selectedColony]="selectedColony"
        (latlng)="getLatLng($event)" [newCoordinates]="newCoordinates" style="height: 300px; width: 100%"></app-map>
    </div>
  </div>
</div>

<div class="property-photos mb-4 mmb-4">
  <div class="row" style="margin-bottom: 10px;">
    <div class="col-6">
      <p style="margin: 0; font-size: 16px; font-weight: 600; color: #3D3D3D;">Property Photos</p>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <div class="position-relative property-photos__capture-photo">
        <button class="btn bg-primary text-white px-4">
          Capture Photo
        </button>
        <input type="file" accept=".png,.jpeg" class="form-control mr-2 upload-input" aria-label="Upload"
          (change)="capturePhoto($event)">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ngx-dropzone [accept]="'image/jpeg,image/jpg,image/png'" (change)="newFiles($event)">
        <ngx-dropzone-label>Drop files here or click to upload.</ngx-dropzone-label>
      </ngx-dropzone>
      <hr>

      <app-photos-list [photos]="property.photos ?? []" [canDelete]="true" (delete)="delete($event)"></app-photos-list>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary" (click)="nextForm()">Next</button>
</div>
<div class="container">
  <div class="search-wrap">
    <!-- Start Pay Online Form -->
    <div class="paynowform">
      <!-- <h3>Search Property</h3> -->
      <div class="payform">
        <div class="form-row main-search mnfrst">

          <div class="col-md-3 search-section">
            <div class="form-group">
              <label for="ServiceNumber">{{'home.property_uid_label' | translate}}</label>
              <input type="text" class="form-control" id="ServiceNumber" placeholder="{{'home.property_uid_hint' | translate}}"
                     [(ngModel)]="searchParameters.serviceNo">
            </div>
          </div>

          <div class="col-md-3 search-section">
            <div class="form-group">
              <label for="holder-name">{{'home.account_holder_name_label' | translate}}</label>
              <input type = "text" class="form-control" id="holder-name" placeholder="{{'home.account_holder_name_hint' | translate}}" 
              [(ngModel)] = "searchParameters.accountHolderName">
            </div>
          </div>

          <div class="col-md-3 search-section">
            <div class="form-group">
              <app-search-input
                inputId="MobileNum"
                label="{{'home.mobile_number_label' | translate}}"
                placeholder="{{'home.mobile_number_hint' | translate}}"
                [(inputModel)]="searchParameters.mobile"
                [terms$]="addressTerms"
                (newTerm)="newAddressTerm($event)"
              ></app-search-input>
            </div>
          </div>

          <div class="col-md-3 search-section">
            <div class="form-group selectdroupdown">
              <app-search-input
                      inputId="HouseNo"
                      label="{{'home.address_house_number_label' | translate}}"
                      placeholder="{{'home.address_house_number_hint' | translate}}"
                      [(inputModel)]="searchParameters.address"
                      [terms$]="addressTerms"
                      (newTerm)="newAddressTerm($event)"
              ></app-search-input>
            </div>
          </div>
        </div>

        <div class="form-row main-search">

          <div class="col-md-3 search-section">
            <div class="form-group selectdroupdown">
                <label for="account-holder">{{'home.ward_label' | translate}}</label>
                <ng-select placeholder="{{'home.ward_hint' | translate}}"
                          [(ngModel)]="searchParameters.wardId"
                          [loading]="wards.length === 0"
                          (change)="getColonyDetails($event)"
                          bindLabel="name"
                          bindValue="id"
                >
                <ng-option *ngFor="let ward of wards" value = {{ward.id}}>{{ward.name}}</ng-option>
                </ng-select>
            </div>
          </div>

          <div class="col-md-3 search-section">
            <div class="form-group selectdroupdown">
              <label for="account-holder">{{'home.colony' | translate}}</label>
              <ng-select placeholder="{{'home.select_colony_hint' | translate}}" bindValue="id" [items]="colonies"
                [loading]="loadingColonies" [typeahead]="colonyInput$" [compareWith]="selectCompareWithFn"
                [(ngModel)]="searchParameters.colonyId" (change)="updateLandmarks(searchParameters.colonyId)">
                <ng-template ng-option-tmp ng-label-tmp let-item="item">
                  {{ item  | translate }}
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class=" col-md-3 search-section">
            <div class="form-group">
              <label for="selectTaxType">{{'home.property_category_label' | translate}}</label>
              <ng-select id="selectTaxType"
                         placeholder="{{'home.property_category_hint' | translate}}"
                         [(ngModel)]="searchParameters.propertyCategory"
                         (change) = "onPropertyCategoryChange($event)"
              >
                <ng-option *ngFor="let categoryId of getPropertyCategoriesId()" value="{{categoryId}}">
                  {{$any(Property.PROPERTY_CATEGORIES)[categoryId]}}
                </ng-option>
              </ng-select>
            </div>
          </div>
          <div class=" col-md-3 search-section">
            <div class="form-group">
              <label for="address-landnmark">{{'home.landmark_label' | translate}}</label>
          <ng-select placeholder="{{'home.landmark_dropdown_hint' | translate}}" [loading]="loadingLandmarks" [(ngModel)]="searchParameters.selectedLandmark" [searchable]="true" bindValue="id" [compareWith]="selectCompareWithFn" [items]="landmarkFiltered">
            <ng-template ng-option-tmp ng-label-tmp let-item="item">
              {{ item | translate }}
            </ng-template>
          </ng-select>
            </div>
          </div>
          
        </div>

        <div class="payform" *ngIf="advancedBufferLocal">

          <div class="form-row main-search">
            <div class="col-md-3 search-section">
              <div class="form-group">
                <label for="father-name">{{'home.father__name_label' | translate}}</label>
                <input type = "text" class="form-control" id="father-name" placeholder="{{'home.father_name_hint' | translate}}" 
              [(ngModel)] = "searchParameters.fatherName">
              </div>
            </div>
  
  
            <div class="col-md-3 search-section">
              <div class="form-group">
                <app-search-input
                        inputId="PropertyName:"
                        label="{{'home.property_name_label' | translate}}"
                        placeholder="{{'home.property_name_hint' | translate}}"
                        [(inputModel)]="searchParameters.propertyName"
                        (newTerm)="newAccountHolderTermPN($event)"
                ></app-search-input>
              </div>
            </div>
  
            <div class="col-md-3 search-section">
              <div class="form-group">
                <div class="form-group">
                  <label for="OldPropertyId">{{'home.old_property_id_label' | translate}}</label>
                  <input type="text" class="form-control" id="OldPropertyId" placeholder="{{'home.old_property_id_hint' | translate}}"
                         [(ngModel)]="searchParameters.oldPropertyId">
                </div>
              </div>
            </div>

            <div class="col-md-3 search-section">
              <div class="form-group">
                <label for="property-type">{{'home.property_type' | translate}}</label>
                <ng-select id = "property-type" placeholder="{{'home.property_type_hint' | translate}}"
                        [(ngModel)]="searchParameters.property_type_id"
                        [loading]="loadingPropertyTypes"
                        (focus) = "getPropertyType()"
                        (change) = "onPropertyTypeChange($event)"
                        bindLabel="name"
                          bindValue="id"
              >
              <ng-option *ngFor="let propertyType of propertyTypeData" value = "{{propertyType.id}}">{{propertyType.name}}</ng-option>
              </ng-select>
              </div>
            </div>
            <div class="col-md-3 search-section">
              <div class="form-group">
                <label for="property-sub-type">{{'home.property_sub_category_label' | translate}}</label>
                <ng-select id = "property-sub-type" placeholder="{{'home.property_sub_category_hint' | translate}}"
                        [(ngModel)]="searchParameters.property_sub_type_id"
                        [loading]="loadingPropertySubTypes"
                        (focus) = "getPropertySubType()"
                        bindLabel="name"
                          bindValue="id"
              >
              <ng-option *ngFor="let propertySubType of propertySubTypeData" value = "{{propertySubType.id}}">{{propertySubType.name}}</ng-option>
              </ng-select>
              </div>
            </div>
            <div class="col-md-3 search-section">
              <div class="form-group">
                <label for="Landmark">{{'home.landmark_label' | translate}}</label>
                <input type="text"
                       class="form-control"
                       id="Landmark"
                       placeholder="{{'home.landmark_hint' | translate}}"
                       [(ngModel)]="searchParameters.landmark">
              </div>
          </div>
          </div>
        </div>
  

        <div class="form-row main-search">
          <div class="col-md-12 search-section btn-locker mobile-hide">
            <button type="submit" class="searchbtn btn btn-primary btn-lg mr-2" (click)="search()"><i class="bi bi-search"></i> {{'home.search_button' | translate}}
            </button>
            <button type="submit" class="searchbtn btn btn-grey btn-lg" (click)="viewMap()"><i class="bi bi-map"></i>
              {{'home.map_view_button' | translate}}
            </button>
          </div>
        </div>
       
      <p class="advanced_search" (click)="OnClick()">{{'home.advanced_search' | translate}}   <span class="advanced_search_arrow_1"></span><span class="advanced_search_arrow_2"></span></p>
        <div class="col-md-12  search-section btn-locker mobile-show">
          <button type="submit" class="searchbtn btn btn-primary btn-lg mr-2" (click)="search()"><i class="bi bi-search"></i> {{'home.search_button' | translate}}
          </button>
        
          <button type="submit" class="searchbtn btn btn-grey btn-lg" (click)="viewMap()"><i class="bi bi-map"></i>
            {{'home.map_view_button' | translate}}
          </button>
        </div>
      </div>


    
    </div>
  </div>
</div>

<ng-template #relationColony>
  <div class="form-group selectdroupdown">
    <app-search-input-by-id
      inputId="Colony"
      label="Colony rel:"
      placeholder="Colony"
      [(inputModel)]="colonyInputModel"
      [terms$]="coloniesTerms"
      (newTerm)="newColoniesTerm($event)"
    ></app-search-input-by-id>
  </div>
</ng-template>

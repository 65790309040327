import {Component, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Photo} from '../../../models';
import {NgbCarousel, NgbCarouselConfig, NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';
import {getFileUrl} from '../../../helpers/get-file-url';
import {cloneDeep} from 'lodash';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-photos-slider',
  templateUrl: './photos-slider.component.html',
  styleUrls: ['./photos-slider.component.scss'],
  providers: [NgbCarouselConfig],
})
export class PhotosSliderComponent implements OnInit {

  @ViewChild('carousel', {static: true}) carousel!: NgbCarousel;
  @Input() photos?: Photo[] = [];
  formattedPhotos: Photo[] = [];
  loading: boolean = true;
  rotateDegrees: number[] = [];
  currentSlide: number = 0;
  viewerOpen = false;
  currentPhotoUrl : string = '';
  imageId = 'img';

  constructor(config: NgbCarouselConfig, private renderer: Renderer2, private commonService: CommonService) {
  }

  ngOnInit(): void {
    if (this.photos) {
      this.photos = cloneDeep(this.photos);
      for (let i = 0; i < this.photos.length; i++) {
        if (this.photos[i].photoType.id == 50) {
          this.photos[i].url = getFileUrl(this.photos[i].url);
          this.rotateDegrees.push(90);
          this.formattedPhotos.push(this.photos[i]);
        }
      }
    }
    this.loading = false;
  }

  rotate() {
    const image = document.querySelectorAll('#image')[this.currentSlide];

    this.renderer.setStyle(
      image,
      'transform',
      `rotate(${this.rotateDegrees[this.currentSlide]}deg)`,
    );

    this.rotateDegrees[this.currentSlide] += 90;
  }

  changeSlide($event: NgbSlideEvent) {
    this.currentSlide = parseInt($event.current.split('-')[2]);
  }
  fullscreenPhoto(url :string){
    this.viewerOpen = true;
    this.setAccessibleUrl(url);
  }
  setAccessibleUrl(url: string) {
    this.currentPhotoUrl = this.commonService.getAbsoluteUrl(url);
  }
}

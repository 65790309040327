import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from './index/index.component';
import {AuthGuard} from '../../guards/auth.guard';

const routes: Routes = [
    {
        path: 'properties',
        component: MainComponent,
        children: [
          {
            path: ':propertyId/correction',
            component: IndexComponent,
            canActivate: [AuthGuard]
          },
        ]
    },
];

export const propertyCorrectionRouting = RouterModule.forChild(routes);

import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Property} from "../../../models";
import {LedgerService} from "../../../services/ledger.service";
import {capitalize} from "lodash";
import TaxDetails from '../../../@types/tax-details';
import {takeUntil} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ReplaySubject} from 'rxjs';
import {PenaltyService} from "../../../services/penalty.service";
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'app-tax-details',
  templateUrl: './tax-details.component.html',
  styleUrls: ['./tax-details.component.scss']
})
export class TaxDetailsComponent implements OnInit {
  private destroy$ = new ReplaySubject();

  Property = Property;

  loading = true;

  taxDetails!: TaxDetails;

  @Output() taxData = new EventEmitter();
  @Input('property') property!: Property;

  constructor(
    private router: Router,
    private ledgerService: LedgerService,
    private penaltyService: PenaltyService,
    public cs: CityService
  ) {
  }

  ngOnInit(): void {
    this.initTaxCheck();
  }

  capitalize(s: string) {
    return capitalize(s)
  }

  private initTaxCheck() {
    const successfulRes = (details: TaxDetails | undefined) => {
      if (!details) return;

      this.taxDetails = details;
      this.taxData.emit(details as any);
    }

    this.ledgerService.taxDetails(this.property.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        successfulRes,
        () => '',
        () => this.loading = false
      )
  }
}

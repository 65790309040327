import {User} from "./user";
import {Property} from "./property";
import {PayuPayment} from './payu-payment';
import {PaymentMethods} from '../enums/payment-methods';
import {PaymentSources} from '../enums/payment-sources';
import {PaymentStatuses} from '../enums/payment-statuses';

export class Payment {
  static PAYMENT_METHODS: Pick<string, PaymentMethods> = {
    [PaymentMethods.Cash]: 'cash',
    [PaymentMethods.Cheque]: 'cheque',
    [PaymentMethods.DemandDrafts]: 'demand drafts',
    [PaymentMethods.CreditCard]: 'credit card',
    [PaymentMethods.DebitCard]: 'debit card',
    [PaymentMethods.NetBanking]: 'net banking',
    [PaymentMethods.UPI]: 'UPI',
  }

  static PAYMENT_STATUSES: Pick<string, PaymentStatuses> = {
    [PaymentStatuses.NotProcessed]: 'pending',
    [PaymentStatuses.Success]: 'success',
    [PaymentStatuses.Failure]: 'failure',
    [PaymentStatuses.Abandoned]: 'abandoned',
  }

  id!: number;
  transaction_id!: string;
  payment_date!: Date;
  payment_source!: PaymentSources;
  payment_method!: PaymentMethods;
  amount!: number;
  status!: PaymentStatuses;
  inserted_by!: number;
  reviewed_by!: number;
  created_at!: number;
  updated_at!: number;

  user = new User();
  property = new Property();
  payuPayment = new PayuPayment();
}

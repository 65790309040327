import {environment} from '../../environments/environment';

export function getFileUrl(url: string): string {
  if (!url) return '';

  return url.includes('http')
    ? url
    : environment.apiBaseUrl + getFormattedPath(url);
}

function getFormattedPath(path: string) {
  return path.startsWith('/') ? path.slice(1) : path;
}

<div class="mb-4">
  <!-- Electricity & Water connection Start -->
  <div class="row mb-4">
    <div class="col-3">
      <app-vertical-title title="{{'property_search.electricity_&_water_connection' | translate}}"
                          subTitle="{{'property_search.current_electricity_&_water_connection_details_as_per_latest_bill'| translate}}"
      ></app-vertical-title>
    </div>
    <div class="col-9">

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.water_supply_source' | translate}}<span class="text-danger">*</span></p>
        </div>
        <div class="col-4">
          <ng-select bindValue="id"
                     bindLabel="name"
                     placeholder="{{'property_search.select_water_supply_source' | translate}}"
                     [items]="FORMATTED_WATER_SUPPLY_SOURCES"
                     [compareWith]="compare"
                     [(ngModel)]="property.water_connection_number"
          ></ng-select>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.water_meter' | translate}}<span class="text-danger">*</span></p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.water_connection">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.electricity_connection' | translate}}<span class="text-danger">*</span></p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.electricity_connection">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <ng-container *ngIf="property.electricity_connection">
        <hr>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <p class="m-0">{{'property_search.electricity_connection_number' | translate}}<span class="text-danger">*</span></p>
          </div>
          <div class="col-8 d-flex align-items-center">
            <input class="form-control" placeholder="{{'property_search.enter_electricity_connection_number' | translate}}"
                   [(ngModel)]="property.electricity_connection_number"
            >
          </div>
        </div>
      </ng-container>

    </div>
  </div>
  <!-- Electricity & Water connection End -->

  <hr>

  <div class="row mb-4">
    <div class="col-3">
      <app-vertical-title title="{{'property_search.other_utilities' | translate}}"></app-vertical-title>
    </div>

    <div class="col-9">

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.free_hold_patta' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.free_hold_patta">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.one_time_deposited' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.one_time_deposited">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.lease_deed_patta' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.lease_deed_patta">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.advertisement_on_building' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.advertisement_on_building">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.sewerage' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.sewerage_line">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.septic_tank' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.septic_tank">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.compost_pit' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.compost_pit">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.solar_plant_unit' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.solar_plant_or_unit">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.rain_water_harvesting' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.rainwater_harvesting">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.toilet' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.toilet">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.parking' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.parking">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.street_light' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.street_light">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.fire_fighting_system' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.fire_fighting_system">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <hr>

      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <p class="m-0">{{'property_search.mobile_towers' | translate}}</p>
        </div>
        <div class="col-8 d-flex align-items-center">
          <mat-radio-group [(ngModel)]="property.mobile_tower">
            <mat-radio-button class="mr-4" *ngFor="let option of YES_NO_OPTIONS" [value]="option.id">
              {{option.name}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>

      <ng-container *ngIf="property.mobile_tower">
        <hr>

        <div class="row">
          <div class="col-4 d-flex align-items-center">
            <p class="m-0">{{'property_search.tower_location' | translate}}<span class="text-danger">*</span></p>
          </div>
          <div class="col-8 d-flex align-items-center">
            <ng-select style="width: 100%"
                       bindValue="id"
                       bindLabel="name"
                       placeholder="{{'property_search.select_tower_location' | translate}}"
                       [items]="FORMATTED_TOWER_LOCATIONS"
                       [(ngModel)]="property.mobile_tower_placement"
            ></ng-select>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="d-flex justify-content-end">
  <button class="btn btn-primary mr-4" (click)="previousForm()">{{'property_search.previous' | translate}}</button>

  <button class="btn btn-primary" (click)="nextForm()">{{'property_search.next' | translate}}</button>
</div>


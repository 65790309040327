import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';
import {selfAssessmentFormRouting} from "./self-assessment-form.routing";
import {FormComponent} from './form/form.component';
import {NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {GeneralInformationComponent} from './general-information/general-information.component';
import {FloorsDetailsComponent} from './floors-details/floors-details.component';
import {ReviewComponent} from './review/review.component';
import {DirectivesModule} from '../../directives/directives.module';
import {WidgetsModule} from '../../widgets/widgets.module';
import {FormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxMaskModule} from 'ngx-mask';
import {PipesModule} from '../../pipes/pipes.module';
import { OwnersComponent } from './owners/owners.component';
import { ReviewPdfComponent } from './review-pdf/review-pdf.component';
import { MapComponent } from './map/map.component';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { PhotosListComponent } from './photos-list/photos-list.component';

@NgModule({
  declarations: [
    IndexComponent,
    FormComponent,
    GeneralInformationComponent,
    FloorsDetailsComponent,
    ReviewComponent,
    OwnersComponent,
    ReviewPdfComponent,
    MapComponent,
    PhotosListComponent,
  ],
  imports: [
    CommonModule,
    selfAssessmentFormRouting,
    NgbNavModule,
    DirectivesModule,
    WidgetsModule,
    FormsModule,
    NgSelectModule,
    NgxMaskModule,
    PipesModule,
    DropzoneModule,
    NgxDropzoneModule,
  ],
})
export class SelfAssessmentFormModule { }

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchComponent} from './search/search.component';
import {FormsModule} from '@angular/forms';
import {SearchInputComponent} from './search-input/search-input.component';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap';
import {SearchInputByIdComponent} from './search-input-by-id/search-input-by-id.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {FaqComponent} from './faq/faq.component';
import {TypeaheadInputComponent} from './typeahead-input/typeahead-input.component';
import {SearchInputByApiComponent} from './search-input-by-api/search-input-by-api.component';
import {SearchModelByApi} from './search-model-by-api/search-model-by-api.component';
import { CorrectionRequestStatusComponent } from './correction-request-status/correction-request-status.component';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from "../shared-module/shared-module.module";
import { CardBlockComponent } from './card-block/card-block.component';
import { NoContentRowComponent } from './no-content-row/no-content-row.component';
import { PageTitleComponent } from './page-title/page-title.component';
import { DocumentRequiredPattaComponent } from './document-required-patta/document-required-patta.component';

@NgModule({
    declarations: [
        SearchComponent,
        SearchInputComponent,
        SearchInputByIdComponent,
        FaqComponent,
        TypeaheadInputComponent,
        SearchInputByApiComponent,
        SearchModelByApi,
        CorrectionRequestStatusComponent,
        CardBlockComponent,
        NoContentRowComponent,
        PageTitleComponent,
        DocumentRequiredPattaComponent,
    
    ],
    exports: [
        SearchComponent,
        SearchInputComponent,
        SearchInputByIdComponent,
        FaqComponent,
        TypeaheadInputComponent,
        SearchInputByApiComponent,
        SearchModelByApi,
        CardBlockComponent,
        NoContentRowComponent,
        PageTitleComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgbTypeaheadModule,
        NgSelectModule,
        NgbModule,
        PipesModule,
        SharedModule
    ]
})
export class WidgetsModule {
}

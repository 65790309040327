import {Pipe, PipeTransform} from '@angular/core';
import { LanguageService } from '../services/language.service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
    readonly langCodeMapping = {
      en: 'name',
      hi: 'name_hindi'
    }
    constructor(private languageService: LanguageService){

    }
  transform(key:string | Object): string {
    const langData : Record<string, string> = this.languageService.currentLangData;
    const langCode:string = this.languageService.defaultLang;
    if(typeof key === 'object') {
      return (key as any)[(this.langCodeMapping as any)[langCode]];
    }
    const keyArr = key.split(".");
    let value:any = langData;
    keyArr.forEach(el => {
        value = value[el.trim()];
    })
    return value || key;
  }

}

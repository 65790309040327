<div class="container height-container p-4">
  <div *ngIf="!loading; else loadingBlock">
    <app-form [property]="property"
              [isSubmitting]="isSubmitting"
              (submit)="submit($event)"
    ></app-form>
  </div>
</div>

<ng-template #loadingBlock>
  <div class="d-flex justify-content-center align-items-center h-100">
    <div class="spinner-border" role="status">
      <span class="sr-only">{{'property_search.loading' | translate}}</span>
    </div>
  </div>
</ng-template>

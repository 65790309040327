import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-document-required-patta',
  templateUrl: './document-required-patta.component.html',
  styleUrls: ['./document-required-patta.component.scss']
})
export class DocumentRequiredPattaComponent implements OnInit {
  pattaIssuingOrg : any = [
    {id: 1, name :'Development Authority (DA)'}, 
    {id: 2, name :'Nagar Nigam'}, 
    {id: 3, name :'Urban Improvement Trust (UIT)'}, 
    {id: 4, name :'Nagar Parishad'}, 
    {id: 5, name :'Nagar Palika'}, 
  ];
  schemeName : any = [];
  allotmentType : any = [
    {id: 1, name :'Original Allottee/ Allotee on the basis of Transfer Letter'}, 
  ];
  requiredDocs : any = [
    {id: 1, name :'Photo ID issued by Government (Aadhar Card/ Driving License / Passport / Voter ID)', mandatory : true}, 
    {id: 2, name :'Self-Declaration in prescribed format (प्रपत्र-1)', mandatory : true}, 
    {id: 3, name :'Allotment letter issued by UIT', mandatory : true}, 
    {id: 4, name :'Possession Letter Issued by UIT', mandatory : false}, 
    {id: 5, name :'Site Plan Issued by UIT', mandatory : false}, 
    {id: 6, name :'Receipt(s) of Amount Deposited in UIT', mandatory : false}, 
    {id: 7, name :'Name Transfer Letter issued by UIT', mandatory : false}, 
    {id: 8, name :'In case property is mortgage letter from Financial Institution', mandatory : false}, 
    {id: 9, name :'Original Chain of Documents if not an Original Allotee', mandatory : false}, 
  ];
  selectedPattaIssuingOrg: any = null;
  selectedSchemeName: any = null;
  selectedAllottmentType: any = null;

  
  constructor(
    public dialogRef: MatDialogRef<any>
  ) { }

  ngOnInit(): void {
  }

  onPattaIssueSelectionChange() {
    this.selectedSchemeName = null;
    this.selectedAllottmentType = null;
    if(this.selectedPattaIssuingOrg === 3){
      this.schemeName = [
        {id: 1, name :'UIT Scheme'}, 
      ];
    }else{
      this.schemeName = [];
    }
  }

  onClearPattaIssueSelection(){
    this.selectedPattaIssuingOrg = null;
    this.selectedSchemeName = null;
    this.selectedAllottmentType = null;
  }

  onClearSchemeName(){
    this.selectedSchemeName = null;
    this.selectedAllottmentType = null;
  }

  closeModal(){
    this.dialogRef.close();
  }
}

<div class="container">
    <nav aria-label="breadcrumb" class="brdcrum">
        <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
            <li class="breadcrumb-item"><a [routerLink]="'/property-search'">Property Search</a></li>
            <li class="breadcrumb-item active" aria-current="page">Recently Viewed Properties</li>
        </ol>
    </nav>
    <!-- Start Title and Filter Options -->
    <div class="listing-head mb-4 d-flex align-items-center justify-content-between">
        <div class="flex_coulmn">
            <h2 class="m-0">Recently Viewed Properties</h2>
            <div class="property-items">Recently Viewed Property List <span>({{this.properties.length}})</span></div>

        </div>

        <div class="view-options d-flex align-items-center">

            <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="grid">
                <label ngbButtonLabel class="">
                    <input ngbButton type="radio" [value]="false"> <i class="bi bi-list-task"></i>
                </label>
                <label ngbButtonLabel class="">
                    <input ngbButton type="radio" [value]="true"> <i class="bi bi-grid-3x3-gap"></i>
                </label>
            </div>
        </div>
    </div>

    <app-list-view *ngIf="grid === false && isRecentlyViewedAvailable" [properties]="properties" [page]="page"
        [perPage]="perPage"></app-list-view>

    <app-grid-view *ngIf="grid === true && isRecentlyViewedAvailable" [properties]="properties"></app-grid-view>

    <div class="no_data" *ngIf="isRecentlyViewedAvailable == false">
        <h3>No Recently Viewed Properties</h3>
    </div>
</div>
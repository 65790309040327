import {Component, Input, OnInit} from '@angular/core';
import {Property} from "../../../models";

@Component({
  selector: 'app-building-utility-information',
  templateUrl: './building-utility-information.component.html',
  styleUrls: ['./building-utility-information.component.scss']
})
export class BuildingUtilityInformationComponent implements OnInit {

  Property = Property;
  isCollapsed: boolean = true;

  @Input('property') property!: Property;

  constructor() { }

  ngOnInit(): void {
  }

  yesNoNothing(value?: boolean) {
    if (value === null || value === undefined) return '';

    return value ? 'Yes' : 'No';
  }
}

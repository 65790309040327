export enum CreditRequestStatus {
  Pending = 1,
  Approved = 2,
  Rejected = 3,
}

export const CreditRequestStatusesNames: Record<CreditRequestStatus, string> = {
  [CreditRequestStatus.Pending]: 'Pending',
  [CreditRequestStatus.Approved]: 'Approved',
  [CreditRequestStatus.Rejected]: 'Rejected',
}

export function getFormattedSelfAssessmentStatuses(): { id: CreditRequestStatus, name: string }[] {
  return Object.entries(CreditRequestStatusesNames).map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
}


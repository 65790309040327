import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { currentFinancialYear } from 'src/app/helpers/current-financial-year';
import { getFinancialYears } from 'src/app/helpers/get-financial-years';
import { CommonService } from 'src/app/services/common.service';
import { InformPaymentCreditService } from 'src/app/services/inform-payment-credit.service';
import Swal from 'sweetalert2';

type InformPaymentTableForm = {
  financial_year: string | null,
  transaction_date_time: string,
  amount: number | null
}
type InformPaymentCredit = {
  property_id: number,
  transactions: InformPaymentTableForm[],
  user_remark: string,
  attachments: string[]
}
@Component({
  selector: 'app-inform-payment-credit',
  templateUrl: './inform-payment-credit.component.html',
  styleUrls: ['./inform-payment-credit.component.scss']
})
export class InformPaymentCreditComponent implements OnInit {
  readonly STARTING_YEAR = 2007;
  propertyId!: number;
  transaction_Date: NgbDateStruct | undefined;
  informPaymentTableForm: InformPaymentTableForm = {
    financial_year: null,
    transaction_date_time: "",
    amount: null
  };
  informPaymentTableFormArray: InformPaymentTableForm[] = [];
  informPaymentCredit: InformPaymentCredit = {
    property_id: this.propertyId,
    transactions: this.informPaymentTableFormArray,
    user_remark: "",
    attachments: []
  }
  financialYears: number[] = [];
  financial_year!: string;
  uploadedFiles: { name: string, path: string, url: string }[] = [];
  isAgree = false;
  isValidateAmount = false;
  isDocumentUploaded = false;
  isTransactionsAdded = false;
  isValid = true;
  private destroy$ = new Subject();
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth() + 1;
  public currentDay: number = this.today.getDate();
  public maxDate = { year: this.currentYear, month: this.currentMonth, day: this.currentDay }
  public minDate = { year: 2007, month: 1, day: 1 }
  loading: boolean = false;

  constructor(
    private modalService: NgbModal,
    private currentRoute: ActivatedRoute,
    private informPaymentCreditService: InformPaymentCreditService,
    private commonService:CommonService,
    private _router: Router,
  ) {
    this.propertyId = this.currentRoute.snapshot.params.propertyId;
  }

  ngOnInit(): void {
    this.informPaymentCredit.property_id = this.propertyId;
    const firstDate = this.STARTING_YEAR;
    const currentDate = currentFinancialYear();
    this.financialYears = getFinancialYears(
      firstDate,
      firstDate > currentDate ? firstDate : currentDate,
    );
  }

  addTransaction(modal: any) {
    this.informPaymentTableForm = {
      financial_year: null,
      transaction_date_time: "",
      amount: null
    };
    this.transaction_Date = undefined;
    this.openTransactionModal(modal);
  }

  private openTransactionModal(modal: any) {
    this.modalService.open(modal, { scrollable: true, centered: true });
  }

  informPaymentCreditSubmit(modal: any) {
    if (this.informPaymentTableForm.amount != null && this.informPaymentTableForm.amount <= 0) {
      this.isValidateAmount = true;
      return;
    }
    modal.dismiss();
    if (this.transaction_Date) {
      let date = new Date(`${this.transaction_Date.month} ${this.transaction_Date.day}  ${this.transaction_Date.year}`);
      let convertedDate = this.formatDate(date);
      this.informPaymentTableForm.transaction_date_time = convertedDate;
    }
    this.informPaymentTableFormArray.unshift(this.informPaymentTableForm);
  }

  deleteinformPaymentCredit(index: number) {
    this.informPaymentTableFormArray = this.informPaymentTableFormArray.filter((v, i) => i !== index);
  }

  afterFileUpload(fileData: any) {
    this.uploadedFiles.push(fileData);
    this.informPaymentCredit.attachments.push(fileData.path);
  }

  removeFile(index: number) {
    this.uploadedFiles.splice(index, 1);
    this.informPaymentCredit.attachments.splice(index, 1);
  }

  submit() {
    this.checkvalidations();
    if (!this.isValid) {
      return;
    }
    this.loading = true;
    this.informPaymentCreditService.createInformPaymentCredit(this.informPaymentCredit)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: any) => {
        this.loading = false;
        if (res.data.CreateCreditRequest.success == true) {
          this.commonService.showSuccessMessage('property_search.inform_payment_credit_request_submitted');
          this._router.navigate(['/property/' + this.propertyId]);
        } else {
          this.loading = false;
          Swal.fire('Error', res.data.CreateCreditRequest.message, 'error');
        }
      }, (error: any) => {
        this.loading = false;
        this.commonService.showErrorMessage('home.something_went_wrong');
      })
  }

  checkvalidations() {
    if (this.uploadedFiles.length == 0) {
      this.isDocumentUploaded = false;
    } else {
      this.isDocumentUploaded = true;
    }

    if (this.informPaymentTableFormArray.length == 0) {
      this.isTransactionsAdded = false;
    } else {
      this.isTransactionsAdded = true;
    }

    if (this.isDocumentUploaded == false || this.isTransactionsAdded == false) {
      this.isValid = false;
    }
  }

  formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


}

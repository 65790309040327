<div class="property-item" *ngIf="property.id; else skeleton">
  <div class="item-img list-image" *ngIf="getPhotoUrl()"
       style="background-image: url({{getPhotoUrl()}})"></div>
  <div class="item-img list-image" *ngIf="!getPhotoUrl()"
       style="background-image: url('assets/images/no-image.svg')"></div>

  <div class="item-details">
    <div class="d-flex itemrow mb-3">
      <p class="card-title">{{'property_search.service_no' | translate}}:</p>
      <p class="card-sub-title text-right"> {{property.property_uid}}
    </div>

    <div class="d-flex itemrow mb-3">
      <p class="card-title">
        {{'property_search.owner_name' | translate}}: </p>
      <p class="card-sub-title text-right">
        {{property?.owners[0]?.first_name ?? ''}} {{property?.owners[0]?.last_name ?? ''}}</p>
    </div>

    <div class="d-flex itemrow mb-3">
      <p class="card-title">{{'property_search.plot_area' | translate}}:</p>
      <p class="card-sub-title text-right">{{property.plot_area}}</p>
    </div>
    <div class="d-flex itemrow mb-3">
      <p class="card-title">{{'property_search.property_category' | translate}}:</p>
      <p class="card-sub-title text-right">{{PROPERTY_CATEGORIES[property.property_category]}}</p>
    </div>

    <div class="item-emaddress">
      <p class="card-title mb-2">{{'property_search.address' | translate}}:</p>
      <p class="footer-title">
        {{(property?.address_house_number != null)? property?.address_house_number + ', ' : ''}}
        {{(property?.address_line2 != null)? property?.address_line2 + ', ' : ''}}
        {{(property?.street?.name != null)? property?.street?.name + ', ' : ''}}
        {{(property?.colony?.name != null)? property?.colony?.name + ', ' : ''}}
        {{(property?.ward?.name != null)? property?.ward?.name + ', ' : ''}}
        {{(property?.city?.name != null)? property?.city?.name + ', ' : ''}}
        {{(property?.district?.name != null)? property?.district?.name + ', ' : ''}}
        {{(property?.pincode != null)? property?.pincode : ''}}
      </p>
    </div>

    <div class="view-pro-btn">
      <a [routerLink]="'/property/'+property.id" class="btn btn-primary btn-sm" target="_blank">{{'property_search.view_details' | translate}}</a>
    </div>
  </div>
</div>

<ng-template #skeleton>
  <div class="mb-4">
    <div class="ssc ssc-card ">
      <div class="ssc-wrapper  mb-5">
        <div class="ssc-square mb"></div>
        <div class="row">
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="ssc-line w-40"></div>
            <div class="ssc-line w-40"></div>
          </div>
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="ssc-line w-40"></div>
            <div class="ssc-line w-40"></div>
          </div>
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="ssc-line w-40"></div>
            <div class="ssc-line w-40"></div>
          </div>
          <div class="col-sm-12 d-flex justify-content-between">
            <div class="ssc-line w-40"></div>
            <div class="ssc-line w-40"></div>
          </div>
        </div>

      </div>
      <div class="ssc-wrapper py-0">
        <div class="row">
          <div class="col-sm-12">
            <div class="ssc-hr"></div>
          </div>
        </div>
      </div>

      <div class="ssc-wrapper">
        <div class="ssc-line w-50"></div>
        <div class="ssc-line w-75"></div>
        <div class="ssc-line w-100"></div>
        <div class="ssc-line w-100"></div>
      </div>
    </div>
  </div>
</ng-template>

import { RouterModule, Routes } from '@angular/router';
import {MainComponent} from "../../layouts/main/main.component";
import {IndexComponent} from "./index/index.component";

const routes: Routes = [
    {
        path: '',
        component: MainComponent,
        children: [
          {
            path: 'invoice/:id',
            component: IndexComponent
          },
        ]
    },
];

export const invoiceRouting = RouterModule.forChild(routes);

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SigninComponent } from './signin/signin.component';
import { OtpConfirmComponent } from './otp-confirm/otp-confirm.component';
import { SignupComponent } from './signup/signup.component';
import {MatDialogModule} from "@angular/material/dialog";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AuthenticationService} from "../services";
import {PipesModule} from "../pipes/pipes.module";
import {NgxMaskModule} from 'ngx-mask';



@NgModule({
  declarations: [
    SigninComponent,
    OtpConfirmComponent,
    SignupComponent
  ],
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    PipesModule,
    NgxMaskModule
  ],
  providers:[
    AuthenticationService
  ]
})
export class DialogsModule { }

<div class="container">

  <!-- Start breadcrumb -->
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
      <li class="breadcrumb-item active" aria-current="page">My Properties</li>
    </ol>
  </nav>


  <!-- Start Title and Filter Options -->
  <div class="listing-head mb-4 d-flex align-items-center justify-content-between">
    <h2 class="m-0">My Properties</h2>
    <div class="view-options d-flex align-items-center">
      <div class="property-items">Property List <span>({{properties.length}})</span></div>

      <div class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="grid">
        <label ngbButtonLabel class="">
          <input ngbButton type="radio" [value]="true"> <i class="bi bi-grid-3x3-gap"></i>
        </label>
        <label ngbButtonLabel class="">
          <input ngbButton type="radio" [value]="false"> <i class="bi bi-list-task"></i>
        </label>
      </div>
    </div>
  </div>
  <!-- End Title and Filter Options -->

  <!-- ================= Start Grid View ===================== -->
  <div *ngIf="properties; else skeletonGrid">
    <div class="row" *ngIf="grid == true">
      <!-- 01 Start Item -->
      <div class="grid-view col-lg-4 col-md-6 col-sm-6 col-xsm-12" *ngFor="let property of properties">
        <div class="property-item">
          <div class="item-img list-image" *ngIf="property.photos && property.photos.length"
               style="background-image: url({{property.photos[0].url}})"></div>
          <div class="item-img list-image" *ngIf="property.photos && !property.photos.length"
               style="background-image: url('assets/images/no-image.svg')"></div>

          <div class="item-details">
            <div class="d-flex itemrow mb-3">
              <p class="card-title">Service No.:</p>
              <p class="card-sub-title text-right">{{property.property_uid}}
            </div>

            <div class="d-flex itemrow mb-3">
              <p class="card-title">
                Owner Name.:
              </p>
              <p class="card-sub-title text-right">
                {{property?.owners?.[0]?.first_name ?? ''}} {{property?.owners?.[0]?.last_name ?? ''}}
              </p>
            </div>

            <div class="d-flex itemrow mb-3">
              <p class="card-title">Plot Area:</p>
              <p class="card-sub-title text-right">{{property.plot_area}}</p>
            </div>
            <div class="d-flex itemrow mb-3">
              <p class="card-title">Property Category:</p>
              <p class="card-sub-title text-right">{{PROPERTY_CATEGORIES[property.property_category]}}
              </p>
            </div>

            <div class="item-emaddress">
              <p class="card-title mb-2">Address:</p>
              <p class="footer-title">{{(property?.address_house_number != null)? property?.address_house_number + ', ' : ''}}
                {{(property?.address_line2 != null)? property?.address_line2 + ', ' : ''}}
                {{(property?.street?.name != null)? property?.street?.name + ', ' : ''}}
                {{(property?.colony?.name != null)? property?.colony?.name + ', ' : ''}}<br>
                {{(property?.ward?.name != null)? 'Ward ' + property?.ward?.name : ''}}</p>
            </div>

            <div class="view-pro-btn">
              <a [routerLink]="'/property/'+property.id" class="btn btn-primary btn-sm" target="_blank">View Details</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <!-- ================= Start List View ===================== -->
  <div *ngIf="properties; else skeletonList">
    <div class="table-hold responsive-table" *ngIf="grid == false">
      <table class="table table-bordered th-bg">
        <thead>
        <tr>
          <th>S.No.</th>
          <th>Service No.</th>
          <th>Property Category</th>
          <th>Owner Name</th>
          <th>Area (Sq. Yrd)</th>
          <th>Address</th>
          <th>Total Tax Due</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let property of properties; let i = index">
          <td data-th="S.No.:">{{i + 1}}</td>
          <td data-th="Service No.:">{{property.property_uid}}</td>
          <td data-th="Property Category:">{{PROPERTY_CATEGORIES[property.property_category]}}</td>
          <td data-th="Owner Name:">{{property?.owners?.[0]?.first_name ?? ''}} {{property?.owners?.[0]?.last_name ?? ''}}</td>
          <td data-th="Area (Sq. Yrd):">{{property.plot_area}}</td>
          <td data-th="Address:">{{(property?.address_house_number != null)? property?.address_house_number + ', ' : ''}}
            {{(property?.address_line2 != null)? property?.address_line2 + ', ' : ''}}
            {{(property?.street?.name != null)? property?.street?.name + ', ' : ''}}
            {{(property?.colony?.name != null)? property?.colony?.name + ', ' : ''}}<br>
            {{(property?.ward?.name != null)? 'Ward ' + property?.ward?.name : ''}}</td>
          <td data-th="Address:">{{propertiesDue[property.id] ?? ''}}</td>
          <td data-th="Action:">
            <a [routerLink]="'/property/'+property.id" class="btn btn-sm btn-outline-secondary">
              View
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <!-- ========================================
    Start Grid View Skeleton Loading List
  ========================================== -->
  <ng-template #skeletonGrid>
    <div class="row mb-5" *ngIf="grid == true">
      <!-- 01 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 01 End Item Card -->

      <!-- 02 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 02 End Item Card -->

      <!-- 03 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 03 End Item Card -->

      <!-- 04 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 04 End Item Card -->

      <!-- 05 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 05 End Item Card -->

      <!-- 06 Start Item Card -->
      <div class="col-lg-4 col-md-6 col-sm-6 col-12 mb-4">
        <div class="ssc ssc-card">
          <div class="ssc-wrapper">
            <div class="ssc-square mb"></div>
            <div class="row">
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
              <div class="col-sm-12 d-flex justify-content-between">
                <div class="ssc-line w-40"></div>
                <div class="ssc-line w-40"></div>
              </div>
            </div>

          </div>
          <div class="ssc-wrapper py-0">
            <div class="row">
              <div class="col-sm-12">
                <div class="ssc-hr"></div>
              </div>
            </div>
          </div>

          <div class="ssc-wrapper">
            <div class="ssc-line w-50"></div>
            <div class="ssc-line w-75"></div>
            <div class="ssc-line w-100"></div>
            <div class="ssc-line w-100"></div>
          </div>
        </div>
      </div>
      <!-- 06 End Item Card -->

    </div>
  </ng-template>


  <!-- ========================================
    Start Grid View Skeleton Loading List
  ========================================== -->
  <ng-template #skeletonList>
    <div class="list-loading responsive-table" *ngIf="grid == false">
      <table class="table table-bordered">
        <thead>
        <tr>
          <th>S.No</th>
          <th>Owner Name</th>
          <th>Service No.</th>
          <th>Property Category</th>
          <th>Area (Sq. Yrd)</th>
          <th>Address</th>
          <th>Action</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>

        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>

        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>

        <tr>
          <td data-th="S.No:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Service No.:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Property Category:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Owner Name:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Plot Area:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Address:">
            <div class="ssc-line w-100"></div>
          </td>
          <td data-th="Action:">
            <div class="ssc-line w-100"></div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </ng-template>
</div>

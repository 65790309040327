import {Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {Citizen, Floor, Ledger, Property} from '../../../models';
import {TaxCalculationService} from '../../../services/tax-calculation.service';
import {PropertyTaxCalculation} from '../../../@types/tax-calculation';
import {debug} from '../../../helpers/debug';
import {LedgerService} from '../../../services/ledger.service';
import {startCase} from 'lodash';
import {getFormattedGenderTypes} from '../../../enums/genderTypes';
import {PropertyService} from '../../../services/property.service';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services';
import { CommonService } from 'src/app/services/common.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
})
export class ReviewComponent implements OnInit {

  @Input() property!: Property;

  @Output('previousForm') previousFormEmitter = new EventEmitter();
  @ViewChildren('balance') balanceCells?: QueryList<ElementRef>;

  readonly Property = Property;

  readonly GENDER_TYPES = getFormattedGenderTypes();
  readonly FLOOR_NAMES = Floor.FLOOR_NUMBER;
  readonly PROPERTY_CATEGORIES = Property.PROPERTY_CATEGORIES;
  readonly PROPERTY_USAGES = Property.PROPERTY_USAGES;
  readonly CITIZEN_RELATION = Citizen.RELATION;
  
  isLedgersLoading = false;
  isTaxCalculationLoading = false;
  isSelfAssessmentFormPreSave = false;

  taxCalculation!: PropertyTaxCalculation;
  ledgersDetails!: { ledgers: Ledger[], penalties: Ledger[], rebates: Ledger[] };
  selfAssessmentFormPreSave: any;
  isSaving = false;

  constructor(
    private taxCalculationService: TaxCalculationService,
    private ledgerService: LedgerService,
    private propertyService: PropertyService,
    public authService: AuthenticationService,
    public commonService: CommonService,
    public languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.loadUpdatedTaxCalculation();
    this.loadLedgers();
    this.loadSelfAssessmentFormPreSave();
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  toInt(s: string | number) {
    return typeof s === 'number' ? s : parseInt(s);
  }

  loadUpdatedTaxCalculation() {
    try {
      this.isTaxCalculationLoading = true;

      this.taxCalculationService.getTaxCalculationByProperty(this.property).subscribe(
        (taxCalculation) => {
          this.isTaxCalculationLoading = false;

          if (!taxCalculation) return;

          this.taxCalculation = taxCalculation;
        },
        () => this.isTaxCalculationLoading = false,
      );
    } catch (e) {
      this.isTaxCalculationLoading = false;
      debug(e);
    }
  }

  loadLedgers() {
    try {
      this.isLedgersLoading = true;

      this.ledgerService.ledgersNewProperty(this.property).subscribe(
        (ledgersDetails) => {
          this.isLedgersLoading = false;

          if (!ledgersDetails) return;

          this.ledgersDetails = ledgersDetails;
        },
        () => this.isLedgersLoading = false,
      );
    } catch (e) {
      this.isLedgersLoading = false;
      debug(e);
    }
  }

  loadSelfAssessmentFormPreSave() {
    this.isSelfAssessmentFormPreSave = true;

    try {
      this.propertyService.selfAssessmentFormPreSave(this.property).subscribe(
        (res) => {
          this.selfAssessmentFormPreSave = res;
          this.isSelfAssessmentFormPreSave = false;
        },
        () => this.isSelfAssessmentFormPreSave = false,
      );
    } catch (e) {
      this.isSelfAssessmentFormPreSave = false;
    }
  }

  formatParticulars(item: Ledger) {
    if (item.transaction_type === Ledger.REBATE_TYPE || item.transaction_type === Ledger.PENALTY_TYPE) {
      return startCase(`${Ledger.LEDGER_TYPES[item.transaction_type]}`) + (item.remarks ? `(${item.remarks})` : '');
    }

    return startCase(`${Ledger.LEDGER_TYPES[item.transaction_type]}`);
  }

  save() {
    if (!this.authService.user) {
      this.showLoginWarning();
      return;
    }
    if(this.property.photos.length == 0){
      Swal.fire('Property Photos are required to save', '', 'warning');
      return;
    }
    if (this.isSaving) return;

    this.isSaving = true;

    this.propertyService.createSelfAssessment(this.property).subscribe(
      (status) => {
        this.isSaving = false;

        if (!status) {
          Swal.fire('Error', 'Something went wrong', 'error');
        } else {
          Swal.fire('Success', 'Self-Assessment saved', 'success').then(() => location.reload())
        }
      },
      () => {
        Swal.fire('Error', 'Something went wrong', 'error');
        this.isSaving = false;
      }
    );
  }

  private showLoginWarning(){
    Swal.fire(this.languageService.translate('home.login_to_proceed'), '', 'warning');
  }
  
}

import {Injectable} from '@angular/core';
import {Apollo} from "apollo-angular";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {gql} from "@apollo/client/core";
import {IServicePaginate} from "../@types/service-paginate";
import {IServiceGetOne} from "../@types/service-get-one";
import {Sector} from "../models";
import {BaseService} from "./base-service.service";

@Injectable({
  providedIn: 'root'
})
export class SectorService extends BaseService implements IServicePaginate<Sector>, IServiceGetOne<Sector> {

  constructor(private apollo: Apollo) {
    super();
  }

  defaultSearchWhere(term: string): string {
    return `where: { column: NAME, operator: LIKE, value: "${term}%"}`;
  }

  public paginate(page: number = 1, perPage: number = 10, where: string): Observable<Sector[]> {
    return this.apollo.watchQuery({
      query: this.paginateQuery(page, perPage, where)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.SectorPaginate && result.data.SectorPaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public paginateById(id: string, page: number = 1, perPage: number = 10, where: () => string): Observable<Sector[]> {
    return this.apollo.watchQuery({
      query: this.paginateQueryByWardId(id, page, perPage, where)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.SectorPaginate && result.data.SectorPaginate.data),
      map((result: any) => result ?? [])
    );
  }

  public getOne(column: string, value: any): Observable<Sector | undefined> {
    return this.apollo.watchQuery({
      query: this.getOneQuery(column, value)
    }).valueChanges.pipe(
      map((result: any) => result.data && result.data.SectorPaginate && result.data.SectorPaginate.data && result.data.SectorPaginate.data[0])
    );
  }

  /**
   * GraphQL Queries
   * */
  private getAllFields() {
    return `
      id,
      name,
      description,
    `
  }

  private paginateQuery(page: number = 1, perPage: number = 10, where: string) {
    return gql`{
      SectorPaginate(
        page: ${page},
        first: ${perPage},
        ${where}
      ) {
        data {${this.getAllFields()}}
      }
    }`
  }

  private paginateQueryByWardId(ward_id: string, page: number = 1, perPage: number = 10, where: () => string) {
    return gql`{
      SectorPaginate(
        page: ${page},
        first: ${perPage},
        where: {
          OR: [
            {
              HAS: {
                relation: "ward",
                condition: {
                  column: ID,
                  operator: LIKE,
                  value: ${ward_id}
                }
              }
            }
          ]
        }
      ) {
        data {${this.getAllFields()}}
      }
    }`
  }

  private getOneQuery(column: string, value: any) {
    const normalizedValue = typeof value === 'string' ? `"${value}"` : value;

    return gql`{
      SectorPaginate(
        page: 1,
        first: 1,
        where:{ column: ${column.toUpperCase()}, value: ${normalizedValue} }
      ) {
        data {${this.getAllFields()}}
      }
    }`;
  }
}

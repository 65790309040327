import {Component, Input, OnInit} from '@angular/core';
import {Floor, Property} from "../../../models";


@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  Property = Property;
  isCollapsed: boolean = false;


  @Input('property') property!: Property;
  @Input('floors') floors: Floor[] = [];


  constructor() {
  }

  ngOnInit(): void {
  }

  yesNoNothing(value?: boolean | number) {
    if(value == 1){
      return 'Yes'
    } else if (value == 0){
      return 'No';
    }
    return '';
  }

  getTotalBuiltUpArea(){
    let totalBuiltUpArea = 0;
    this.floors.forEach((floor) => {
      totalBuiltUpArea += floor.carpet_area;
    })
    return totalBuiltUpArea;
  }
}

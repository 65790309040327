import {Pipe, PipeTransform} from '@angular/core';
import {Ledger} from "../models";

@Pipe({
  name: 'rebateBalanceCalculator'
})
export class RebateBalanceCalculatorPipe implements PipeTransform {

  private static _currentBalance: number = 0;

  transform(value: Ledger | null, index?: number): number {
    if (index === 0) {
      RebateBalanceCalculatorPipe._currentBalance = 0;
    }

    if (value?.balance) {
      RebateBalanceCalculatorPipe._currentBalance = value.balance;
      return value.balance;
    }

    if(value){
      RebateBalanceCalculatorPipe._currentBalance += value.debit_amount - value.credit_amount;
    }

    return RebateBalanceCalculatorPipe._currentBalance;
  }
}

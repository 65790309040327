import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive, EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';

@Component({
  template: '<span class="text-danger" [class]="classes" style="font-size: 11px"><strong>{{error}}</strong></span>'
})
class InputError {
  @Input() error = '';
  private _classes: string[] = [];

  @Input() set classes(classes: string[]) {
    this._classes = [...classes, ...this._classes]
  };

  get classes() {
    return this._classes;
  }
}

@Directive({
  selector: '[appInputError]'
})
export class InputErrorDirective {

  @Input() error: any = '';
  @Output() errorChange = new EventEmitter<string>();

  @Input() inputErrorClasses: string[] = [];

  @HostListener('input') onInput = () => this.clearEvent();
  @HostListener('change') onChange = () => this.clearEvent();
  @HostListener('select') onSelect = () => this.clearEvent();
  @HostListener('selectItem') onSelectItem = () => this.clearEvent();
  @HostListener('ngModelChange') onNgModelChange = () => this.clearEvent();
  @HostListener('inputModelChange') onInputModelChange = () => this.clearEvent();
  @HostListener('currentItemChange') onCurrentItemChange = () => this.clearEvent();

  private errorBlockRef!: ComponentRef<InputError>;

  constructor(
    public viewContainerRef: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {
  }

  ngOnInit(): void {
    let compFactory = this.cfr.resolveComponentFactory(InputError);
    this.errorBlockRef = this.viewContainerRef.createComponent(compFactory);
    this.errorBlockRef.instance.classes = this.inputErrorClasses;
    this.errorBlockRef.instance.error = this.error;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.errorBlockRef && changes.error) {
      this.errorBlockRef.instance.error = changes.error.currentValue;
    }
  }

  private clearEvent() {
    if (this.error === '') return;

    this.errorChange.emit('');
  }
}

function numberToGraphQLRequest(num: number): string {
  return `${num}`
}

function stringToGraphQLRequest(str: string): string {
  return `"${str}"`
}

function booleanToGraphQLRequest(bool: boolean): string {
  return 'bool';
}

function objectToGraphQLRequest(object: { [key: string]: any }): string {
  return '{' + Object.entries(object).reduce((acc, entity) => {
    return acc + ` ${entity[0]}: ` + toGraphQLRequest(entity[1])
  }, '') + '}'
}

function arrayToGraphQLRequest(items: any[]): string {
  return '[' + items.reduce((acc, v) => acc + ` ${toGraphQLRequest(v)}`, '') + ']';
}

export function toGraphQLRequest(data: number | string | boolean | undefined | null | any[] | object): string {
  const dataType = typeof data;

  if (dataType === 'undefined' || data === null) return 'null'

  if (dataType === 'object' && Array.isArray(data)) return arrayToGraphQLRequest(data)

  return ({
    'number': numberToGraphQLRequest,
    'string': stringToGraphQLRequest,
    'boolean': booleanToGraphQLRequest,
    'object': objectToGraphQLRequest
  } as any)[dataType](data) ?? 'null';
}

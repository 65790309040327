import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Floor, Ledger, Property, PropertySubType, PropertyType } from '../../../models';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PropertyTypeService } from '../../../services/property-type.service';
import { PropertySubTypeService } from '../../../services/property-sub-type.service';
import { PropertyCategories } from '../../../enums/property-categories';
import { getFormattedErrorsByValidator, Validated, ValidationErrors } from '../../../@types/validation';
import { cloneDeep, floor, uniq } from 'lodash';
import { FloorDetailsValidationRules } from '../data/validation-rules';
// @ts-ignore
import Validator from 'Validator/src/validator.js';
import { getFinancialYears } from '../../../helpers/get-financial-years';
import { currentFinancialYear } from '../../../helpers/current-financial-year';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from 'src/app/services/language.service';
import { CommonService } from 'src/app/services/common.service';

type FloorRelationValidation = { property_type?: string, property_sub_type?: string };

@Component({
  selector: 'app-floor-details',
  templateUrl: './floor-details.component.html',
  styleUrls: ['./floor-details.component.scss']
})
export class FloorDetailsComponent implements OnInit {

  @Input() property!: Property;
  @Input() oldServiceProperty: Property | undefined;
  @Input() ledgers: Ledger[] = [];

  @Output('nextForm') nextFormEmitter = new EventEmitter();
  @Output('previousForm') previousFormEmitter = new EventEmitter();

  formFloor = new Floor();
  floorFormErrors: ValidationErrors<{ [key in keyof (Floor & FloorRelationValidation)]?: string }> = {};
  financialYears: number[] = []
  yearsWithPayments: number[] = [];
  financialYearsWithOutPayments: number[] = [];
  private destroy$ = new Subject();
  private propertyTypesAll: PropertyType[] = [];
  private propertySubTypeAll: PropertySubType[] = [];

  readonly STARTING_YEAR = 2007;
  readonly Property = Property;
  readonly FLOOR_FORM_ADD_MODE = 1;
  readonly FLOOR_FORM_EDIT_MODE = 2;
  readonly GROUND_FLOOR = 0;

  readonly FLOOR_NAMES = Floor.FLOOR_NUMBER;
  readonly PROPERTY_CATEGORIES = Property.PROPERTY_CATEGORIES;
  readonly PROPERTY_USAGES = Property.PROPERTY_USAGES;
  readonly FORMATTED_FLOOR_NUMBERS = Floor.formattedFloorNumber();

  readonly FORMATTED_PROPERTY_USAGES = Property.formattedPropertyUsages();
  readonly FORMATTED_PROPERTY_CATEGORIES = Property.formattedPropertyCategories();
  readonly FORMATTED_PROPERTY_CATEGORIES_WITHOUT_MIX = Property.formattedPropertyCategoriesWithoutMix();
  readonly FORMATTED_PROPERTY_CONSTRUCTION_TYPES = Property.formattedConstructionTypes();
  readonly MIX_CATEGORY = PropertyCategories.Mix;
  readonly VACANT_CATEGORY = PropertyCategories.Vacant;
  readonly RESIDENTAL_CATEGORY = PropertyCategories.Residential;

  public floorFormMode = this.FLOOR_FORM_ADD_MODE;
  private typesError!: boolean;
  private carpetError!: boolean;
  carpetAreaNeededProperty!: boolean;
  propertyTypeData: PropertyType[] = [];
  propertySubTypeData: PropertySubType[] = [];
  disableCarpetArea: boolean = false;
  disableFloorNumber: boolean = false;

  constructor(
    private modalService: NgbModal,
    public propertyTypeService: PropertyTypeService,
    public propertySubTypeService: PropertySubTypeService,
    public languageService : LanguageService,
    private commonService : CommonService
  ) {
  }

  ngOnInit(): void {
    this.getPropertyTypeData();
    this.getPropertySubTypeData();
    
    this.yearsWithPayments = uniq(
      this.ledgers
        .filter((l) => l.transaction_type === Ledger.PAYMENT_TYPE)
        .map((l) => l.financial_year)
    );

    // this.checkPropertyCategory()

    this.financialYears = getFinancialYears(this.STARTING_YEAR, currentFinancialYear());
    if(this.property.plot_allotment_year !==null && this.property.plot_allotment_year > 2007) {
      this.financialYears = this.financialYears.filter(year => year >= this.property.plot_allotment_year);
    }
    this.financialYearsWithOutPayments = this.financialYears.filter((year) => !this.yearsWithPayments.includes(year));
    if (this.oldServiceProperty) {
      this.property.floors.forEach((f) => {
        f.from_year = '2021';
        f.upto_year = currentFinancialYear() + '';
      })
    }
  }

  nextForm() {
    this.validatePropertyTypes()

    if (this.typesError) {
      this.commonService.showErrorMessage('home.set_property_types_and_property_sub_types_for_all_floors');
      return;
    }

    if (this.countOfCYGroundFloor() < 1) {
      this.commonService.showErrorMessage('home.at_least_one_ground_floor_is_required_for_current_year');
      return;
    }

    let currentYear =currentFinancialYear().toString() ;
    let countOfCurrentYearFloor = this.property.floors?.findIndex((f) => f.upto_year == currentYear);
    if (countOfCurrentYearFloor < 0) {
      this.commonService.showErrorMessage('home.at_least_one_floor_upto_year_should_be_in_current_year');
      return;
    }

    // @ts-ignore
    if (isNaN(parseInt(this.property.floor_count))) {
      this.commonService.showErrorMessage('home.floor_count_required_field_and_must_be_number');
     
      return;
    }

    
   // this.calculateVacantArea();
    this.setMainPropertyCategoryData();
    this.nextFormEmitter.emit();
  }

  setMainPropertyCategoryData(){
    //Check if mix use
    const currentYear = currentFinancialYear();
   
    //Update if current year floor category is different previous year
    if(!this.isMixUse()){
      var currentYearFloors=this.property.floors?.filter((f) => +f.upto_year >= currentYear);
      if(this.isCategoryChanged(this.property,currentYearFloors[0])){
        this.property.property_category=currentYearFloors[0].property_category;
        this.setPropertyTypeAndSubtype(this.property,currentYearFloors[0].propertyType?.id,currentYearFloors[0].propertySubType?.id)
      }
    }else{
      if(this.property.property_category!==PropertyCategories.Mix){
        this.property.property_category=PropertyCategories.Mix;
        this.setPropertyTypeAndSubtype(this.property,133,138);
      }
    }
  }

  setPropertyTypeAndSubtype(property:Property,typeId:number,subTypeID:number){
      this.property.propertyType=this.propertyTypesAll.find((el:PropertyType) => el.id === typeId) as PropertyType;
      this.property.propertySubType=this.propertySubTypeAll.find((el: PropertySubType) => el.id === subTypeID) as PropertySubType;
      this.property.property_type_id=this.property.propertyType?.id
      this.property.property_sub_type_id=this.property.propertySubType?.id
  }

  isCategoryChanged(property:Property,currentYearFloor:Floor){
    if(property.property_type_id !== currentYearFloor.propertyType?.id || property.property_sub_type_id !== currentYearFloor.propertySubType?.id 
        || property.property_category !== currentYearFloor.property_category){
      return true;
    }
    return false;
  }

  isMixUse(){
    const currentYear = currentFinancialYear();
    const allDuplcates = new Set(this.property.floors?.filter((f) => +f.upto_year >= currentYear).map(v => v.propertySubType?.id));
    if (allDuplcates.size === 1) {
      return false;
    }
    return true;
  }

  previousForm() {
    this.previousFormEmitter.emit();
  }

  private isVacantCategorySelected() {
    return this.property.floors.some((el: Floor) => el.property_category === PropertyCategories.Vacant);
  }

  addFloor(modal: any) {
    this.floorFormMode = this.FLOOR_FORM_ADD_MODE;
    this.formFloor = new Floor();
    this.formFloor.propertyType = undefined as any;
    this.formFloor.propertySubType = undefined as any;
    this.disableFloorNumber = false;
    this.disableCarpetArea = false;
    this.openFloorFormModal(modal);
  }

  editFloor(modal: any, floor: Floor) { 
    this.floorFormMode = this.FLOOR_FORM_EDIT_MODE;
    this.formFloor = Object.assign(new Floor(), cloneDeep(floor));
    this.getPropertyTypes(this.formFloor.property_category);
    this.getPropertySubtype(this.formFloor.propertyType);
    this.formFloor.propertyType = this.propertyTypeData.find((el:PropertyType) => floor?.propertyType?.id === el.id) as PropertyType;
    this.formFloor.propertySubType = this.propertySubTypeData.find((el: PropertySubType) => floor?.propertySubType?.id === el.id) as PropertySubType;
    if(this.formFloor.property_category === PropertyCategories.Vacant) {
      this.selectDefaultsForVacantCategory(true);
    }else {
      this.selectDefaultsForVacantCategory(false);
    }
    this.openFloorFormModal(modal);
  }

  deleteFloor(index: number) {
    if (!this?.property?.floors || this.property.floors.length === 0) return;

    const deleteFloorNumber = this.property.floors[index].floor_number;

    if (deleteFloorNumber === this.GROUND_FLOOR && this.totalGroundFloor() <= 1) {
      this.commonService.showErrorMessage('home.atleast_one_ground_floor_is_required');
      return;
    }

    this.property.floors = this.property.floors.filter((v, i) => i !== index);
  }

  floorSubmit(modal: any) {
    const validated = this.validateFloor(this.formFloor);

    this.floorFormErrors = validated.errors;
    if(this.isCarpetAreaMoreThanPlotArea(this.formFloor.floor_number)) {
      Swal.fire({
        title:  this.languageService.translate('home.error'),
        text : this.languageService.translate('home.total_carpet_area_can_not_exceed_plot_area_for') +' ' +Floor.getFloorName(this.formFloor.floor_number),
        icon :'error',
        confirmButtonText: this.languageService.translate('home.ok'),
      });
      return;
    }

    const vacantPlotFound= this.property.floors.some(el => (el.property_category==PropertyCategories.Vacant || this.formFloor.property_category==PropertyCategories.Vacant)&& el.id!==this.formFloor.id && ((this.formFloor.from_year >= el.from_year && this.formFloor.from_year <= el.upto_year) || (this.formFloor.upto_year>=el.from_year && this.formFloor.upto_year<=el.upto_year)));
    if(vacantPlotFound){
      this.commonService.showErrorMessage('home.vacant_plot_exists_between_selected_year_range');
       return;
    }

    if (!validated.isSuccess || this.carpetError) return;

    modal.dismiss();

    if (this.floorFormMode === this.FLOOR_FORM_ADD_MODE) {
      this.formFloor['temp_id'] = this.property.floors.length + 1;
      this.property.floors.push(
        this.formFloor
      );
      return;
    }

    const editingFloorIndex = this.property.floors.findIndex((el) => {
      if(el.temp_id) {
        return el.temp_id === this.formFloor.temp_id;
      }else {
        return el.id === this.formFloor.id;
      }
    });

    if (editingFloorIndex === -1) return;

    this.property.floors[editingFloorIndex] = this.formFloor;
  }

  toInt(s: string) {
    return parseInt(s);
  }

  compareFinancialYear(a: any, b: any) {
    return parseInt(a) === parseInt(b);
  }

  private selectDefaultsForVacantCategory(value: boolean) {
    this.disableCarpetArea = value;
    this.disableFloorNumber = value;
    if(value) {
      this.formFloor.carpet_area = 0;
      this.formFloor.floor_number = this.GROUND_FLOOR;
    }
  }

  /* Setters */
  setPropertyCategory(val: number | undefined) {
    if (val === this.formFloor.property_category) return;
    this.getPropertyTypes(val);
    if(!val) this.propertyTypeData = [];

    this.formFloor.property_category = val as number;
    this.setPropertyType(undefined);

    if(val === PropertyCategories.Vacant) {
      this.selectDefaultsForVacantCategory(true);
    }else {
      this.selectDefaultsForVacantCategory(false);
    }
  }

  setPropertyType(val: PropertyType | undefined) {
    if(!val) this.propertySubTypeData = [];
    this.formFloor.propertyType = val as any;
    this.formFloor.propertySubType = undefined as any;
  }

  /* Where Conditions */
  propertyTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_CATEGORY, value: ${this.formFloor.property_category ?? 0}}
  ] }`);

  propertySubTypeWhere = (term: string | undefined) => (`where: { AND: [
    {column: NAME, operator: LIKE, value: "%${term}%"}
    {column: PROPERTY_TYPE_ID, value: ${this.formFloor.propertyType?.id ?? 0}}
  ] }`);

  private openFloorFormModal(modal: any) {
    if (this.property.property_usage_id !== Property.SELF_OCCUPIED_AND_RENTED) {
      this.formFloor.property_usage_id = this.property.property_usage_id;
    }

    this.modalService.open(modal, { scrollable: true, centered: true });
  }

  private countOfCYGroundFloor() {
    let currentYear = currentFinancialYear().toString() ;
    return this.property.floors?.filter((f) => f.upto_year >=currentYear && f.floor_number === this.GROUND_FLOOR)?.length ?? 0;
  }

  private totalGroundFloor(){
    return this.property.floors?.filter((f) =>f.floor_number === this.GROUND_FLOOR)?.length ?? 0;
  }

  private validateFloor(floor: Floor): Validated<Floor & FloorRelationValidation> {
    let rules = cloneDeep(FloorDetailsValidationRules);
    rules.carpet_area = 'required';

    const errors: { [key: string]: string } = {};
    const validated = Validator.make(floor, rules);
    const relationValidated = this.validateRelation(floor);

    return {
      isSuccess: !validated.fails() && relationValidated.isSuccess,
      errors: {
        ...getFormattedErrorsByValidator(validated.getErrors() ?? {}),
        ...relationValidated.errors,
      }
    };
  }

  private validateRelation(floor: Floor): Validated<FloorRelationValidation> {
    const errors: FloorRelationValidation = {}
    const message = 'This field is required.';

    if (!this.formFloor.propertyType?.id) errors.property_type = message;
    if (!this.formFloor.propertySubType?.id) errors.property_sub_type = message;

    return {
      isSuccess: Object.getOwnPropertyNames(errors).length === 0,
      errors
    }
  }

  private normalizeFloorForNonMixProperty(floor: Floor) {
    floor.property_category = this.property.property_category;
    floor.propertyType = cloneDeep(this.property.propertyType);
    floor.propertySubType = cloneDeep(this.property.propertySubType);

    return floor;
  }

  validatePropertyTypes = () => {
    this.typesError = false
    this.property.floors.forEach((floor) => {
      if (!floor.propertyType || !floor.propertySubType) {
        this.typesError = true
        return
      };
    })
  };

  checkCarpetAreaSize() {
    this.carpetError = false
    let floorCarpetArea = this.formFloor.carpet_area ?? 0
    this.carpetError = this.property.plot_area < floorCarpetArea
  }

  getPropertyTypes(category: any): void {
    let id = category;
    if(typeof category === 'object') id = category?.id;
    if(this.propertyTypesAll.length && id) {
      this.propertyTypeData = this.propertyTypesAll.filter((el: PropertyType) => el.property_category === id)
    }
  }

  getPropertySubtype(propertyType: PropertyType) {
    this.setPropertyType(propertyType);
    if(this.propertySubTypeAll.length && propertyType?.id) {
      this.propertySubTypeData = this.propertySubTypeAll.filter((el: PropertySubType) => el.propertyType.id === propertyType.id);
    }
    
  }

  private getPropertyTypeData() {
    this.propertyTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertyType[]) => {
      let filteredData = data.filter(x => x.form == true);
      this.propertyTypesAll = filteredData;
    })
  }
  

  private getPropertySubTypeData() {
    this.propertySubTypeService.getAll()
    .pipe(
      takeUntil(this.destroy$)
    ).subscribe((data:PropertySubType[]) => {
      let filteredData = data.filter(x => x.form == true);
      this.propertySubTypeAll = filteredData;
    })
  }

  private isSameRecord(isAdd:boolean, el:Floor, newFloorData: Floor) {
    if(isAdd) return false;
    if((el.temp_id && newFloorData.temp_id && (el.temp_id === newFloorData.temp_id)) || (el.id && newFloorData.id && (el.id === newFloorData.id))){
      return true;
    }
    return false;
  }

  private isCarpetAreaMoreThanPlotArea(foorNumber: number):boolean {
    const currentYear = currentFinancialYear();
    let totalCarpetArea = 0;
    this.property.floors?.forEach((el: Floor) => {
      if(el.floor_number === foorNumber && +el.from_year <= currentYear && +el.upto_year >= currentYear && !this.isSameRecord(this.floorFormMode === this.FLOOR_FORM_ADD_MODE, el, this.formFloor)) {
        totalCarpetArea += +el.carpet_area
      }
    })

    if(+this.formFloor.from_year<=currentYear && +this.formFloor.upto_year >=currentYear){
      totalCarpetArea += +this.formFloor.carpet_area;
    }
    
    return totalCarpetArea / 9 > this.property.plot_area;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}

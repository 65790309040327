<div class="table-hold tax-due-table" id="tax-due" *ngIf="loading === false && taxDetails; else loadingBlock">
  <table class="table table-bordered th-bg">
    <thead>
    <tr>
      <th colspan="8">
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div>{{'property_search.tax_due_summary' | translate}}</div>
        </div>
      </th>
    </tr>

    <tr>
      <th width="15%">{{'property_search.total_tax_before_current_year' | translate}}</th>
      <th width="10%">{{'property_search.tax_amount_current_year' | translate}}</th>
      <th width="10%" *ngIf="taxDetails?.needShowPenalty()">{{'property_search.penalty' | translate}}</th>
      <th width="10%" *ngFor="let item of taxDetails?.availableRebates; let i = index">
        {{'property_search.rebate' | translate}} {{'(' + item.remarks + ')'}}
      </th>
      <th width="10%" *ngIf="taxDetails?.paidAmount()">{{'property_search.amount_paid' | translate}}</th>
      <th width="15%" class="total-amount">{{'property_search.total_amount_due' | translate}}</th>
    </tr>
    </thead>
    <tbody>
    <tr>
    <tr>
      <td data-th="Total Tax before current year">
        {{taxDetails?.propertyDebtInPastYear}}
      </td>
      <td data-th="Tax Amount (Current Year)">
        {{taxDetails?.taxInCurrentYear}}
      </td>
      <td data-th="Penalty" *ngIf="taxDetails?.needShowPenalty()">
        {{taxDetails?.totalPenaltyAmount}}
      </td>
      <td data-th="Rebate " *ngFor="let rebate of taxDetails.availableRebates; let i = index">
        {{ rebate.credit_amount }}
      </td>
      <td data-th="Amount Paid" *ngIf="taxDetails && taxDetails.paidAmount() > 0">
        {{taxDetails?.paidAmount()}}
      </td>
      <td data-th="Total Amount Due">{{ taxDetails?.debtWithAllRebates() | number:'1.2'}}</td>
    </tr>
    </tbody>

    <tfoot>
    <td colspan="8">
      <div class="total-section">
        <div class="tax-notes">
          <h6>{{'property_search.note' | translate}}: <span *ngIf="cs.city === 'jodhpur'"  style="color:red;font-weight:bold">{{'property_search.payment_maintenance' | translate}}</span></h6>
        </div>
        <div class="total-table">
          <div colspan="2" *ngIf="taxDetails && taxDetails.propertyDebt > 0">
              <button
              class="paynowbtn btn btn-primary"
              [routerLink]="'/payment/' + property.id" *ngIf="cs.city !== 'kotputli' && cs.city !== 'jodhpur'">
              {{'property_search.pay_now' | translate}} <i class="bi bi-arrow-right-short"></i>
            </button>
          </div>
        </div>
      </div>
    </td>
    </tfoot>
  </table>
</div>

<ng-template #loadingBlock>
  <div class="table-hold tax-due-table loading-container" id="tax-due">
    <table class="table table-bordered th-bg">
      <thead>
      <tr>
        <th colspan="6">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <div>Tax Due Summary</div>
            <div class="print-icon print-icon_header-table">
            </div>
          </div>
        </th>
      </tr>
      </thead>
    </table>

    <div class="loading-container-spinner-block">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</ng-template>

import {Injectable} from '@angular/core';
import TaxPayRequest from '../@types/tax-pay-request';
import {gql} from '@apollo/client/core';
import {map} from 'rxjs/operators';
import {Apollo} from 'apollo-angular';

@Injectable({
  providedIn: 'root',
})
export class PaytmService {

  constructor(
    private apollo: Apollo,
  ) {
  }

  initiateTransaction(info: TaxPayRequest) {
    return this.apollo.mutate({
      mutation: gql`mutation{ InitiatePaytmPayment(
        first_name: "${info.firstName}",
        last_name: "${info.lastName}",
        email: "${info.email}",
        phone: "${info.phoneNumber}",
        property_id: ${info.propertyId}
        amount: ${info.amount},
      )
      }`,
    }).pipe(
      map((res: any) => res?.data?.InitiatePaytmPayment),
    );
  }

  async startPayment({orderId, token, amount}: { orderId: string, token: string, amount: number }) {
    const config = {
      'root': '',
      'flow': 'DEFAULT',
      'data': {
        'orderId': orderId,
        'token': token,
        'tokenType': 'TXN_TOKEN',
        'amount': amount,
      },
      'handler': {
        'notifyMerchant': function (eventName: any, data: any) {
        },
      },
    };

    // @ts-ignore
    if (window.Paytm && window.Paytm.CheckoutJS) {
      // @ts-ignore
      const init = await window.Paytm.CheckoutJS.init(config).catch((error: any) => {
       // console.log('error => ', error);
      });

      // @ts-ignore
      window.Paytm.CheckoutJS.invoke();
    }
  }
}

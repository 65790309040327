import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {PropertyService} from '../../../services/property.service';
import {Property, User} from '../../../models';
import Swal from 'sweetalert2';
import {cloneDeep} from 'lodash';
import {AuthenticationService} from '../../../services';
import {ReplaySubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PropertyCorrectionRequest} from '../../../@types/property-correction';
import {ObjectionService} from '../../../services/objection.service';
import {Objection} from '../../../models/objection';
import {currentFinancialYear} from '../../../helpers/current-financial-year';
import { LanguageService } from 'src/app/services/language.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, OnDestroy {

  loading = true;
  isSubmitting = false;

  user!: User;
  property!: Property;

  private propertyId!: number;
  private destroy$ = new ReplaySubject();

  constructor(
    private router: Router,
    private currentRoute: ActivatedRoute,
    private propertyService: PropertyService,
    private authService: AuthenticationService,
    private objectionService: ObjectionService,
    private languageService: LanguageService,
    private commonService: CommonService
  ) {
  }

  ngOnInit(): void {
    if (!this.authService.user) {
      this.redirectPopUp('/property-search', 'User not auth!');
      return;
    }

    this.user = this.authService.user;
    this.initProperty();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  submit(propertyCorrectionRequest: PropertyCorrectionRequest) {
    if (this.isSubmitting) return;

    if (!this.isChangesExists(propertyCorrectionRequest)) {
      this.commonService.showErrorMessage('home.changes_do_not_exist');
      return;
    }

    this.isSubmitting = true;

    this.objectionService.createObjection(this.propertyId, propertyCorrectionRequest)
      .pipe(takeUntil(this.destroy$))
      .subscribe(this.successSubmitResponse, this.failedSubmitResponse);
  }

  private initProperty() {
    this.propertyId = this.currentRoute.snapshot?.params?.propertyId;

    if (!this.propertyId) {
      this.redirectPopUp('/property-search', 'Invalid Property!');
      return;
    }
    this.propertyService.getOneById(this.propertyId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((property) => {
        if (property?.has_objection) {
          this.redirectPopUp('/property/' + this.propertyId, 'There is an unfinished objection!');
          return;
        }

        if (property?.source !== 'new_survey') {
          this.redirectPopUp('/property/' + this.propertyId, 'Correction requests are accepted only for a new survey property!');
          return;
        }

        this.property = Object.assign(new Property(), cloneDeep(property));
        this.loading = false;
      });
  }

  private successSubmitResponse = (objection: Objection | undefined) => {
    this.isSubmitting = false;

    if (!objection) {
      return this.commonService.showErrorMessage('home.something_went_wrong');
    }

    return Swal.fire({
      title: this.languageService.translate('patta_application.validation_msg.success_text'),
      html: 'You objection number is <strong>' + objection.objection_no + '</strong>',
      icon: 'success',
      confirmButtonText: this.languageService.translate('home.ok')
    }).then(() => location.replace('property/' + this.propertyId));
  };

  private failedSubmitResponse = () => {
    this.isSubmitting = false;
    return this.commonService.showErrorMessage('home.something_went_wrong');
  };

  private async redirectPopUp(url: string, message: string) {
    await Swal.fire({
      title: this.languageService.translate('home.error'),
      html: message,
      icon: 'error',
      confirmButtonText: this.languageService.translate('home.ok')
    }).then(() => this.router.navigate([url]));
  }

  private isChangesExists(propertyCorrectionRequest: PropertyCorrectionRequest) {
    return propertyCorrectionRequest.propertyChanges.length > 0 || propertyCorrectionRequest.floorsChanges.new.length > 0
      || propertyCorrectionRequest.floorsChanges.deleted.length > 0 || propertyCorrectionRequest.floorsChanges.fieldChanges.length > 0
      || propertyCorrectionRequest.ownersChanges.new.length > 0 || propertyCorrectionRequest.ownersChanges.deleted.length > 0
      || propertyCorrectionRequest.ownersChanges.fieldChanges.length > 0;
  }
}

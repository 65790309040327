import {Property} from '../../../models';

const toBoolean = (v: any) => {
  const type = typeof v;

  if (type === 'boolean') return v;

  if (type === 'number' && (v === 0 || v === 1)) return v === 1;

  if (type === 'string') {
    const lowerCase = v.toLowerCase();

    return lowerCase === 'false' || lowerCase === 'true' ? lowerCase === 'true' : null;
  }

  return null;
};

const yesNoBoolean = (v: any) => {
  const bool = toBoolean(v);

  return bool === null ? '' : (bool ? 'Yes' : 'No');
};

export const PROPERTY_FIELD_RESOLVERS: any = {
  // From Enum
  property_category: (v: any) => Property.PROPERTY_CATEGORIES[v] ?? '',

  property_usage_id: (v: any) => Property.PROPERTY_USAGES[v] ?? '',

  construction_type_id: (v: any) => Property.CONSTRUCTION_TYPES[v] ?? '',

  building_permission_use_type: (v: any) => Property.PROPERTY_CATEGORIES[v] ?? '',

  water_connection_number: (v: any) => Property.WATER_SUPPLY_SOURCES[v] ?? '',

  // @ts-ignore
  road_type_id: (v: any) => Property.ROAD_TYPES[v] ?? '',

  // @ts-ignore
  mobile_tower_placement: (v: any) => Property.TOWER_LOCATIONS[v] ?? '',

  // boolean
  landuse_change: (v: any) => yesNoBoolean(v),

  sub_divided_plots: (v: any) => yesNoBoolean(v),

  building_permission: (v: any) => yesNoBoolean(v),

  water_connection: (v: any) => yesNoBoolean(v),

  electricity_connection: (v: any) => yesNoBoolean(v),

  free_hold_patta: (v: any) => yesNoBoolean(v),

  one_time_deposited: (v: any) => yesNoBoolean(v),

  lease_deed_patta: (v: any) => yesNoBoolean(v),

  advertisement_on_building: (v: any) => yesNoBoolean(v),

  sewerage_line: (v: any) => yesNoBoolean(v),

  septic_tank: (v: any) => yesNoBoolean(v),

  compost_pit: (v: any) => yesNoBoolean(v),

  solar_plant_or_unit: (v: any) => yesNoBoolean(v),

  rainwater_harvesting: (v: any) => yesNoBoolean(v),

  toilet: (v: any) => yesNoBoolean(v),

  parking: (v: any) => yesNoBoolean(v),

  street_light: (v: any) => yesNoBoolean(v),

  fire_fighting_system: (v: any) => yesNoBoolean(v),

  mobile_tower: (v: any) => yesNoBoolean(v),

  multi: (v: any) => yesNoBoolean(v),
};

export const PROPERTY_FIELD_NAME_RESOLVERS: { [key in keyof Property]?: string } = {
  property_name: 'Property Name',
  property_uid: 'Property UID',
  address_house_number: 'House Number',
  nearby_houseno: 'Near-by House Number',
  road_name: 'Street/Road Name',
  landmark: 'Landmark',
  pincode: 'Pincode',
  property_category: 'Property Category',
  landuse_change: 'Land Use Change',
  sub_divided_plots: 'Sub Divided Plots',
  property_usage_id: 'Property Usage',
  construction_type_id: 'Construction Type',
  business_type_id: 'Business Type',
  building_permission: 'Building permission',
  building_permission_year: 'Year of building permission',
  building_permission_num_floor: 'Building permission for number of floor',
  building_permission_use_type: 'Type of use for building permission',
  building_construction_year: 'Approximate year of building construction',
  plot_allotment_year:'Plot Allotment Year',
  length: 'Length',
  breadth: 'Breadth',
  plot_area: 'Plot Area (In sq yd)',
  vacant_area: 'Vacant Area (In sq yd)',
  water_connection: 'Water Supply Source',
  water_connection_number: 'Water Meter',
  electricity_connection: 'Electricity connection',
  electricity_connection_number: 'Electricity connection',
  free_hold_patta: 'Free Hold Patta',
  one_time_deposited: 'One Time Deposited',
  lease_deed_patta: 'Lease Deed Patta',
  advertisement_on_building: 'Advertisement on building',
  sewerage_line: 'Sewerage',
  septic_tank: 'Septic Tank',
  compost_pit: 'Compost Pit',
  solar_plant_or_unit: 'Solar Plant/Unit',
  rainwater_harvesting: 'Rain Water Harvesting',
  toilet: 'Toilet',
  parking: 'Parking',
  name_of_shop: 'Name Of The Firm',
  establishment_year: 'Establishment Year',
  items_traded: 'Items Traded',
  occupation_year: 'Occupation Year',
  license_number: 'License Number',
  license_date: 'License Date',
  road_type_id: 'Road Type',
  road_width: 'Road Width',
  street_light: 'Street Light',
  fire_fighting_system: 'Fire Fighting System',
  mobile_tower: 'Mobile Towers',
  mobile_tower_placement: 'Tower Location',
  ward: 'Ward',
  sector: 'Sector',
  colony_id: 'Colony',
  multi: 'Multi',
  propertyType: 'Property Type',
  propertySubType: 'Property Sub Type',
  sector_id: 'Sector',
  property_type_id: 'Property Type',
  property_sub_type_id: 'Property Sub Type',
  old_house_tax_no: 'Old House Tax No',
}

export class Citizen {
  static readonly MALE = true;
  static readonly FEMALE = false;

  static readonly RELATION: { [key: number]: string } = {
    1: 'Self',
    2: 'Father',
    3: 'Husband',
    4: 'W/O',
    5: 'S/O',
    6: 'D/O'
  };

  static readonly PROFESSION: { [key: number]: string } = {
    1: 'Service',
    2: 'Profession',
    3: 'Housewife',
    4: 'Other',
  }
  temp_id!: number;
  id!: number;
  first_name!: string;
  last_name!: string;
  type!: string;
  birth_date!: Date;
  relation!: number;
  relation_name!: string;
  phone_number_na_reason!: string;
  ownership_share!: number;
  uid_number!: string;
  occupation!: string;
  phone_number!: string;
  email!: string;
  profession!: number;
  created_at: Date = new Date();
  updated_at: Date = new Date();
  name!: string;
  gender!: number;
  document_proof_type_id!: number;
  bpl_card!: boolean;
  bpl_no!: string;

  static formattedRelations() {
    return Object.entries(Citizen.RELATION)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  static formattedProfessions() {
    return Object.entries(Citizen.PROFESSION)
      .map((entity) => ({id: Number.parseInt(entity[0]), name: entity[1]}));
  }

  get fullName() {
    return (this.first_name ?? '') + ' ' + (this.last_name ?? '')
  }
}
